import React, { useState, useEffect } from "react";
import { Button, message as antdMessage, Tooltip } from "antd";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import "./announcement.scss"; // Ensure to import your CSS file
import { useNavigate } from "react-router-dom";

function Announcement() {
  const [announcements, setAnnouncements] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  const fetchAnnouncements = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/Announcement/Get-Announcement`);
      setAnnouncements(response.data);
    } catch (error) {
      antdMessage.error("An error occurred while fetching announcements: " + error.message);
    }
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const handleViewAnnouncement = (ann) => {
    navigate(`/employeedashboard/emp-announcement-detail/${ann.announcementId}`, {
      state: { announcement: ann },
    });
  };

  return (
    <div className="card" style={{ height: "100%" }}>
      <div className="card-header" style={{ position: "relative" }}>
        <div className="col-md-12">
          <h5>Announcement</h5>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="announcement-list">
            {announcements.length > 0 ? (
              announcements.map((ann) => (
                <div key={ann.announcementId} className="announcement-container">
                  <FontAwesomeIcon icon={faInfoCircle} className="frame-icon" />
                  <div className="announcement-content">
                    <p className="announcement-text">
                      <Tooltip title={ann.announcement} trigger="hover">
                        {truncateText(ann.announcement, 50)}
                      </Tooltip>
                    </p>
                    <p className="message-text">
                      <Tooltip title={ann.message} trigger="hover">
                        {truncateText(ann.message, 45)}
                      </Tooltip>
                    </p>
                  </div>
                  <Button
                    className="complete-button"
                    color="primary"
                    onClick={() => handleViewAnnouncement(ann)}
                  >
                    View Announcement
                  </Button>
                </div>
              ))
            ) : (
              <span style={{ fontSize: "medium" }}>No announcement added</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Announcement;
