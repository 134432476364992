import React, { useState, useMemo, useEffect } from "react";
import DashboardLayout from "../LayoutNavbar/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../LayoutNavbar/Navbars/DashboardNavbar";
import DatePicker from "react-datepicker";
import "./Job.scss";
import { Table } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import SoftButton from "components/SoftButton";
import ProfileCard from "./ProfileCard";
import axios from "axios";
import InitiateAccountability from "./InitiateAccountability";
import Resignation from "./Resignation";
import PerformanceFactor from "./PerformanceFactor";
import DynamicOrganization from "./DynamicOrganization";
import { useLocation, useNavigate } from "react-router-dom";
import AvailableTraining from "./AvailableTraining";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Job() {
  const [showAdd, setShowAdd] = useState(true);
  const [showInitiate, setShowInitiate] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate=useNavigate();
  const [selectedEmail, setSelectedEmail] = useState({ email: "", personalId: "" });

  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();
  const employeeDetail = location.state?.employeeDetail || null;

  const handleNextPage = () => {
    setCurrentPage((prevPage) => (prevPage === 4 ? 1 : prevPage + 1));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage === 1 ? 4 : prevPage - 1));
  };

  useEffect(() => {
    fetchEmployeesByEmail();
    fetchEmployee();
  }, []);
  const [employeeDetails, setEmployeeDetails] = useState([]);

  async function fetchEmployeesByEmail() {
    try {
      const loginEmail = localStorage.getItem("loginEmail");
      if (loginEmail) {
        const response = await axios.get(
          `${apiUrl}/api/Job/GetEmployee-JobDataby-Email/${loginEmail}`
        );
        const responseData = response.data;

        if (Array.isArray(responseData)) {
          setEmployeeDetails(responseData);
          if (responseData.length > 0) {
            const managerName = responseData[0].manager; // Assuming manager name is in the first employee data
            fetchEmployee(managerName);
          }
        } else {
          setEmployeeDetails([responseData]);
          if (responseData.manager) {
            fetchEmployee(responseData.manager);
          }
        }
      } else {
        console.error("User email not found in local storage");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const handleAddClick = () => {
    setShowAdd(false);
  };

  const handleInitiateClick = (email, personalId) => {
    setShowInitiate(false);
    setSelectedEmail({ email, personalId });
  };

  const columnsSR = [
    {
      title: "Date of Leaving",
      dataIndex: "DateofLeaving",
      key: "DateofLeaving",
    },
    {
      title: "Reason of Leaving",
      dataIndex: "ReasonofLeaving",
      key: "ReasonofLeaving",
    },
    {
      title: "Notice Period",
      dataIndex: "NoticePeriod",
      key: "NoticePeriod",
    },
    {
      title: "Resignation Date",
      dataIndex: "ResignationDate",
      key: "ResignationDate",
    },
    {
      title: "Approval",
      dataIndex: "Approval",
      key: "Approval",
    },
  ];
  const column = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
    },
    {
      title: "Job Title",
      dataIndex: "JobTitle",
      key: "JobTitle",
    },
    {
      title: "Department",
      dataIndex: "Team",
      key: "Team",
    },
    {
      title: "Effective Date",
      dataIndex: "EffectiveDate",
      key: "EffectiveDate",
    },
    {
      title: "Review",
      dataIndex: "Review",
      key: "Review",
    },
  ];

  const [dataSource, setDataSource] = useState([]);

  function formatDateTime(dateTimeString) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date(dateTimeString);
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutes = date.getMinutes();
    return `${month} ${day < 10 ? "0" : ""}${day}, ${year} ${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${ampm}`;
  }

  async function fetchEmployee() {
    try {
      const loginEmail = localStorage.getItem("loginEmail");
      if (loginEmail) {
        const response = await axios.get(
          `${apiUrl}/api/Job/GetEmployee-JobDataby-Email/${loginEmail}`
        );
        const responseData = response.data;
        const managerName =
          responseData.personalInformation.firstName +
          " " +
          responseData.personalInformation.lastName;

        const employeesResponse = await axios.get(
          `${apiUrl}/api/Job/GetEmployees-JobDataby-Manager/${managerName}`
        );
        const employeesData = employeesResponse.data;

        if (Array.isArray(employeesData) && employeesData.length > 0) {
          // Filter employees whose manager is the logged-in user
          const filteredEmployees = employeesData.filter(
            (employee) => employee.manager === managerName
          );

          if (filteredEmployees.length > 0) {
            // If manager's employees are found, map the response data to match the datasource format
            const mappedData = filteredEmployees.map((employee, index) => ({
              key: index + 1,
              PersonalId: employee.personalInformation.personalId,
              Name: `${employee.personalInformation.firstName} ${employee.personalInformation.lastName}`,
              JobTitle: employee.forJobApply,
              Team: employee.team,
              EffectiveDate: employee.hiringDate,
              Email: employee.personalInformation.email,
              Review: (
                <SoftButton
                onClick={() =>
                  handleInitiateClick(
                    employee.personalInformation.email,
                    employee.personalInformation.personalId
                  )
                }
                variant={employee.personalInformation.status === 2 ? "contained" : "outlined"}
                color={employee.personalInformation.status === 2 ? "success" : "info"}
                size="small"
                disabled={employee.personalInformation.status === 0 || employee.personalInformation.status === 2}
              >
                {employee.personalInformation.status === 2 ? "Initiated" : "Initiate"}
              </SoftButton>
              
              ),
            }));

            setDataSource(mappedData);
          } else {
            // If no employee data found, set data source to an empty array and display a message
            console.error("No employee data found");
            setDataSource([]);
          }
        } else {
          console.error("No employee data found");
          setDataSource([]);
        }
      } else {
        console.error("User email not found in local storage");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <ToastContainer />
        <div className="body flex-grow-1 px-3">
          {showAdd && showInitiate && (
            <div className="card">
              <div className="card-header" style={{ position: "relative" }}>
                <h5>Job</h5>
              </div>
              <div className="card-body">
                {employeeDetails.map((employee) => (
                  <>
                    <div className="row">
                      <label className="mb-3" style={{ textAlign: "left" }}>
                        <i className="fa fa-suitcase" aria-hidden="true"></i>&nbsp;
                        {employee.forJobApply}
                      </label>
                      <div className="col-md-9 mb-3">
                        <div className="form-group">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                            style={{ fontSize: "medium" }}
                          >
                            Hiring Date
                          </label>
                          <input
                            type="text"
                            value={formatDateTime(employee.hiringDate)}
                            className="form-control"
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-9 mb-4">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInput"
                            className="form-label"
                            style={{ fontSize: "medium" }}
                          >
                            Department *
                          </label>
                          <input
                            type="text"
                            value={employee.team}
                            className="form-control"
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInput"
                            className="form-label"
                            style={{ fontSize: "medium" }}
                          >
                            Manager *
                          </label>
                          <div style={{ marginLeft: "-25px" }}>
                            <ProfileCard />
                          </div>
                        </div>
                      </div>
                      <label
                        htmlFor="exampleInput"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Direct Reports
                      </label>
                      <Table
                        className="table-responsive"
                        bordered
                        dataSource={dataSource}
                        columns={column}
                        pagination={{ pageSize: 10 }}
                      />
                      <div className="col-md-9 mb-3">
                        <div className="form-group">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                            style={{ fontSize: "medium" }}
                          >
                            Probation Start Date
                          </label>
                          <input
                            type="text"
                            value={formatDateTime(employee.probationStartDate)}
                            className="form-control"
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-9 mb-3">
                        <div className="form-group">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                            style={{ fontSize: "medium" }}
                          >
                            Probation End Date
                          </label>
                          <input
                            type="text"
                            value={formatDateTime(employee.probationEndDate)}
                            className="form-control"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ))}
                <hr></hr>
                <div className="row mb-3">
                  <div className="col-md-12">
                    <label className="mb-3" style={{ textAlign: "left" }}>
                      <i className="fa fa-arrow-circle-o-left" aria-hidden="true"></i>&nbsp;Submit
                      Resignation
                    </label>
                    <SoftButton
                      variant="outlined"
                      style={{ float: "inline-end" }}
                      color="info"
                      size="small"
                      onClick={handleAddClick}
                    >
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;Add Entry
                    </SoftButton>
                  </div>
                  <Table
                    className="table-responsive"
                    bordered
                    // dataSource={datasource}
                    columns={columnsSR}
                    pagination={{ pageSize: 10 }}
                  />
                </div>
              </div>
            </div>
          )}
          {!showAdd && <Resignation />}
          {/* {!showInitiate && <InitiateAccountability />} */}
          {!showInitiate && (
            <>
              {currentPage === 1 && (
                <InitiateAccountability
                  selectedEmail={selectedEmail}
                  employeeDetail={employeeDetail}
                  onNext={handleNextPage}
                />
              )}
              {currentPage === 2 && (
                <PerformanceFactor
                  selectedEmail={selectedEmail}
                  employeeDetail={employeeDetail}
                  onNext={handleNextPage}
                  onBack={handlePreviousPage}
                />
              )}
              {currentPage === 3 && (
                <DynamicOrganization
                  selectedEmail={selectedEmail}
                  employeeDetail={employeeDetail}
                  onNext={handleNextPage}
                  onBack={handlePreviousPage}
                />
              )}
              {currentPage === 4 && (
                <AvailableTraining
                  selectedEmail={selectedEmail}
                  onNext={handleNextPage}
                  onBack={handlePreviousPage}
                />
              )}
            </>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Job;
