import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "layouts/authentication/components/Footer";

export default function PerformanceManagementDetail() {
  const { email } = useParams();
  const [employeeData, setEmployeeData] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/InitiateAccountability/GetInitiateDataByEmail?email=${email}`
        );
        setEmployeeData(response.data);
      } catch (error) {
        console.error("Error fetching employee data:", error);
        toast.error("Error fetching employee data");
      }
    };

    fetchEmployeeData();
  }, [email, apiUrl]);

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <ToastContainer />
        <div className="body flex-grow-1 px-3">
          <div className="row">
            <div className="col-md-10">
              <div
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <h6
                  className="h6-trainingdetail"
                  style={{ marginLeft: "15px", color: "cadetblue" }}
                >
                  Performance Completed Detail
                </h6>
              </div>
              {employeeData ? (
                <>
                  {/* Display InitiateAccountabilities */}
                  <div className="card cardSet mb-3">
                    <div className="card-body">
                      <h6 style={{ fontSize: "medium" }}>Initiate Accountabilities</h6>
                      {employeeData.initiateAccountabilities.map((item, index) => (
                        <div key={index} className="mb-2">
                          <div className="row">
                            <div className="col">
                              <p className="h6-trainingdetail">
                                (1): Account Abilities One: {item.accountAbilities_one}
                              </p>
                              <p className="h6-trainingdetail">Weight One: {item.weight_one}</p>
                              <p className="h6-trainingdetail">Rating One: {item.rating_one}</p>
                            </div>
                            <div className="col">
                              <p className="h6-trainingdetail">
                                (2): Account Abilities Two: {item.accountAbilities_two}
                              </p>
                              <p className="h6-trainingdetail">Weight Two: {item.weight_two}</p>
                              <p className="h6-trainingdetail">Rating Two: {item.rating_two}</p>
                            </div>
                            <div className="col">
                              <p className="h6-trainingdetail">
                                (3): Account Abilities Three: {item.accountAbilities_three}
                              </p>
                              <p className="h6-trainingdetail">Weight Three: {item.weight_three}</p>
                              <p className="h6-trainingdetail">Rating Three: {item.rating_three}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <p className="h6-trainingdetail">
                                (4): Account Abilities Four: {item.accountAbilities_four}
                              </p>
                              <p className="h6-trainingdetail">Weight Four: {item.weight_four}</p>
                              <p className="h6-trainingdetail">Rating Four: {item.rating_four}</p>
                            </div>
                            <div className="col">
                              <p className="h6-trainingdetail">
                                (5): Account Abilities Five: {item.accountAbilities_five}
                              </p>
                              <p className="h6-trainingdetail">Weight Five: {item.weight_five}</p>
                              <p className="h6-trainingdetail">Rating Five: {item.rating_five}</p>
                            </div>
                            <div className="col">
                              <p className="h6-trainingdetail">
                                (6): Account Abilities Six: {item.accountAbilities_six}
                              </p>
                              <p className="h6-trainingdetail">Weight Six: {item.weight_six}</p>
                              <p className="h6-trainingdetail">Rating Six: {item.rating_six}</p>
                            </div>
                          </div>
                          {/* Add other fields as needed */}
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Display PerformanceFactors */}
                  <div className="card cardSet mb-3">
                    <div className="card-body">
                      <h6 style={{ fontSize: "medium" }}>Performance Factors</h6>
                      {employeeData.performanceFactors.map((item, index) => (
                        <div key={index} className="mb-2">
                          <p className="h6-trainingdetail">Job Knowledge: {item.jobKnowledge}</p>
                          <p className="h6-trainingdetail">Administration: {item.administration}</p>
                          <p className="h6-trainingdetail">
                            Decision Making: {item.decision_Making}
                          </p>
                          <p className="h6-trainingdetail">Communication: {item.communication}</p>
                          <p className="h6-trainingdetail">Initiative: {item.initiative}</p>
                          <p className="h6-trainingdetail">
                            Work Relationships: {item.work_Relationships}
                          </p>
                          <p className="h6-trainingdetail">Flexibility: {item.flexibility}</p>
                          <p className="h6-trainingdetail">
                            Overall Performance: {item.overall_Performance}
                          </p>
                          {/* Add other fields as needed */}
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Display DynamicOrganizations */}
                  <div className="card cardSet mb-3">
                    <div className="card-body">
                      <h6 style={{ fontSize: "medium" }}>Dynamic Organizations</h6>
                      {employeeData.dynamicOrganizations.map((item, index) => (
                        <div key={index} className="mb-2">
                          <p className="h6-trainingdetail">
                            Valuing Diversity: {item.valuing_Diversity}
                          </p>
                          <p className="h6-trainingdetail">
                            Overall Accountability Rating: {item.overall_Accountability_Rating}
                          </p>
                          <p className="h6-trainingdetail">
                            Overall Performance Rating: {item.overall_Performance_Rating}
                          </p>
                          <p className="h6-trainingdetail">
                            Overall DynamicOrganization Rating:{" "}
                            {item.overall_DynamicOrganization_Rating}
                          </p>
                          <p className="h6-trainingdetail">
                            Total Performance Rating: {item.total_performance_Rating}
                          </p>
                          <p className="h6-trainingdetail">
                            Associate Strengths: {item.associate_Strengths}
                          </p>
                          <p className="h6-trainingdetail">
                            Associate Development: {item.associate_Development}
                          </p>
                          <p className="h6-trainingdetail">Action Plan: {item.action_Plan}</p>
                          <p className="h6-trainingdetail">
                            Associate Comments: {item.associate_Comments}
                          </p>
                          <p className="h6-trainingdetail">Associate Name: {item.associate_Name}</p>
                          <p className="h6-trainingdetail">Submitted Date: {item.date}</p>
                          <p className="h6-trainingdetail">
                            Supervisor Name: {item.supervisor_Name}
                          </p>
                          {/* Add other fields as needed */}
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Display EnrolledTrainingEmployees */}
                  <div className="card cardSet mb-3">
                    <div className="card-body">
                      <h6 style={{ fontSize: "medium" }}>Enrolled Training Employees</h6>
                      {employeeData.enrolledTrainingEmployees.map((item, index) => (
                        <div key={index} className="mb-2">
                          <p className="h6-trainingdetail">Provider: {item.provider}</p>
                          <p className="h6-trainingdetail">Mentor: {item.mentor}</p>
                          <p className="h6-trainingdetail">
                            Allocated Budget: $-{item.allocated_budget}
                          </p>
                          <p className="h6-trainingdetail">Start Date: {item.startDate}</p>
                          <p className="h6-trainingdetail">End Date: {item.endDate}</p>
                          {/* Add other fields as needed */}
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <p className="h6-trainingdetail">Loading data...</p>
              )}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </DashboardLayout>
  );
}
