import React, { useState, useMemo, useEffect } from "react";
import DashboardLayout from "../LayoutNavbar/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../LayoutNavbar/Navbars/DashboardNavbar";
import "./Personal.scss";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { CInputGroup } from "@coreui/react";
import countryList from "react-select-country-list";
import axios from "axios";
function Personal() {
  useEffect(() => {
    fetchEmployeesByEmail();
  }, []);

  const options = useMemo(() => countryList().getData(), []);
  const [showAdd, setShowAdd] = useState(true);
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL

  async function fetchEmployeesByEmail() {
    try {
      const loginEmail = localStorage.getItem("loginEmail");
      if (loginEmail) {
        const response = await axios.get(
          `${apiUrl}/api/PersonalInformation/GetEmployeeby-Email/${loginEmail}`
        );
        const responseData = response.data;

        if (Array.isArray(responseData)) {
          setEmployeeDetails(responseData);
        } else {
          setEmployeeDetails([responseData]);
        }
      } else {
        console.error("User email not found in local storage");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          {showAdd && (
            <div className="card">
              <div className="card-header" style={{ position: "relative" }}>
                <h5>Personal</h5>
              </div>
              <div className="card-body">
                {employeeDetails.map((employee) => (
                  <>
                    <div className="row">
                      <label className="mb-3" style={{ textAlign: "left" }}>
                        <i className="fa fa-info-circle" aria-hidden="true"></i>&nbsp;BASIC
                        INFORMATION
                      </label>
                      <div className="row">
                        <div className="col-md-9 mb-3">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInput"
                              className="form-label"
                              style={{ fontSize: "medium" }}
                            >
                              Employee Number *
                            </label>
                            <input
                              disabled
                              value={employee.employeeNumber}
                              type="text"
                              className="form-control"
                              placeholder="Enter Employee Number here"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-5 mb-3">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInput"
                              className="form-label"
                              style={{ fontSize: "medium" }}
                            >
                              First Name *
                            </label>
                            <input
                              disabled
                              type="text"
                              value={employee.firstName}
                              className="form-control"
                              placeholder="Enter Last Name here"
                            />
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInput"
                              className="form-label"
                              style={{ fontSize: "medium" }}
                            >
                              Last Name *
                            </label>
                            <input
                              disabled
                              type="text"
                              value={employee.lastName}
                              className="form-control"
                              placeholder="Enter Last Name here"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-5 mb-3">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInput"
                              className="form-label"
                              style={{ fontSize: "medium" }}
                            >
                              DOB *
                            </label>
                            <input
                              disabled
                              type="date"
                              value={employee.dob}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                              style={{ fontSize: "medium" }}
                            >
                              Disabilities *
                            </label>
                            <select
                              disabled
                              value={employee.disabilities}
                              className="form-select"
                              aria-label="Default select example"
                            >
                              <option value=""> Please Select </option>
                              <option value="Mentally"> Mentally </option>
                              <option value="Physically"> Physically </option>
                              <option value="Others"> Others </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-5 mb-3">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                              style={{ fontSize: "medium" }}
                            >
                              Gender *
                            </label>
                            <select
                              disabled
                              value={employee.gender}
                              className="form-select"
                              aria-label="Default select example"
                            >
                              <option value=""> Please Select </option>
                              <option value="Male"> Male </option>
                              <option value="Female"> Female </option>
                              <option value="UnSpecified"> UnSpecified </option>
                              <option value="Do not wish to disclosed">
                                {" "}
                                Do not wish to disclosed{" "}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                              style={{ fontSize: "medium" }}
                            >
                              Marital Status *
                            </label>
                            <select
                              disabled
                              value={employee.maritalStatus}
                              className="form-select"
                              aria-label="Default select example"
                            >
                              <option value=""> Please Select </option>
                              <option value="Single"> Single </option>
                              <option value="Married"> Married </option>
                              <option value="Divorced"> Divorced </option>
                              <option value="Widow"> Widow </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-5 mb-3">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInput"
                              className="form-label"
                              style={{ fontSize: "medium" }}
                            >
                              Social Security Number (SSN) *
                            </label>
                            <input
                              disabled
                              value={employee.ssn}
                              type="number"
                              className="form-control"
                              placeholder="Enter SSN here"
                            />
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                            style={{ fontSize: "medium" }}
                          >
                            Nationality
                          </label>
                          <CInputGroup className="mb-3">
                            <select
                              disabled
                              value={employee.nationality}
                              className="form-control"
                              aria-label=".form-select-sm example"
                              defaultValue="US"
                            >
                              <option value="" disabled>
                                Please select Nationality
                              </option>
                              {options.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </CInputGroup>
                        </div>
                      </div>
                    </div>
                    <hr></hr>
                    <div className="row">
                      <label className="mb-3" style={{ textAlign: "left" }}>
                        <i className="fa fa-home" aria-hidden="true"></i>&nbsp;ADDRESS
                      </label>
                      <div className="row">
                        <div className="col-md-9 mb-3">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlTextarea1"
                              className="form-label"
                              style={{ fontSize: "medium" }}
                            >
                              Street Address
                            </label>
                            <input
                              disabled
                              type="text"
                              value={employee.streetAddress}
                              className="form-control"
                              placeholder="Enter Street Address here"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3 mb-3">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                              style={{ fontSize: "medium" }}
                            >
                              City
                            </label>
                            <input
                              disabled
                              type="text"
                              value={employee.city}
                              className="form-control"
                              placeholder="Enter City here"
                            />
                          </div>
                        </div>
                        <div className="col-md-3 mb-3">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                              style={{ fontSize: "medium" }}
                            >
                              State
                            </label>
                            <input
                              disabled
                              type="text"
                              value={employee.state}
                              className="form-control"
                              placeholder="Enter State / Province here"
                            />
                          </div>
                        </div>
                        <div className="col-md-3 mb-3">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                              style={{ fontSize: "medium" }}
                            >
                              Postal / Zip Code
                            </label>
                            <input
                              disabled
                              type="text"
                              value={employee.postalCode}
                              className="form-control"
                              placeholder="Enter Postal / Zip Code here"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-9 mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                            style={{ fontSize: "medium" }}
                          >
                            Country
                          </label>
                          <CInputGroup className="mb-3">
                            <select
                              disabled
                              className="form-control"
                              aria-label=".form-select-sm example"
                              defaultValue="US"
                              value={employee.country}
                            >
                              <option value="">Please select</option>
                              {options.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </CInputGroup>
                        </div>
                      </div>
                    </div>
                    <hr></hr>
                    <div className="row">
                      <label className="mb-3" style={{ textAlign: "left" }}>
                        <i className="fa fa-phone-square" aria-hidden="true"></i>&nbsp;CONTACT
                      </label>
                      <div className="row">
                        <div className="col-md-5 mb-3">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInput"
                              className="form-label"
                              style={{ fontSize: "medium" }}
                            >
                              EXT *
                            </label>
                            <input
                              disabled
                              value={employee.ext}
                              type="text"
                              className="form-control"
                              placeholder="Enter EXT here"
                            />
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                              style={{ fontSize: "large" }}
                            >
                              Mobile Phone
                            </label>
                            <PhoneInput
                              disabled
                              value={employee.mobileno}
                              className="col-md-12"
                              defaultCountry="us"
                              inputStyle={{ width: "840px" }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-9 mb-3">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInput"
                              className="form-label"
                              style={{ fontSize: "medium" }}
                            >
                              Work Email *
                            </label>
                            <input
                              disabled
                              value={employee.workEmail}
                              type="text"
                              className="form-control"
                              placeholder="Enter work email here"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-9 mb-3">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInput"
                              className="form-label"
                              style={{ fontSize: "medium" }}
                            >
                              Home Email *
                            </label>
                            <input
                              disabled
                              type="text"
                              value={employee.email}
                              className="form-control"
                              placeholder="Enter home email here"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-9 mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                            style={{ fontSize: "medium" }}
                          >
                            Work Authorization US
                            <i
                              className="fa fa-info-circle"
                              title="Are you authorized to work lawfully in the united states for Demand base?"
                              style={{ marginLeft: "5px", cursor: "pointer" }}
                              aria-hidden="true"
                            ></i>
                          </label>
                          <select
                            disabled
                            aria-label="Default select example"
                            name="workAuthorizationUS"
                            value={employee.workAuthorizationUS}
                            className="form-select"
                          >
                            <option value=""> Please Select </option>
                            <option value="Yes"> Yes </option>
                            <option value="NO"> No </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-9 mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                            style={{ fontSize: "medium" }}
                          >
                            Sponsorship Needed
                            <i
                              className="fa fa-info-circle"
                              title="Will you now or in the future require Demandbase to commence ('sponsor') an immigration case in order to employ you (for example, H-1B, OPT, F1, J1, TN or other employment-based immigration case)? This is sometimes called 'sponsorship' for an employment-based visa status."
                              style={{ marginLeft: "5px", cursor: "pointer" }}
                              aria-hidden="true"
                            ></i>
                          </label>
                          <select
                            disabled
                            aria-label="Default select example"
                            name="sponsorshipNeeded"
                            value={employee.sponsorshipNeeded}
                            className="form-select"
                          >
                            <option value=""> Please Select </option>
                            <option value="Yes"> Yes </option>
                            <option value="NO"> No </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-9 mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                            style={{ fontSize: "medium" }}
                          >
                            10 Years Strategic Success
                            <i
                              className="fa fa-info-circle"
                              title="Do you have at least 10 years of demonstrated success developing business strategy and building strategic ecosystem with global enterprises?"
                              style={{ marginLeft: "5px", cursor: "pointer" }}
                              aria-hidden="true"
                            ></i>
                          </label>
                          <select
                            disabled
                            aria-label="Default select example"
                            name="tenYearsStrategicSuccess"
                            value={employee.tenYearsStrategicSuccess}
                            className="form-select"
                          >
                            <option value=""> Please Select </option>
                            <option value="Yes"> Yes </option>
                            <option value="NO"> No </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-9 mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                            style={{ fontSize: "medium" }}
                          >
                            High Value Deal Success
                            <i
                              className="fa fa-info-circle"
                              title="Have you had success facilitating six-to-seven figure deals in large organizations with complex sales cycles?"
                              style={{ marginLeft: "5px", cursor: "pointer" }}
                              aria-hidden="true"
                            ></i>
                          </label>
                          <select
                            disabled
                            aria-label="Default select example"
                            name="highValueDealSuccess"
                            value={employee.highValueDealSuccess}
                            className="form-select"
                          >
                            <option value=""> Please Select </option>
                            <option value="Yes"> Yes </option>
                            <option value="NO"> No </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-9 mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                            style={{ fontSize: "medium" }}
                          >
                            Team Leadership Proven
                            <i
                              className="fa fa-info-circle"
                              title="Do you have a proven ability to hire, mentor and retain a team?"
                              style={{ marginLeft: "5px", cursor: "pointer" }}
                              aria-hidden="true"
                            ></i>
                          </label>
                          <select
                            disabled
                            aria-label="Default select example"
                            name="teamLeadershipProven"
                            value={employee.teamLeadershipProven}
                            className="form-select"
                          >
                            <option value=""> Please Select </option>
                            <option value="Yes"> Yes </option>
                            <option value="NO"> No </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-9 mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                            style={{ fontSize: "medium" }}
                          >
                            Martech/Adtech Experience
                            <i
                              className="fa fa-info-circle"
                              title="Do you have a proven ability to hire, mentor and retain a team?"
                              style={{ marginLeft: "5px", cursor: "pointer" }}
                              aria-hidden="true"
                            ></i>
                          </label>
                          <select
                            disabled
                            aria-label="Default select example"
                            name="martechAdtechExperience"
                            value={employee.martechAdtechExperience}
                            className="form-select"
                          >
                            <option value=""> Please Select </option>
                            <option value="Yes"> Yes </option>
                            <option value="NO"> No </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-9 mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                            style={{ fontSize: "medium" }}
                          >
                            Are you hispanic/Latino? *
                          </label>
                          <select
                            disabled
                            aria-label="Default select example"
                            name="hispanic_Latino"
                            value={employee.hispanic_Latino}
                            className="form-select"
                          >
                            <option value=""> Please Select </option>
                            <option value="Yes"> Yes </option>
                            <option value="NO"> No </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-9 mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                            style={{ fontSize: "medium" }}
                          >
                            Veteran Status *
                            <i
                              className="fa fa-info-circle"
                              title="1: If you believe you belong to any of the categories of protected veterans listed below, please indicate by making the appropriate selection. As a government contractor subject to the Vietnam Era Veterans Readjustment Assistance Act (VEVRAA), we request this information in order to measure the effectiveness of the outreach and positive recruitment efforts we undertake pursuant to VEVRAA. Classification of protected categories is as follows:
                                  2: A 'disabled veteran' is one of the following: a veteran of the U.S. military, ground, naval or air service who is entitled to compensation (or who but for the receipt of military retired pay would be entitled to compensation) under laws administered by the Secretary of Veterans Affairs; or a person who was discharged or released from active duty because of a service-connected disability.
                                  3: A 'recently separated veteran' means any veteran during the three-year period beginning on the date of such veteran's discharge or release from active duty in the U.S. military, ground, naval, or air service.
                                  4: An 'active duty wartime or campaign badge veteran' means a veteran who served on active duty in the U.S. military, ground, naval or air service during a war, or in a campaign or expedition for which a campaign badge has been authorized under the laws administered by the Department of Defense.
                                  5: An 'Armed forces service medal veteran' means a veteran who, while serving on active duty in the U.S. military, ground, naval or air service, participated in a United States military operation for which an Armed Forces service medal was awarded pursuant to Executive Order 12985."
                              style={{ marginLeft: "5px", cursor: "pointer" }}
                              aria-hidden="true"
                            ></i>
                          </label>
                          <select
                            disabled
                            aria-label="Default select example"
                            name="veteranStatus"
                            value={employee.veteranStatus}
                            className="form-select"
                          >
                            <option value=""> Please Select </option>
                            <option value="Yes"> Yes </option>
                            <option value="NO"> No </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Personal;
