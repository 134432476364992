import React, { useState, useEffect } from "react";
import "antd/dist/antd";
import "./Onboarding.scss";
import DashboardLayout from "../LayoutNavbar/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../LayoutNavbar/Navbars/DashboardNavbar";
import check from "assets/images/checklist.jpg";
import axios from "axios";

const Onboarding = () => {
  const [employeeContract, setEmployeeContract] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL

  useEffect(() => {
    showContractData();
  }, []);

  const showContractData = () => {
    try {
      const loginEmail = localStorage.getItem("loginEmail");
      if (loginEmail) {
        axios
          .get(
            `${apiUrl}/api/EmployeeInterview/GetEmployee-Onboardingby-Email/${loginEmail}`
          )
          .then((response) => {
            const responseData = response.data;
            setEmployeeContract(responseData);
          })
          .catch((error) => {
            console.error("Error fetching contract data:", error);
          });
      }
    } catch (error) {
      console.error("Error fetching contract data:", error);
    }
  };

  const renderCheckbox = (label, id) => {
    const isChecked = employeeContract && employeeContract[id];
    return (
      <div className="form-check OnboardCheckbox" key={id}>
        <input
          disabled
          className="form-check-input"
          type="checkbox"
          value=""
          id={`Check${id}`}
          checked={isChecked}
        />
        <label className="form-check-label" htmlFor={`Check${id}`}>
          {label}
        </label>
      </div>
    );
  };

  return (
    <>
      <DashboardLayout>
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <DashboardNavbar />
          <div className="body flex-grow-1 px-3">
            <div className="card">
              <div className="card-header" style={{ position: "relative" }}>
                <b>New Hire Onboarding Checklist</b>
              </div>
              <div className="card-body">
                {employeeContract && (
                  <>
                    <label
                      className="mb-3"
                      style={{ textAlign: "left", fontSize: "large", color: "#3dc00f" }}
                    >
                      <img src={check} height={"35px"} style={{ marginLeft: "-8px" }} />
                      Tasks for HR Administrator
                    </label>
                    {renderCheckbox(
                      "Share the good news! Send a welcome email introducing your new hire.",
                      "welcomeEmail"
                    )}
                    {renderCheckbox(
                      "Assign your new hire a mentor and/or onboarding buddy.",
                      "mentorAssignment"
                    )}
                    {renderCheckbox(
                      "Invite your new employee to recurring one-on-one meetings to provide regular check-ins.",
                      "recurringOneOnOneMeetings"
                    )}
                    {renderCheckbox(
                      "Schedule your new hire`s first day meetings, prioritizing intro calls with colleagues who will work closely with the new hire.",
                      "firstDayMeetings"
                    )}
                    {renderCheckbox(
                      "Coordinate a welcome meeting or phone call to kick off your new hire`s first day.",
                      "welcomeMeeting"
                    )}
                    {renderCheckbox(
                      "Set up a welcome lunch with the new hire`s team, if appropriate.",
                      "welcomeLunch"
                    )}
                    {renderCheckbox(
                      "Give the new employee an introduction to the business, including how their roles fits in and any performance expectations you`ll have for their onboarding.",
                      "businessIntroduction"
                    )}
                    {renderCheckbox("Take your new hire on a tour of the office.", "officeTour")}
                   
                    <label
                      className="mb-3"
                      style={{ textAlign: "left", fontSize: "large", color: "#3dc00f" }}
                    >
                      <img src={check} height={"35px"} style={{ marginLeft: "-8px" }} />
                      Mentor or Onboarding Buddy Checklist
                    </label>
                    {renderCheckbox(
                      "Schedule regular check-ins throughout the first two weeks.",
                      "regularCheckIns"
                    )}
                    {renderCheckbox(
                      "Invite new hires to shadow relevant meetings or projects.",
                      "shadowingOpportunities"
                    )}
                    {renderCheckbox(
                      "Be responsive to ad hoc questions throughout the day!",
                      "adHocSupport"
                    )}
                    <label
                      className="mb-3"
                      style={{ textAlign: "left", fontSize: "large", color: "#3dc00f" }}
                    >
                      <img src={check} height={"35px"} style={{ marginLeft: "-8px" }} />
                      Required Onboarding Documents for New Hires
                    </label>
                    {renderCheckbox("Benefits enrollment paperwork", "benefitsEnrollment")}{" "}
                    {renderCheckbox("Emergency contact information", "emergencyContactInfo")}{" "}
                    {renderCheckbox(
                      "Employee handbook featuring all company policies",
                      "employeeHandbook"
                    )}{" "}
                    {renderCheckbox("Direct deposit and payroll information", "directDepositInfo")}{" "}
                    {renderCheckbox(
                      "1-9 form (employment eligibility verification)",
                      "employmentEligibilityForm"
                    )}{" "}
                    {renderCheckbox(
                      "Non-disclosure agreement, non-compete, or confidentiality agreement (if applicable)",
                      "ndaOrNonCompete"
                    )}{" "}
                    {renderCheckbox(
                      "State tax withholding form (varies by location)",
                      "stateTaxWithholdingForm"
                    )}{" "}
                    {renderCheckbox(
                      "W-4 form (employee`s withholding certificate for federal taxes) or W-9 for contractors",
                      "federalTaxWithholdingForm"
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default Onboarding;
