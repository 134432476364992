import React, { useState, useEffect } from "react";
import "./ViewCandidate.scss";
import {
  CalculateOutlined,
  CreditCard,
  FileOpenOutlined,
  PublishOutlined,
  StarOutlineSharp,
  ThumbDownOffAltOutlined,
} from "@mui/icons-material";
import filter from "assets/images/filter1.png";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Modal, Radio, Spin, Tooltip } from "antd";
import { Table, Space } from "antd";
import FeedBackService from "../APIServices/ViewCandidateService/FeedBackService";
import InterviewService from "../APIServices/ViewCandidateService/InterviewService";
import JobOfferService from "../APIServices/ViewCandidateService/JobOfferService";
import EmployeeContractService from "../APIServices/ViewCandidateService/EmployeeContractService";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { confirm } = Modal;

function CandidateList() {
  const { postingJobId } = useParams(); // Use useParams to get the postingJobId from the URL
  const [jobDetails, setJobDetails] = useState(null);
  const [employeeDetails, setEmployeeDetails] = useState(null);
  const [isOverall, setIsOverall] = useState(true);
  const [employeeCount, setEmployeeCount] = useState(0);
  // const [rejectEmployeeCount, setRejectEmployeeCount] = useState(0);
  const [selectedPdf, setSelectedPdf] = useState("");
  const [selectedCoverLetter, setSelectedCoverLetter] = useState("");
  const [emailSent, setEmailSent] = useState({});
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingEmails, setLoadingEmails] = useState({});
  const [loadingEmployeeId, setLoadingEmployeeId] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  const {
    feedbacks,
    setFeedbacks,
    openfeedback,
    setOpenfeedback,
    feedbackDetail,
    showfeedbackModal,
    feedbackData,
    setFeedbackData,
    handleSubmitFeedback,
    fetchfeedBack,
    emailStatusList,
  } = FeedBackService();
  const {
    employeeId,
    open,
    setOpen,
    email,
    firstDateTime,
    secondDateTime,
    thirdDateTime,
    setFirstDateTime,
    setSecondDateTime,
    setThirdDateTime,
    showModal,
    handleOk,
    handleCancel,
    interviewScheduled,
    loadingInterviewEmails,
  } = InterviewService();
  const {
    jobOfferOpen,
    jobOfferScheduled,
    handleContractChange,
    showjobOfferModal,
    handleJobOfferCancel,
    handleJobOfferClick,
    loadingJobOffer,
  } = JobOfferService();
  const {
    openContract,
    employeeContract,
    contractPdf,
    handleContractCancel,
    showcontractModal,
    handleContractPreview,
    employeeEmail,
    empId,
  } = EmployeeContractService();

  useEffect(() => {
    fetchJobDetails();
    fetchEmployeesByPostingJobId(postingJobId);
    handleButtonClick(postingJobId);
    fetchfeedBack(emailStatusList.map((status) => status.email));
    fetchEmailStatus();
  }, [interviewScheduled, feedbacks, jobOfferScheduled]);

  const fetchEmailStatus = async () => {
    try {
      const emailsToCheck = [];
      const emailStatus = {};

      for (const email of emailsToCheck) {
        const response = await axios.get(
          `${apiUrl}/api/EmployeeJobApplication/GetEmailStatus?email=${email}`
        );
        if (response.status === 200) {
          const { emailSent, status } = response.data;
          emailStatus[email] = { emailSent, status };
        }
      }

      setEmailSent(emailStatus);
    } catch (error) {
      console.error("Error fetching email status:", error);
    }
  };

  function displayWarningMessage(emailData) {
    confirm({
      title: "Are you sure you want to send this email?",
      content: `You are about to send an email to ${emailData.firstName} ${emailData.lastName}.`,
      okText: "Yes, Send",
      okType: "primary",
      cancelText: "No, Cancel",
      onOk: async () => {
        // Call the sendEmailToEmployee function here with the emailData
        await sendEmailToEmployee(emailData);
      },
      onCancel() {
        console.log("Cancel button clicked");
      },
    });
  }

  // Update the sendEmailToEmployee function
  const sendEmailToEmployee = async (emailData) => {
    try {
      await axios.post(`${apiUrl}/api/EmployeeJobApplication/SendEmail`, emailData);

      toast.success("Successfully Sent");
      fetchEmployeesByPostingJobId(postingJobId);
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Failed to send email.");
    }
  };

  // Example usage
  const handleSendEmail = (email, firstName, lastName, forJobApply, password) => {
    const emailData = { email, firstName, lastName, forJobApply, password };
    displayWarningMessage(emailData); // Call the warning message with email data
  };

  const renderButton = (info) => {
    const isLoading = loadingEmails[info.email] || false;
    const isInterLoading = loadingInterviewEmails[info.email] || false;
    const jobOfferLoading = loadingJobOffer[info.email] || false;

    switch (info.status) {
      case 0:
        return (
          <button
            style={{ float: "inline-end" }}
            type="button"
            className="btn btn-outline-success"
            onClick={() =>
              handleShortlistClick(
                info.email,
                info.firstName,
                info.lastName,
                info.forJobApply,
                info.password
              )
            }
          >
            {isLoading ? "Sending..." : "Shortlist"}
            {isLoading && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </button>
        );
      case 1:
        return (
          <button
            disabled={info.status === 1}
            onClick={() =>
              showModal(
                info.employeeId,
                info.email,
                info.firstDateTime,
                info.secondDateTime,
                info.thirdDateTime
              )
            }
            style={{ float: "inline-end" }}
            type="button"
            className="btn btn-outline-primary"
          >
            Interview
          </button>
        );
      case 2:
        return (
          <button
            onClick={() =>
              showModal(
                info.employeeId,
                info.email,
                info.firstDateTime,
                info.secondDateTime,
                info.thirdDateTime
              )
            }
            style={{ float: "inline-end" }}
            type="button"
            className="btn btn-outline-primary"
          >
            {isInterLoading ? "Please Wait..." : "Interview"}
            {isInterLoading && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </button>
        );
      case 3:
        return (
          <button
            disabled={info.status === 3}
            style={{ float: "inline-end" }}
            type="button"
            className="btn btn-outline-info"
            onClick={() => {
              showfeedbackModal(info.employeeId, info.email);
            }}
          >
            Feedback
          </button>
        );
      case 4:
        return (
          <button
            style={{ float: "inline-end" }}
            type="button"
            className="btn btn-outline-info"
            onClick={() => {
              showfeedbackModal(info.employeeId, info.email);
            }}
          >
            Feedback
          </button>
        );
      case 5:
        return (
          <button
            style={{ float: "inline-end" }}
            type="button"
            className="btn btn-outline-warning"
            onClick={() => showjobOfferModal(info.employeeId, info.email)}
          >
            {jobOfferLoading ? "Please Wait..." : "Job Offer"}
            {jobOfferLoading && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </button>
        );
      case 6:
        return (
          <button
            disabled={info.status === 5}
            style={{ float: "inline-end" }}
            type="button"
            className="btn btn-outline-success"
            onClick={() => showcontractModal(info.employeeId, info.email)}
          >
            View Document
          </button>
        );
      case 7:
        return (
          <button
            style={{ float: "inline-end" }}
            type="button"
            className="btn btn-outline-success"
            onClick={() => showcontractModal(info.employeeId, info.email)}
          >
            View Document
          </button>
        );
      case 8:
        return (
          <button
            style={{ float: "inline-end" }}
            type="button"
            className="btn btn-outline-success"
            onClick={() => showcontractModal(info.employeeId, info.email)}
          >
            View Document
          </button>
        );
      default:
        return null;
    }
  };

  async function fetchJobDetails() {
    try {
      const response = await axios.get(
        `${apiUrl}/api/HiringProcess/GetpostingById/${postingJobId}`
      );
      setJobDetails(response.data);
    } catch (error) {
      console.error("Error fetching job details:", error);
    }
  }
  const [pending, setPending] = useState(0);
  const [interview, setInterview] = useState(0);
  const [qualified, setQualified] = useState(0);

  async function fetchEmployeesByPostingJobId(postingJobId) {
    try {
      const response = await axios.get(
        `${apiUrl}/api/EmployeeJobApplication/GetEmployeesByPostingJobId/${postingJobId}`
      );
      const responseData = response.data;
      const employeeJobApplications = responseData.employeeJobApplications;

      const pendingEmployees = employeeJobApplications.filter((info) => info.status === 3);
      const interviewEmployees = employeeJobApplications.filter((info) => info.status === 4);
      const qualifiedEmployees = employeeJobApplications.filter((info) => info.status === 8);
      setPending(pendingEmployees.length);
      setInterview(interviewEmployees.length);
      setQualified(qualifiedEmployees.length);

      const updatedRows = employeeJobApplications.map((info) => ({
        key: info.employeeId.toString(),
        employeeId: info.employeeId,
        firstName: info.firstName,
        lastName: info.lastName,
        email: info.email,
        mobileno: info.mobileno,
        telePhone: info.telePhone,
        streetAddress: info.streetAddress,
        city: info.city,
        state: info.state,
        postalCode: info.postalCode,
        forJobApply: info.forJobApply,
        coverLetter: info.coverLetter,
        resume: info.resume,
        status: info.status,
        password: info.password,
        dateAvailable: <span>{formatDateTime(info.dateAvailable)}</span>,
        appliedOn: <span>{formatDateTime(info.appliedOn)}</span>,
      }));
      setEmployeeCount(updatedRows.length);
      setEmployeeDetails(updatedRows);
      setIsOverall(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }

  function renderStatus(status) {
    if (status === 0) {
      return <span style={{ color: "green" }}>Applied</span>;
    } else if (status === 1) {
      return <span style={{ color: "green" }}>Pending</span>;
    } else if (status === 2) {
      return <span style={{ color: "green" }}>Interview Date Time Schedule</span>;
    } else if (status === 3) {
      return <span style={{ color: "green" }}>Short List</span>;
    } else if (status === 4) {
      return <span style={{ color: "green" }}>Interview</span>;
    } else if (status === 5) {
      return <span style={{ color: "green" }}>FeedBack</span>;
    } else if (status === 6) {
      return <span style={{ color: "green" }}>Document Send</span>;
    } else if (status === 7) {
      return <span style={{ color: "green" }}>Document Received</span>;
    } else if (status === 8) {
      return <span style={{ color: "green" }}>Qualified</span>;
    } else {
      return <span>{status}</span>;
    }
  }

  const handleButtonClick = (postingJobId) => {
    fetchEmployeesByPostingJobId(postingJobId);
  };

  const handleShortListClick = (postingJobId, status) => {
    fetchPendingEmployeesByPostingJobId(postingJobId, status);
  };

  function formatDateTime(dateTimeString) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date(dateTimeString);
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutes = date.getMinutes();
    return `${month} ${day < 10 ? "0" : ""}${day}, ${year} ${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${ampm}`;
  }

  const handleShortlistClick = async (email, firstName, lastName, forJobApply, password) => {
    setLoadingEmails((prevState) => ({ ...prevState, [email]: true }));

    try {
      await handleSendEmail(email, firstName, lastName, forJobApply, password);
    } catch (error) {
      console.error("Error sending email:", error);
    } finally {
      setLoadingEmails((prevState) => ({ ...prevState, [email]: false }));
    }
  };
  const handleDelete = (employeeId, employeeData) => {
    confirm({
      title: "Are you sure you want to reject this employee?",
      icon: <ExclamationCircleOutlined />,
      content: "After rejection, this action cannot be undone.",
      okText: "Yes, Reject",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          // Set the loading state to the current employee's ID
          setLoadingEmployeeId(employeeId);

          // Email data to send with the request
          const emailData = {
            firstName: employeeData.firstName,
            lastName: employeeData.lastName,
            email: employeeData.email,
            forJobApply: employeeData.forJobApply,
            Password: employeeData.Password,
          };

          const response = await axios.delete(
            `${apiUrl}/api/EmployeeJobApplication/Employee-Reject/${employeeId}`,
            {
              data: emailData,
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          toast.success("Employee rejected successfully.");

          // Filter the employeeDetails to remove the rejected employee
          setEmployeeDetails((prevDetails) => {
            const updatedDetails = prevDetails.filter((info) => info.employeeId !== employeeId);

            // If no employees left, set employeeDetails to null to show "No Candidate available"
            if (updatedDetails.length === 0) {
              return null;
            }

            return updatedDetails;
          });

          setEmployeeCount((prevCount) => prevCount - 1);
        } catch (error) {
          console.error("Error rejecting employee:", error);
          toast.error("Failed to reject employee.");
        } finally {
          // Reset the loading state after the request completes
          setLoadingEmployeeId(null);
        }
      },
      onCancel() {
        console.log("Cancel button clicked");
      },
    });
  };

  const handlePdfPreview = async (fileUrl) => {
    try {
      // Get the file extension
      const extension = fileUrl.split(".").pop().toLowerCase();

      if (extension === "pdf") {
        // If PDF, set the selected PDF state and open modal for preview
        setSelectedPdf(fileUrl);
        const modal = new bootstrap.Modal(document.getElementById("staticBackdrop"));
        modal.show();
      } else {
        // If not PDF, download the file
        window.open(fileUrl, "_blank");
      }
    } catch (error) {
      console.error("Error handling file:", error);
    }
  };
  const handlecoverLetterPdfPreview = (fileUrl) => {
    try {
      // Get the file extension
      const extension = fileUrl.split(".").pop().toLowerCase();

      if (extension === "pdf") {
        // If PDF, set the selected PDF state and open modal for preview
        setSelectedCoverLetter(fileUrl);
        const modal = new bootstrap.Modal(document.getElementById("coverLetter"));
        modal.show();
      } else {
        // If not PDF, download the file
        window.open(fileUrl, "_blank");
      }
    } catch (error) {
      console.error("Error handling file:", error);
    }
  };

  async function fetchPendingEmployeesByPostingJobId(postingJobId, status) {
    try {
      const response = await axios.get(
        `${apiUrl}/api/EmployeeJobApplication/GetEmployeesByPostingJobId/${postingJobId}`
      );
      const responseData = response.data;
      const employeeJobApplications = responseData.employeeJobApplications;

      const filteredEmployees = employeeJobApplications.filter((info) => info.status === status);

      if (status === 3) {
        setPending(filteredEmployees.length);
      } else if (status === 4) {
        setInterview(filteredEmployees.length);
      } else if (status === 8) {
        setQualified(filteredEmployees.length);
      }

      const updatedRows = filteredEmployees.map((info) => ({
        key: info.employeeId.toString(),
        employeeId: info.employeeId,
        firstName: info.firstName,
        lastName: info.lastName,
        email: info.email,
        phone: info.phone,
        address: info.address,
        city: info.city,
        state: info.state,
        zipcode: info.zipcode,
        forJobApply: info.forJobApply,
        coverLetter: info.coverLetter,
        resume: info.resume,
        status: info.status,
        password: info.password,
        dateAvailable: <span>{formatDateTime(info.dateAvailable)}</span>,
        appliedOn: <span>{formatDateTime(info.appliedOn)}</span>,
      }));
      setEmployeeDetails(updatedRows);
      setIsOverall(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const handleOnboarding = () => {
    navigate(`/hiringOnboarding/${empId}/${employeeEmail}`);
  };

  const columns = [
    {
      title: "File Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Download/Preview",
      key: "download",
      render: (record) => (
        <Space size="middle">
          <FileOpenOutlined
            onClick={() => handleContractPreview(`${apiUrl}/${record.file}`)}
            style={{ cursor: "pointer" }}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="row">
        <ToastContainer />
        <div className="col-md-4">
          <div className="card cardSet" style={{ height: "575px" }}>
            <div className="card-body" style={{ overflowY: "auto" }}>
              {" "}
              <div className="d-flex align-items-center">
                <span className="ml-2" style={{ fontSize: "medium", cursor: "pointer" }}>
                  CandidateList
                </span>
              </div>
              <hr style={{ marginTop: "5px" }}></hr>
              <div>
                {jobDetails && (
                  <>
                    <h3 style={{ color: "green", fontFamily: "system-ui" }}>
                      {jobDetails.postingInfo.postingJobTitle}
                    </h3>
                    <p className="textspan">
                      <CalculateOutlined /> &nbsp;&nbsp;
                      <span>{jobDetails.postingInfo.postingJobTitle}</span>
                    </p>
                    <p className="textp">
                      <CreditCard />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <span>{jobDetails.postingInfo.compensation} USD/Month</span>
                    </p>
                    <p className="textp">
                      <PublishOutlined />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <span>{formatDateTime(jobDetails.postingInfo.createdOn)}</span>
                    </p>
                    <p className="textp">
                      <span>
                        <StarOutlineSharp />
                        &nbsp;&nbsp;&nbsp;&nbsp;Status:{" "}
                        <span style={{ color: "green" }}>{jobDetails.postingInfo.jobStatus}</span>
                      </span>
                    </p>
                    {/* <p className="textp">
                      <span>
                        <CalendarMonthOutlined />
                        &nbsp;&nbsp;&nbsp;&nbsp;Application till:{" "}
                        <span style={{ color: "red" }}>05/02/2024</span>
                      </span>
                    </p> */}
                  </>
                )}
              </div>
              <div className="col-md-10">
                <h5>Candidate List</h5>
                <button
                  type="button"
                  onClick={() => handleButtonClick(postingJobId)}
                  className="btn-custom"
                >
                  Over All &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </button>
                <div className="number-box">
                  <span className="badge number-box-span">{employeeCount}</span>
                </div>
                <br />
                <button
                  type="button"
                  onClick={() => handleShortListClick(postingJobId, 3)}
                  className="btn-custom"
                >
                  Short List &nbsp;&nbsp;&nbsp;
                </button>
                <div className="number-box">
                  <span className="badge number-box-span">{pending}</span>
                </div>
                <br />
                <button
                  type="button"
                  onClick={() => handleShortListClick(postingJobId, 4)}
                  className="btn-custom"
                >
                  Interview &nbsp;&nbsp;&nbsp;&nbsp;
                </button>
                <div className="number-box">
                  <span className="badge number-box-span">{interview}</span>
                </div>
                <br />
                <button
                  type="button"
                  onClick={() => handleShortListClick(postingJobId, 8)}
                  className="btn-custom"
                >
                  Qualified &nbsp;&nbsp;&nbsp;&nbsp;
                </button>
                <div className="number-box">
                  <span className="badge number-box-span">{qualified}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-8">
          <div className="card cardSet-i" style={{ height: "575px" }}>
            <div className="d-flex align-items-center">
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span className="ml-2" style={{ fontSize: "medium", marginTop: "15px" }}></span>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end align-items-end mb-3 right-align-container">
              <span className="spec-hr">Spec HR</span>&nbsp;&nbsp;
              <h6> Posting Process</h6>
            </div>

            <div className="scrollable-container">
              <div className="container">
                {loading ? (
                  <div
                    className="card"
                    style={{
                      height: "450px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Spin size="large" />
                  </div>
                ) : (
                  <div>
                    {!employeeDetails ? (
                      <div
                        className="card"
                        style={{
                          height: "450px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h4>No Candidate available</h4>
                      </div>
                    ) : (
                      <div>
                        {employeeDetails
                          .filter((info) => !info.SoftDeleted)
                          .map((info) => (
                            <div className="card" key={info.key}>
                              <div className="card-body">
                                <p className="cardsecbodylist">
                                  {info.status === 2
                                    ? "Set Interview Schedule"
                                    : info.status === 3
                                    ? "Shortlist Candidate"
                                    : info.status === 4
                                    ? "Interview Process"
                                    : info.status === 5
                                    ? "Job Offer Process"
                                    : info.status === 6
                                    ? "Contract Sended"
                                    : info.status === 7
                                    ? "Candidate Contract Response"
                                    : info.status === 8
                                    ? "Qualified Candidate"
                                    : "Candidate Applied"}
                                </p>
                                <div
                                  className="col-md-10"
                                  style={{
                                    fontSize: "medium",
                                    marginLeft: "28px",
                                    marginTop: "20px",
                                  }}
                                >
                                  <div className="col-md-8">
                                    <b>Name:</b>&nbsp;
                                    <span className="card-subtitle mb-2 text-muted">
                                      {info.firstName} {info.lastName}
                                    </span>
                                  </div>
                                  <div className="col-md-8">
                                    <b>Email:</b>&nbsp;
                                    <span className="card-subtitle mb-2 text-muted">
                                      {info.email}
                                    </span>
                                  </div>
                                  <div className="col-md-8">
                                    <b>Phone Number:</b>&nbsp;
                                    <span className="card-subtitle mb-2 text-muted">
                                      {info.mobileno}
                                    </span>
                                  </div>
                                  <div className="col-md-8">
                                    <b>Address:</b>&nbsp;
                                    <span className="card-subtitle mb-2 text-muted">
                                      {info.streetAddress}
                                    </span>
                                  </div>
                                  <div className="col-md-8">
                                    <b>City:</b>&nbsp;
                                    <span className="card-subtitle mb-2 text-muted">
                                      {info.city}
                                    </span>
                                  </div>
                                  <div className="col-md-8">
                                    <b>State:</b>&nbsp;
                                    <span className="card-subtitle mb-2 text-muted">
                                      {info.state}
                                    </span>
                                  </div>
                                  <div className="col-md-8">
                                    <b>ZipCode:</b>&nbsp;
                                    <span className="card-subtitle mb-2 text-muted">
                                      {info.postalCode}
                                    </span>
                                  </div>
                                  <div className="col-md-8">
                                    <b>Applied On:</b>&nbsp;
                                    <span className="card-subtitle mb-2 text-muted">
                                      {info.appliedOn}
                                    </span>
                                  </div>
                                  <div className="col-md-8">
                                    <b>Available On:</b>&nbsp;
                                    <span className="card-subtitle mb-2 text-muted">
                                      {info.dateAvailable}
                                    </span>
                                  </div>
                                  <div className="col-md-8">
                                    <b>Status:</b>&nbsp;
                                    <span className="card-subtitle mb-2 text-muted">
                                      {renderStatus(info.status)}
                                    </span>
                                  </div>
                                  {feedbackDetail &&
                                    feedbackDetail.length > 0 &&
                                    feedbackDetail
                                      .filter((feedbackItem) => feedbackItem.email === info.email)
                                      .map((filteredFeedbackItem, index) => (
                                        <div key={index}>
                                          <b>Feedback Mark Average:</b>&nbsp;
                                          <span className="card-subtitle mb-2 text-muted">
                                            {filteredFeedbackItem.marksAverage}
                                          </span>
                                        </div>
                                      ))}
                                </div>
                                <br></br>
                                <div className="col-md-12" style={{ marginTop: "-20px" }}>
                                  <FileOpenOutlined
                                    onClick={() => handlePdfPreview(`${apiUrl}/${info.resume}`)}
                                    style={{ marginLeft: "25px", cursor: "pointer" }}
                                  />
                                  <FileOpenOutlined
                                    onClick={() =>
                                      handlecoverLetterPdfPreview(`${apiUrl}/${info.coverLetter}`)
                                    }
                                    style={{ marginLeft: "15px", cursor: "pointer" }}
                                  />
                                </div>
                                {/* Render button for all candidates */}
                                <div className="col-md-12">
                                  <>
                                    {renderButton(info)}
                                    {info.status !== 5 &&
                                      info.status !== 6 &&
                                      info.status !== 7 &&
                                      info.status !== 8 && (
                                        <button
                                          style={{ float: "inline-end", marginRight: "10px" }}
                                          type="button"
                                          className="btn btn-outline-danger"
                                          onClick={() =>
                                            handleDelete(info.employeeId, {
                                              firstName: info.firstName,
                                              lastName: info.lastName,
                                              email: info.email,
                                              forJobApply: info.forJobApply,
                                              Password: info.password,
                                            })
                                          }
                                          disabled={loadingEmployeeId === info.employeeId} // Disable the button when it's loading
                                        >
                                          <ThumbDownOffAltOutlined />
                                          &nbsp;
                                          <span style={{ fontSize: "medium" }}>
                                            {loadingEmployeeId === info.employeeId
                                              ? "Please Wait..."
                                              : "Not Suitable"}
                                          </span>
                                        </button>
                                      )}
                                  </>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <embed src={selectedPdf} type="application/pdf" width="100%" height="450px" />
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="coverLetter"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="coverLetterLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <embed
                    src={selectedCoverLetter}
                    type="application/pdf"
                    width="100%"
                    height="450px"
                  />
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* -----------Interview Date Option Modal------------ */}
          <Modal
            open={open}
            title="Interview Schedule"
            onOk={() => handleOk(employeeId, email, firstDateTime, secondDateTime, thirdDateTime)}
            onCancel={handleCancel}
            footer={(_, { OkBtn, CancelBtn }) => (
              <>
                <CancelBtn />
                <OkBtn />
              </>
            )}
          >
            <hr></hr>
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <label
                  htmlFor="firstDateTime"
                  className="form-label"
                  style={{ fontSize: "medium" }}
                >
                  First Date time *
                </label>
                <input
                  id="firstDateTime"
                  type="datetime-local"
                  value={firstDateTime}
                  onChange={(e) => setFirstDateTime(e.target.value)}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <label
                  htmlFor="secondDateTime"
                  className="form-label"
                  style={{ fontSize: "medium" }}
                >
                  Second Date time *
                </label>
                <input
                  id="secondDateTime"
                  type="datetime-local"
                  value={secondDateTime}
                  onChange={(e) => setSecondDateTime(e.target.value)}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <label
                  htmlFor="thirdDateTime"
                  className="form-label"
                  style={{ fontSize: "medium" }}
                >
                  Third Date time *
                </label>
                <input
                  id="thirdDateTime"
                  type="datetime-local"
                  value={thirdDateTime}
                  onChange={(e) => setThirdDateTime(e.target.value)}
                  className="form-control"
                />
              </div>
            </div>
          </Modal>
          {/* {--------------Feedback Modal---------------} */}

          <Modal
            open={openfeedback}
            title="Interview Feedback"
            onOk={handleSubmitFeedback}
            onCancel={() => setOpenfeedback(false)}
            footer={(_, { OkBtn, CancelBtn }) => (
              <>
                <CancelBtn />
                <OkBtn onClick={handleSubmitFeedback} />
              </>
            )}
            style={{ width: "auto" }} // Responsive width for the modal
          >
            <hr />
            {[
              { label: "Interview Rating", stateKey: "Rating" },
              { label: "Communication Skill", stateKey: "CommunicationSkill" },
              { label: "Technical Aptitude", stateKey: "TechnicalAptitude" },
              { label: "Attitude and Motivation", stateKey: "AttitudeAndMotivation" },
              { label: "Critical Thinking", stateKey: "CriticalThinking" },
              { label: "Professionalism", stateKey: "Professionalism" },
              { label: "Organizational Fit", stateKey: "OrganizationalFit" },
              { label: "Suitable for Post", stateKey: "SuitableForPost" },
            ].map((item) => (
              <div className="questionWithRadio" key={item.label}>
                {/* Show tooltip only on smaller screens */}
                <Tooltip title={item.label}>
                  <p className="responsive-label">{item.label}</p>
                </Tooltip>
                <Radio.Group
                  value={feedbackData[item.stateKey]}
                  onChange={(e) =>
                    setFeedbackData({ ...feedbackData, [item.stateKey]: e.target.value })
                  }
                >
                  {[1, 2, 3, 4, 5].map((value) => (
                    <Radio key={value} value={value}>
                      {value}
                    </Radio>
                  ))}
                </Radio.Group>
              </div>
            ))}
          </Modal>

          {/* -----------Interview Job Offer Modal------------ */}
          <Modal
            open={jobOfferOpen}
            title="Job Offer"
            onOk={handleJobOfferClick} // Just pass the function without calling it
            onCancel={handleJobOfferCancel}
            footer={(_, { OkBtn, CancelBtn }) => (
              <>
                <CancelBtn />
                <OkBtn /> {/* Just pass the function without calling it */}
              </>
            )}
          >
            <hr></hr>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleJobOfferClick();
              }}
              encType="multipart/formData"
            >
              {" "}
              {/* Prevent default form submission */}
              <div className="col-md-8 mb-3">
                <div className="form-group">
                  <label
                    htmlFor="contractfile"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Choose NDA File *
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    accept=".pdf,.PDF,.docx,.DOCX,.doc,.DOC"
                    onChange={(e) => {
                      handleContractChange(e, "ndafile");
                    }}
                  />
                </div>
              </div>
              <div className="col-md-8 mb-3">
                <div className="form-group">
                  <label
                    htmlFor="contractfile"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Choose NCA File *
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    accept=".pdf,.PDF,.docx,.DOCX,.doc,.DOC"
                    onChange={(e) => {
                      handleContractChange(e, "ncafile");
                    }}
                  />
                </div>
              </div>
              <div className="col-md-8 mb-3">
                <div className="form-group">
                  <label
                    htmlFor="contractfile"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Choose Employee Agreement File *
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    accept=".pdf,.PDF,.docx,.DOCX,.doc,.DOC"
                    onChange={(e) => {
                      handleContractChange(e, "employeeAgreement");
                    }}
                  />
                </div>
              </div>
              <div className="col-md-8 mb-3">
                <div className="form-group">
                  <label
                    htmlFor="contractfile"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Choose Offer Letter *
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    accept=".pdf,.PDF,.docx,.DOCX,.doc,.DOC"
                    onChange={(e) => {
                      handleContractChange(e, "offerLetter");
                    }}
                  />
                </div>
              </div>
            </form>
          </Modal>
          {/* -----------Contract Modal------------ */}

          <Modal
            open={openContract}
            title="Employee Contract Documents"
            // onOk={handleJobOfferClick}
            onCancel={handleContractCancel}
            footer={(_, { CancelBtn }) => (
              <>
                <CancelBtn />
                <Button onClick={() => handleOnboarding(empId, employeeEmail)}>Onboarding</Button>
              </>
            )}
          >
            <hr></hr>
            <div className="row">
              <div className="row mb-3">
                {employeeContract ? (
                  <Table
                    dataSource={[
                      employeeContract.ndafile
                        ? { key: "ndafile", name: "NDA File", file: employeeContract.ndafile }
                        : null,
                      employeeContract.ncafile
                        ? { key: "ncafile", name: "NCA File", file: employeeContract.ncafile }
                        : null,
                      employeeContract.employeeAgreement
                        ? {
                            key: "employeeAgreement",
                            name: "Employee Agreement",
                            file: employeeContract.employeeAgreement,
                          }
                        : null,
                      employeeContract.i_nine
                        ? { key: "i_nine", name: "I-9 File", file: employeeContract.i_nine }
                        : null,
                      employeeContract.w_four
                        ? { key: "w_four", name: "W-4 File", file: employeeContract.w_four }
                        : null,
                      employeeContract.offerLetter
                        ? {
                            key: "offerLetter",
                            name: "Offer Letter",
                            file: employeeContract.offerLetter,
                          }
                        : null,
                    ].filter((item) => item !== null)} // Filter out null values
                    pagination={false}
                    columns={columns}
                  />
                ) : (
                  <p>Sorry, you don`t have any documents.</p>
                )}
              </div>
            </div>
          </Modal>

          <div
            className="modal fade"
            id="staticBackdropc"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <embed src={contractPdf} type="application/pdf" width="100%" height="450px" />
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CandidateList;
