import Grid from "@mui/material/Grid";

import SoftBox from "components/SoftBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import UpComingHolidays from "layouts/dashboard/components/UpcommingHolidays";
import Attendance from "layouts/dashboard/components/Attendence";

// Dashboard layout components
import Announcement from "layouts/dashboard/components/Announcement";
import Projects from "layouts/dashboard/components/Projects";
import EmployeeNameHeader from "./components/EmployeeNameHeader";
import DocumentCards from "./components/DocumentCards/DocumentCards";
import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Footer from "layouts/authentication/components/Footer";

function Dashboard() {
  const [loading, setLoading] = useState(true); // Initial loading state

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress color="info" />
        </div>
      ) : (
        <SoftBox py={3}>
          <Grid mb={3} container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <EmployeeNameHeader />
            </Grid>
          </Grid>
          <SoftBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <DocumentCards />
              </Grid>
            </Grid>
          </SoftBox>
          <SoftBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5}>
                <UpComingHolidays />
              </Grid>
              <Grid item xs={12} lg={7}>
                <Announcement />
              </Grid>
            </Grid>
          </SoftBox>
          <SoftBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <Attendance />
              </Grid>
            </Grid>
          </SoftBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Projects />
            </Grid>
          </Grid>
        </SoftBox>
      )}
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
