import React, { useState, useEffect } from "react";
import SoftButton from "components/SoftButton";
import axios from "axios";
import "./TimeOff.scss";
import image from "assets/images/marie.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function LeaveRequest() {
  const [leaveRequests, setLeaveRequests] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [buttonStates, setButtonStates] = useState({});

  useEffect(() => {
    fetchPolicy();
  }, []);

  async function fetchPolicy() {
    axios
      .get(`${apiUrl}/api/LeaveAddPolicy/Get-request-data`)
      .then((response) => {
        setLeaveRequests(response.data);
      })
      .catch((error) => {
        console.error("Error fetching leave requests:", error);
      });
  }

  const handleAccept = async (requestTimeOffId, index) => {
    try {
      await axios.put(`${apiUrl}/api/LeaveAddPolicy/HR-Approved-Response`, {
        requestTimeOffId: requestTimeOffId,
      });
      toast.success("Request Approved Successfully");
      const updatedButtonStates = { ...buttonStates };
      updatedButtonStates[requestTimeOffId] = "approved";
      setButtonStates(updatedButtonStates);
      fetchPolicy();
    } catch (error) {
      toast.error("Oops!");
    }
  };

  const handleDecline = async (requestTimeOffId, index) => {
    try {
      await axios.put(`${apiUrl}/api/LeaveAddPolicy/HR-Decline-Response`, {
        requestTimeOffId: requestTimeOffId,
      });
      toast.success("Request Rejected Successfully");
      const updatedButtonStates = { ...buttonStates };
      updatedButtonStates[requestTimeOffId] = "declined";
      setButtonStates(updatedButtonStates);
      fetchPolicy();
    } catch (error) {
      toast.error("Oops!");
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="col-md-12">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h6 className="h6-timeoffdetail" style={{ marginLeft: "15px", color: "cadetblue" }}>
            Leaves Requests
          </h6>
        </div>
        <div className="card" style={{ marginTop: "10px", marginLeft: "15px" }}>
          <div className="card-body">
            {leaveRequests.filter((request) => request.status !== 1).length > 0 ? (
              <div className="row">
                {leaveRequests
                  .filter((request) => request.status !== 1) // Filter out approved requests
                  .map((request, index) => (
                    <div key={request.requestTimeOffId} className="col-md-6">
                      <div className="card cardpd" style={{ marginBottom: "15px" }}>
                        <div className="cardpd-statistic-3 p-4">
                          <div className="trainingDetail-card mb-2">
                            <h6 className="card-title mb-0 text">
                              {/* <img
                              src={image}
                              alt=""
                              style={{ height: "45px", borderRadius: "50px" }}
                            /> */}

                              <h6 className="card-title mb-0 text">Name</h6>
                            </h6>
                            <span className="h6-trainingdetail">
                              {request.employeeFirstName} {request.employeeLastName}
                            </span>
                          </div>
                          <div className="trainingDetail-card mb-2">
                            <h6 className="card-title mb-0 text">Leave Request</h6>
                            <span className="h6-trainingdetail">{request.amountDays} Days</span>
                          </div>
                          <div className="trainingDetail-card mb-2">
                            <h6 className="card-title mb-0 text">Granted Days</h6>
                            <span className="h6-trainingdetail">{request.grantedDays} Days</span>
                          </div>
                          <div className="trainingDetail-card mb-2">
                            <h6 className="card-title mb-0 text">Leave Type</h6>
                            <span className="h6-trainingdetail">{request.timeOffCategory}</span>
                          </div>
                          <div className="trainingDetail-card mb-2">
                            <h6 className="card-title mb-0 text">Status</h6>
                            <span className="h6-trainingdetail">
                              {request.status === 0 && "Pending"}
                              {request.status === 1 && "Approved"}
                              {request.status === 2 && "Declined"}
                            </span>
                          </div>
                          <div className="trainingDetail-card mb-2">
                            <h6 className="card-title mb-0 text">Available Days</h6>
                            <span className="h6-trainingdetail">
                              {" "}
                              {request.availableDays} days
                            </span>{" "}
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              {buttonStates[request.requestTimeOffId] !== "approved" &&
                                buttonStates[request.requestTimeOffId] !== "declined" && (
                                  <>
                                    <SoftButton
                                      style={{ float: "inline-end" }}
                                      variant="outlined"
                                      color="success"
                                      size="small"
                                      onClick={() => handleAccept(request.requestTimeOffId, index)}
                                    >
                                      Accept
                                    </SoftButton>
                                    <SoftButton
                                      style={{
                                        float: "inline-end",
                                        marginRight: "10px",
                                      }}
                                      variant="outlined"
                                      color="error"
                                      size="small"
                                      onClick={() => handleDecline(request.requestTimeOffId, index)}
                                    >
                                      Decline
                                    </SoftButton>
                                  </>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <p style={{ fontSize: "medium" }}>No leave requests are available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}