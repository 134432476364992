import { Table } from "antd";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import Footer from "layouts/authentication/components/Footer";

function Compliance() {
  const apiUrl = process.env.REACT_APP_API_URL
  const navigate = useNavigate();
  const addPolicy = () => {
    navigate("/addPolicy");
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "complainceId",
      key: "complainceId",
    },
    {
      title: "Policy Name",
      dataIndex: "policyName",
      key: "policyName",
    },
    {
      title: "Action",
      dataIndex: "policyAction", // Ensure this matches the key in the response data
      key: "policyAction",
      render: (_, record) => ( // Use render method to render custom JSX
        <span
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <DownloadOutlined
            className="eyeOutlined"
            onClick={() => handlePdfPreview(`${apiUrl}/${record.policyAction}`)}
          />
        </span>
      ),
    },
  ];
  
  async function fetchData() {
    try {
      const response = await axios.get(`${apiUrl}/api/Complaince/GetComplaince`);
      const responseData = response.data;
  
      // Update dataSource state with responseData
      setDataSource(responseData); 
  
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  
  
  

  const [dataSource, setDataSource] = useState([]);

  const handlePdfPreview = async (fileUrl) => {
    try {
      // Get the file extension
      const extension = fileUrl.split(".").pop().toLowerCase();

      if (extension === "pdf") {
        window.open(fileUrl, "_blank");
      } else {
        // If not PDF, download the file
        window.open(fileUrl, "_blank");
      }
    } catch (error) {
      console.error("Error handling file:", error);
    }
  };

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <div className="card">
            <div className="card-header" style={{ position: "relative" }}>
              <h5>Compliance</h5>
            </div>
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-md-12 mb-3">
                  <SoftButton
                    variant="outlined"
                    style={{ float: "inline-end" }}
                    color="info"
                    size="small"
                    onClick={addPolicy}
                  >
                    <i className="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;Add New Policy
                  </SoftButton>
                </div>
                <Table
                  className="table-responsive"
                  bordered
                  dataSource={dataSource}
                  columns={columns}
                  pagination={{ pageSize: 10 }}
                />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Compliance;
