import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import SoftBox from 'components/SoftBox';
import DashboardLayout from '../LayoutNavbar/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../LayoutNavbar/Navbars/DashboardNavbar';
import Payroll from 'EmployeeDashboard/dashboard/components/Payroll/EmployeeSalarySlip';
import Attendance from 'EmployeeDashboard/dashboard/components/Attendence';
import Leaves from 'EmployeeDashboard/dashboard/components/Leaves';
import Projects from 'EmployeeDashboard/dashboard/components/Projects';
import EmployeeNameHeader from './components/EmployeeNameHeader';
import Announcement from 'EmployeeDashboard/dashboard/components/Announcement';

function Dashboard() {
  const [loading, setLoading] = useState(true); // Initial loading state

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
          <CircularProgress color="info"/>
        </div>
      ) : (
        <SoftBox py={3}>
          <Grid mb={3} container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <EmployeeNameHeader />
            </Grid>
          </Grid>

          <SoftBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <Leaves />
              </Grid>
            </Grid>
          </SoftBox>
          <SoftBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <Payroll />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Attendance />
              </Grid>
            </Grid>
          </SoftBox>
          <SoftBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <Announcement />
              </Grid>
            </Grid>
          </SoftBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Projects />
            </Grid>
          </Grid>
        </SoftBox>
      )}
    </DashboardLayout>
  );
}

export default Dashboard;
