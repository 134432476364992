import { useState, useEffect, useContext } from "react";
import SoftButton from "components/SoftButton";
import { useNavigate, useParams } from "react-router-dom";
import { Radio, Input } from "antd";
import axios from "axios";
import { CorrectiveActionContext } from "./CorrectiveActionContext";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "layouts/authentication/components/Footer";

function CorrectiveActionForm() {
  const { complaintId } = useParams();
  const navigate = useNavigate();
  const [complaintDetails, setComplaintDetails] = useState([]);
  const { setIsSubmitted } = useContext(CorrectiveActionContext);
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchComplaintByEmail();
  }, []);

  const [value, setValue] = useState();
  const onChange = (e) => {
    setValue(e.target.value);
  };

  const [warned, setWarned] = useState();
  const onChangewarned = (e) => {
    setWarned(e.target.value);
  };

  const [yes, setYes] = useState();
  const onyesChange = (e) => {
    setYes(e.target.value);
  };
  const [formFields, setFormFields] = useState({
    departmentName: "",
    whenDate: "",
    improvement: "",
    associateComments: "",
    summarySession: "",
    leaderName: "",
    date: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const handleSubmit = async () => {
    if (complaintDetails.length > 0) {
      const com = complaintDetails[0];
      const data = {
        assosiateName: com.firstName,
        complaintId: complaintId,
        email: com.email,
        departmentName: com.departments,
        dateOfIncident: com.complaintDate.split("T")[0],
        typeOfWarning: value,
        detailOfIncident: com.detailofIncident,
        warnedPreviousl: warned,
        ifYes: yes,
        whenDate: formFields.whenDate,
        improvement: formFields.improvement,
        associateComments: formFields.associateComments,
        summarySession: formFields.summarySession,
        leaderName: formFields.leaderName,
        date: formFields.date,
      };

      try {
        setIsLoading(true);
        const response = await axios.post(
          `${apiUrl}/api/CorrectiveComplaintAction/Add-Corrective-Action?complaintId=${complaintId}`,
          data
        );
        setIsSubmitted(true);
        localStorage.setItem(`complaint_${complaintId}_submitted`, "true");
        navigate(`/complaintDetail/${com.complaintId}`);
      } catch (error) {
        console.error("Error submitting data:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  async function fetchComplaintByEmail() {
    try {
      const loginEmail = localStorage.getItem("loginEmail");
      if (loginEmail) {
        const response = await axios.get(
          `${apiUrl}/api/CorrectiveComplaintAction/GetComplaint-by-Email/${loginEmail}`
        );
        const responseData = response.data;

        if (Array.isArray(responseData)) {
          setComplaintDetails(responseData);
        } else {
          setComplaintDetails([responseData]);
        }
      } else {
        console.error("User email not found in local storage");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const { TextArea } = Input;

  return (
    <>
      <DashboardLayout>
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <DashboardNavbar />
          <div className="body flex-grow-1 px-3"></div>
          <div className="card">
            <div className="card-header" style={{ position: "relative" }}>
              <h6>ASSOCIATE CORRECTIVE ACTION</h6>
            </div>
            <div className="card-body">
              {complaintDetails.map((com) => (
                <>
                  <div className="row">
                    <div className="col-md-5">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInput"
                          className="form-label"
                          style={{ fontSize: "medium" }}
                        >
                          Associate Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={com.firstName}
                          readOnly
                          placeholder="Enter Associate Name here"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInput"
                          className="form-label"
                          style={{ fontSize: "medium" }}
                        >
                          Associate Email
                        </label>
                        <input type="text" value={com.email} readOnly className="form-control" />
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInput"
                          className="form-label"
                          style={{ fontSize: "medium" }}
                        >
                          Dept Name *
                        </label>
                        <input
                          type="text"
                          name="departments"
                          disabled
                          placeholder="Enter department Name here"
                          className="form-control"
                          value={com.departments}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInput"
                          className="form-label"
                          style={{ fontSize: "medium" }}
                        >
                          Date of Incident
                        </label>
                        <input
                          type="date"
                          value={com.complaintDate ? com.complaintDate.split("T")[0] : ""}
                          readOnly
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <hr></hr>
                  <div className="row mb-3">
                    <h6>Type of Warning:</h6>
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value="Verbal Warning">Verbal Warning</Radio>
                      <Radio value="Written Warning">Written Warning</Radio>
                      <Radio value="Final Written Warning">Final Written Warning</Radio>
                    </Radio.Group>
                  </div>
                  <div className="row">
                    <div className="col-md-9">
                      <div className="mb-3">
                        <h6>Details of Incident</h6>
                        <TextArea rows={3} value={com.detailofIncident} readOnly />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <h6>Has the associate been warned of a similar incident? </h6>
                    <Radio.Group onChange={onChangewarned} value={warned}>
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                    </Radio.Group>
                  </div>
                  <div className="row mb-3">
                    <h6>If yes, how and when? </h6>
                    <Radio.Group onChange={onyesChange} value={yes}>
                      <Radio value="Verbal">Verbal</Radio>
                      <Radio value="Written">Written</Radio>
                      <div className="col-md-4 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInput"
                            className="form-label"
                            style={{ fontSize: "medium" }}
                          >
                            When (dates):
                          </label>
                          <input
                            type="date"
                            name="whenDate"
                            className="form-control"
                            value={formFields.whenDate}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </Radio.Group>
                  </div>
                  <div className="row">
                    <div className="col-md-9">
                      <div className="mb-3">
                        <h6>Action Plan(s) for Improvement: </h6>
                        <TextArea
                          rows={3}
                          name="improvement"
                          value={formFields.improvement}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="mb-3">
                        <h6>Associate’s Comments: </h6>
                        <TextArea
                          rows={3}
                          name="associateComments"
                          value={formFields.associateComments}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="mb-3">
                        <h6>Summary of counseling session: </h6>
                        <TextArea
                          rows={3}
                          name="summarySession"
                          value={formFields.summarySession}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <h6>
                      Further Violations May Result In Further Disciplinary Action, Up To and
                      Including Termination.
                    </h6>
                    <div className="col-md-5">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInput"
                          className="form-label"
                          style={{ fontSize: "medium" }}
                        >
                          Leader Name
                        </label>
                        <input
                          type="text"
                          name="leaderName"
                          className="form-control"
                          placeholder="Enter Leader name here"
                          value={formFields.leaderName}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInput"
                          className="form-label"
                          style={{ fontSize: "medium" }}
                        >
                          Date
                        </label>
                        <input
                          type="date"
                          name="date"
                          className="form-control"
                          value={formFields.date}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <SoftButton
                      variant="outlined"
                      style={{ float: "inline-end" }}
                      color="info"
                      size="small"
                      onClick={handleSubmit}
                    >
                      {isLoading ? "Submitting..." : "Submit"}
                      {isLoading && (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                    </SoftButton>
                  </div>
                </>
              ))}
            </div>
          </div>
          <Footer />
        </div>
      </DashboardLayout>
    </>
  );
}

export default CorrectiveActionForm;
