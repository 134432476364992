import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useNavigate } from "react-router-dom";
import ComplainceService from "../APIServices/ComplainceService";
import Footer from "layouts/authentication/components/Footer";

function AddPolicy() {
  const navigate = useNavigate();
  const { formData, formDataError, handleChange, handleUploadDocChange, handleSubmit } =
    ComplainceService();
  const handleCancel = () => {
    navigate("/compliance");
  };
  return (
    <>
      <DashboardLayout>
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <DashboardNavbar />
          <div className="body flex-grow-1 px-3">
            <div className="card">
              <div className="card-header" style={{ position: "relative" }}>
                <h5>Add Policy</h5>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit} encType="multipart/formData">
                  <div className="row">
                    <div className="col-md-5">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInput"
                          className="form-label"
                          style={{ fontSize: "medium" }}
                        >
                          Policy Name *
                        </label>
                        <input
                          type="text"
                          placeholder="Enter Beneficiary here"
                          name="policyName"
                          value={formData.policyName}
                          onChange={handleChange}
                          className={`form-control ${
                            formDataError.policyNameError ? "is-invalid" : ""
                          }`}
                        />
                        {formDataError.policyNameError && (
                          <div className="invalid-feedback invalid-message">
                            {formDataError.policyNameError}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInput"
                          className="form-label"
                          style={{ fontSize: "medium" }}
                        >
                          Policy File *
                        </label>
                        <input
                          type="file"
                          accept=".pdf,.PDF,.docx,.DOCX,.doc,.DOC"
                          onChange={(e) => {
                            handleUploadDocChange(e);
                          }}
                          className={`form-control ${
                            formDataError.policyActionError ? "is-invalid" : ""
                          }`}
                        />
                        {formDataError.policyActionError && (
                          <div className="invalid-feedback invalid-message">
                            {formDataError.policyActionError}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <SoftButton
                      variant="outlined"
                      style={{ float: "inline-end" }}
                      color="info"
                      size="small"
                      onClick={handleSubmit}
                    >
                      Submit
                    </SoftButton>
                    <SoftButton
                      variant="outlined"
                      style={{ float: "inline-end", marginRight: "10px" }}
                      color="error"
                      size="small"
                      onClick={handleCancel}
                    >
                      Cancel
                    </SoftButton>
                  </div>
                </form>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </DashboardLayout>
    </>
  );
}

export default AddPolicy;
