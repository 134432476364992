import React, { useState, useEffect } from "react";
import DashboardLayout from "../LayoutNavbar/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../LayoutNavbar/Navbars/DashboardNavbar";
import "./Training.scss";
import { Table, Spin } from "antd";
import axios from "axios";
import { EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

function EmployeeTrainings() {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [enrolled, setEnrolled] = useState(false); // Track if user is enrolled
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      setLoading(true);
      // Retrieve email from local storage
      const email = localStorage.getItem("loginEmail");

      if (!email) {
        console.error("Email not found in local storage");
        return;
      }

      // Fetch data from API using the retrieved email
      const response = await axios.get(`${apiUrl}/api/InitiateAccountability/Get-Enrolled-Employee-By-Email?email=${email}`);
      const responseData = response.data;

      if (responseData.length > 0) {
        setEnrolled(true);
      }

      // Generate rows based on the fetched data
      const updatedRows = responseData.map((info, index) => {
        function formatDateTime(dateTimeString) {
          const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
          ];
          const date = new Date(dateTimeString);
          const month = months[date.getMonth()];
          const day = date.getDate();
          const year = date.getFullYear();
          let hours = date.getHours();
          const ampm = hours >= 12 ? "PM" : "AM";
          hours = hours % 12;
          hours = hours ? hours : 12;
          const minutes = date.getMinutes();
          return `${month} ${day < 10 ? "0" : ""}${day}, ${year} ${hours}:${minutes < 10 ? "0" : ""}${minutes} ${ampm}`;
        }

        return {
          key: info.trainingId.toString(),
          Name: `${info.trainingDevelopement.trainingName}`,
          Provider: `${info.trainingDevelopement.provider}`,
          Mentor: info.trainingDevelopement.mentor,
          Enrollments: "N/A", // Replace with actual enrollment data
          Allocated_budget: info.trainingDevelopement.allocated_budget,
          StartDate: <span>{formatDateTime(info.trainingDevelopement.startDate)}</span>,
          EndDate: <span>{formatDateTime(info.trainingDevelopement.endDate)}</span>,
          Action: (
            <span style={{ display: "flex", alignItems: "center" }}>
              <Link to={`/employeedashboard/emptrainingDetail/${info.trainingId}`}>
                <EyeOutlined className="eyeOutlined" />
              </Link>
            </span>
          ),
        };
      });

      // Update dataSource state
      setDataSource(updatedRows);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }

  const columns = [
    { title: "Name", dataIndex: "Name", key: "Name" },
    { title: "Provider", dataIndex: "Provider", key: "Provider" },
    { title: "Mentor", dataIndex: "Mentor", key: "Mentor" },
    { title: "Start Date", dataIndex: "StartDate", key: "StartDate" },
    { title: "End Date", dataIndex: "EndDate", key: "EndDate" },
    { title: "Action", dataIndex: "Action", key: "Action" },
  ];

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <div className="card">
            <div className="card-header" style={{ position: "relative" }}>
              <h5>{enrolled ? "You are Enrolled in this Training" : "Trainings"}</h5>
              {loading && <Spin style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />}
            </div>
            <div className="card-body">
              <div className="row mb-3">
                <Table
                  className="table-responsive"
                  bordered
                  dataSource={dataSource}
                  columns={columns}
                  pagination={{ pageSize: 10 }}
                  loading={loading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default EmployeeTrainings;
