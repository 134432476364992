import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Table } from "antd";
import axios from "axios";
import { FileOpenOutlined } from "@mui/icons-material";
import { Space } from "antd";

function ReceivedDocuments() {
  const [companyDoc, setCompanyDoc] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchCompanyDocuments();
  }, []);

  const fetchCompanyDocuments = () => {
    axios
      .get(`${apiUrl}/api/EmployeeDocuments/Get-Employee-Received-Documents`)
      .then((response) => {
        setCompanyDoc(response.data);
      })
      .catch((error) => {
        console.error("Error fetching document data:", error);
      });
  };

  const handlePdfPreview = (fileUrl) => {
    window.open(`${apiUrl}/${fileUrl}`, "_blank");
  };

  const columns = [
    {
      title: "Employee Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
    },
    {
      title: "Job Title",
      dataIndex: "jobtitle",
      key: "jobtitle",
    },
    {
      title: "HR Policy",
      key: "hrpolicy",
      render: (record) => (
        <Space size="middle">
          <FileOpenOutlined
            onClick={() => handlePdfPreview(record.hrpolicy)}
            style={{ cursor: "pointer" }}
          />
        </Space>
      ),
    },
    {
      title: "I-9 Form",
      key: "i_nine",
      render: (record) => (
        <Space size="middle">
          <FileOpenOutlined
            onClick={() => handlePdfPreview(record.i_nine)}
            style={{ cursor: "pointer" }}
          />
        </Space>
      ),
    },
    {
      title: "W-4 Form",
      key: "w_four",
      render: (record) => (
        <Space size="middle">
          <FileOpenOutlined
            onClick={() => handlePdfPreview(record.w_four)}
            style={{ cursor: "pointer" }}
          />
        </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <div className="card">
            <div className="card-header" style={{ position: "relative" }}>
              <h5>Received Documents</h5>
            </div>
            <div className="card-body">
              <div className="row mb-3">
                <Table
                  dataSource={companyDoc}
                  pagination={false}
                  columns={columns}
                  rowKey="docId"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default ReceivedDocuments;
