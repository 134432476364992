import React, { useState, useMemo } from "react";
import DashboardLayout from "../LayoutNavbar/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../LayoutNavbar/Navbars/DashboardNavbar";
import { useNavigate } from "react-router-dom";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import countryList from "react-select-country-list";
import SoftButton from "components/SoftButton";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EmployeeContactService from "EmployeeDashboard/EmployeeApiService/EmployeeEmergencyContactService";

import { CInputGroup } from "@coreui/react";
function Emergency() {
  const { formData, formDataError, handleChange, isLoading, handleSubmit } =
    EmployeeContactService();
  const options = useMemo(() => countryList().getData(), []);
  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <ToastContainer />
        <div className="body flex-grow-1 px-3">
          <div className="card">
            <div className="card-header" style={{ position: "relative" }}>
              <h5>Emergency</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <label className="mb-3" style={{ textAlign: "left" }}>
                  <i className="fa fa-phone-square" aria-hidden="true"></i>&nbsp;CONTACT
                </label>
                <div className="col-md-5 mb-3">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInput"
                      className="form-label"
                      style={{ fontSize: "medium" }}
                    >
                      Name *
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className={`form-control ${formDataError.nameError ? "is-invalid" : ""}`}
                      placeholder="Enter name here"
                    />
                    {formDataError.nameError && (
                      <div className="invalid-feedback invalid-message">
                        {formDataError.nameError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInput"
                      className="form-label"
                      style={{ fontSize: "medium" }}
                    >
                      RelationShip *
                    </label>
                    <input
                      type="text"
                      name="relation"
                      value={formData.relation}
                      onChange={handleChange}
                      className={`form-control ${formDataError.relationError ? "is-invalid" : ""}`}
                      placeholder="Enter relation here"
                    />
                    {formDataError.relationError && (
                      <div className="invalid-feedback invalid-message">
                        {formDataError.relationError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-5 mb-3">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label"
                      style={{ fontSize: "large" }}
                    >
                      Phone
                    </label>
                    <CInputGroup className="mb-3">
                      <PhoneInput
                        onChange={(value) => handleChange(value, "phoneNo")}
                        className={`col-md-12 ${formDataError.phoneNoError ? "is-invalid" : ""}`}
                        defaultCountry="us"
                        inputStyle={{ width: "420px" }}
                        onBlur={handleChange}
                        name="phoneNo"
                        value={formData.phoneNo}
                        placeholder="Enter Phone here"
                      />

                      {formDataError.phoneNoError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.phoneNoError}
                        </div>
                      )}
                    </CInputGroup>
                  </div>
                </div>
                <div className="col-md-4 mb-3">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label"
                      style={{ fontSize: "large" }}
                    >
                      Home Phone
                    </label>
                    <CInputGroup className="mb-3">
                      <PhoneInput
                        onChange={(value) => handleChange(value, "homePhone")}
                        className={`col-md-12 ${formDataError.homePhoneError ? "is-invalid" : ""}`}
                        defaultCountry="us"
                        inputStyle={{ width: "420px" }}
                        onBlur={handleChange}
                        name="homePhone"
                        value={formData.homePhone}
                        placeholder="Enter Home Phone here"
                      />

                      {formDataError.homePhoneError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.homePhoneError}
                        </div>
                      )}
                    </CInputGroup>
                  </div>
                </div>
                <div className="col-md-9 mb-3">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInput"
                      className="form-label"
                      style={{ fontSize: "medium" }}
                    >
                      Email *
                    </label>
                    <input
                      type="text"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className={`form-control ${formDataError.emailError ? "is-invalid" : ""}`}
                      placeholder="Enter email here"
                    />
                    {formDataError.emailError && (
                      <div className="invalid-feedback invalid-message">
                        {formDataError.emailError}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <hr></hr>
              <div className="row">
                <label className="mb-3" style={{ textAlign: "left" }}>
                  <i className="fa fa-home" aria-hidden="true"></i>&nbsp;ADDRESS
                </label>

                <div className="col-md-9 mb-3">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className="form-label"
                      style={{ fontSize: "medium" }}
                    >
                      Address 1
                    </label>
                    <input
                      type="text"
                      name="address_one"
                      value={formData.address_one}
                      onChange={handleChange}
                      className={`form-control ${
                        formDataError.address_oneError ? "is-invalid" : ""
                      }`}
                      placeholder="Enter address one here"
                    />
                    {formDataError.address_oneError && (
                      <div className="invalid-feedback invalid-message">
                        {formDataError.address_oneError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-9 mb-3">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label"
                      style={{ fontSize: "medium" }}
                    >
                      Address 2
                    </label>
                    <input
                      type="text"
                      name="address_two"
                      value={formData.address_two}
                      onChange={handleChange}
                      className={`form-control ${
                        formDataError.address_twoError ? "is-invalid" : ""
                      }`}
                      placeholder="Enter address two here"
                    />
                    {formDataError.address_twoError && (
                      <div className="invalid-feedback invalid-message">
                        {formDataError.address_twoError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        City
                      </label>
                      <input
                        type="text"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        className={`form-control ${formDataError.cityError ? "is-invalid" : ""}`}
                        placeholder="Enter city here"
                      />
                      {formDataError.cityError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.cityError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        State
                      </label>
                      <input
                        type="text"
                        name="state"
                        value={formData.state}
                        onChange={handleChange}
                        className={`form-control ${formDataError.stateError ? "is-invalid" : ""}`}
                        placeholder="Enter State here"
                      />
                      {formDataError.stateError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.stateError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Postal / Zip Code
                      </label>
                      <input
                        type="text"
                        name="postalCode"
                        value={formData.postalCode}
                        onChange={handleChange}
                        className={`form-control ${
                          formDataError.postalCodeError ? "is-invalid" : ""
                        }`}
                        placeholder="Enter postal Code here"
                      />
                      {formDataError.postalCodeError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.postalCodeError}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-9 mb-3">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Country
                  </label>
                  <CInputGroup className="mb-3">
                    <select
                      defaultValue="US"
                      aria-label=".form-select-sm example"
                      name="country"
                      value={formData.country}
                      onChange={handleChange}
                      className={`form-control ${formDataError.countryError ? "is-invalid" : ""}`}
                    >
                      <option value="">Please select</option>
                      {options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    {formDataError.countryError && (
                      <div className="invalid-feedback invalid-message">
                        {formDataError.countryError}
                      </div>
                    )}
                  </CInputGroup>
                </div>
                <div className="col-md-9">
                  <SoftButton
                    variant="outlined"
                    style={{ float: "inline-end" }}
                    color="info"
                    size="small"
                    type="Submit"
                    onClick={handleSubmit}
                  >
                    Submit
                  </SoftButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Emergency;
