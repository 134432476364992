import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSoftwareInfo } from "../../Services/Actions/actions";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import { message } from "antd";

function SoftwareService() {
  const [isLoading, setIsLoading] = useState(false);
  const [softwareware, setSoftware] = useState(true);  
  const { personal, job, hardwareAssets } = useSelector((state) => state);
  const [dataSource, setDataSource] = useState([]);
  const { empId } = useParams();
  const navigate=useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    fetchData();
  }, []);

  const [softwareformData, setSoftwareFormData] = useState({
    category: "",
    assetsCost: "",
    provider: "",
    licenseKey: "",
    subscriptionDate: "",
    expiryDate: "",
    notes: "",
    description: "",
  });

  useEffect(() => {
    fetchEmployeeJobData(empId);
  }, [empId]);

  async function fetchEmployeeJobData(empId) {
    try {
      const response = await axios.get(
        `${apiUrl}/api/PersonalInformation/GetEmployeesById/${empId}`
      );
      const responseData = response.data;
      if (responseData.employeeJobApplications && responseData.employeeJobApplications.length > 0) {
        const firstEmployee = responseData.employeeJobApplications[0];
        const updatedFormData = { ...softwareformData };
        updatedFormData.category = firstEmployee.hiring.department;
        setSoftwareFormData(updatedFormData);
      } else {
        console.error("No employee data found.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const handleChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or target is undefined:", e);
      return;
    }
  
    const { name, value } = e.target;
  
    // Clear the error message if the value is not empty
    if (value.trim() !== "") {
      setFormDataError((prevState) => ({
        ...prevState,
        [`${name}Error`]: "",
      }));
    } else {
      // Set the error message if the value is empty
      setFormDataError((prevState) => ({
        ...prevState,
        [`${name}Error`]: `${name} is required.`,
      }));
    }
  
    // Custom validation for expiryDate against subscriptionDate
    if (name === "expiryDate" && softwareformData.subscriptionDate) {
      const subscriptionDate = new Date(softwareformData.subscriptionDate);
      const expiryDate = new Date(value);
      
      if (expiryDate <= subscriptionDate) {
        setFormDataError((prevState) => ({
          ...prevState,
          [`${name}Error`]: "Expiry date must be after the subscription date.",
        }));
      } else {
        setFormDataError((prevState) => ({
          ...prevState,
          [`${name}Error`]: "",
        }));
      }
    }
  
    setSoftwareFormData({ ...softwareformData, [name]: value });
  };
  

  const [formDataError, setFormDataError] = useState({
    categoryError: "",
    assetsCostError: "",
    providerError: "",
    licenseKeyError: "",
    subscriptionDateError: "",
    expiryDateError: "",
    notesError: "",
    descriptionError: "",
  });

  const clearFields = () => {
    // Reset the form data to initial values
    setSoftwareFormData({
      category: "",
      assetsCost: "",
      provider: "",
      licenseKey: "",
      subscriptionDate: "",
      expiryDate: "",
      notes: "",
      description: "",
    });
  };

  function validateValidation(softwareformData, setFormDataError) {
    let hasError = false;

    // Check each field for emptiness or invalidity
    Object.keys(softwareformData).forEach((fieldName) => {
      if (
        (typeof softwareformData[fieldName] === "string" &&
          (!softwareformData[fieldName] || !softwareformData[fieldName].trim())) || // For string fields
        (typeof softwareformData[fieldName] === "number" &&
          (isNaN(softwareformData[fieldName]) || softwareformData[fieldName] === null)) // For numeric fields
      ) {
        const fieldNameWithSpaces = fieldName
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase());
        setFormDataError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: `${fieldNameWithSpaces} is required.`,
        }));
        hasError = true;
      } else {
        // Clear the error message if the field is not empty
        setFormDataError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: "", // Clear the error message
        }));
      }
    });

    // Check if any error occurred
    if (hasError) {
      return false;
    }

    // Check if all fields are filled out
    const isAllFieldsFilled = Object.values(softwareformData).every(
      (value) => value !== null && value !== undefined && value !== ""
    );

    if (!isAllFieldsFilled) {
      // If any field is empty, display an error message
      setFormDataError((prevState) => ({
        ...prevState,
        genericError: "Please fill out all fields.",
      }));
      return false;
    }

    return true;
  }
  const dispatch = useDispatch();
  const handleNext = async (e) => {
    e.preventDefault();
    const isValid = validateValidation(softwareformData, setFormDataError);
    if (isValid) {
      dispatch(updateSoftwareInfo(softwareformData));
      const formData = new FormData();

      Object.entries(personal).forEach(([key, value]) => {
        formData.append(key, value);
      });
      // Append job properties
      Object.entries(job).forEach(([key, value]) => {
        formData.append(`JobRequestDto.${key}`, value);
      });

      Object.entries(hardwareAssets).forEach(([key, value]) => {
        formData.append(`HardwareAssetsDto.${key}`, value);
      });

      Object.entries(softwareformData).forEach(([key, value]) => {
        formData.append(`SoftwareAssetsDto.${key}`, value);
      });

      try {
        setIsLoading(true)
        await axios.post(`${apiUrl}/api/PersonalInformation/Addpersonal-info`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        message.success("Data has Been Added Successfully and also email and password send to the employee Successfully");
        navigate("/employees");
        fetchData()
        setSoftware(true);
      } catch (error) {
        console.error("Error adding personal information:", error);
        toast.error(error.message || "Failed to add personal information", {
          autoClose: 5000,
          style: { background: "lightcoral", color: "white" },
        });
      }finally {
        setIsLoading(false)
      }
    }
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const success = await handleNext();
      if (success) {
        toast.success(
          "Data has Been Added Successfully and also email and password send to the employee Successfully",
          {
            autoClose: 5000,
            style: { background: "lightgreen", color: "white" },
          }
        );
        fetchData();
      }
    } catch (error) {
      toast.error(error.message || "Added failed", {
        autoClose: 5000,
        style: { background: "lightcoral", color: "white" },
      });
    }
  };

  async function fetchData() {
    try {
      const response = await axios.get(`${apiUrl}/api/Assets/Get-Software-Assets`);
      const softwareassets = response.data; // Directly accessing the array
      const updatedRows = softwareassets.map((info) => {
        function formatDateTime(dateTimeString) {
          const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          const date = new Date(dateTimeString);
          const month = months[date.getMonth()];
          const day = date.getDate();
          const year = date.getFullYear();
          let hours = date.getHours();
          const ampm = hours >= 12 ? "PM" : "AM";
          hours = hours % 12;
          hours = hours ? hours : 12;
          const minutes = date.getMinutes();
          return `${month} ${day < 10 ? "0" : ""}${day}, ${year} ${hours}:${
            minutes < 10 ? "0" : ""
          }${minutes} ${ampm}`;
        }

        return {
          key: info.softwareAssetsId.toString(),
          Department: `${info.category}`,
          Description: `${info.description}`,
          Provider: info.provider,
          Licensekey: info.licenseKey,
          SubscriptionDate: <span>{formatDateTime(info.subscriptionDate)}</span>,
          ExpiryDate: <span>{formatDateTime(info.expiryDate)}</span>,
          AssetsCost: info.assetsCost,
          Notes: info.notes,
        };
      });

      // Update dataSource state
      setDataSource(updatedRows);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  return {
    softwareformData,
    setSoftwareFormData,
    formDataError,
    setFormDataError,
    handleChange,
    clearFields,
    validateValidation,
    isLoading,
    setSoftware,
    softwareware,
    onSubmit,
    handleNext,
    fetchData,
    dataSource
  };
}

export default SoftwareService;
