import React, { useState } from "react";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Footer from "layouts/authentication/components/Footer";

function AddPolicy() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: ""
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate("/associate-managers");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update form data
    setFormData({
      ...formData,
      [name]: value,
    });

    // Clear errors for this field if user starts typing again
    if (value.trim() !== "" && errors[name]) {
      setErrors({
        ...errors,
        [name]: undefined,
      });
    } else if (value.trim() === "") {
      // If value becomes empty again, re-validate
      setErrors({
        ...errors,
        [name]: `${name.charAt(0).toUpperCase() + name.slice(1)} is required`,
      });
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.password) {
      newErrors.password = "Password is required";
    } else {
      if (formData.password.length < 8) {
        newErrors.password = "Password must be at least 8 characters";
      } else if (
        !/(?=.*[a-z])/.test(formData.password) ||
        !/(?=.*[A-Z])/.test(formData.password) ||
        !/(?=.*\d)/.test(formData.password) ||
        !/(?=.*[@$!%*?&])/.test(formData.password)
      ) {
        newErrors.password =
          "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character";
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setIsLoading(true);
        await axios.post(`${apiUrl}/api/ChildManager/Add-ChildManager`, formData);
        alert("Child Manager Account Created successfully");
        navigate("/associate-managers");
      } catch (error) {
        alert(error.response.data);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <div className="card">
            <div className="card-header" style={{ position: "relative" }}>
              <h5>Add Associate Managers</h5>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit} encType="multipart/formData">
                <div className="row">
                  <div className="col-md-5">
                    <div className={`mb-3 ${errors.name ? "has-error" : ""}`}>
                      <label htmlFor="name" className="form-label" style={{ fontSize: "medium" }}>
                        Manager Name *
                      </label>
                      <input
                        type="text"
                        name="name"
                        className={`form-control ${errors.name ? "is-invalid" : ""}`}
                        placeholder="Enter manager name here"
                        value={formData.name}
                        onChange={handleChange}
                      />
                      {errors.name && (
                        <div className="invalid-feedback" style={{ fontSize: "medium" }}>
                          {errors.name}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className={`mb-3 ${errors.email ? "has-error" : ""}`}>
                      <label htmlFor="email" className="form-label" style={{ fontSize: "medium" }}>
                        Email *
                      </label>
                      <input
                        type="text"
                        name="email"
                        className={`form-control ${errors.email ? "is-invalid" : ""}`}
                        placeholder="Enter manager email here"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {errors.email && (
                        <div className="invalid-feedback" style={{ fontSize: "medium" }}>
                          {errors.email}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className={`mb-3 ${errors.password ? "has-error" : ""}`}>
                      <label
                        htmlFor="password"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Password *
                      </label>
                      <input
                        type="password"
                        name="password"
                        className={`form-control ${errors.password ? "is-invalid" : ""}`}
                        placeholder="Enter strong password here"
                        value={formData.password}
                        onChange={handleChange}
                      />
                      {errors.password && (
                        <div className="invalid-feedback" style={{ fontSize: "medium" }}>
                          {errors.password}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <SoftButton
                    variant="outlined"
                    style={{ float: "inline-end" }}
                    color="info"
                    size="small"
                    type="submit"
                  >
                    {isLoading ? "Submitting..." : "Submit"}
                    {isLoading && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </SoftButton>
                  <SoftButton
                    variant="outlined"
                    style={{ float: "inline-end", marginRight: "10px" }}
                    color="error"
                    size="small"
                    onClick={handleCancel}
                  >
                    Cancel
                  </SoftButton>
                </div>
              </form>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default AddPolicy;
