import React, { useState, useMemo, useEffect } from "react";
import "./Job.scss";
import "react-datepicker/dist/react-datepicker.css";
import SoftButton from "components/SoftButton";
import { useDispatch } from "react-redux";
import { updateInitiateInfo } from "../../layouts/Services/Actions/actions";
import axios from "axios";
import InitiateService from "../EmployeeApiService/JobInitiateService/InitiateService";
import PropTypes from "prop-types";

export default function InitiateAccountability({ onNext, selectedEmail }) {
  const { formData, handleJobChange } = InitiateService({ onNext, selectedEmail });

  const dispatch = useDispatch();
  const handleNext = (e) => {
    e.preventDefault();

    // Validation check for required fields
    if (!formData.accountAbilities_one || !formData.weight_one || !formData.rating_one) {
      alert("Please fill out all required fields: Account abilities/Goal No.1, % Weight, Rating");
      return;
    }

    dispatch(updateInitiateInfo(formData));
    onNext();
  };

  return (
    <div>
      <div className="card">
        <div className="card-header" style={{ position: "relative" }}>
          <h5>Initiate</h5>
        </div>
        <div className="card-body">
          <div className="row">
            <label className="mb-3" style={{ textAlign: "left" }}>
              Account abilities/Goals
            </label>
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label"
                  style={{ fontSize: "medium" }}
                >
                  Account abilities/Goal No.1 *
                </label>
                <input
                  name="accountAbilities_one"
                  type="text"
                  value={formData.accountAbilities_one}
                  className="form-control"
                  onChange={handleJobChange}
                  placeholder="Enter Account abilities/Goal No.1 here"
                />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="form-group">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label"
                  style={{ fontSize: "medium" }}
                >
                  % Weight *
                </label>
                <input
                  type="number"
                  min={0}
                  max={10}
                  name="weight_one"
                  value={formData.weight_one}
                  className="form-control"
                  onChange={handleJobChange}
                  placeholder="Enter Weight here"
                />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="mb-3">
                <label htmlFor="exampleInput" className="form-label" style={{ fontSize: "medium" }}>
                  Rating *
                </label>
                <input
                  type="number"
                  min={0}
                  max={10}
                  name="rating_one"
                  value={formData.rating_one}
                  className="form-control"
                  onChange={handleJobChange}
                  placeholder="Enter Rating here"
                />
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label"
                  style={{ fontSize: "medium" }}
                >
                  Account abilities/Goal No.2 *
                </label>
                <input
                  name="accountAbilities_two"
                  type="text"
                  value={formData.accountAbilities_two}
                  className="form-control"
                  onChange={handleJobChange}
                  placeholder="Enter Account abilities/Goal No.2  here"
                />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="form-group">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label"
                  style={{ fontSize: "medium" }}
                >
                  % Weight *
                </label>
                <input
                  type="number"
                  min={0}
                  max={10}
                  name="weight_two"
                  value={formData.weight_two}
                  className="form-control"
                  onChange={handleJobChange}
                  placeholder="Enter Weight here"
                />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="mb-3">
                <label htmlFor="exampleInput" className="form-label" style={{ fontSize: "medium" }}>
                  Rating *
                </label>
                <input
                  type="number"
                  min={0}
                  max={10}
                  name="rating_two"
                  value={formData.rating_two}
                  className="form-control"
                  onChange={handleJobChange}
                  placeholder="Enter Rating here"
                />
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label"
                  style={{ fontSize: "medium" }}
                >
                  Account abilities/Goal No.3 *
                </label>
                <input
                  name="accountAbilities_three"
                  type="text"
                  value={formData.accountAbilities_three}
                  className="form-control"
                  onChange={handleJobChange}
                  placeholder="Enter Account abilities/Goal No.3  here"
                />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="form-group">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label"
                  style={{ fontSize: "medium" }}
                >
                  % Weight *
                </label>
                <input
                  type="number"
                  min={0}
                  max={10}
                  name="weight_three"
                  value={formData.weight_three}
                  className="form-control"
                  onChange={handleJobChange}
                  placeholder="Enter Weight here"
                />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="mb-3">
                <label htmlFor="exampleInput" className="form-label" style={{ fontSize: "medium" }}>
                  Rating *
                </label>
                <input
                  type="number"
                  min={0}
                  max={10}
                  name="rating_three"
                  value={formData.rating_three}
                  className="form-control"
                  onChange={handleJobChange}
                  placeholder="Enter Rating here"
                />
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label"
                  style={{ fontSize: "medium" }}
                >
                  Account abilities/Goal No.4 *
                </label>
                <input
                  name="accountAbilities_four"
                  type="text"
                  value={formData.accountAbilities_four}
                  className="form-control"
                  onChange={handleJobChange}
                  placeholder="Enter Account abilities/Goal No.4  here"
                />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="form-group">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label"
                  style={{ fontSize: "medium" }}
                >
                  % Weight *
                </label>
                <input
                  type="number"
                  min={0}
                  max={10}
                  name="weight_four"
                  value={formData.weight_four}
                  className="form-control"
                  onChange={handleJobChange}
                  placeholder="Enter Weight here"
                />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="mb-3">
                <label htmlFor="exampleInput" className="form-label" style={{ fontSize: "medium" }}>
                  Rating *
                </label>
                <input
                  type="number"
                  min={0}
                  max={10}
                  name="rating_four"
                  value={formData.rating_four}
                  className="form-control"
                  onChange={handleJobChange}
                  placeholder="Enter Rating here"
                />
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label"
                  style={{ fontSize: "medium" }}
                >
                  Account abilities/Goal No.5 *
                </label>
                <input
                  name="accountAbilities_five"
                  type="text"
                  value={formData.accountAbilities_five}
                  className="form-control"
                  onChange={handleJobChange}
                  placeholder="Enter Account abilities/Goal No.5  here"
                />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="form-group">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label"
                  style={{ fontSize: "medium" }}
                >
                  % Weight *
                </label>
                <input
                  type="number"
                  min={0}
                  max={10}
                  name="weight_five"
                  value={formData.weight_five}
                  className="form-control"
                  onChange={handleJobChange}
                  placeholder="Enter Weight here"
                />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="mb-3">
                <label htmlFor="exampleInput" className="form-label" style={{ fontSize: "medium" }}>
                  Rating *
                </label>
                <input
                  type="number"
                  min={0}
                  max={10}
                  name="rating_five"
                  value={formData.rating_five}
                  className="form-control"
                  onChange={handleJobChange}
                  placeholder="Enter Rating here"
                />
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-group">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label"
                  style={{ fontSize: "medium" }}
                >
                  Account abilities/Goal No.6 *
                </label>
                <input
                  name="accountAbilities_six"
                  type="text"
                  value={formData.accountAbilities_six}
                  className="form-control"
                  onChange={handleJobChange}
                  placeholder="Enter Account abilities/Goal No.6  here"
                />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="form-group">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label"
                  style={{ fontSize: "medium" }}
                >
                  % Weight *
                </label>
                <input
                  type="number"
                  min={0}
                  max={10}
                  name="weight_six"
                  value={formData.weight_six}
                  className="form-control"
                  onChange={handleJobChange}
                  placeholder="Enter Weight here"
                />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="mb-3">
                <label htmlFor="exampleInput" className="form-label" style={{ fontSize: "medium" }}>
                  Rating *
                </label>
                <input
                  type="number"
                  min={0}
                  max={10}
                  name="rating_six"
                  value={formData.rating_six}
                  className="form-control"
                  onChange={handleJobChange}
                  placeholder="Enter Rating here"
                />
              </div>
            </div>
            <div className="col-md-12">
              <SoftButton
                variant="outlined"
                style={{ float: "inline-end" }}
                color="info"
                size="small"
                onClick={handleNext}
              >
                Next
              </SoftButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

InitiateAccountability.propTypes = {
  onNext: PropTypes.func.isRequired,
  selectedEmail: PropTypes.object.isRequired,
};
