import React, { useState, useRef, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import "./TimeOff.scss";
import { Table } from "antd";
import Grid from "@mui/material/Grid";

import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import gradientLineChartData from "layouts/dashboard/components/data/gradientLineChartData";

import SoftBox from "components/SoftBox";
import { DownloadOutlined } from "@mui/icons-material";
import AddPolicyInputFields from "./AddPolicyInputFields";
import LeaveRequest from "./LeaveRequest";
import EmployeesOnLeave from "./EmployeesOnLeave";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Checkbox, Row } from "antd";
import TimeOffService from "layouts/APIServices/AttendanceReport/TimeOffService";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";

import { ExpandMore } from "@mui/icons-material";
import Footer from "layouts/authentication/components/Footer";

function TimeOff() {
  const {
    showDetail,
    showAddPolicy,
    showNewEmployee,
    leavePolicies,
    policyDetails,
    employees,
    datasourceDetail,
    handleDetail,
    handleAddPolicy,
    handlePolicySubmissionSuccess,
    addNewEmployee,
    handleDownload,
    columnsDetail,
    formatDateTime,
    onChange,
    handleFormSubmit,
    anchorRef,
    fetchLeavePolicies,
    enrolledEmployees,
  } = TimeOffService();
  const [expanded, setExpanded] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(true);
  const [loadedData, setLoadedData] = useState(false);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Set loading to true when fetching data
        await fetchLeavePolicies();
        setLoadedData(true); // Set loadedData to true when data is loaded
      } catch (error) {
        console.error("Error fetching leave policies:", error);
      } finally {
        setLoading(false); // Set loading to false after data fetching is complete
      }
    };
    fetchData();
  }, [fetchLeavePolicies]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const handleRemovePolicy = async (id) => {
    if (!id) {
      console.error("Policy ID is undefined");
      toast.error("Invalid policy ID");
      return;
    }
    try {
      const response = await axios.delete(`${apiUrl}/api/LeaveAddPolicy/Delete-Leave-Policy/${id}`);
      toast.success("Policy removed successfully");
      fetchLeavePolicies();
    } catch (error) {
      console.error("Error removing Policy:", error);
      toast.error("Error removing Policy");
    }
  };

  const options = employees.map((employee) => ({
    label: `${employee.firstName} ${employee.lastName} --- ${employee.email}`,
    value: JSON.stringify(employee),
    disabled: enrolledEmployees.some((enrolled) => enrolled.email === employee.email),
  }));

  useEffect(() => {
    const fetchLeaveEmployee = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/LeaveAddPolicy/Get-request-data`);
        setRows(response.data);
      } catch (error) {
        toast.error("Error fetching leave requests");
      }
    };

    fetchLeaveEmployee();
  }, [apiUrl]);

  const handleDownloadReport = () => {
    const doc = new jsPDF();

    doc.setFontSize(18);
    doc.text("Employee Leave Report", 14, 22);

    const lineY = 28;
    const lineWidth = 0.5;
    const lineColor = [192, 192, 192];
    doc.setLineWidth(lineWidth);
    doc.setDrawColor(...lineColor);
    doc.line(14, lineY, 200, lineY);

    const marginTop = 3;
    const marginTopEOM = 2;
    doc.setFontSize(12);
    doc.text("Employee: All - All employees selected", 14, 32 + marginTop);

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString();

    doc.text(`Report Date: ${formattedDate}`, 14, 42 + marginTopEOM);
    doc.text("Exceptions: No Deletions", 14, 52);
    doc.text("Department: All Department", 14, 59);

    const headers = [
      ["Employee Name", "Email", "Department", "Days Off", "From", "To", "Leave Policy", "Status"],
    ];
    const data = rows.map((row) => {
      const employeeName = `${row.employeeFirstName} ${row.employeeLastName}`;
      const employeeEmail = row.email;
      const department = row.department;
      const daysOff = row.amountDays;
      const fromDate = row.from;
      const toDate = row.to;
      const leavePolicy = row.timeOffCategory;
      const status = row.status === 1 ? "Active" : "Inactive";

      return [
        employeeName,
        employeeEmail,
        department,
        daysOff,
        fromDate,
        toDate,
        leavePolicy,
        status,
      ];
    });

    doc.autoTable({
      startY: 62,
      head: headers,
      body: data,
      headerStyles: {
        fillColor: [192, 192, 192],
        fontSize: 8,
      },
      styles: {
        fontSize: 8,
      },
    });

    doc.save("employee_leave_report_AllDepartment.pdf");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer />
      {showDetail && showAddPolicy && (
        <>
          <SoftBox py={3}>
            <SoftBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={5}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h6
                      className="h6-timeoffdetail"
                      style={{ marginLeft: "15px", color: "cadetblue" }}
                    >
                      Leaves Policy
                    </h6>
                    <SoftButton
                      variant="outlined"
                      color="info"
                      size="small"
                      onClick={handleAddPolicy}
                    >
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;Add Policy
                    </SoftButton>
                  </div>

                  <div className="card cardSet">
                    <div className="card-body d-flex justify-content-center align-items-center">
                      <div className="timeDetail-card mb-2">
                        <div className="row">
                          <div className="col-md-12 d-flex flex-column">
                            {loading && !loadedData ? (
                              <p style={{ fontSize: "medium" }}>Loading...</p>
                            ) : (
                              <>
                                {leavePolicies.length > 0 ? (
                                  <>
                                    {leavePolicies.slice(0, 4).map((policy) => (
                                      <SoftButton
                                        key={policy.leavepolicyId}
                                        variant="outlined"
                                        color="dark"
                                        size="large"
                                        style={{
                                          fontSize: "medium",
                                          flex: 1,
                                          marginBottom: "10px",
                                        }}
                                        onClick={() => handleDetail(policy)}
                                      >
                                        {policy.policyName}

                                        <span
                                          style={{
                                            position: "absolute",
                                            top: "50%",
                                            right: "10px",
                                            transform: "translateY(-50%)",
                                            cursor: "pointer",
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleRemovePolicy(policy.leavepolicyId);
                                          }}
                                        >
                                          <i className="fas fa-times" style={{ color: "red" }} />
                                        </span>
                                      </SoftButton>
                                    ))}
                                    {leavePolicies.length > 4 && (
                                      <SoftButton
                                        variant="outlined"
                                        color="dark"
                                        size="large"
                                        style={{
                                          fontSize: "medium",
                                          flex: 1,
                                          marginBottom: "10px",
                                        }}
                                        onClick={toggleExpanded}
                                      >
                                        <ExpandMore />
                                      </SoftButton>
                                    )}
                                    {expanded &&
                                      leavePolicies.slice(4).map((policy) => (
                                        <SoftButton
                                          key={policy}
                                          variant="outlined"
                                          color="dark"
                                          size="large"
                                          style={{
                                            fontSize: "medium",
                                            flex: 1,
                                            marginBottom: "10px",
                                          }}
                                          onClick={() => handleDetail(policy)}
                                        >
                                          {policy.policyName}
                                          <span
                                            style={{
                                              position: "absolute",
                                              top: "50%",
                                              right: "10px",
                                              transform: "translateY(-50%)",
                                              cursor: "pointer",
                                            }}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              handleRemovePolicy(policy.leavepolicyId);
                                            }}
                                          >
                                            <i className="fas fa-times" style={{ color: "red" }} />
                                          </span>
                                        </SoftButton>
                                      ))}
                                  </>
                                ) : (
                                  <p style={{ fontSize: "medium" }}>No policy found.</p>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} lg={7}>
                  <GradientLineChart
                    title="Vacation Report"
                    description={
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox fontSize={30} color="success" lineHeight={0} ml={57} mt={-4}>
                          <DownloadOutlined
                            onClick={handleDownloadReport}
                            style={{ cursor: "pointer" }}
                            className="font-bold"
                          >
                            arrow_upward
                          </DownloadOutlined>
                        </SoftBox>
                      </SoftBox>
                    }
                    height="15.25rem"
                    chart={gradientLineChartData}
                  />
                </Grid>
              </Grid>
            </SoftBox>
          </SoftBox>
          <LeaveRequest />
          <br />
          <EmployeesOnLeave />
        </>
      )}
      {!showDetail &&
        policyDetails.length > 0 &&
        policyDetails.map((policy, index) => (
          <div key={index} className="row">
            <div className="col-md-5">
              <div
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <h6
                  className="h6-trainingdetail"
                  style={{ marginLeft: "15px", color: "cadetblue" }}
                >
                  Policy Detail
                </h6>
                <SoftButton
                  variant="outlined"
                  color="dark"
                  size="small"
                  onClick={handlePolicySubmissionSuccess}
                >
                  Back
                </SoftButton>
              </div>

              <div className="card cardSet">
                <div className="card-body">
                  <div className="trainingDetail-card mb-2">
                    <h6 className="h6-trainingdetail">Policy Id</h6>
                    <span className="h6-trainingdetail">{policy.leavepolicyId}</span>
                  </div>
                  <div className="trainingDetail-card mb-2">
                    <h6 className="h6-trainingdetail">Policy Name</h6>
                    <span className="h6-trainingdetail">{policy.policyName}</span>
                  </div>
                  <div className="trainingDetail-card mb-2">
                    <h6 className="h6-trainingdetail">Granted Days</h6>
                    <span className="h6-trainingdetail">{policy.grantedDays}</span>
                  </div>
                  <div className="trainingDetail-card mb-2">
                    <h6 className="h6-trainingdetail">Approvals</h6>
                    <span className="h6-trainingdetail">{policy.approvals}</span>
                  </div>
                  <div className="trainingDetail-card mb-2">
                    <h6 className="h6-trainingdetail">Enrollments</h6>
                    <span className="h6-trainingdetail">{policy.enrollments}</span>
                  </div>
                  <div className="trainingDetail-card mb-2">
                    <h6 className="h6-trainingdetail">Updated at</h6>
                    <span className="h6-trainingdetail">
                      <span>{formatDateTime(policy.updatedAt)}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <h6
                  className="h6-trainingdetail"
                  style={{ marginLeft: "15px", color: "cadetblue" }}
                >
                  Enrolled Employee
                </h6>
                <SoftButton variant="outlined" color="info" size="small" onClick={addNewEmployee}>
                  Add New Employee
                </SoftButton>
              </div>
              <div className="card" style={{ marginTop: "10px", marginLeft: "15px" }}>
                <div className="card-body">
                  {showNewEmployee && (
                    <>
                      <Checkbox.Group
                        style={{
                          width: "100%",
                        }}
                        onChange={onChange}
                      >
                        {showNewEmployee && (
                          <>
                            <Checkbox.Group options={options} onChange={onChange} />
                            <br></br>
                            <div className="col-md-12">
                              <button
                                className="btn btn-info"
                                style={{ float: "inline-end" }}
                                onClick={() => handleFormSubmit(policy)}
                              >
                                Submit
                              </button>
                            </div>
                          </>
                        )}
                      </Checkbox.Group>
                    </>
                  )}

                  {!showNewEmployee && (
                    <Table
                      className="table-responsive"
                      bordered
                      dataSource={datasourceDetail}
                      columns={columnsDetail}
                      pagination={{ pageSize: 10 }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}

      {!showAddPolicy && <AddPolicyInputFields onSubmitSuccess={handlePolicySubmissionSuccess} />}
      
      <Footer />
    </DashboardLayout>
  );
}

export default TimeOff;
