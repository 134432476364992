import { Button, Input, message as antdMessage, Tooltip, Dropdown, Menu } from "antd";
import React, { useState, useEffect } from "react";
import SoftButton from "components/SoftButton";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import "./announcement.scss"; // Ensure to import your CSS file
import { useNavigate } from "react-router-dom";
import { Oval } from "react-loader-spinner"; // Import Oval from react-loader-spinner

function Announcement() {
  const [announcements, setAnnouncements] = useState([]);
  const [announcement, setAnnouncement] = useState("");
  const [announcementMessage, setAnnouncementMessage] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null); // State to hold the selected announcement for editing
  const [loading, setLoading] = useState(true); // Add loading state
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  const fetchAnnouncements = async () => {
    setLoading(true); // Set loading to true when fetching data
    try {
      const response = await axios.get(`${apiUrl}/api/Announcement/Get-Announcement`);
      setAnnouncements(response.data);
    } catch (error) {
      antdMessage.error(error.message);
    } finally {
      setLoading(false); // Set loading to false when data is fetched
    }
  };

  const handleAddAnnouncement = () => {
    setIsAdding(true);
    setEditMode(false); // Ensure editing mode is off when adding new announcement
    setSelectedAnnouncement(null); // Reset selected announcement
  };

  const handleCancel = () => {
    setIsAdding(false);
    setEditMode(false);
    setAnnouncement("");
    setAnnouncementMessage("");
    setSelectedAnnouncement(null);
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${apiUrl}/api/Announcement/Add-Announcement`, {
        announcement: announcement,
        message: announcementMessage,
      });
      if (response.status === 200) {
        antdMessage.success("Announcement added successfully");
        setIsAdding(false);
        setAnnouncement("");
        setAnnouncementMessage("");
        fetchAnnouncements(); // Refresh the announcements after submission
      } else {
        antdMessage.error("Failed to add announcement");
      }
    } catch (error) {
      console.log("An error occurred: " + error.message);
    }
  };

  const handleEditAnnouncement = async () => {
    try {
      const response = await axios.put(
        `${apiUrl}/api/Announcement/Edit-Announcement/${selectedAnnouncement.announcementId}`,
        {
          announcement: announcement,
          message: announcementMessage,
        }
      );
      if (response.status === 200) {
        antdMessage.success("Announcement updated successfully");
        setEditMode(false);
        setAnnouncement("");
        setAnnouncementMessage("");
        setSelectedAnnouncement(null);
        fetchAnnouncements(); // Refresh the announcements after update
      } else {
        antdMessage.error("Failed to update announcement");
      }
    } catch (error) {
      console.log("An error occurred: " + error.message);
    }
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const handleMenuClick = async (action, ann) => {
    switch (action) {
      case "view":
        navigate(`/announcement-detail/${ann.announcementId}`, { state: { announcement: ann } });
        break;
      case "edit":
        // Populate selected announcement for editing
        setEditMode(true);
        setSelectedAnnouncement(ann);
        setAnnouncement(ann.announcement);
        setAnnouncementMessage(ann.message);
        break;
      case "delete":
        // Display confirmation dialog
        const confirmDelete = window.confirm("Are you sure you want to delete this announcement?");
        if (confirmDelete) {
          try {
            await axios.delete(
              `${apiUrl}/api/Announcement/Delete-Announcement/${ann.announcementId}`
            );
            antdMessage.success("Announcement deleted successfully");
            fetchAnnouncements(); // Refresh the announcements after deletion
          } catch (error) {
            antdMessage.error("Failed to delete announcement: " + error.message);
          }
        }
        break;
      default:
        break;
    }
  };

  const menu = (ann) => (
    <Menu>
      <Menu.Item key="view" onClick={() => handleMenuClick("view", ann)}>
        View Announcement
      </Menu.Item>
      <Menu.Item key="edit" onClick={() => handleMenuClick("edit", ann)}>
        Edit
      </Menu.Item>
      <Menu.Item key="delete" onClick={() => handleMenuClick("delete", ann)}>
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="card" style={{ height: "100%" }}>
      <div className="card-header">
        <div className="announcement-header">
          <h5 className="announcement-title">Announcement</h5>
          {!isAdding && !editMode && (
            <Button
              type="primary"
              className="add-announcement-button"
              onClick={handleAddAnnouncement}
            >
              Add Announcement
            </Button>
          )}
        </div>
      </div>

      <div className="card-body">
        <div className="row">
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Oval
                height={40}
                width={40}
                color="#4fa94d"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#4fa94d"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          ) : (
            <>
              {!isAdding && !editMode && (
                <div className="announcement-list">
                  {announcements.length > 0 ? (
                    announcements.map((ann) => (
                      <div key={ann.announcementId} className="announcement-container">
                        <FontAwesomeIcon icon={faInfoCircle} className="frame-icon" />
                        <div className="announcement-content">
                          <p className="announcement-text">
                            <Tooltip title={ann.announcement} trigger="hover">
                              {truncateText(ann.announcement, 50)}
                            </Tooltip>
                          </p>
                          <p className="message-text">
                            <Tooltip title={ann.message} trigger="hover">
                              {truncateText(ann.message, 45)}
                            </Tooltip>
                          </p>
                        </div>
                        <Dropdown overlay={menu(ann)} placement="bottomRight">
                          <Button className="complete-button" color="primary">
                            Actions
                          </Button>
                        </Dropdown>
                      </div>
                    ))
                  ) : (
                    <span style={{ fontSize: "medium", textAlign: "center" }}>
                      No announcement added
                    </span>
                  )}
                </div>
              )}
              {(isAdding || editMode) && (
                <div>
                  <p>Subject:</p>
                  <Input
                    placeholder="Enter announcement"
                    value={announcement}
                    onChange={(e) => setAnnouncement(e.target.value)}
                  />
                  <p>Message:</p>
                  <Input
                    placeholder="Enter message"
                    value={announcementMessage}
                    onChange={(e) => setAnnouncementMessage(e.target.value)}
                  />
                  <div style={{ marginTop: "10px" }}>
                    {isAdding ? (
                      <>
                        <SoftButton
                          variant="contained"
                          color="primary"
                          style={{ marginRight: "10px" }}
                          onClick={handleSubmit}
                        >
                          Submit
                        </SoftButton>
                        <SoftButton variant="outlined" color="secondary" onClick={handleCancel}>
                          Cancel
                        </SoftButton>
                      </>
                    ) : (
                      <div>
                        <SoftButton
                          variant="contained"
                          color="primary"
                          style={{ marginRight: "10px" }}
                          onClick={handleEditAnnouncement}
                        >
                          Update
                        </SoftButton>
                        <SoftButton variant="outlined" color="secondary" onClick={handleCancel}>
                          Cancel
                        </SoftButton>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Announcement;
