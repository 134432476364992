import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useState, useEffect, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import SoftButton from "components/SoftButton";
import "./complaintDetail.scss";
import { useNavigate } from "react-router-dom";
import Footer from "layouts/authentication/components/Footer";

function ViweCorrectiveDetail() {
  const { complaintId } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [complaintDetail, setComplaintDetail] = useState(null);
  const backtoComplaint = () => {
    navigate("/complaints");
  };

  useEffect(() => {
    async function fetchComplaintDetail() {
      try {
        const response = await axios.get(
          `${apiUrl}/api/CorrectiveComplaintAction/GetComplaint-by-ComplaintId/${complaintId}`
        );
        setComplaintDetail(response.data);
      } catch (error) {
        console.error("Error fetching employee detail:", error);
      }
    }
    fetchComplaintDetail();
  }, [complaintId]);

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <ToastContainer />
        <div className="body flex-grow-1 px-3">
          <div className="row">
            <div className="col-md-10">
              <div
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <h6
                  className="h6-trainingdetail"
                  style={{ marginLeft: "15px", color: "cadetblue" }}
                >
                  Corrective Complaint Detail
                </h6>
              </div>
              {complaintDetail && (
                <>
                  <div className="card cardSet">
                    <div className="card-body">
                      <div className="trainingDetail-card mb-2">
                        <h6 className="h6-trainingdetail">Associate Name </h6>
                        <span className="h6-trainingdetail">{complaintDetail.assosiateName}</span>
                      </div>
                      <div className="trainingDetail-card mb-2">
                        <h6 className="h6-trainingdetail"> Email </h6>
                        <span className="h6-trainingdetail">{complaintDetail.email}</span>
                      </div>
                      <div className="trainingDetail-card mb-2">
                        <h6 className="h6-trainingdetail">Department name </h6>
                        <span className="h6-trainingdetail">{complaintDetail.departmentName}</span>
                      </div>
                      <div className="trainingDetail-card mb-2">
                        <h6 className="h6-trainingdetail">Date Of Incident </h6>
                        <span className="h6-trainingdetail">{complaintDetail.dateOfIncident}</span>
                      </div>
                      <div className="trainingDetail-card mb-2">
                        <h6 className="h6-trainingdetail">Type of Warning</h6>
                        <span className="h6-trainingdetail">{complaintDetail.typeOfWarning}</span>
                      </div>
                      <div className="trainingDetail-card mb-2">
                        <h6 className="h6-trainingdetail">Complaint Detail of Incident</h6>
                        <div className="incident-detail">
                          <span className="h6-trainingdetail">
                            {complaintDetail.detailOfIncident}
                          </span>
                        </div>
                      </div>

                      <div className="trainingDetail-card mb-2">
                        <h6 className="h6-trainingdetail">Action Plan(s) for Improvement:</h6>
                        <span className="h6-trainingdetail">{complaintDetail.improvement}</span>
                      </div>
                      <div className="trainingDetail-card mb-2">
                        <h6 className="h6-trainingdetail">Associate’s Comments:</h6>
                        <span className="h6-trainingdetail">
                          {complaintDetail.associateComments}
                        </span>
                      </div>
                      <div className="trainingDetail-card mb-2">
                        <h6 className="h6-trainingdetail">Summary of counseling session:</h6>
                        <span className="h6-trainingdetail">{complaintDetail.summarySession}</span>
                      </div>
                      <div className="col-md-12">
                        <SoftButton
                          variant="outlined"
                          style={{ float: "inline-end" }}
                          color="info"
                          size="small"
                          onClick={backtoComplaint}
                        >
                          Back
                        </SoftButton>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default ViweCorrectiveDetail;
