import DashboardLayout from "../LayoutNavbar/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../LayoutNavbar/Navbars/DashboardNavbar";
import { Table } from "antd";
import { useState, useEffect } from "react";
import axios from "axios";

function EmployeeAssets() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [datahardwareSource, setDatahardwareSource] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    fetchData();
    fetchHardwareData();
  }, []);

  async function fetchData() {
    try {
      const email = localStorage.getItem('loginEmail');
      if (!email) {
        console.error("No email found in local storage.");
        return;
      }

      // Fetch hardware assets by email
      const response = await axios.get(`${apiUrl}/api/Assets/Get-Software-Assets-By-Email`, {
        params: {
          email: email,
        }
      });
      const softwareassets = response.data; // Directly accessing the array
      const updatedRows = softwareassets.map((info) => {
        function formatDateTime(dateTimeString) {
          const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          const date = new Date(dateTimeString);
          const month = months[date.getMonth()];
          const day = date.getDate();
          const year = date.getFullYear();
          let hours = date.getHours();
          const ampm = hours >= 12 ? "PM" : "AM";
          hours = hours % 12;
          hours = hours ? hours : 12;
          const minutes = date.getMinutes();
          return `${month} ${day < 10 ? "0" : ""}${day}, ${year} ${hours}:${
            minutes < 10 ? "0" : ""
          }${minutes} ${ampm}`;
        }

        return {
          key: info.softwareAssetsId.toString(),
          Category: `${info.category}`,
          Description: `${info.description}`,
          Provider: info.provider,
          Licensekey: info.licenseKey,
          SubscriptionDate: <span>{formatDateTime(info.subscriptionDate)}</span>,
          ExpiryDate: <span>{formatDateTime(info.expiryDate)}</span>,
          AssetsCost: info.assetsCost,
          Notes: info.notes,
        };
      });

      // Update dataSource state
      setDataSource(updatedRows);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async function fetchHardwareData() {
    try {
      // Retrieve email from local storage
      const email = localStorage.getItem('loginEmail');
      if (!email) {
        console.error("No email found in local storage.");
        return;
      }

      // Fetch hardware assets by email
      const response = await axios.get(`${apiUrl}/api/Assets/Get-Hardware-Assets-By-Email`, {
        params: {
          email: email,
        }
      });
      
      const hardware = response.data;

      // Generate rows based on the fetched data
      const updatedRows = hardware.map((info) => {
        function formatDateTime(dateTimeString) {
          const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December",
          ];
          const date = new Date(dateTimeString);
          const month = months[date.getMonth()];
          const day = date.getDate();
          const year = date.getFullYear();
          let hours = date.getHours();
          const ampm = hours >= 12 ? "PM" : "AM";
          hours = hours % 12;
          hours = hours ? hours : 12;
          const minutes = date.getMinutes();
          return `${month} ${day < 10 ? "0" : ""}${day}, ${year} ${hours}:${minutes < 10 ? "0" : ""}${minutes} ${ampm}`;
        }

        return {
          key: info.hardwareAssetsId.toString(),
          Category: `${info.category}`,
          Description: `${info.description}`,
          Serial: info.serial,
          DateLoaned: <span>{formatDateTime(info.dateLoaned)}</span>,
          DateReturned: <span>{formatDateTime(info.dateReturned)}</span>,
          AssetsCost: info.assetsCost,
          Notes: info.notes,
        };
      });

      // Update dataSource state
      setDatahardwareSource(updatedRows);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  

  const hardwarecol = [
    {
      title: "Category",
      dataIndex: "Category",
      key: "Category",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
    },
    {
      title: "Serial",
      dataIndex: "Serial",
      key: "Serial",
    },
    {
      title: "Date Loaned",
      dataIndex: "DateLoaned",
      key: "DateLoaned",
    },
    {
      title: "Date Returned",
      dataIndex: "DateReturned",
      key: "DateReturned",
    },
    {
      title: "Assets Cost",
      dataIndex: "AssetsCost",
      key: "AssetsCost",
    },
    {
      title: "Notes",
      dataIndex: "Notes",
      key: "Notes",
    },
  ];

  const softwarecol = [
    {
      title: "Category",
      dataIndex: "Category",
      key: "Category",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
    },
    {
      title: "Provider",
      dataIndex: "Provider",
      key: "Provider",
    },
    {
      title: "License key",
      dataIndex: "Licensekey",
      key: "Licensekey",
    },
    {
      title: "Subscription Date",
      dataIndex: "SubscriptionDate",
      key: "SubscriptionDate",
    },
    {
      title: "Expiry Date",
      dataIndex: "ExpiryDate",
      key: "ExpiryDate",
    },
    {
      title: "Assets Cost",
      dataIndex: "AssetsCost",
      key: "AssetsCost",
    },
    {
      title: "Notes",
      dataIndex: "Notes",
      key: "Notes",
    },
  ];
  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <div className="card">
            <div className="card-header" style={{ position: "relative" }}>
              <h5>Assets</h5>
            </div>
            <div className="card-body">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className={`accordion-button${activeIndex === 0 ? "" : " collapsed"}`}
                      type="button"
                      onClick={() => toggleAccordion(0)}
                      aria-expanded={activeIndex === 0 ? "true" : "false"}
                      aria-controls="collapseOne"
                    >
                      Hardware Assets
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className={`accordion-collapse collapse${activeIndex === 0 ? " show" : ""}`}
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="row mb-3">
                        <Table
                          className="table-responsive"
                          bordered
                          dataSource={datahardwareSource}
                          columns={hardwarecol}
                          pagination={{ pageSize: 10 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className={`accordion-button${activeIndex === 2 ? "" : " collapsed"}`}
                      type="button"
                      onClick={() => toggleAccordion(2)}
                      aria-expanded={activeIndex === 2 ? "true" : "false"}
                      aria-controls="collapseThree"
                    >
                      Software Assets
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className={`accordion-collapse collapse${activeIndex === 2 ? " show" : ""}`}
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="row mb-3">
                        <Table
                          className="table-responsive"
                          bordered
                          dataSource={dataSource}
                          columns={softwarecol}
                          pagination={{ pageSize: 10 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default EmployeeAssets;
