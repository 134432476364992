import { Table, Select } from "antd";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import axios from "axios";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Footer from "layouts/authentication/components/Footer";

function SkillsReport() {
  const apiUrl = process.env.REACT_APP_API_URL; // Ensure this is defined in your environment variables

  const columns = [
    {
      title: "Serial No",
      key: "serialNo",
      render: (text, record, index) => index + 1, // Generate serial number based on index
    },
    {
      title: "Post Name",
      dataIndex: "postingJobTitle",
      key: "postingJobTitle",
      render: (text, record) => record.hiring.postingJobTitle, // Access nested object
    },
    {
      title: "Employee Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (text, record) => `${record.firstName} ${record.lastName}`, // Combine first and last names
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      render: (text, record) => record.hiring.department, // Access nested object
    },
    {
      title: "Skills",
      dataIndex: "skills",
      key: "skills",
      render: (text, record) => record.hiring.skills.split(", ").join(", "), // Access nested object and join array
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (record.status === 8 ? "Qualified" : record.status),
    },
  ];

  const [dataSource, setDataSource] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [skills, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/api/EmployeeJobApplication/GetEmployee-Detail`)
      .then((response) => {
        if (response.status === 200) {
          const employees = response.data.getemployee.filter((employee) => employee.status === 8);
          setDataSource(employees);
          setFilteredData(employees); // Initialize filteredData with all employees

          // Extract skills and remove duplicates
          const allSkills = employees.flatMap((employee) => employee.hiring.skills.split(", "));
          const uniqueSkills = [...new Set(allSkills)];
          setSkills(uniqueSkills);
        } else {
          console.error("Error fetching data:", response.status, response.statusText);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [apiUrl]);

  const handleSkillsChange = (value) => {
    setSelectedSkills(value);

    if (value.length === 0) {
      setFilteredData(dataSource);
    } else {
      const filtered = dataSource.filter((employee) =>
        value.every((skill) => employee.hiring.skills.split(", ").includes(skill))
      );
      setFilteredData(filtered);
    }
  };

  const handleDownloadReport = () => {
    const doc = new jsPDF();

    doc.setFontSize(18);
    doc.text("Employee Skills Report", 14, 22);

    const lineY = 28;
    const lineWidth = 0.5;
    const lineColor = [192, 192, 192];
    doc.setLineWidth(lineWidth);
    doc.setDrawColor(...lineColor);
    doc.line(14, lineY, 200, lineY);

    const marginTop = 3;
    const marginTopEOM = 2;
    doc.setFontSize(12);

    // Employee section
    doc.text("Employee: All - All employees selected", 14, 32 + marginTop);

    // Current date
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString();
    doc.text(`Report Date: ${formattedDate}`, 14, 42 + marginTopEOM);

    // Exceptions section
    doc.text("Exceptions: No Deletions", 14, 52);

    // Department section, replaced with Skills
    const skillText = selectedSkills.length === 0 ? "All Skills" : selectedSkills.join(", ");
    doc.text(`Skill: ${skillText}`, 14, 59);

    const headers = [["Serial No", "Post Name", "Employee Name", "Department", "Skills", "Status"]];
    const data = filteredData.map((employee, index) => {
      const serialNo = index + 1;
      const postName = employee.hiring.postingJobTitle;
      const employeeName = `${employee.firstName} ${employee.lastName}`;
      const department = employee.hiring.department;
      const skills = employee.hiring.skills;
      const status = employee.status === 8 ? "Qualified" : employee.status;

      return [serialNo, postName, employeeName, department, skills, status];
    });

    doc.autoTable({
      startY: 62,
      head: headers,
      body: data,
      headerStyles: {
        fillColor: [192, 192, 192],
        fontSize: 8,
      },
      styles: {
        fontSize: 8,
      },
    });

    doc.save(`skills_report_${selectedSkills.join("_") || "all_skills"}.pdf`);
  };

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <div className="card">
            <div className="card-header" style={{ position: "relative" }}>
              <h5>Skills Report</h5>
            </div>
            <div className="card-body">
              <div className="mb-3 d-flex justify-content-between">
                <div className="col-md-5 mb-3">
                  <div className="form-group">
                    <Select
                      mode="tags"
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      placeholder="Please select skills"
                      options={skills.map((skill) => ({ value: skill, label: skill }))}
                      onChange={handleSkillsChange}
                      value={selectedSkills}
                    />
                  </div>
                </div>
                <SoftBox color="text" style={{ marginRight: "20px" }}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    size="small"
                    onClick={handleDownloadReport}
                  >
                    <i className="fa fa-download" aria-hidden="true"></i>&nbsp;Download Skills Wise
                    Reports
                  </SoftButton>
                </SoftBox>
              </div>

              <div className="row mb-3">
                <Table
                  className="table-responsive"
                  bordered
                  dataSource={filteredData}
                  columns={columns}
                  pagination={{ pageSize: 10 }}
                  rowKey="employeeId" // Ensure each row has a unique key
                />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default SkillsReport;
