import React, { useState, useEffect } from "react";
import { Button, Table, Spin } from "antd";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import "./SelfServices.scss";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import CustomerSupport from "assets/images/OrganizationHierarchy/customerSuccess.png";
import HR from "assets/images/OrganizationHierarchy/HumanResource.png";
import IT from "assets/images/OrganizationHierarchy/IT.png";
import Marketing from "assets/images/OrganizationHierarchy/maketing.png";
import Operations from "assets/images/OrganizationHierarchy/operations.png";
import Product from "assets/images/OrganizationHierarchy/product.png";
import Sales from "assets/images/OrganizationHierarchy/sales.png";
import UX from "assets/images/OrganizationHierarchy/UI.png";
import payrollimg from "assets/images/payroll.png";
import Footer from "layouts/authentication/components/Footer";

function OrganizationHierarchy() {
  const navigate = useNavigate();
  const [postingDetail, setPostingDetail] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [buttonText, setButtonText] = useState("Back To Report");
  const [employeesData, setEmployeesData] = useState([]);
  const [showEmployeeTable, setShowEmployeeTable] = useState(false);
  const [childEmployees, setChildEmployees] = useState({});
  const [loading, setLoading] = useState(false);
  const [expandedManager, setExpandedManager] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/HiringProcess/Getposting-Detail`);
        setPostingDetail(response.data.postingDetail);
      } catch (error) {
        console.error("Error fetching posting detail:", error);
      }
    };

    fetchData();
  }, []);

  const handleBack = () => {
    if (showEmployeeTable) {
      setShowEmployeeTable(false);
      setShowTable(true);
    } else if (showTable) {
      setShowTable(false);
      setButtonText("Back To Report");
      setSelectedDepartment(null);
    } else {
      navigate("/report");
    }
  };

  const departmentClickHandler = (department) => {
    setSelectedDepartment(department);
    setShowTable(true);
    setButtonText("Back");
  };

  const departments = [
    { name: "Customer Success", image: CustomerSupport },
    { name: "Human Resources", image: HR },
    { name: "IT", image: IT },
    { name: "Finance", image: payrollimg },
    { name: "Marketing", image: Marketing },
    { name: "Operations", image: Operations },
    { name: "Product", image: Product },
    { name: "Sales", image: Sales },
  ];

  const filteredData = postingDetail.filter((job) => job.department === selectedDepartment);

  const columns = [
    {
      title: "Job Title",
      dataIndex: "postingJobTitle",
      key: "postingJobTitle",
    },
    {
      title: "Recruiter",
      dataIndex: "recruiter",
      key: "recruiter",
    },
    {
      title: "Hiring Lead",
      dataIndex: "hiringLead",
      key: "hiringLead",
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => showJobTitleEmployees(record.postingJobTitle, record.department)}
        >
          <i className="fas fa-eye" /> View Employees
        </Button>
      ),
    },
  ];

  const showJobTitleEmployees = async (jobTitle, department) => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/PersonalInformation/Get-PersonalInfo-By-PostingTitle/${jobTitle}`
      );
      setEmployeesData(response.data.map((employee) => ({ ...employee, department })));
      setShowTable(false);
      setShowEmployeeTable(true);
    } catch (error) {
      console.error("Error fetching job title employees:", error);
    }
  };

  const fetchChildEmployees = async (managerName) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${apiUrl}/api/Job/GetEmployees-JobDataby-Manager/${managerName}`
      );
      setChildEmployees((prev) => ({ ...prev, [managerName]: response.data }));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching child employees:", error);
      setLoading(false);
    }
  };

  const handleEmployeeClick = (employee) => {
    const managerName = `${employee.firstName} ${employee.lastName}`;
    if (expandedManager === managerName) {
      setExpandedManager(null);
    } else {
      setExpandedManager(managerName);
      if (!childEmployees[managerName]) {
        fetchChildEmployees(managerName);
      }
    }
  };

  const handleChildEmployeeClick = (employee) => {
    const managerName = `${employee.personalInformation.firstName} ${employee.personalInformation.lastName}`;
    if (expandedManager === managerName) {
      setExpandedManager(null);
    } else {
      setExpandedManager(managerName);
      if (!childEmployees[managerName]) {
        fetchChildEmployees(managerName);
      }
    }
  };

  const renderEmployees = (employees, displayedEmployees = new Set(), isChild = false) => {
    return (
      <div className="employee-names-box">
        {employees.map((employee, index) => {
          const employeeName = isChild
            ? `${employee.personalInformation.firstName} ${employee.personalInformation.lastName}`
            : `${employee.firstName} ${employee.lastName}`;

          if (displayedEmployees.has(employeeName)) {
            return null; // Skip rendering this employee if already displayed
          }

          displayedEmployees.add(employeeName); // Mark this employee as displayed

          return (
            <div className="employee-container" key={index}>
              <span
                className="employee-name"
                onClick={() =>
                  isChild ? handleChildEmployeeClick(employee) : handleEmployeeClick(employee)
                }
              >
                {employeeName}
              </span>
              {expandedManager === employeeName && childEmployees[employeeName] && (
                <div className="child-employees">
                  {renderEmployees(childEmployees[employeeName], displayedEmployees, true)}
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const renderJobTitles = (employees) => {
    const jobTitles = employees.reduce((acc, employee) => {
      const jobTitle = employee.employeeJobApplication.forJobApply;
      if (!acc[jobTitle]) {
        acc[jobTitle] = { employees: [], department: employee.department };
      }
      acc[jobTitle].employees.push(employee);
      return acc;
    }, {});

    if (Object.keys(jobTitles).length === 0) {
      return (
        <div className="no-data">
          <span style={{ fontSize: "medium" }}>No Data Available</span>
        </div>
      );
    }

    return Object.keys(jobTitles).map((jobTitle, index) => (
      <div key={index} className="job-title-box">
        <div className="job-title">{`${jobTitle} - ${jobTitles[jobTitle].department}`}</div>
        <div className="employee-names">
          {renderEmployees(jobTitles[jobTitle].employees, new Set())}
        </div>
      </div>
    ));
  };

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h5 style={{ margin: 0 }}>Organization Hierarchy By Job Titles</h5>
              <Button type="primary" onClick={handleBack}>
                {buttonText}
              </Button>
            </div>

            <div className="card-body">
              {!showTable && !showEmployeeTable && (
                <div className="row">
                  {departments.map((dept, index) => (
                    <div className="col-xl-4 col-lg-4" key={index}>
                      <div
                        className="card cardpd"
                        style={{ height: "158px", cursor: "pointer" }}
                        onClick={() => departmentClickHandler(dept.name)}
                      >
                        <div className="cardpd-statistic-3 p-4">
                          <div className="mb-3">
                            <h6 className="card-title mb-0 selfService-text">{dept.name}</h6>
                          </div>
                          <div className="mb-2">
                            <h4 className="card-title mb-0 selfService-text">
                              <img src={dept.image} alt="" height="55px" />
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {showTable && (
                <div className="job-titles-table">
                  <Table
                    columns={columns}
                    className="table-responsive"
                    dataSource={filteredData}
                    rowKey="id"
                  />
                </div>
              )}

              {showEmployeeTable && (
                <div className="employee-table">
                  {loading ? (
                    <div className="loading">
                      <Spin />
                    </div>
                  ) : (
                    renderJobTitles(employeesData)
                  )}
                </div>
              )}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default OrganizationHierarchy;
