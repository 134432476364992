import { Table } from "antd";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Footer from "layouts/authentication/components/Footer";

function TrainingDetail() {
  const { trainingId } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL;

  const [trainingDetail, setTrainingDetail] = useState(null);
  const [enrolledCount, setEnrolledCount] = useState(0); // State variable for enrolled count

  useEffect(() => {
    async function fetchTrainingDetail() {
      try {
        const trainingResponse = await axios.get(`${apiUrl}/api/TrainingDevelopement/GetAllByTrainingId/${trainingId}`);
        setTrainingDetail(trainingResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchTrainingDetail();
    fetchDatalenrolled();
  }, [trainingId]);

  const [dataSourceenrolled, setDataSourceenrolled] = useState([]);

  async function fetchDatalenrolled() {
    try {
      const response = await axios.get(`${apiUrl}/api/InitiateAccountability/Get-Enrolled-Employee`);
      const responseData = response.data;
      if (Array.isArray(responseData)) {
        const filteredData = responseData.filter(info => info.trainingId === parseInt(trainingId));

        const updatedRows = filteredData.map((info) => {
          return {
            key: info.enrolledTrainingId.toString(),
            EmployeeID: info.personalInformation.personalId,
            Email: info.email,
            Provider: info.provider,
            Mentor: info.mentor,
          };
        });

        setDataSourceenrolled(updatedRows);
        setEnrolledCount(filteredData.length); // Update the enrolled count
      } else {
        console.error("Unexpected response data format:", responseData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  function formatDateTime(dateTimeString) {
    const months = [
      "January", "February", "March", "April", "May", "June", "July",
      "August", "September", "October", "November", "December"
    ];
    const date = new Date(dateTimeString);
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutes = date.getMinutes();
    return `${month} ${day < 10 ? "0" : ""}${day}, ${year} ${hours}:${minutes < 10 ? "0" : ""}${minutes} ${ampm}`;
  }

  const columnsTrainingDetail = [
    {
      title: "Employee_ID",
      dataIndex: "EmployeeID",
      key: "EmployeeID",
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
    },
    {
      title: "Provider",
      dataIndex: "Provider",
      key: "Provider",
    },
    {
      title: "Mentor",
      dataIndex: "Mentor",
      key: "Mentor",
    }
  ];

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <div className="row">
            <div className="col-md-5">
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <h6 className="h6-trainingdetail" style={{ marginLeft: "15px", color: "cadetblue" }}>
                  Training Detail
                </h6>
              </div>
              {trainingDetail && (
                <>
                  <div className="card cardSet">
                    <div className="card-body">
                      <div className="trainingDetail-card mb-2">
                        <h6 className="h6-trainingdetail">Name </h6>
                        <span className="h6-trainingdetail">{trainingDetail.trainingInfo.trainingName}</span>
                      </div>
                      <div className="trainingDetail-card mb-2">
                        <h6 className="h6-trainingdetail">Provider</h6>
                        <span className="h6-trainingdetail">{trainingDetail.trainingInfo.provider}</span>
                      </div>
                      <div className="trainingDetail-card mb-2">
                        <h6 className="h6-trainingdetail">Mentor</h6>
                        <span className="h6-trainingdetail">{trainingDetail.trainingInfo.mentor}</span>
                      </div>
                      <div className="trainingDetail-card mb-2">
                        <h6 className="h6-trainingdetail">Budget</h6>
                        <span className="h6-trainingdetail">{trainingDetail.trainingInfo.allocated_budget}</span>
                      </div>
                      <div className="trainingDetail-card mb-2">
                        <h6 className="h6-trainingdetail">Enrollments</h6>
                        <span className="h6-trainingdetail">{enrolledCount}</span> {/* Displaying the enrolled count */}
                      </div>
                      <div className="trainingDetail-card mb-2">
                        <h6 className="h6-trainingdetail">Start Date</h6>
                        <span className="h6-trainingdetail">{formatDateTime(trainingDetail.trainingInfo.startDate)}</span>
                      </div>
                      <div className="trainingDetail-card mb-2">
                        <h6 className="h6-trainingdetail">End Date</h6>
                        <span className="h6-trainingdetail">{formatDateTime(trainingDetail.trainingInfo.endDate)}</span>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="col-md-7">
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <h6 className="h6-trainingdetail" style={{ marginLeft: "15px", color: "cadetblue" }}>
                  Enrolled Employee
                </h6>
              </div>
              <div className="card" style={{ marginTop: "10px", marginLeft: "15px" }}>
                <div className="card-body">
                  <Table
                    className="table-responsive"
                    bordered
                    dataSource={dataSourceenrolled}
                    columns={columnsTrainingDetail}
                    pagination={{ pageSize: 10 }}
                  />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default TrainingDetail;
