import React, { useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import DashboardLayout from "../../../LayoutNavbar/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../LayoutNavbar/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import "./announcement.scss";

function EmployeeAnnouncementDetail() {
  const location = useLocation();
  const { announcement } = location.state;
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/employeedashboard");
  };

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h5>Announcement Detail</h5>
              <SoftButton
                variant="outlined"
                style={{ float: "inline-end" }}
                color="info"
                size="small"
                type="text"
                onClick={handleBack}
              >
                Back to Dashboard
              </SoftButton>
            </div>
            <div className="card-body">
              <div className="row mb-3">
                <strong className="subject-detail">{announcement.announcement}</strong>
                <br />
                <p className="message-detail">{announcement.message}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default EmployeeAnnouncementDetail;
