import { Table } from "antd";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import axios from "axios";
import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Footer from "layouts/authentication/components/Footer";

function EnrolledEmployeeForTraining() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [dataSource, setDataSource] = useState([]);
  const [trainingNames, setTrainingNames] = useState([]);
  const [selectedTrainingName, setSelectedTrainingName] = useState("All Training Detail");

  useEffect(() => {
    fetchEnrolledEmployees();
  }, []);

  async function fetchEnrolledEmployees() {
    try {
      const response = await axios.get(
        `${apiUrl}/api/InitiateAccountability/Get-Enrolled-Employee`
      );

      if (response.status === 200) {
        const responseData = response.data;

        const updatedRows = responseData.map((info) => ({
          key: info.enrolledTrainingId.toString(),
          EmployeeID: info.personalInformation.personalId,
          name: `${info.personalInformation.firstName} ${info.personalInformation.lastName}`,
          Email: info.email,
          TrainingName: info.trainingDevelopement.trainingName,
          Provider: info.provider,
          Mentor: info.mentor || "",
          StartDate: info.startDate,
          EndDate: info.endDate,
        }));

        setDataSource(updatedRows);

        const uniqueTrainingNames = [...new Set(responseData.map(info => info.trainingDevelopement.trainingName))];
        setTrainingNames(uniqueTrainingNames);

      } else {
        console.error("Unexpected status code:", response.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const handleTrainingNameChange = (event) => {
    setSelectedTrainingName(event.target.value);
  };

  const handleDownloadReport = () => {
    const filteredData = selectedTrainingName === "All Training Detail"
      ? dataSource
      : dataSource.filter((item) => item.TrainingName === selectedTrainingName);

    const doc = new jsPDF();

    doc.setFontSize(18);
    doc.text("Training Detail Report", 14, 22);

    const lineY = 28;
    const lineWidth = 0.5;
    const lineColor = [192, 192, 192];
    doc.setLineWidth(lineWidth);
    doc.setDrawColor(...lineColor);
    doc.line(14, lineY, 200, lineY);

    const marginTop = 3;
    const marginTopEOM = 2;
    doc.setFontSize(12);
    doc.text("Training: All - All training details selected", 14, 32 + marginTop);

    // Get the current date
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString();

    doc.text(`Report Date: ${formattedDate}`, 14, 42 + marginTopEOM);
    doc.text("Exceptions: No Deletions", 14, 52);
    doc.text(`Training: ${selectedTrainingName}`, 14, 59);

    const headers = [
      [
        "Employee ID",
        "Name",
        "Email",
        "Training Name",
        "Provider",
        "Mentor",
        "Start Date",
        "End Date",
      ],
    ];
    const data = filteredData.map((employee) => {
      return [
        employee.EmployeeID,
        employee.name,
        employee.Email,
        employee.TrainingName,
        employee.Provider,
        employee.Mentor,
        employee.StartDate,
        employee.EndDate,
      ];
    });

    doc.autoTable({
      startY: 62,
      head: headers,
      body: data,
      headerStyles: {
        fillColor: [192, 192, 192],
        fontSize: 8,
      },
      styles: {
        fontSize: 8,
      },
    });

    doc.save(`training_detail_report_${selectedTrainingName}.pdf`);
  };

  const filteredDataSource = selectedTrainingName === "All Training Detail"
    ? dataSource
    : dataSource.filter((item) => item.TrainingName === selectedTrainingName);

  const columns = [
    {
      title: "Employee ID",
      dataIndex: "EmployeeID",
      key: "EmployeeID",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
    },
    {
      title: "Training Name",
      dataIndex: "TrainingName",
      key: "TrainingName",
    },
    {
      title: "Start Date",
      dataIndex: "StartDate",
      key: "StartDate",
    },
    {
      title: "End Date",
      dataIndex: "EndDate",
      key: "EndDate",
    },
    {
      title: "Provider",
      dataIndex: "Provider",
      key: "Provider",
    },
    {
      title: "Mentor",
      dataIndex: "Mentor",
      key: "Mentor",
    },
  ];

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <div className="card">
            <div className="card-header" style={{ position: "relative" }}>
              <h5>Enrolled Employees for Training and Development</h5>
            </div>
            <div className="card-body">
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <SoftBox color="text" style={{ marginRight: "20px" }}>
                  <div style={{ display: "inline-block" }}>
                    <SoftButton
                      variant="gradient"
                      color="info"
                      size="small"
                      onClick={handleDownloadReport}
                    >
                      <i className="fa fa-download" aria-hidden="true"></i>&nbsp;Download Employee
                      Reports
                    </SoftButton>
                  </div>
                </SoftBox>
                <div className="mb-3" style={{ display: "inline-block" }}>
                  <select
                    className="form-select form-select-sm"
                    value={selectedTrainingName}
                    onChange={handleTrainingNameChange}
                  >
                    <option value="All Training Detail">All Training Detail</option>
                    {trainingNames.map((training) => (
                      <option key={training} value={training}>
                        {training}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row mb-3">
                <Table
                  className="table-responsive"
                  bordered
                  dataSource={filteredDataSource}
                  columns={columns}
                  pagination={{ pageSize: 10 }}
                />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default EnrolledEmployeeForTraining;
