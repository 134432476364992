import SoftButton from "components/SoftButton";
import { Table } from "antd";
import { useState, useEffect } from "react";
import SoftwareService from "layouts/APIServices/AssetsService/SoftwareService";
import { Category, Description, Edit } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";


function SoftwareInputFields() {
  const {
    softwareformData,
    formDataError,
    handleChange,
    isLoading,
    onSubmit,
    handleNext,
    setSoftware,
    softwareware,
    dataSource
  } = SoftwareService();
  

  const handleSofware = () => {
    setSoftware(false);
  };

  const softwarecol = [
    {
      title: "Department",
      dataIndex: "Department",
      key: "Department",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
    },
    {
      title: "Provider",
      dataIndex: "Provider",
      key: "Provider",
    },
    {
      title: "License key",
      dataIndex: "Licensekey",
      key: "Licensekey",
    },
    {
      title: "Subscription Date",
      dataIndex: "SubscriptionDate",
      key: "SubscriptionDate",
    },
    {
      title: "Expiry Date",
      dataIndex: "ExpiryDate",
      key: "ExpiryDate",
    },
    {
      title: "Assets Cost",
      dataIndex: "AssetsCost",
      key: "AssetsCost",
    },
    {
      title: "Notes",
      dataIndex: "Notes",
      key: "Notes",
    },
  ];
  return (
    <>
      <ToastContainer />
      {softwareware && (
        <>
          <div className="col-md-12 mb-3">
            <SoftButton
              variant="outlined"
              style={{ float: "inline-end" }}
              color="info"
              size="small"
              onClick={handleSofware}
            >
              Add Sofware Assets
            </SoftButton>
          </div>
          <br></br>
          
          <h6>Overall Assigned Software Assets Data to Employees</h6>
          <Table
            className="table-responsive"
            bordered
            dataSource={dataSource}
            columns={softwarecol}
            pagination={{ pageSize: 10 }}
          />
        </>
      )}
      {!softwareware && (
        <>
          <form onSubmit={onSubmit} encType="multipart/form-data">
            <div className="row">
              <div className="col-md-5">
                <div className="mb-3">
                  <label
                    htmlFor="exampleInput"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Department *
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Department here"
                    name="category"
                    disabled
                    value={softwareformData.category}
                    onChange={handleChange}
                    className={`form-control ${formDataError.categoryError ? "is-invalid" : ""}`}
                  />
                  {formDataError.categoryError && (
                    <div className="invalid-feedback invalid-message">
                      {formDataError.categoryError}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <label
                    htmlFor="exampleInput"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Assets Cost *
                  </label>
                  <input
                    type="number"
                    min="0"
                    placeholder="Enter Assets cost here"
                    name="assetsCost"
                    value={softwareformData.assetsCost}
                    onChange={handleChange}
                    className={`form-control ${formDataError.assetsCostError ? "is-invalid" : ""}`}
                  />
                  {formDataError.assetsCostError && (
                    <div className="invalid-feedback invalid-message">
                      {formDataError.assetsCostError}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-5">
                <div className="mb-3">
                  <label
                    htmlFor="exampleInput"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Provider *
                  </label>

                  <input
                    type="text"
                    placeholder="Enter Provider here"
                    name="provider"
                    value={softwareformData.provider}
                    onChange={handleChange}
                    className={`form-control ${formDataError.providerError ? "is-invalid" : ""}`}
                  />
                  {formDataError.providerError && (
                    <div className="invalid-feedback invalid-message">
                      {formDataError.providerError}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <label
                    htmlFor="exampleInput"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    License key *
                  </label>
                  <input
                    type="text"
                    placeholder="License key"
                    name="licenseKey"
                    value={softwareformData.licenseKey}
                    onChange={handleChange}
                    className={`form-control ${formDataError.licenseKeyError ? "is-invalid" : ""}`}
                  />
                  {formDataError.licenseKeyError && (
                    <div className="invalid-feedback invalid-message">
                      {formDataError.licenseKeyError}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-5">
                <div className="mb-3">
                  <label
                    htmlFor="exampleInput"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Notes *
                  </label>
                  <input
                    type="text"
                    placeholder="Enter notes here"
                    name="notes"
                    value={softwareformData.notes}
                    onChange={handleChange}
                    className={`form-control ${formDataError.notesError ? "is-invalid" : ""}`}
                  />
                  {formDataError.notesError && (
                    <div className="invalid-feedback invalid-message">
                      {formDataError.notesError}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <label
                    htmlFor="exampleInput"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Subscription Date *
                  </label>
                  <input
                    type="date"
                    name="subscriptionDate"
                    value={softwareformData.subscriptionDate}
                    onChange={handleChange}
                    className={`form-control ${
                      formDataError.subscriptionDateError ? "is-invalid" : ""
                    }`}
                  />
                  {formDataError.subscriptionDateError && (
                    <div className="invalid-feedback invalid-message">
                      {formDataError.subscriptionDateError}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-5">
                <div className="mb-3">
                  <label
                    htmlFor="exampleInput"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Expiry Date *
                  </label>
                  <input
                    type="date"
                    name="expiryDate"
                    value={softwareformData.expiryDate}
                    onChange={handleChange}
                    className={`form-control ${formDataError.expiryDateError ? "is-invalid" : ""}`}
                  />
                  {formDataError.expiryDateError && (
                    <div className="invalid-feedback invalid-message">
                      {formDataError.expiryDateError}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <label
                    htmlFor="exampleInput"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Description *
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Description here"
                    name="description"
                    value={softwareformData.description}
                    onChange={handleChange}
                    className={`form-control ${formDataError.descriptionError ? "is-invalid" : ""}`}
                  />
                  {formDataError.descriptionError && (
                    <div className="invalid-feedback invalid-message">
                      {formDataError.descriptionError}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
          <div className="col-md-9">
            <SoftButton
              variant="outlined"
              style={{ float: "inline-end" }}
              color="info"
              size="small"
              onClick={handleNext}
            >
              {isLoading ? "Submitting..." : "Submit"}
              {isLoading && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </SoftButton>
          </div>
        </>
      )}
    </>
  );
}

export default SoftwareInputFields;
