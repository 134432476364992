import React from "react";
import "./Job.scss";
import "react-datepicker/dist/react-datepicker.css";
import SoftButton from "components/SoftButton";

export default function Resignation() {

  return (
    <div>
      <div className="card">
        <div className="card-header" style={{ position: "relative" }}>
          <h5>Resignation</h5>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-5 mb-3">
              <div className="form-group">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label"
                  style={{ fontSize: "medium" }}
                >
                  Date of Leaving *
                </label>
                <br></br>
                <input type="date" className="form-control" />
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="form-group">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label"
                  style={{ fontSize: "medium" }}
                >
                  Resignation Date *
                </label>
                <br></br>
                <input type="date" className="form-control" />
              </div>
            </div>
            <div className="col-md-5 mb-3">
              <div className="mb-3">
                <label htmlFor="exampleInput" className="form-label" style={{ fontSize: "medium" }}>
                  Notice Period *
                </label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter Notice Period here"
                />
              </div>
            </div>
            <div className="col-md-5 mb-3">
              <div className="form-group">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label"
                  style={{ fontSize: "medium" }}
                >
                  Reason of Leaving *
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Reason of Leaving  here"
                />
              </div>
            </div>
            <div className="col-md-10">
              <SoftButton
                variant="outlined"
                style={{ float: "inline-end" }}
                color="info"
                size="small"
              >
                Submit
              </SoftButton>
              <SoftButton
                style={{ float: "inline-end", marginRight: "10px" }}
                variant="outlined"
                color="dark"
                size="small"
                // onClick={handleCancelClick}
              >
                Cancel
              </SoftButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
