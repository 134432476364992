import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import Table from "examples/Tables/Table";

// Data
import data from "layouts/dashboard/components/Projects/data";

function Projects() {
  const { columns, rows } = data();

  return (
    <Card>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <SoftBox>
          <SoftTypography variant="h6" gutterBottom>
            <h5>Trainings</h5>
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "90px", // Adjust height as needed for your layout
        }}
      >
        {rows.length > 0 ? (
          <Table columns={columns} rows={rows} />
        ) : (
          <h6 style={{ fontSize: "medium", textAlign: "center" }}>No Training Available.</h6>
        )}
      </SoftBox>
    </Card>
  );
}

export default Projects;
