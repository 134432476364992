import { useState } from "react";
import './indexPeople.scss'
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// @mui material components
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Table from "examples/Tables/Table";

// Data
import data from "layouts/People/data";
import Footer from "layouts/authentication/components/Footer";

function PeopleTable() {
  const { columns, rows } = data();

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftBox style={{ display: "flex", justifyContent: "space-between" }}>
                <SoftTypography variant="h6" gutterBottom>
                  Employees
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={1}>
              <SoftBox style={{ display: "flex", justifyContent: "space-between" }}></SoftBox>

              <SoftBox color="text" px={2}>
                {/* First select dropdown */}
                {/* <div style={{ display: "inline-block" }}>
                  <select
                    className="form-select form-select-sm"
                    aria-label=".form-select-sm example"
                  >
                    <option selected>All Employees</option>
                    <option value="Managers">Managers</option>
                    <option value="New Employees">New Employees</option>
                    <option value="Terminated People">Terminated People</option>
                  </select>
                </div> */}
              </SoftBox>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={columns} rows={rows} />
            </SoftBox>
          </Card>
          <Footer />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default PeopleTable;