import React, { useState, useMemo, useEffect } from "react";
import "./Job.scss";
import { Table } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import SoftButton from "components/SoftButton";
import axios from "axios";
import { EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

export default function AvailableTraining({ selectedEmail }) {
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (selectedEmail) { // Ensure selectedEmail is available before fetching data
      fetchData();
    }
  }, [selectedEmail]);

  const [dataSource, setDataSource] = useState([]);

  async function fetchData() {
    try {
      const response = await axios.get(`${apiUrl}/api/TrainingDevelopement/Get-Training-Detail`);
      const responseData = response.data;
      const trainingDetail = responseData.trainingDetail;

      // Generate rows based on the fetched data
      const updatedRows = trainingDetail.map((info, index) => {
        function formatDateTime(dateTimeString) {
          const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          const date = new Date(dateTimeString);
          const month = months[date.getMonth()];
          const day = date.getDate();
          const year = date.getFullYear();
          let hours = date.getHours();
          const ampm = hours >= 12 ? "PM" : "AM";
          hours = hours % 12;
          hours = hours ? hours : 12;
          const minutes = date.getMinutes();
          return `${month} ${day < 10 ? "0" : ""}${day}, ${year} ${hours}:${
            minutes < 10 ? "0" : ""
          }${minutes} ${ampm}`;
        }

        return {
          key: info.trainingId.toString(),
          Name: `${info.trainingName}`,
          Provider: `${info.provider}`,
          Mentor: info.mentor,
          Enrollments: "N/A",
          Allocated_budget: info.allocated_budget,
          StartDate: <span>{formatDateTime(info.startDate)}</span>,
          EndDate: <span>{formatDateTime(info.endDate)}</span>,
          Action: (
            <span
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Link
                to={{
                  pathname: `/employeedashboard/enrolledTrainingEmployee/${info.trainingId}`,
                  // state: { selectedEmail }, // Ensure selectedEmail is passed in the state
                }}
              >
                <SoftButton variant="outlined" color="info" size="small">
                  Recommend
                </SoftButton>
              </Link>
            </span>
          ),
        };
      });

      // Update dataSource state
      setDataSource(updatedRows);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "Provider",
      dataIndex: "Provider",
      key: "Provider",
    },
    {
      title: "Mentor",
      dataIndex: "Mentor",
      key: "Mentor",
    },
    {
      title: "Enrollments",
      dataIndex: "Enrollments",
      key: "Enrollments",
    },
    {
      title: "Start Date",
      dataIndex: "StartDate",
      key: "StartDate",
    },
    {
      title: "End Date",
      dataIndex: "EndDate",
      key: "EndDate",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
    },
  ];

  return (
    <div>
      <div className="card">
        <div className="card-header" style={{ position: "relative" }}>
          <h5>Available Trainings</h5>
        </div>
        <div className="card-body">
          <div className="row">
            <label htmlFor="exampleInput" className="form-label" style={{ fontSize: "medium" }}>
              Available Trainings
            </label>
            <Table
              className="table-responsive"
              bordered
              dataSource={dataSource}
              columns={columns}
              pagination={{ pageSize: 10 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

AvailableTraining.propTypes = {
  selectedEmail: PropTypes.string,
};
