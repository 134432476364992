import { Table } from "antd";
import { EyeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import DashboardLayout from "../LayoutNavbar/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../LayoutNavbar/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import { useState, useEffect } from "react";
import SoftBox from "components/SoftBox";
import SoftProgress from "components/SoftProgress";
import "./Training.scss";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useParams } from "react-router-dom";

function EmployeeTrainingDetail() {
  const { trainingId } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL;
  
  const [trainingDetail, setTrainingDetail] = useState(null);
  useEffect(() => {
    async function fetchTrainingDetail() {
      try {
        const response = await axios.get(
          `${apiUrl}/api/TrainingDevelopement/GetAllByTrainingId/${trainingId}`
        );
        setTrainingDetail(response.data);
      } catch (error) {
        console.error("Error fetching employee detail:", error);
      }
    }

    fetchTrainingDetail();
  }, [trainingId]);

  function formatDateTime(dateTimeString) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date(dateTimeString);
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutes = date.getMinutes();
    return `${month} ${day < 10 ? "0" : ""}${day}, ${year} ${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${ampm}`;
  }

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <div className="row">
            <div className="col-md-8">
              <div
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <h6
                  className="h6-trainingdetail"
                  style={{ marginLeft: "15px", color: "cadetblue" }}
                >
                  Training Detail
                </h6>
              </div>
              {trainingDetail && (
                <>
                  <div className="card cardSet">
                    <div className="card-body">
                      <div className="trainingDetail-card mb-2">
                        <h6 className="h6-trainingdetail">Name </h6>
                        <span className="h6-trainingdetail">
                          {trainingDetail.trainingInfo.trainingName}
                        </span>
                      </div>
                      <div className="trainingDetail-card mb-2">
                        <h6 className="h6-trainingdetail">Provider</h6>
                        <span className="h6-trainingdetail">
                          {trainingDetail.trainingInfo.provider}
                        </span>
                      </div>
                      <div className="trainingDetail-card mb-2">
                        <h6 className="h6-trainingdetail">Mentor</h6>
                        <span className="h6-trainingdetail">
                          {" "}
                          {trainingDetail.trainingInfo.mentor}
                        </span>
                      </div>
                      <div className="trainingDetail-card mb-2">
                        <h6 className="h6-trainingdetail">Budget</h6>
                        <span className="h6-trainingdetail">
                          {" "}
                          {trainingDetail.trainingInfo.allocated_budget}
                        </span>
                      </div>
                      <div className="trainingDetail-card mb-2">
                        <h6 className="h6-trainingdetail">Start Date</h6>
                        <span className="h6-trainingdetail">
                          {formatDateTime(trainingDetail.trainingInfo.startDate)}
                        </span>
                      </div>
                      <div className="trainingDetail-card mb-2">
                        <h6 className="h6-trainingdetail">End Date</h6>
                        <span className="h6-trainingdetail">
                          {formatDateTime(trainingDetail.trainingInfo.endDate)}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default EmployeeTrainingDetail;
