import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

function HardwareService() {
  const { empId } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL;

  const [hardwareformData, setHardwareFormData] = useState({
    category: "",
    assetsCost: "",
    serial: "",
    dateLoaned: "",
    notes: "",
    dateReturned: "",
    description: "",
  });

  useEffect(() => {
    fetchEmployeeJobData(empId);
  }, [empId]);

  async function fetchEmployeeJobData(empId) {
    try {
      const response = await axios.get(
        `${apiUrl}/api/PersonalInformation/GetEmployeesById/${empId}`
      );
      const responseData = response.data;
      if (responseData.employeeJobApplications && responseData.employeeJobApplications.length > 0) {
        const firstEmployee = responseData.employeeJobApplications[0];
        const updatedFormData = { ...hardwareformData };
        updatedFormData.category = firstEmployee.hiring.department;
        setHardwareFormData(updatedFormData);
      } else {
        console.error("No employee data found.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const handleChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or target is undefined:", e);
      return;
    }
  
    const { name, value } = e.target;
  
    // Clear the error message if the value is not empty
    if (value.trim() !== "") {
      setFormDataError((prevState) => ({
        ...prevState,
        [`${name}Error`]: "",
      }));
    } else {
      // Set the error message if the value is empty
      setFormDataError((prevState) => ({
        ...prevState,
        [`${name}Error`]: `${name} is required.`,
      }));
    }
  
    // Custom validation for dateReturned against dateLoaned
    if (name === "dateReturned" && hardwareformData.dateLoaned) {
      const dateAssigned = new Date(hardwareformData.dateLoaned);
      const dateReturned = new Date(value);
      
      if (dateReturned <= dateAssigned) {
        setFormDataError((prevState) => ({
          ...prevState,
          [`${name}Error`]: "Returned date must be after the assigned date.",
        }));
      } else {
        setFormDataError((prevState) => ({
          ...prevState,
          [`${name}Error`]: "",
        }));
      }
    }
  
    // Custom validation for dateLoaned against dateReturned
    if (name === "dateLoaned" && hardwareformData.dateReturned) {
      const dateAssigned = new Date(value);
      const dateReturned = new Date(hardwareformData.dateReturned);
      
      if (dateReturned <= dateAssigned) {
        setFormDataError((prevState) => ({
          ...prevState,
          dateReturnedError: "Returned date must be after the assigned date.",
        }));
      } else {
        setFormDataError((prevState) => ({
          ...prevState,
          dateReturnedError: "",
        }));
      }
    }
  
    setHardwareFormData({ ...hardwareformData, [name]: value });
  };
  

  const [formDataError, setFormDataError] = useState({
    categoryError: "",
    assetsCostError: "",
    serialError: "",
    dateLoanedError: "",
    notesError: "",
    dateReturnedError: "",
    descriptionError: "",
  });

  const clearFields = () => {
    // Reset the form data to initial values
    setHardwareFormData({
      category: "",
      assetsCost: "",
      serial: "",
      dateLoaned: "",
      notes: "",
      dateReturned: "",
      description: "",
    });
  };

  function validateValidation(hardwareformData, setFormDataError) {
    let hasError = false;

    // Check each field for emptiness or invalidity
    Object.keys(hardwareformData).forEach((fieldName) => {
      if (
        (typeof hardwareformData[fieldName] === "string" &&
          (!hardwareformData[fieldName] || !hardwareformData[fieldName].trim())) || // For string fields
        (typeof hardwareformData[fieldName] === "number" &&
          (isNaN(hardwareformData[fieldName]) || hardwareformData[fieldName] === null)) // For numeric fields
      ) {
        const fieldNameWithSpaces = fieldName
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase());
        setFormDataError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: `${fieldNameWithSpaces} is required.`,
        }));
        hasError = true;
      } else {
        // Clear the error message if the field is not empty
        setFormDataError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: "", // Clear the error message
        }));
      }
    });

    // Check if any error occurred
    if (hasError) {
      return false;
    }

    // Check if all fields are filled out
    const isAllFieldsFilled = Object.values(hardwareformData).every(
      (value) => value !== null && value !== undefined && value !== ""
    );

    if (!isAllFieldsFilled) {
      // If any field is empty, display an error message
      setFormDataError((prevState) => ({
        ...prevState,
        genericError: "Please fill out all fields.",
      }));
      return false;
    }

    return true;
  }

  return {
    hardwareformData,
    setHardwareFormData,
    formDataError,
    setFormDataError,
    handleChange,
    clearFields,
    validateValidation,
  };
}

export default HardwareService;
