import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AddPolicyInputFieldsService() {
  const [loading, setLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [employees, setEmployees] = useState([]);

  const [formData, setFormData] = useState({
    policyName: "",
    grantedDays: "",
    approvals: "",
    enrolledEmployees: 0,
  });

  useEffect(() => {
    // Fetch employees data
    async function fetchEmployees() {
      try {
        const response = await axios.get(`${apiUrl}/api/PersonalInformation/Get-All`);
        setEmployees(response.data.personalInfo);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    }

    fetchEmployees();
    fetchLeavePolicies();
  }, []);

  const handleChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or target is undefined:", e);
      return;
    }

    const { name, value } = e.target;

    // Clear the error message if the value is not empty
    if (value.trim() !== "") {
      setFormDataError((prevState) => ({
        ...prevState,
        [`${name}Error`]: "",
      }));
    } else {
      // Set the error message if the value is empty
      setFormDataError((prevState) => ({
        ...prevState,
        [`${name}Error`]: `${name} is required.`,
      }));
    }

    if (name === "startDate") {
      const selectedDate = new Date(value);
      const currentDate = new Date();
      if (selectedDate <= currentDate) {
        // Changed from >= to <=
        setFormDataError((prevState) => ({
          ...prevState,
          [`${name}Error`]: "Please select a date after today.", // Changed the error message
        }));
        return;
      }
    }

    if (name === "endDate") {
      const selectedDate = new Date(value);
      const currentDate = new Date();
      if (selectedDate <= currentDate) {
        // Changed from >= to <=
        setFormDataError((prevState) => ({
          ...prevState,
          [`${name}Error`]: "Please select a date after today.", // Changed the error message
        }));
        return;
      }
    }

    // Update compensation field to $0 if budgetApproved is 'No'
    if (name === "budgetApproved" && value === "No") {
      setFormData({ ...formData, [name]: value, compensation: "$0" });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const [formDataError, setFormDataError] = useState({
    policyNameError: "",
    grantedDaysError: "",
    approvalsError: "",
  });

  const clearFields = () => {
    // Reset the form data to initial values
    setFormData({
      policyName: "",
      grantedDays: "",
      approvals: "",
      enrolledEmployees: "",
    });
  };

  function validateValidation(formData, setFormDataError) {
    let hasError = false;

    // Check each field for emptiness or invalidity
    Object.keys(formData).forEach((fieldName) => {
      if (
        (typeof formData[fieldName] === "string" &&
          (!formData[fieldName] || !formData[fieldName].trim())) || // For string fields
        (typeof formData[fieldName] === "number" &&
          (isNaN(formData[fieldName]) || formData[fieldName] === null)) // For numeric fields
      ) {
        const fieldNameWithSpaces = fieldName
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase());
        setFormDataError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: `${fieldNameWithSpaces} is required.`,
        }));
        hasError = true;
      } else {
        // Clear the error message if the field is not empty
        setFormDataError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: "", // Clear the error message
        }));
      }
    });

    // Check if any error occurred
    if (hasError) {
      return false;
    }

    // Check if all fields are filled out
    const isAllFieldsFilled = Object.values(formData).every(
      (value) => value !== null && value !== undefined && value !== ""
    );

    if (!isAllFieldsFilled) {
      // If any field is empty, display an error message
      setFormDataError((prevState) => ({
        ...prevState,
        genericError: "Please fill out all fields.",
      }));
      return false;
    }

    return true;
  }

  const handleSubmit = async () => {
    const isValid = validateValidation(formData, setFormDataError);
    if (isValid) {
      try {
        setLoading(true);
        const updatedFormData = { ...formData };
        const response = await axios.post(
          `${apiUrl}/api/LeaveAddPolicy/Add-Leave-Policy`,
          updatedFormData
        );
        clearFields();
        toast.success("Policy added successfully");
        fetchLeavePolicies();
        return true;
      } catch (error) {
        console.error("Error adding Policy:", error);
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data);
        } else {
          toast.error("Error adding Policy");
        }
        return false;
      } finally {
        setLoading(false);
      }
    }
  };

  const fetchLeavePolicies = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/LeaveAddPolicy/Get-Leave-Policy`);
    } catch (error) {
      console.error("Error fetching leave policies:", error);
      toast.error("Oops fetching leave policies");
    }
  };

  return {
    handleSubmit,
    formData,
    setFormData,
    formDataError,
    setFormDataError,
    handleChange,
    clearFields,
    validateValidation,
    loading,
    employees,
  };
}

export default AddPolicyInputFieldsService;
