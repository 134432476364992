import React, { useState } from "react";
import SoftButton from "components/SoftButton";
import PropTypes from "prop-types";
import "./Training.scss";
import { Table } from "antd";
import SoftBox from "components/SoftBox";
import SoftProgress from "components/SoftProgress";
import { EyeOutlined } from "@ant-design/icons";
import Footer from "layouts/authentication/components/Footer";

function Trainings({ onNext, onBack }) {
  const [training, setTraining] = useState(true);
  const handleeye = () => {
    setTraining(false);
  };
  const backtotraining = () => {
    setTraining(true);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "Provider",
      dataIndex: "Provider",
      key: "Provider",
    },
    {
      title: "Mentor",
      dataIndex: "Mentor",
      key: "Mentor",
    },
    {
      title: "Enrollment",
      dataIndex: "Enrollment",
      key: "Enrollment",
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
    },
  ];
  const datasource = [
    {
      key: "1",
      Name: "Web Development",
      Provider: "Udemy",
      Mentor: "Sir Hamad",
      Enrollment: "2-3-2024",
      Status: (
        <SoftBox width="8rem" textAlign="left">
          <SoftProgress value={40} color="info" variant="gradient" label={false} />
        </SoftBox>
      ),
      Action: (
        <span
          style={{
            fontSize: "17px",
            color: "black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <EyeOutlined onClick={handleeye} />
        </span>
      ),
    },
  ];
  const handleNext = () => {
    onNext();
  };
  const handleBack = () => {
    onBack();
  };
  return (
    <div className="body flex-grow-1 px-3">
      {training && (
        <div className="card">
          <div className="card-header" style={{ position: "relative" }}>
            <h5>Trainings</h5>
          </div>
          <div className="card-body">
            <div className="row mb-3">
              <Table
                className="table-responsive"
                bordered
                dataSource={datasource}
                columns={columns}
                pagination={{ pageSize: 10 }}
              />
            </div>
            <div className="col-md-12">
              <SoftButton
                variant="outlined"
                style={{ float: "inline-end" }}
                color="info"
                size="small"
                onClick={handleNext}
              >
                Next
              </SoftButton>
              <SoftButton
                variant="outlined"
                style={{ float: "inline-end", marginRight:"10px" }}
                color="black"
                size="small"
                onClick={handleBack}
              >
                Back
              </SoftButton>
            </div>
          </div>
        </div>
      )}
      {!training && (
        <>
          {" "}
          <div className="d-flex justify-content-center mb-3">
            <div className="col-xl-9 col-lg-9">
              <div className="card cardcardtraining">
                <div className="cardcardtraining-statistic-3 p-4">
                  <div className="mb-3">
                    <h6 className="card-title mb-0">Training Detail</h6>
                    <button
                      className="btn btn-outline-danger"
                      style={{ float: "inline-end", marginTop: "-30px", height: "35px" }}
                      onClick={backtotraining}
                    >
                      X
                    </button>
                  </div>
                  <hr></hr>
                  <div className="mb-3">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <h6 className="card-title mb-0" style={{ marginRight: "110px" }}>
                        Name
                      </h6>
                      <span style={{ marginRight: "100px" }}>::</span>
                      <h6 className="card-title mb-0">Web Development</h6>
                    </div>
                  </div>

                  <div className="mb-3">
                    <div style={{ display: "flex" }}>
                      <h6 className="card-title mb-0" style={{ marginRight: "92px" }}>
                        Provider
                      </h6>
                      <span style={{ marginRight: "100px" }}>::</span>
                      <h6 className="card-title mb-0">Udemy</h6>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div style={{ display: "flex" }}>
                      <h6 className="card-title mb-0" style={{ marginRight: "102px" }}>
                        Mentor
                      </h6>
                      <span style={{ marginRight: "100px" }}>::</span>
                      <h6 className="card-title mb-0">Sir Hamad</h6>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div style={{ display: "flex" }}>
                      <h6 className="card-title mb-0" style={{ marginRight: "75px" }}>
                        Enrollment
                      </h6>
                      <span style={{ marginRight: "100px" }}>::</span>
                      <h6 className="card-title mb-0">2-3-2024</h6>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div style={{ display: "flex" }}>
                      <h6 className="card-title mb-0" style={{ marginRight: "108px" }}>
                        Status
                      </h6>
                      <span style={{ marginRight: "100px" }}>::</span>
                      <h6 className="card-title mb-0">40</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <Footer />
    </div>
  );
}

Trainings.propTypes = {
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default Trainings;
