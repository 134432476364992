import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import "../dashboard/components/DocumentCards/DocumentCards.scss";
import payrollimg from "assets/images/payroll.png";
import attendance from "assets/images/attendance.png";
import hiringimg from "assets/images/hiringimg.png";
import salary from "assets/images/salary.jpg";
import skillsReport from "assets/images/skillsReport.png";
import departmentPerformance from "assets/images/departmentPerformance.jpg";
import managerPerformance from "assets/images/performance.png";
import orgHierarchy from "assets/images/orgHierarchy.png";
import traininganddevelopement from "assets/images/traininganddevelopement.png";
import leave from "assets/images/leave.png";
import { Link, useNavigate } from "react-router-dom";
import Footer from "layouts/authentication/components/Footer";
import { Card, Row, Col, Typography } from "antd";

function SelfService() {
  const navigate = useNavigate();
  const handleExpenseManagement = () => {
    navigate("/expenseManagement");
  };
  const reportsAttendance = () => {
    navigate("/reportsAttendance");
  };
  const timeOff = () => {
    navigate("/timeOff");
  };
  const salarySlip = () => {
    navigate("/salarySlip");
  };
  const hiring = () => {
    navigate("/recruitmentIntegration");
  };
  const TrainingDevelopement = () => {
    navigate("/enrolledEmployeeforTraining");
  };
  const SkillsReport = () => {
    navigate("/skillsReport");
  };
  const salaryBaseJobData = () => {
    navigate("/salaryAnalysis");
  };
  const organizationHierarchy = () => {
    navigate("/organizationHierarchy");
  };
  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <div className="card">
            <div
              className="card-header"
              style={{ position: "relative", display: "flex", alignItems: "center" }}
            >
              <h5 style={{ margin: "0" }}>Self Services</h5>
            </div>

            <div className="card-body">
              <div className="row">
                <div style={{ padding: "10px" }}>
                  <Row gutter={[16, 16]} justify="center">
                    <Col xs={24} sm={12} md={8} lg={6}>
                      <Card className="document-card first-card pointer" onClick={handleExpenseManagement}>
                        <div className="ribbon-content">
                          <img src={payrollimg} alt="" height="65px" />
                          <div className="card-content">
                            <p>Expense Management</p>
                          </div>
                        </div>
                      </Card>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6}>
                      <Card className="document-card second-card pointer" onClick={reportsAttendance}>
                        <div className="ribbon-content">
                          <img src={attendance} alt="" height="45px" />
                          <div className="card-content">
                            <p>Attendance</p>
                          </div>
                        </div>
                      </Card>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6}>
                      <Card className="document-card third-card pointer" onClick={timeOff}>
                        <div className="ribbon-content">
                          <img src={leave} alt="" height="45px" />
                          <div className="card-content">
                            <p>Time Off/Leaves</p>
                          </div>
                        </div>
                      </Card>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6}>
                      <Card className="document-card fourth-card pointer" onClick={salarySlip}>
                        <div className="ribbon-content">
                          <img src={payrollimg} alt="" height="65px" />
                          <div className="card-content">
                            <p>Salary Slip</p>
                          </div>
                        </div>
                      </Card>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6}>
                      <Card className="document-card fifth-card pointer" onClick={hiring}>
                        <div className="ribbon-content">
                          <img src={hiringimg} alt="" height="50px" />
                          <div className="card-content">
                            <p>Hiring</p>
                          </div>
                        </div>
                      </Card>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6}>
                      <Card className="document-card sixth-card pointer" onClick={TrainingDevelopement}>
                        <div className="ribbon-content">
                          <img src={traininganddevelopement} alt="" height="55px" />
                          <div className="card-content">
                            <p>Training and Development</p>
                          </div>
                        </div>
                      </Card>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6}>
                      <Card className="document-card first-card pointer" onClick={SkillsReport}>
                        <div className="ribbon-content">
                          <img src={skillsReport} alt="" height="85px" />
                          <div className="card-content">
                            <p>Skills Report</p>
                          </div>
                        </div>
                      </Card>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6}>
                      <Card className="document-card second-card pointer" onClick={salaryBaseJobData}>
                        <div className="ribbon-content">
                          <img src={salary} alt="" height="85px" />
                          <div className="card-content">
                            <p>Salary Analysis</p>
                          </div>
                        </div>
                      </Card>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6}>
                      <Card className="document-card third-card pointer" onClick={organizationHierarchy}>
                        <div className="ribbon-content">
                          <img src={orgHierarchy} alt="" height="55px" />
                          <div className="card-content">
                            <p style={{ marginTop: "10px" }}>Organization Hierarchy</p>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default SelfService;
