import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function WorkingHoursService() {
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [showdefinePolicy, setDefinePolicy] = useState(true);

  const [formData, setFormData] = useState({
    workingHoursFrom: "",
    workingHoursTo: "",
    presentTime: "",
    lateArrivalTime: "",
  });
  

  const handleChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or target is undefined:", e);
      return;
    }

    const { name, value } = e.target;

    // Update formData state
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Clear the error message if the value is not empty
    if (value.trim() !== "") {
      setFormDataError((prevState) => ({
        ...prevState,
        [`${name}Error`]: "",
      }));
    } else {
      // Set the error message if the value is empty
      setFormDataError((prevState) => ({
        ...prevState,
        [`${name}Error`]: `${name} is required.`,
      }));
    }
  };
  

  const handleAddWorkingHours = async () => {
    const isValid = validateValidation(formData, setFormDataError);
    if (isValid) {
      try {
        setIsLoading(true)
        const updatedFormData = { ...formData };
        const response = await axios.post(
          `${apiUrl}/api/AttendanceReport/AddWorking-Hours`,
          updatedFormData
        );
        clearFields();
        toast.success("Working Hours added successfully");
        setDefinePolicy(true);
      } catch (error) {
        console.error("Error Working Hours:", error);
        toast.error("Failed adding Working Hours");
      }finally{
        setIsLoading(false)
      }
    }
  };

  const [formDataError, setFormDataError] = useState({
    workingHoursFromError: "",
    workingHoursToError: "",
    presentTimeError: "",
    lateArrivalTimeError: "",
  });

  const clearFields = () => {
    setFormData({
      workingHoursFrom: "",
      workingHoursTo: "",
      presentTime: "",
      lateArrivalTime: "",
      absentTime: "",
    });
  }

  function validateValidation(formData, setFormDataError) {
    let hasError = false;

    // Check each field for emptiness or invalidity
    Object.keys(formData).forEach((fieldName) => {
      if (
        (typeof formData[fieldName] === "string" &&
          (!formData[fieldName] || !formData[fieldName].trim())) || // For string fields
        (typeof formData[fieldName] === "number" &&
          (isNaN(formData[fieldName]) || formData[fieldName] === null)) // For numeric fields
      ) {
        const fieldNameWithSpaces = fieldName
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase());
        setFormDataError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: `${fieldNameWithSpaces} is required.`,
        }));
        hasError = true;
      } else {
        // Clear the error message if the field is not empty
        setFormDataError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: "", // Clear the error message
        }));
      }
    });

    // Check if any error occurred
    if (hasError) {
      return false;
    }

    // Check if all fields are filled out
    const isAllFieldsFilled = Object.values(formData).every(
      (value) => value !== null && value !== undefined && value !== ""
    );

    if (!isAllFieldsFilled) {
      // If any field is empty, display an error message
      setFormDataError((prevState) => ({
        ...prevState,
        genericError: "Please fill out all fields.",
      }));
      return false;
    }

    return true;
  }

  // const handleDefinePolicy = async () => {
  //   try {
  //     const response = await axios.get(`${apiUrl}/api/AttendanceReport/GetLatestWorkingHours`);
  //     const latestWorkingHours = response.data;
  //     setFormData({
  //       workingHoursFrom: latestWorkingHours.workingHoursFrom,
  //       workingHoursTo: latestWorkingHours.workingHoursTo,
  //       presentTime: latestWorkingHours.presentTime,
  //       lateArrivalTime: latestWorkingHours.lateArrivalTime,
  //     });
  //     setDefinePolicy(false);
  //   } catch (error) {
  //     console.error("Error fetching latest working hours:", error);
  //     toast.error("Failed to fetch latest working hours");
  //   }
  // };

  const handleDefinePolicy = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/AttendanceReport/GetLatestWorkingHours`);
      const latestWorkingHours = response.data;

      if (latestWorkingHours) {
        setFormData({
          workingHoursFrom: latestWorkingHours.workingHoursFrom || "",
          workingHoursTo: latestWorkingHours.workingHoursTo || "",
          presentTime: latestWorkingHours.presentTime || "",
          lateArrivalTime: latestWorkingHours.lateArrivalTime || "",
        });
        setDefinePolicy(false);
      } else {
        setDefinePolicy(false);
      }
    } catch (error) {
      setDefinePolicy(false);
    }
  };

  return {
    formData,
    setFormData,
    formDataError,
    setFormDataError,
    handleChange,
    clearFields,
    validateValidation,
    isLoading,
    handleAddWorkingHours,
    setFormData,
    showdefinePolicy,
    handleDefinePolicy
  };
}

export default WorkingHoursService;
