import SoftButton from "components/SoftButton";
import "./TimeOff.scss";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import axios from "axios";
import AddPolicyInputFieldsService from "layouts/APIServices/AttendanceReport/AddPolicyInputFieldService";

export default function AddPolicyInputFields({ onSubmitSuccess }) {
  const { handleSubmit, formData, setFormData, formDataError, handleChange, loading, employees } =
    AddPolicyInputFieldsService();

  const apiUrl = process.env.REACT_APP_API_URL;
  const [userName, setUserName] = useState("HR"); // Default to "HR" until fetched

  useEffect(() => {
    const email = localStorage.getItem("hremail");
    if (email) {
      fetchUserNameByEmail(email);
    }
  }, []);

  async function fetchUserNameByEmail(email) {
    try {
      const response = await axios.get(
        `${apiUrl}/api/Auth/GetUserByEmail/${encodeURIComponent(email)}`
      );
      if (response.status === 200) {
        const { userName } = response.data;
        setUserName(userName);
        setFormData((prevData) => ({
          ...prevData,
          approvals: `${userName} HR Manager`, // Assign userName + "HR Manager" to approvals field
        }));
      } else {
        console.error("Unexpected status code:", response.status);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const handleFormSubmit = async () => {
    const isSuccess = await handleSubmit();
    if (isSuccess) {
      onSubmitSuccess();
    }
  };

  return (
    <div>
      <div className="card">
        <div className="card-header" style={{ position: "relative" }}>
          <h5>Time-Off Add Policy</h5>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-9 mb-3">
              <div className="form-group">
                <label
                  htmlFor="policyName"
                  className="form-label"
                  style={{ fontSize: "medium" }}
                >
                  Policy Name *
                </label>
                <input
                  type="text"
                  placeholder="Enter Policy name here"
                  name="policyName"
                  value={formData.policyName}
                  onChange={handleChange}
                  className={`form-control ${
                    formDataError.policyNameError ? "is-invalid" : ""
                  }`}
                />
                {formDataError.policyNameError && (
                  <div className="invalid-feedback invalid-message">
                    {formDataError.policyNameError}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-5 mb-3">
              <div className="form-group">
                <label
                  htmlFor="grantedDays"
                  className="form-label"
                  style={{ fontSize: "medium" }}
                >
                  Granted Days *
                </label>
                <input
                  type="number"
                  placeholder="Enter granted days here"
                  name="grantedDays"
                  value={formData.grantedDays}
                  onChange={handleChange}
                  className={`form-control ${
                    formDataError.grantedDaysError ? "is-invalid" : ""
                  }`}
                />
                {formDataError.grantedDaysError && (
                  <div className="invalid-feedback invalid-message">
                    {formDataError.grantedDaysError}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="form-group">
                <label
                  htmlFor="approvals"
                  className="form-label"
                  style={{ fontSize: "medium" }}
                >
                  Approvals *
                </label>
                <input
                  type="text"
                  name="approvals"
                  value={formData.approvals || `${userName} HR Manager`} // Display userName + "HR Manager"
                  onChange={handleChange}
                  className="form-control"
                  disabled // Disable the input field
                />
                {formDataError.approvalsError && (
                  <div className="invalid-feedback invalid-message">
                    {formDataError.approvalsError}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <SoftButton
              variant="outlined"
              style={{ float: "inline-end" }}
              color="info"
              size="small"
              onClick={handleFormSubmit}
            >
              {loading ? "Submitting..." : "Submit"}
              {loading && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </SoftButton>
            <SoftButton
              variant="outlined"
              style={{ float: "inline-end", marginRight: "10px" }}
              color="error"
              size="small"
              // onClick={handleBack}
            >
              Cancel
            </SoftButton>
          </div>
        </div>
      </div>
    </div>
  );
}

AddPolicyInputFields.propTypes = {
  onSubmitSuccess: PropTypes.func.isRequired,
};
