import { useState } from "react";
import HardwareInputFields from "./HardwareInputFields";
import SoftwareInputFields from "./SoftwareInputFields";

function Assets() {
  const [showHardware, setShowHardware] = useState(true);

  const handleHardwareSubmit = () => {
    setShowHardware(false);
  };

  return (
    <div className="card">
      <div className="card-header" style={{ position: "relative" }}>
        <h5>Assets</h5>
      </div>
      <div className="card-body">
        {showHardware ? (
          <HardwareInputFields onNext={handleHardwareSubmit} />
        ) : (
          <SoftwareInputFields />
        )}
      </div>
    </div>
  );
}

export default Assets;
