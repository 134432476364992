// @mui material components
import Tooltip from "@mui/material/Tooltip";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftProgress from "components/SoftProgress";
import ivancik from "assets/images/ivancik.jpg";
import { useEffect, useState } from "react";
import axios from "axios";

export default function data() {
  const [enrolledEmployees, setEnrolledEmployees] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const email = localStorage.getItem("loginEmail");

      if (!email) {
        console.error("Email not found in local storage");
        return;
      }
        const response = await axios.get(
          `${apiUrl}/api/InitiateAccountability/Get-Enrolled-Employee-By-Email?email=${email}`
        );
        setEnrolledEmployees(response.data);
      } catch (error) {
        console.error('Error fetching enrolled employees:', error);
      }
    };

    fetchData();
  }, []);

  // Assuming enrolledEmployees contains the fetched data
  const rows = enrolledEmployees.map((employee) => ({
    Name: (
      <SoftTypography variant="caption" color="text" fontWeight="medium">
        {employee.trainingDevelopement.trainingName}
      </SoftTypography>
    ),
    Provider: (
      <SoftTypography variant="caption" color="text" fontWeight="medium">
        {employee.trainingDevelopement.provider}
      </SoftTypography>
    ),
    Mentor: (
      <SoftTypography variant="caption" color="text" fontWeight="medium">
        {employee.trainingDevelopement.mentor}
      </SoftTypography>
    ),
    Allocated_Budget: (
      <SoftTypography variant="caption" color="text" fontWeight="medium">
        {employee.trainingDevelopement.allocated_budget}
      </SoftTypography>
    ),
    Start_Date: (
      <SoftTypography variant="caption" color="text" fontWeight="medium">
        {employee.trainingDevelopement.startDate}
      </SoftTypography>
    ),
    End_Date: (
      <SoftTypography variant="caption" color="text" fontWeight="medium">
        {employee.trainingDevelopement.endDate}
      </SoftTypography>
    ),
  }));

  return {
    columns: [
      { title: "Name", name: "Name", align: "left" },
      { title: "Provider", name: "Provider", align: "left" },
      { title: "Mentor", name: "Mentor", align: "center" },
      { title: "Allocated Budget", name: "Allocated_Budget", align: "center" },
      { title: "Start Date", name: "Start_Date", align: "center" },
      { title: "End Date", name: "End_Date", align: "center" },
    ],
    rows: rows,
  };
}
