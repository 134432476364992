import { useState, useEffect } from "react";
import { Table } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import DashboardLayout from "../LayoutNavbar/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../LayoutNavbar/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import "./Complaints.scss";
import ComplaintService from "../../EmployeeDashboard/EmployeeApiService/EmployeeComplaintService";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

function Complaints() {
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(true);
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [loggedInEmployeeName, setLoggedInEmployeeName] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;
  const {
    formData,
    formDataError,
    setFormDataError,
    clearFields,
    setFormData,
    validateValidation,
  } = ComplaintService();

  const handleComplaints = () => {
    setShow(false);
  };

  const handleSubmitComplaints = async () => {
    const loginEmail = localStorage.getItem("loginEmail");
    const updatedFormData = { ...formData, email: loginEmail };

    const isValid = validateValidation(updatedFormData, setFormDataError);
    if (isValid) {
      try {
        setIsLoading(true);
        const response = await axios.post(`${apiUrl}/api/Complaint/AddComplaints`, updatedFormData);
        clearFields();
        toast.success("Complaint Submitted successfully");
        fetchData();
        setShow(true);
      } catch (error) {
        console.error("Error adding Complaint:", error);
        toast.error("Error adding Complaint");
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
    fetchEmployeeByEmail();
    fetchEmployees();
  }, []);

  const [dataSource, setDataSource] = useState([]);
  async function fetchData() {
    try {
      const response = await axios.get(`${apiUrl}/api/Complaint/Get-Complaint-Detail`);
      const responseData = response.data;

      if (!responseData || !responseData.complaintDetail) {
        throw new Error("Invalid API response structure");
      }

      const complaintDetail = responseData.complaintDetail;

      // Generate rows based on the fetched data
      const updatedRows = complaintDetail.map((info) => ({
        key: info.complaintId.toString(),
        ComplaintName: info.complaintName,
        ComplaintAgainst: info.complaintAgainst,
        Status:
          info.status === 0
            ? "Pending"
            : info.status === 1
            ? "Resolved"
            : info.status === 2
            ? "Rejected"
            : "Unknown",
        Action: (
          <span style={{ display: "flex", alignItems: "center" }}>
            <Link to={`/employeedashboard/empcomplaintDetail/${info.complaintId}`}>
              <EyeOutlined className="eyeOutlined" />
            </Link>
          </span>
        ),
      }));

      // Update dataSource state
      setDataSource(updatedRows);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
    }
  }

  const handleChange = async (e) => {
    const { name, value } = e.target;
    let updatedFormData = { ...formData, [name]: value };
    let updatedFormDataError = { ...formDataError };

    if (value.trim() === "") {
      updatedFormDataError[name + "Error"] = `${name} is required.`;
    } else {
      updatedFormDataError[name + "Error"] = "";
    }

    setFormDataError(updatedFormDataError);
    setFormData(updatedFormData);

    if (name === "complaintAgainst") {
      try {
        const [firstName, lastName] = value.split(" ");
        const response = await axios.get(
          `${apiUrl}/api/PersonalInformation/Get-Department-By-EmployeeName?firstName=${firstName}&lastName=${lastName}`
        );
        const department = response.data;

        updatedFormData = { ...updatedFormData, departments: department };
        setFormData(updatedFormData);
      } catch (error) {
        updatedFormData = { ...updatedFormData, departments: "" };
        setFormData(updatedFormData);
      }
    }
  };

  const columns = [
    {
      title: "Complaint Name",
      dataIndex: "ComplaintName",
      key: "ComplaintName",
    },
    {
      title: "Complaint Against",
      dataIndex: "ComplaintAgainst",
      key: "ComplaintAgainst",
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
    },
  ];

  async function fetchEmployeeByEmail() {
    try {
      const loginEmail = localStorage.getItem("loginEmail");
      if (loginEmail) {
        const response = await axios.get(
          `${apiUrl}/api/PersonalInformation/GetEmployeeby-Email/${loginEmail}`
        );
        const responseData = response.data;

        if (responseData) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            firstName: responseData.firstName,
            lastName: responseData.lastName,
            phoneNo: responseData.mobileno,
            email: loginEmail,
          }));
        } else {
          console.error("Employee details not found");
        }
      } else {
        console.error("User email not found in local storage");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async function fetchEmployees() {
    try {
      const loginEmail = localStorage.getItem("loginEmail");
      if (loginEmail) {
        const response = await axios.get(`${apiUrl}/api/PersonalInformation/Get-All`);
        const responseData = response.data;
        const personalInfo = responseData.personalInfo;

        const updatedRows = personalInfo.map((info) => ({
          employeeName: `${info.firstName} ${info.lastName}`,
          departments: info.department,
          isCurrentUser: info.email === loginEmail,
        }));

        setEmployeeDetails(updatedRows);
        const loggedInEmployee = personalInfo.find((info) => info.email === loginEmail);
        if (loggedInEmployee) {
          setLoggedInEmployeeName(`${loggedInEmployee.firstName} ${loggedInEmployee.lastName}`);
        }
      } else {
        console.error("User email not found in local storage");
      }
    } catch (error) {
      console.error("Error fetching employees:", error);
      toast.error("Error fetching employees");
    }
  }

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <ToastContainer />
          {/* {showComplaintDetail && ( */}
          <div className="card">
            <div className="card-header" style={{ position: "relative" }}>
              <h5>Complaints</h5>
            </div>
            <div className="card-body">
              {show && (
                <div className="row mb-3">
                  <div className="col-md-12 mb-3">
                    <SoftButton
                      variant="outlined"
                      style={{ float: "inline-end" }}
                      color="info"
                      size="small"
                      onClick={handleComplaints}
                    >
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;New Complaints
                    </SoftButton>
                  </div>
                  <Table
                    className="table-responsive"
                    bordered
                    dataSource={dataSource}
                    columns={columns}
                    pagination={{ pageSize: 10 }}
                  />
                </div>
              )}
              {!show && (
                <div className="row">
                  <div className="col-md-5 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInput"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        First Name *
                      </label>
                      <input
                        type="text"
                        placeholder="Enter First Name here"
                        name="firstName"
                        disabled
                        value={formData.firstName}
                        onChange={handleChange}
                        className={`form-control ${
                          formDataError.firstNameError ? "is-invalid" : ""
                        }`}
                      />
                      {formDataError.firstNameError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.firstNameError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInput"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Last Name *
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Last Name here"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        disabled
                        className={`form-control ${
                          formDataError.lastNameError ? "is-invalid" : ""
                        }`}
                      />
                      {formDataError.lastNameError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.lastNameError}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-5 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInput"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Phone no *
                      </label>
                      <input
                        type="text"
                        name="phoneNo"
                        value={formData.phoneNo}
                        disabled
                        onChange={handleChange}
                        className={`form-control ${formDataError.phoneNoError ? "is-invalid" : ""}`}
                      />
                      {formDataError.phoneNoError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.phoneNoError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInput"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Complaints Name *
                      </label>
                      <input
                        type="text"
                        placeholder="Enter complaints name here"
                        name="complaintName"
                        value={formData.complaintName}
                        onChange={handleChange}
                        className={`form-control ${
                          formDataError.complaintNameError ? "is-invalid" : ""
                        }`}
                      />
                      {formDataError.complaintNameError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.complaintNameError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-5 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInput"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Complaints Against *
                      </label>
                      <select
                        aria-label="Default select example"
                        name="complaintAgainst"
                        value={formData.complaintAgainst}
                        onChange={handleChange}
                        className={`form-select ${
                          formDataError.complaintAgainstError ? "is-invalid" : ""
                        }`}
                      >
                        <option value="">Select Complaint Against</option>
                        {employeeDetails.map((employee, index) => (
                          <option
                            key={index}
                            value={employee.employeeName}
                            disabled={employee.employeeName === loggedInEmployeeName}
                          >
                            {employee.employeeName}
                            {employee.isCurrentUser && " (you)"}
                          </option>
                        ))}
                      </select>
                      {formDataError.complaintAgainstError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.complaintAgainstError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInput"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Department *
                      </label>
                      <input
                        type="text"
                        name="department"
                        value={formData.departments}
                        onChange={handleChange}
                        disabled
                        className={`form-control ${
                          formDataError.departmentError ? "is-invalid" : ""
                        }`}
                        readOnly={formData.complaintAgainst ? true : false}
                        placeholder={
                          formData.complaintAgainst
                            ? "Select Complaint Against to see department"
                            : "Department"
                        }
                      />
                      {formDataError.departmentError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.departmentError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-9 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInput"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Details of Incident *
                      </label>
                      <textarea
                        rows="3"
                        type="text"
                        placeholder="Type here..."
                        name="detailofIncident"
                        value={formData.detailofIncident}
                        onChange={handleChange}
                        className={`form-control ${
                          formDataError.detailofIncidentError ? "is-invalid" : ""
                        }`}
                      />
                      {formDataError.detailofIncidentError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.detailofIncidentError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <SoftButton
                      variant="outlined"
                      style={{ float: "inline-end" }}
                      color="info"
                      size="small"
                      onClick={handleSubmitComplaints}
                    >
                      {isLoading ? "Submitting..." : "Submit"}
                      {isLoading && (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                    </SoftButton>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Complaints;
