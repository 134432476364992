import React, { useEffect, useState } from "react";
import axios from "axios";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Table, Modal } from "antd";
import SoftBox from "components/SoftBox";
import jsPDF from "jspdf";
import "jspdf-autotable";
import SoftButton from "components/SoftButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

function RecruitmentIntegration() {
  const [employees, setEmployees] = useState([]);
  const [allEmployees, setAllEmployees] = useState([]);
  const [jobPostings, setJobPostings] = useState([]);
  const [postingJobTitles, setPostingJobTitles] = useState([]);
  const [selectedJobTitle, setSelectedJobTitle] = useState("All postingJobTitle");
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("All Departments");
  const [selectedMonth, setSelectedMonth] = useState(""); // State for selected month
  const apiUrl = process.env.REACT_APP_API_URL;
  const [activeTab, setActiveTab] = useState(true);
  const [employeeTab, setEmployeeTab] = useState(false);

  const handleTabChange = (tab) => {
    setActiveTab(true);
    setEmployeeTab(false);
  };
  const handleEmployee = (tab) => {
    setEmployeeTab(true);
    setActiveTab(false);
  };

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/EmployeeJobApplication/GetEmployee-Detail`);
        setAllEmployees(response.data.getemployee);
        setEmployees(response.data.getemployee);
      } catch (error) {
        console.error("Error fetching employees data:", error);
      }
    };

    const fetchJobPostings = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/HiringProcess/Getposting-Detail`);
        setJobPostings(response.data.postingDetail);
        const jobTitles = response.data.postingDetail.map((post) => post.postingJobTitle);
        setPostingJobTitles(["All postingJobTitle", ...new Set(jobTitles)]);
      } catch (error) {
        console.error("Error fetching job postings data:", error);
      }
    };

    const fetchDepartments = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/AttendanceReport/Get-All-Departments`);
        const departmentNames = response.data.map((dept) => dept.department);
        setDepartments(["All Departments", ...departmentNames]);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    fetchEmployees();
    fetchJobPostings();
    fetchDepartments();
  }, [apiUrl]);

  useEffect(() => {
    if (selectedJobTitle === "All postingJobTitle") {
      setEmployees(allEmployees);
    } else {
      const filteredEmployees = allEmployees.filter(
        (employee) => employee.forJobApply === selectedJobTitle
      );
      setEmployees(filteredEmployees);
    }
  }, [selectedJobTitle, allEmployees]);

  const statusMap = {
    0: "Applied",
    1: "Pending",
    2: "ShortList",
    3: "ShortList",
    4: "Interview",
    5: "Interview In-Process",
    6: "Contract Send",
    7: "Contract Received",
    8: "Qualified",
  };

  const handleJobTitleChange = (e) => {
    setSelectedJobTitle(e.target.value);
  };

  const handleDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const handleDownloadReport = () => {
    let data, title;
    if (activeTab) {
      if (selectedDepartment === "All Departments") {
        data = jobPostings;
        title = "Posted Job Report: All Departments";
      } else {
        data = jobPostings.filter((post) => post.department === selectedDepartment);
        title = `Posted Job: ${selectedDepartment}`;
      }
    } else if (employeeTab) {
      if (selectedJobTitle === "All postingJobTitle") {
        data = allEmployees;
        title = "Employee Report: All postingJobTitle";
      } else {
        data = employees.filter((employee) => employee.forJobApply === selectedJobTitle);
        title = `Employee Report: ${selectedJobTitle}`;
      }
    }

    let selectedMonthName = ""; // Initialize selectedMonthName

    // Set selectedMonthName if selectedMonth is defined
    if (selectedMonth !== "") {
      const monthIndex = parseInt(selectedMonth, 10);
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      selectedMonthName = monthNames[monthIndex];
      // title += ` - Month: ${selectedMonthName}`;

      // Filter data based on the selected month
      data = data.filter((item) => {
        const itemMonth = new Date(item.createdOn).getMonth();
        return itemMonth === monthIndex;
      });
    } else {
      // Handle the case where All Months is selected
      // title += ` - All Months`;
    }

    const doc = new jsPDF();

    doc.setFontSize(18);
    doc.text(title, 14, 22);

    const lineY = 28;
    const lineWidth = 0.5;
    const lineColor = [192, 192, 192];
    doc.setLineWidth(lineWidth);
    doc.setDrawColor(...lineColor);
    doc.line(14, lineY, 200, lineY);

    const marginTop = 3;
    const marginTopEOM = 2;
    doc.setFontSize(12);
    doc.text("Posted Job: All - All Posted Job", 14, 32 + marginTop);

    let formattedDate;

    // Set the report date based on the selected criteria
    if (activeTab) {
      // For download by department, set the date to the selected month
      formattedDate =
        selectedMonth !== "" ? `Report Date: ${selectedMonthName}` : `Report Date: All Months`;
    } else if (employeeTab) {
      // For download by position (employees), use the current date
      formattedDate = `Report Date: ${new Date().toLocaleDateString()}`;
    }

    doc.text(formattedDate, 14, 42 + marginTopEOM);
    doc.text("Exceptions: No Deletions", 14, 52);

    if (activeTab) {
      doc.text(`Department: ${selectedDepartment}`, 14, 59);
    } else if (employeeTab) {
      doc.text(`Posted Job: ${selectedJobTitle}`, 14, 59);
    }

    let headers, dataRows;

    if (activeTab) {
      headers = [
        [
          // "Posted_Job_ID",
          "Job_Title",
          "Recruiter",
          "Hiring_Lead",
          "Department",
          "Employement_Type",
          "Compensation",
          "Created_On",
          "Job_Status",
        ],
      ];
      dataRows = data.map((post) => [
        // post.postingJobId,
        post.postingJobTitle, // Ensure this field is correctly populated
        post.recruiter,
        post.hiringLead,
        post.department,
        post.employementType,
        post.compensation,
        // post.createdOn,
        new Date(post.createdOn).toLocaleString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }),
        post.jobStatus,
      ]);
    } else if (employeeTab) {
      headers = [["Employee ID", "First Name", "Last Name", "Email", "For Job Apply", "Status"]];
      dataRows = data.map((employee) => [
        employee.employeeId,
        employee.firstName,
        employee.lastName,
        employee.email,
        employee.forJobApply,
        statusMap[employee.status],
      ]);
    }

    doc.autoTable({
      startY: 62,
      head: headers,
      body: dataRows,
      headerStyles: {
        fillColor: [192, 192, 192],
        fontSize: 8,
      },
      styles: {
        fontSize: 8,
      },
    });

    let fileName;
    if (activeTab) {
      fileName =
        selectedDepartment === "All Departments"
          ? "all_departments"
          : selectedDepartment.replace(/ /g, "_");
    } else if (employeeTab) {
      fileName =
        selectedJobTitle === "All postingJobTitle"
          ? "all_job_titles"
          : selectedJobTitle.replace(/ /g, "_");
    }

    doc.save(`employee_report_${fileName}.pdf`);
  };

  const employeeColumns = [
    {
      title: "Employee ID",
      dataIndex: "employeeId",
      key: "employeeId",
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "For Position",
      dataIndex: "forJobApply",
      key: "forJobApply",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => statusMap[status],
    },
  ];

  const jobPostingColumns = [
    {
      title: "Job Title",
      dataIndex: "postingJobTitle",
      key: "postingJobTitle",
    },
    {
      title: "Recruiter",
      dataIndex: "recruiter",
      key: "recruiter",
    },
    {
      title: "Hiring Lead",
      dataIndex: "hiringLead",
      key: "hiringLead",
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
    },
    {
      title: "Created On",
      dataIndex: "createdOn",
      key: "createdOn",
      render: (createdOn) => {
        const formattedDate = new Date(createdOn).toLocaleString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
        return formattedDate;
      },
    },
    {
      title: "Read More",
      key: "readMore",
      render: (_, record) => (
        <button className="btn btn-link" onClick={() => handleReadMore(record)}>
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      ),
    },
  ];

  const handleReadMore = (record) => {
    Modal.info({
      title: "Job Posting Details",
      content: (
        <div>
          <p>
            <strong>Posted Job Id:</strong> {record.postingJobId}
          </p>
          <p>
            <strong>Employment Type:</strong> {record.employementType}
          </p>
          <p>
            <strong>Compensation:</strong> {record.compensation}
          </p>
          <p>
            <strong>Created On:</strong> {new Date(record.createdOn).toLocaleString("en-US")}
          </p>
          <p>
            <strong>Job Status:</strong> {record.jobStatus}
          </p>
        </div>
      ),
      width: 600,
    });
  };

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <div className="card">
            <div className="card-header" style={{ position: "relative" }}>
              <h5>Recruitment Integrations</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="d-flex justify-content-center mb-3">
                  <SoftBox
                    color={activeTab === "positions" ? "info" : "text"}
                    onClick={() => handleTabChange()}
                    style={{
                      width: "150px",
                      height: "150px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      background:
                        activeTab === "positions"
                          ? "linear-gradient(45deg, #FF5722, #FF8A00)"
                          : "#f0f0f0",
                      marginRight: "20px",
                    }}
                  >
                    <h6 style={{ textAlign: "center" }}>Number of Positions</h6>
                  </SoftBox>

                  <SoftBox
                    color={activeTab === "employees" ? "info" : "text"}
                    onClick={() => handleEmployee()}
                    style={{
                      width: "150px",
                      height: "150px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      background:
                        activeTab === "employees"
                          ? "linear-gradient(45deg, #FF5722, #FF8A00)"
                          : "#f0f0f0",
                    }}
                  >
                    <h6 style={{ textAlign: "center" }}>Number of Employees</h6>
                  </SoftBox>
                </div>

                {activeTab ? (
                  <>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                      <SoftBox color="text" style={{ marginRight: "20px" }}>
                        <div style={{ display: "inline-block" }}>
                          <SoftButton
                            variant="gradient"
                            color="info"
                            size="small"
                            onClick={handleDownloadReport}
                          >
                            <i className="fa fa-download" aria-hidden="true"></i>&nbsp;Download
                            Employee By Department
                          </SoftButton>
                        </div>
                      </SoftBox>
                      <div className="col-md-2 mb-3">
                        <select
                          className="form-select form-select-sm"
                          onChange={handleMonthChange}
                          value={selectedMonth}
                        >
                          <option value="">All Months</option>
                          <option value="0">January</option>
                          <option value="1">February</option>
                          <option value="2">March</option>
                          <option value="3">April</option>
                          <option value="4">May</option>
                          <option value="5">June</option>
                          <option value="6">July</option>
                          <option value="7">August</option>
                          <option value="8">September</option>
                          <option value="9">October</option>
                          <option value="10">November</option>
                          <option value="11">December</option>
                        </select>
                      </div>
                      &nbsp;&nbsp;
                      <div className="mb-3" style={{ display: "inline-block" }}>
                        <select
                          className="form-select form-select-sm"
                          value={selectedDepartment}
                          onChange={handleDepartmentChange}
                        >
                          {departments.map((department) => (
                            <option key={department} value={department}>
                              {department}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <Table
                      className="table-responsive"
                      dataSource={jobPostings.filter(
                        (post) =>
                          (selectedDepartment === "All Departments" ||
                            post.department === selectedDepartment) &&
                          (!selectedMonth ||
                            new Date(post.createdOn).getMonth() === parseInt(selectedMonth, 10))
                      )}
                      columns={jobPostingColumns}
                      pagination={{ pageSize: 10 }}
                    />
                  </>
                ) : null}

                {employeeTab ? (
                  <>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                      <SoftBox color="text" style={{ marginRight: "20px" }}>
                        <div style={{ display: "inline-block" }}>
                          <SoftButton
                            variant="gradient"
                            color="info"
                            size="small"
                            onClick={handleDownloadReport}
                          >
                            <i className="fa fa-download" aria-hidden="true"></i>&nbsp;Download
                            Employee By Position
                          </SoftButton>
                        </div>
                      </SoftBox>
                      <div className="mb-3" style={{ display: "inline-block" }}>
                        <select
                          className="form-select form-select-sm"
                          onChange={handleJobTitleChange}
                        >
                          {postingJobTitles.map((postingJobTitle) => (
                            <option key={postingJobTitle} value={postingJobTitle}>
                              {postingJobTitle}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <Table
                      className="table-responsive"
                      dataSource={employees}
                      columns={employeeColumns}
                      pagination={{ pageSize: 10 }}
                    />
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default RecruitmentIntegration;
