import { Link } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import { EyeOutlined } from "@ant-design/icons";
import { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function TrainingService() {
  const [showFields, setShowFields] = useState(true);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [selectedJob, setSelectedJob] = useState(null);

  const [formData, setFormData] = useState({
    trainingName: "",
    provider: "",
    mentor: "",
    allocated_budget: "",
    startDate: "",
    endDate: "",
  });

  const handleChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or target is undefined:", e);
      return;
    }

    const { name, value } = e.target;

    // Clear the error message if the value is not empty
    if (value.trim() !== "") {
      setFormDataError((prevState) => ({
        ...prevState,
        [`${name}Error`]: "",
      }));
    } else {
      // Set the error message if the value is empty
      setFormDataError((prevState) => ({
        ...prevState,
        [`${name}Error`]: `${name} is required.`,
      }));
    }

    if (name==="startDate") {
      const selectedDate = new Date(value);
      const currentDate = new Date();
      if (selectedDate <= currentDate) { // Changed from >= to <=
          setFormDataError((prevState) => ({
          ...prevState,
          [`${name}Error`]: "Please select a date after today.", // Changed the error message
        }));
        return;
      }
    }

    if (name==="endDate") {
      const selectedDate = new Date(value);
      const currentDate = new Date();
      if (selectedDate <= currentDate) { // Changed from >= to <=
          setFormDataError((prevState) => ({
          ...prevState,
          [`${name}Error`]: "Please select a date after today.", // Changed the error message
        }));
        return;
      }
    }

    // Update compensation field to $0 if budgetApproved is 'No'
    if (name === "budgetApproved" && value === "No") {
      setFormData({ ...formData, [name]: value, compensation: "$0" });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const [formDataError, setFormDataError] = useState({
    trainingNameError: "",
    providerError: "",
    mentorError: "",
    allocated_budgetError: "",
    startDateError: "",
    endDateError: "",
  });

  const clearFields = () => {
    // Reset the form data to initial values
    setFormData({
      employeeId: "",
      trainingName: "",
      provider: "",
      mentor: "",
      allocated_budget: "",
      startDate: "",
      endDate: "",
    });
  };

  function validateValidation(formData, setFormDataError) {
    let hasError = false;

    // Check each field for emptiness or invalidity
    Object.keys(formData).forEach((fieldName) => {
      if (
        (typeof formData[fieldName] === "string" &&
          (!formData[fieldName] || !formData[fieldName].trim())) || // For string fields
        (typeof formData[fieldName] === "number" &&
          (isNaN(formData[fieldName]) || formData[fieldName] === null)) // For numeric fields
      ) {
        const fieldNameWithSpaces = fieldName
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase());
        setFormDataError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: `${fieldNameWithSpaces} is required.`,
        }));
        hasError = true;
      } else {
        // Clear the error message if the field is not empty
        setFormDataError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: "", // Clear the error message
        }));
      }
    });

    // Check if any error occurred
    if (hasError) {
      return false;
    }

    // Check if all fields are filled out
    const isAllFieldsFilled = Object.values(formData).every(
      (value) => value !== null && value !== undefined && value !== ""
    );

    if (!isAllFieldsFilled) {
      // If any field is empty, display an error message
      setFormDataError((prevState) => ({
        ...prevState,
        genericError: "Please fill out all fields.",
      }));
      return false;
    }

    return true;
  }

  const [dataSource, setDataSource] = useState([]);

  async function fetchData() {
    try {
      const response = await axios.get(`${apiUrl}/api/TrainingDevelopement/Get-Training-Detail`);
      const responseData = response.data;
      const trainingDetail = responseData.trainingDetail;

      // Generate rows based on the fetched data
      const updatedRows = trainingDetail.map((info, index) => {
        function formatDateTime(dateTimeString) {
          const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          const date = new Date(dateTimeString);
          const month = months[date.getMonth()];
          const day = date.getDate();
          const year = date.getFullYear();
          let hours = date.getHours();
          const ampm = hours >= 12 ? "PM" : "AM";
          hours = hours % 12;
          hours = hours ? hours : 12;
          const minutes = date.getMinutes();
          return `${month} ${day < 10 ? "0" : ""}${day}, ${year} ${hours}:${
            minutes < 10 ? "0" : ""
          }${minutes} ${ampm}`;
        }

        return {
          key: info.trainingId.toString(),
          Name: `${info.trainingName}`,
          Provider: `${info.provider}`,
          Mentor: info.mentor,
          Enrollments: "N/A",
          Allocated_budget: info.allocated_budget,
          StartDate: <span>{formatDateTime(info.startDate)}</span>,
          EndDate: <span>{formatDateTime(info.endDate)}</span>,
          Action: (
            <span
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Edit className="hiOutlined" onClick={() => handleEdit(info)} />
              <Link to={`/trainingDetail/${info.trainingId}`}>
                <EyeOutlined className="eyeOutlined" />
              </Link>
            </span>
          ),
        };
      });

      // Update dataSource state
      setDataSource(updatedRows);
    } catch (error) {
      console.error("Error fetching data:", error);
    }finally{
      setLoading(false)
    }
  }

  const handletraining = () => {
    setShowFields(false);
    setSelectedJob(null);
  };

  const handleEdit = (job) => {
    setSelectedJob(job);
    setFormData(job); // Populate form with selected job data
    setShowFields(false);
  };

  const handleAddTraining = async () => {
    if (!selectedJob) {
      const isValid = validateValidation(formData, setFormDataError);
      if (isValid) {
        try {
          const updatedFormData = { ...formData };
          const response = await axios.post(
            `${apiUrl}/api/TrainingDevelopement/training-and-developement`,
            updatedFormData
          );
          clearFields();
          toast.success("Training added successfully");
          fetchData();
          setShowFields(true);
        } catch (error) {
          console.error("Error adding Training:", error);
          toast.error("Error adding Training");
        }
      }
    } else {
      try {
        const response = await axios.put(
          `${apiUrl}/api/TrainingDevelopement/Edit-Training/${selectedJob.trainingId}`,
          formData
        );
        toast.success("Training updated successfully");
        fetchData();
        setShowFields(true);
      } catch (error) {
        console.error("Error updating Training:", error);
        toast.error("Error updating Training");
      }finally{
        setLoading(false)
      }
    }
  };

  return {
    showFields,
    handletraining,
    dataSource,
    handleAddTraining,
    formData,
    setFormData,
    formDataError,
    fetchData ,
    setFormDataError,
    handleChange,
    clearFields,
    validateValidation,
    loading
  };
}

export default TrainingService;
