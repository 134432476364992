import React, { useEffect, useState } from "react";
import axios from "axios";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Collapse } from "antd";

const { Panel } = Collapse;

function OnboardingBackground() {

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <div className="card">
            <div className="card-header" style={{ position: "relative" }}>
              <h5>Onboarding Background</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <p>Under Construction</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default OnboardingBackground;
