import React, { useState } from "react";
import NotificationItem from "examples/Items/NotificationItem";
import team2 from "assets/images/memorial.jpg";
import independence from "assets/images/independence.webp";
import laborday from "assets/images/laborday.jpg";
import vetrans from "assets/images/vetrans.jpg";
function UpComingHolidays() {

  return (
    <div className="card">
      <div className="card-header" style={{ position: "relative" }}>
        <h5>UpComing Holidays</h5>
      </div>
      <div className="card-body">
        <div className="row">
        <NotificationItem
        image={<img src={team2} alt="person" style={{ height: '36px' }} />}
        title={["Memorial Day"]}
        date="May 27, 2024"
      />
      <NotificationItem
        image={<img src={independence} alt="person" style={{ height: '36px' }} />}
        title={["Independence Day (United Stated)"]}
        date="Jul 4, 2024"
      />
      <NotificationItem
        image={<img src={laborday} alt="person" style={{ height: '36px' }} />}
        title={["Labor Day"]}
        date="Sep 2, 2024"
      />
      <NotificationItem
        image={<img src={vetrans} alt="person" style={{ height: '36px' }} />}
        title={["Veterans Day"]}
        date="Nov 11, 2024"
      />
        </div>
      </div>
    </div>
  );
}

export default UpComingHolidays;
