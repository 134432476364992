import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import routes from "routes";
import employeeRoutes from "employeeRoutes";
import EmployeeSidenav from "examples/Sidenav";

import { useSoftUIController } from "context";

//HR Manager Routes
import brand from "assets/images/datagonomix.jpg";
import RequestTimeOff from "layouts/RequestTimeOff/RequesttimeOff";
import SignIn from "layouts/authentication/sign-in";
import Personal from "layouts/Personal/Personal";
import Job from "layouts/Job/Job";
import Emergency from "layouts/Emergency/Emergency";
import Documents from "layouts/Documents/Documents";
import Benefits from "layouts/Benefits/Benefits";
import Trainings from "layouts/Training/Training";
import Assets from "layouts/Assets/Assets";
import Onboarding from "layouts/Onboarding/Onboarding";
import Attendance from "layouts/Attendence/Attendence";
import ViewCareer from "layouts/View Career Page/ViewCareerPage";
import JobOpenings from "layouts/View Career Page/JobOpenings";
import ViewCandidate from "layouts/Hiring/ViewCandidate";
import EmployeeDetail from "layouts/People/EmployeeDetail/EmployeeDetail";
import HumanResource from "layouts/ParentComponent/ParentComponent";
import Terminate from "layouts/People/Terminate/Terminate";
import People from "layouts/People/index";
import ExpenseManagement from "layouts/ExpenseManagment/index";
import ReportsAttendance from "layouts/SelfSevices/ReportAttendence";
import TimeOff from "layouts/TimeOff/index";
import AddPolicy from "layouts/Compliance/AddPolicyFields";
import HiringOnboarding from "layouts/Hiring/HiringOnboarding/HiringOnboarding";
import TrainingDetail from "layouts/TraningAndDevelopement/TrainingDetail";
import ComplaintDetail from "layouts/Complaints/ComplaintDetail";
import SalarySlip from "layouts/SalarySlip/SalarySlip";
import SkillsReport from "layouts/Hiring/SkillsReport";
import SalaryBaseJobData from "layouts/Job/SalaryBaseJobData";
import EnrolledEmployeeForTraining from "layouts/TraningAndDevelopement/EnrolledEmployeeForTraining";
import AnnouncementDetail from "layouts/dashboard/components/Announcement/AnnuncementDetail";
import PerformanceManagementDetail from "layouts/PerformanceManagement/PerformanceManagementDetail";
import DocumentManager from "layouts/DocumentManager/DocumentManager";
import OfferLetter from "layouts/dashboard/DocumentCardChild/OfferLetter/OfferLetter";
import SpecRecruitment from "layouts/dashboard/DocumentCardChild/SpecRecruitment/SpecRecruitment";
import RecruitmentIntegration from "layouts/dashboard/DocumentCardChild/RecruitmentIntegration/RecruitmentIntegration";
import OnboardingBackground from "layouts/dashboard/DocumentCardChild/OnboardingBackground/OnboardingBackground";
import ProtectedHRRoute from "layouts/authentication/ProtectedHRFile/ProtectedHRFile";
import CorrectiveActionForm from "layouts/Complaints/CorrectiveActionForm";
import ViweCorrectiveDetail from "layouts/Complaints/ViewCorrectiveComplaintAction";
import SoftwareInputFields from "layouts/Assets/SoftwareInputFields";

//Employee Routes
// import EmployeeJob from "EmployeeDashboard/EmployeeJob/EmployeeJob";
import EmployeeSignIn from "EmployeeDashboard/authentication/sign-in/signIn";
import FinalSignIn from "EmployeeDashboard/authentication/Final-Signin/Final-Login";
import EmployeeRequestTimeOff from "EmployeeDashboard/RequestTimeOff/RequesttimeOff";
import ShortListEmployeeForm from "EmployeeDashboard/ShortListEmployeeForm/ShortListEmployeeForm";
import InterviewSchedule from "EmployeeDashboard/ShortListEmployeeForm/InterviewSchedule";
import EmployeeTrainingDetail from "EmployeeDashboard/Training/TrainingDetail";
import EmployeeComplaintDetail from "EmployeeDashboard/Complaints/ComplaintDetail";
import ProtectedRoute from "EmployeeDashboard/authentication/ProtectedRoute/ProtectedRoute";
import EnrolledTrainingEmployee from "EmployeeDashboard/Job/EnrolledTrainingEmployee";
import EmployeeExpenseRequest from "EmployeeDashboard/ExpenseManagment/EmployeeExpenseRequest";
import EmployeeAnnouncementDetail from "EmployeeDashboard/dashboard/components/Announcement/employeeAnnouncementDetail";
import AddEmployeeExpense from "EmployeeDashboard/ExpenseManagment/ExpenseManagment";
import ChildManagerinputfields from "layouts/ChildManager/ChildManagerinputfields";
import AssignRole from "layouts/ChildManager/AssignRole";
import OrganizationHierarchy from "layouts/SelfSevices/OrganizationHierarchy";
import NotFound from "404NotFound";
import EmployeeOnboarding from "EmployeeDashboard/EmpoyeeOnboard/EmployeeOnboarding";
import { element } from "prop-types";

export default function App() {
  const [controller] = useSoftUIController();
  const { layout, sidenavColor } = controller;
  const location = useLocation();

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        let element;
        if (
          route.route === "/dashboard" ||
          route.route === "/employees" ||
          route.route === "/hiring" ||
          route.route === "/report" ||
          route.route === "/performance" ||
          route.route === "/training" ||
          route.route === "/assets" ||
          route.route === "/complaints" ||
          route.route === "/compliance"
        ) {
          element = <ProtectedHRRoute element={route.component} />;
        } else if (
          route.route.startsWith("/employeedashboard") ||
          route.route === "/employeedashboard/personal" ||
          route.route === "/employeedashboard/jobemployee" ||
          route.route === "/employeedashboard/attendance" ||
          route.route === "/employeedashboard/benefits" ||
          route.route.startsWith("/employeedashboard/training") ||
          route.route === "/employeedashboard/documents" ||
          route.route === "/employeedashboard/emergency" ||
          route.route === "/employeedashboard/assets" ||
          route.route === "/employeedashboard/onboarding" ||
          route.route === "/employeedashboard/complaints"
        ) {
          element = <ProtectedRoute element={route.component} />;
        } else {
          element = route.component;
        }

        return <Route exact path={route.route} element={element} key={route.key} />;
      }

      return null;
    });

  const showSidebar = ![
    "/viewcareer",
    "/emplogin",
    /^\/Employee-Onboarding\/[^/]+\/[^/]+$/,
    /^\/hiringOnboarding\/[^/]+\/[^/]+$/,
    /^\/viewcandidate\/[^/]+$/,
    /^\/hiringOnboarding\/[^/]+$/,
    /^\/jobOpenings\/[^/]+$/,
    /^\/announcement-detail\/[^/]+$/,
    "/shortlistEmployeeform",
  ].some((pattern) => {
    if (typeof pattern === "string") {
      return location.pathname === pattern;
    } else if (pattern instanceof RegExp) {
      return pattern.test(location.pathname);
    }
  });

  const isEmployeeDashboard = location.pathname.startsWith("/employeedashboard");
  const RedirectToDashboard = () => <Navigate to="/employeedashboard" />;
  const RedirectToHRDashboard = () => <Navigate to="/dashboard" />;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && showSidebar && (
        <>
          {isEmployeeDashboard ? (
            <EmployeeSidenav
              color={sidenavColor}
              brand={brand}
              brandName="Employee Dashboard"
              routes={employeeRoutes}
            />
          ) : (
            <Sidenav color={sidenavColor} brand={brand} brandName="Spec HR" routes={routes} />
          )}
          <Configurator />
          {/* {configsButton} */}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        {getRoutes(employeeRoutes)}
        {/* HR Manager Routes Starts */}
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<ProtectedHRRoute element={<RedirectToHRDashboard />} />} />
        <Route path="/signIn" name="Sign In" element={<SignIn />} />
        <Route
          path="/personal"
          name="Personal"
          element={<ProtectedHRRoute element={<Personal />} />}
        />
        <Route
          path="/childManager"
          name="Child Manager"
          element={<ProtectedHRRoute element={<ChildManagerinputfields />} />}
        />
        <Route path="/job" name="Job" element={<ProtectedHRRoute element={<Job />} />} />
        <Route
          path="/softwareAssets"
          name="Software Assets"
          element={<ProtectedHRRoute element={<SoftwareInputFields />} />}
        />
        <Route
          path="/emergency"
          name="Emergency"
          element={<ProtectedHRRoute element={<Emergency />} />}
        />
        <Route
          path="/assignRole/:childManagerId"
          name="AssignRole"
          element={<ProtectedHRRoute element={<AssignRole />} />}
        />

        <Route
          path="/documents"
          name="Documents"
          element={<ProtectedHRRoute element={<Documents />} />}
        />
        <Route
          path="/documentsManagers"
          name="Document Manager"
          element={<ProtectedHRRoute element={<DocumentManager />} />}
        />
        <Route
          path="/benefits"
          name="Benefits"
          element={<ProtectedHRRoute element={<Benefits />} />}
        />
        <Route
          path="/training"
          name="Trainings"
          element={<ProtectedHRRoute element={<Trainings />} />}
        />
        <Route path="/assets" name="Assets" element={<ProtectedHRRoute element={<Assets />} />} />
        <Route
          path="/onboarding"
          name="On boarding"
          element={<ProtectedHRRoute element={<Onboarding />} />}
        />
        <Route
          path="/hiringOnboarding/:empId/:employeeEmail"
          name="On boarding"
          element={<ProtectedHRRoute element={<HiringOnboarding />} />}
        />
        <Route
          path="/attendance"
          name="Attendance"
          element={<ProtectedHRRoute element={<Attendance />} />}
        />
        <Route
          path="/viewcareer"
          name="View Career"
          element={<ViewCareer />}
          // element={<ProtectedHRRoute element={<ViewCareer />} />}
        />
        <Route
          path="/jobOpenings/:postingJobId"
          name="JobOpenings"
          element={<JobOpenings />}
          // element={<ProtectedHRRoute element={<JobOpenings />} />}
        />
        <Route
          path="/viewcandidate/:postingJobId"
          name="View Candidate"
          element={<ProtectedHRRoute element={<ViewCandidate />} />}
        />
        <Route
          path="/employeeDetail/:personalId"
          name="Employee Details"
          element={<ProtectedHRRoute element={<EmployeeDetail />} />}
        />
        <Route
          path="/requestTimeOff"
          name="Request Time Off"
          element={<ProtectedHRRoute element={<RequestTimeOff />} />}
        />
        {/* Route for Adding a new employee */}
        <Route
          path="/humanResource/:empId"
          element={<ProtectedHRRoute element={<HumanResource />} />}
        />
        {/* Route for editing an existing employee */}
        <Route
          path="/humanResource/:personalId"
          element={<ProtectedHRRoute element={<HumanResource />} />}
        />
        <Route path="/people" name="People" element={<ProtectedHRRoute element={<People />} />} />
        <Route
          path="/terminate/:personalId"
          name="Terminate"
          element={<ProtectedHRRoute element={<Terminate />} />}
        />
        <Route
          path="/trainingDetail/:trainingId"
          name="Training Detail"
          element={<ProtectedHRRoute element={<TrainingDetail />} />}
        />
        <Route
          path="/complaintDetail/:complaintId"
          name="Complaint Detail"
          element={<ProtectedHRRoute element={<ComplaintDetail />} />}
        />
        <Route
          path="/expenseManagement"
          name="Expense Management"
          element={<ProtectedHRRoute element={<ExpenseManagement />} />}
        />
        <Route
          path="/addPolicy"
          name="Add Policy"
          element={<ProtectedHRRoute element={<AddPolicy />} />}
        />
        <Route
          path="/reportsAttendance"
          name="Reports Attendance"
          element={<ProtectedHRRoute element={<ReportsAttendance />} />}
        />
        <Route
          path="/timeOff"
          name="TimeOff"
          element={<ProtectedHRRoute element={<TimeOff />} />}
        />
        <Route
          path="/performance/:email"
          element={<ProtectedHRRoute element={<PerformanceManagementDetail />} />}
        />
        <Route
          path="/offerLetters"
          name="Offer Letter"
          element={<ProtectedHRRoute element={<OfferLetter />} />}
        />
        <Route
          path="/specRecruitment"
          name="Spec Recruitment"
          element={<ProtectedHRRoute element={<SpecRecruitment />} />}
        />
        <Route
          path="/recruitmentIntegration"
          name="Recruitment Integration"
          element={<ProtectedHRRoute element={<RecruitmentIntegration />} />}
        />
        <Route
          path="/onboardingBackground"
          name="Onboarding Background"
          element={<ProtectedHRRoute element={<OnboardingBackground />} />}
        />
        <Route
          path="/salarySlip"
          name="Salary Slip"
          element={<ProtectedHRRoute element={<SalarySlip />} />}
        />
        <Route
          path="/enrolledEmployeeforTraining"
          name="Enrolled Training Employee"
          element={<ProtectedHRRoute element={<EnrolledEmployeeForTraining />} />}
        />

        <Route
          path="/announcement-detail/:announcementId"
          element={<ProtectedHRRoute element={<AnnouncementDetail />} />}
        />
        <Route path="/skillsReport" element={<ProtectedHRRoute element={<SkillsReport />} />} />
        <Route
          path="/salaryAnalysis"
          element={<ProtectedHRRoute element={<SalaryBaseJobData />} />}
        />

        <Route
          path="/corrective-action-form/:complaintId"
          name="corrective-action-form"
          element={<ProtectedHRRoute element={<CorrectiveActionForm />} />}
        />
        <Route
          path="/view-corrective-action-form/:complaintId"
          name="view corrective-action-form"
          element={<ProtectedHRRoute element={<ViweCorrectiveDetail />} />}
        />
        <Route
          path="/organizationHierarchy"
          name="Organization Hierarchy"
          element={<ProtectedHRRoute element={<OrganizationHierarchy />} />}
        />

        {/* HR Manager Routes Ends */}

        {/* Employee Routes Start*/}
        <Route path="/" element={<ProtectedRoute element={<RedirectToDashboard />} />} />
        <Route path="/empsignIn" name="Sign In" element={<EmployeeSignIn />} />
        <Route
          path="/Employee-Onboarding/:empId/:email"
          name="Employee-Onboarding"
          element={<ProtectedRoute element={<EmployeeOnboarding />} />}
        />

        <Route path="/empLogin" name="Sign In" element={<FinalSignIn />} />
        <Route
          path="/employeedashboard/emprequestTimeOff"
          name="Request Time Off"
          element={<ProtectedRoute element={<EmployeeRequestTimeOff />} />}
        />
        <Route
          path="/shortlistEmployeeform"
          name="Short List Employee Form"
          element={<ShortListEmployeeForm />}
        />
        <Route
          path="/interviewSchedule"
          name="Interview Schedule"
          element={<ProtectedRoute element={<InterviewSchedule />} />}
        />
        <Route
          path="/employeedashboard/emptrainingDetail/:trainingId"
          name="Training Detail"
          element={<ProtectedRoute element={<EmployeeTrainingDetail />} />}
        />
        <Route
          path="/employeedashboard/enrolledTrainingEmployee/:trainingId"
          name="Enrolled Training Employee"
          element={<ProtectedRoute element={<EnrolledTrainingEmployee />} />}
        />
        <Route
          path="/employeedashboard/empcomplaintDetail/:complaintId"
          name="Complaint Detail"
          element={<ProtectedRoute element={<EmployeeComplaintDetail />} />}
        />
        <Route
          path="/employeedashboard/addEmployeeExpense"
          name="Add Employee Expense"
          element={<ProtectedRoute element={<AddEmployeeExpense />} />}
        />
        <Route
          path="/employeedashboard/employeeExpenseRequest"
          name="Employee Expense Request"
          element={<ProtectedRoute element={<EmployeeExpenseRequest />} />}
        />
        <Route
          path="/employeedashboard/emp-announcement-detail/:announcementId"
          element={<ProtectedRoute element={<EmployeeAnnouncementDetail />} />}
        />
        {/* Employee Routes Ends*/}
      </Routes>
    </ThemeProvider>
  );
}
