import { Table } from "antd";
import { EyeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import { useState, useEffect } from "react";
import "./Training.scss";
import TrainingService from "layouts/APIServices/TrainingService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Footer from "layouts/authentication/components/Footer";


function TrainingAndDevelopment() {
  
  const {
    showFields,
    handletraining,
    dataSource,
    handleAddTraining,
    formData,
    fetchData ,
    formDataError,
    handleChange,
    loading,
  } = TrainingService();

  useEffect(() => {
    fetchData();
  }, []);



  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "Provider",
      dataIndex: "Provider",
      key: "Provider",
    },
    {
      title: "Mentor",
      dataIndex: "Mentor",
      key: "Mentor",
    },
    {
      title: "Start Date",
      dataIndex: "StartDate",
      key: "StartDate",
    },
    {
      title: "End Date",
      dataIndex: "EndDate",
      key: "EndDate",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
    },
  ];

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <ToastContainer />
          <div className="card">
            <div className="card-header" style={{ position: "relative" }}>
              <h5>Training and Development</h5>
            </div>
            <div className="card-body">
              {showFields && (
                <div className="row mb-3">
                  <div className="col-md-12 mb-3">
                    <SoftButton
                      variant="outlined"
                      style={{ float: "inline-end" }}
                      color="info"
                      size="small"
                      onClick={handletraining}
                    >
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;Add Training
                    </SoftButton>
                  </div>
                  <Table
                    className="table-responsive"
                    bordered
                    loading={loading}
                    dataSource={dataSource}
                    columns={columns}
                    pagination={{ pageSize: 10 }}
                  />
                </div>
              )}
              {!showFields && (
                <div className="row">
                  <div className="col-md-5 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInput"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Name of Training *
                      </label>
                      <input
                        type="text"
                        placeholder="Enter training name here"
                        name="trainingName"
                        value={formData.trainingName}
                        onChange={handleChange}
                        className={`form-control ${
                          formDataError.trainingNameError ? "is-invalid" : ""
                        }`}
                      />
                      {formDataError.trainingNameError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.trainingNameError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInput"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Provider *
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Provider here"
                        name="provider"
                        value={formData.provider}
                        onChange={handleChange}
                        className={`form-control ${
                          formDataError.providerError ? "is-invalid" : ""
                        }`}
                      />
                      {formDataError.providerError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.providerError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-5 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInput"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Mentor *
                      </label>
                      <input
                        type="text"
                        name="mentor"
                        placeholder="Enter mentor here"
                        value={formData.mentor}
                        onChange={handleChange}
                        className={`form-control ${formDataError.mentorError ? "is-invalid" : ""}`}
                      />
                      {formDataError.mentorError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.mentorError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInput"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Allocated Budget *
                      </label>
                      <input
                        type="text"
                        name="allocated_budget"
                        placeholder="Enter Allocated Budget here"
                        value={formData.allocated_budget}
                        onChange={handleChange}
                        className={`form-control ${
                          formDataError.allocated_budgetError ? "is-invalid" : ""
                        }`}
                      />
                      {formDataError.allocated_budgetError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.allocated_budgetError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-5 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInput"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Start Date *
                      </label>
                      <input
                        type="date"
                        name="startDate"
                        value={formData.startDate}
                        onChange={handleChange}
                        className={`form-control ${
                          formDataError.startDateError ? "is-invalid" : ""
                        }`}
                      />
                      {formDataError.startDateError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.startDateError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInput"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        End Date *
                      </label>
                      <input
                        type="date"
                        name="endDate"
                        value={formData.endDate}
                        onChange={handleChange}
                        className={`form-control ${formDataError.endDateError ? "is-invalid" : ""}`}
                      />
                      {formDataError.endDateError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.endDateError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <SoftButton
                      variant="outlined"
                      style={{ float: "inline-end" }}
                      color="info"
                      size="small"
                      onClick={handleAddTraining}
                    >
                      Submit
                    </SoftButton>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default TrainingAndDevelopment;
