import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

function jobService(onNext) {
  const { empId } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL

  useEffect(() => {
    fetchEmployeeJobData(empId);
  }, [empId]);

  async function fetchEmployeeJobData(empId) {
    try {
      const response = await axios.get(
        `${apiUrl}/api/PersonalInformation/GetEmployeesById/${empId}`
      );
      const responseData = response.data;
      if (responseData.employeeJobApplications && responseData.employeeJobApplications.length > 0) {
        const firstEmployee = responseData.employeeJobApplications[0];
        const updatedFormData = { ...jobFormData };
        updatedFormData.forJobApply = firstEmployee.forJobApply;
        updatedFormData.team = firstEmployee.hiring.department;
        setJobFormData(updatedFormData);
      } else {
        console.error("No employee data found.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }


  const [jobFormData, setJobFormData] = useState({
    forJobApply:"",
    hiringDate: "",
    team: "",
    manager: "",
    managerEmail:"",
    probationStartDate: "",
    probationEndDate: "",
    salary:"",
  });

  const [jobFormDataError, setJobFormDataError] = useState({
    jobTitleError: "",
    hiringDateError: "",
    teamError: "",
    managerError: "",
    probationStartDateError: "",
    probationEndDateError: "",
    salaryError:"",
  });

  const handleJobChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or target is undefined:", e);
      return;
    }

    const { name, value } = e.target;

    if (name === "hiringDate" || name === "probationStartDate") {
      const selectedDate = new Date(value);
      const currentDate = new Date();
      if (selectedDate >= currentDate) {
        setJobFormDataError((prevState) => ({
          ...prevState,
          [`${name}Error`]: "Please select a date before today.",
        }));
        return;
      }
    }
    if (name === "probationEndDate") {
      const selectedDate = new Date(value);
      const currentDate = new Date();
      if (selectedDate <= currentDate) {
        setJobFormDataError((prevState) => ({
          ...prevState,
          [`${name}Error`]: "Please select a date after today.",
        }));
        return;
      }
    }

    setJobFormDataError({ ...jobFormDataError, [`${name}Error`]: "" });
    setJobFormData({ ...jobFormData, [name]: value });
  };

  function validateJobValidation(jobFormData, setJobFormDataError) {
    let hasError = false;

    Object.keys(jobFormData).forEach((fieldName) => {
      if (
        (typeof jobFormData[fieldName] === "string" &&
          (!jobFormData[fieldName] || !jobFormData[fieldName].trim())) || // For string fields
        (typeof jobFormData[fieldName] === "number" &&
          (isNaN(jobFormData[fieldName]) || jobFormData[fieldName] === null)) // For numeric fields
      ) {
        const fieldNameWithSpaces = fieldName
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase());
        setJobFormDataError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: `${fieldNameWithSpaces} is required.`,
        }));
        hasError = true;
      }
    });

    if (hasError) {
      return false;
    }

    const allFieldsFilled = Object.values(jobFormData).every((value) => {
      return (
        (typeof value === "string" && value.trim() !== "") ||
        (typeof value === "number" && !isNaN(value))
      );
    });

    if (!allFieldsFilled) {
      setJobFormDataError((prevState) => ({
        ...prevState,
        commonError: "Please fill in all fields before saving.",
      }));
      return false;
    }

    return true;
  }

  return {
    jobFormData,
    setJobFormData,
    jobFormDataError,
    setJobFormDataError,
    onNext,
    validateJobValidation,
    handleJobChange,
    fetchEmployeeJobData,
  };
}

export default jobService;
