import React, { useEffect, useState } from "react";
import axios from "axios";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { FileOpenOutlined } from "@mui/icons-material";
import { Space, Table, Collapse } from "antd";
import Footer from "layouts/authentication/components/Footer";

function DocumentManager() {
  const [items, setItems] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("All Departments");

  useEffect(() => {
    fetchData();
  }, [selectedDepartment]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/EmployeeInterview/GetReceiveContract`);
      if (!response.data.contract || !Array.isArray(response.data.contract)) {
        throw new Error("Invalid contract data format");
      }

      const contracts = response.data.contract;
      const employees = response.data.employees;

      // Filter contracts by department
      const filteredContracts = selectedDepartment === "All Departments"
        ? contracts
        : contracts.filter(contract => contract.employeeJobApplication.hiring.department === selectedDepartment);

      const items = filteredContracts.map((contract, index) => {
        const employee = employees.find((e) => e.employeeId === contract.employeeId);
        const employeeName = employee ? `${employee.firstName} ${employee.lastName}` : "Unknown";
        const department = employee ? `${employee.department}` : "Unknown";

        return {
          key: index.toString(),
          label: `Contract for ${employeeName} (Employee ID: ${contract.employeeId}, Department: ${department})`,
          children: (
            <Table
              dataSource={[
                { key: "ndafile", name: "NDA File", file: contract.ndafile },
                { key: "ncafile", name: "NCA File", file: contract.ncafile },
                {
                  key: "employeeAgreement",
                  name: "Employee Agreement",
                  file: contract.employeeAgreement,
                },
                { key: "i_nine", name: "I-9 File", file: contract.i_nine },
                { key: "w_four", name: "W-4 File", file: contract.w_four },
                { key: "offerLetter", name: "Offer Letter", file: contract.offerLetter },
              ]}
              pagination={false}
              columns={[
                { title: "File Name", dataIndex: "name", key: "name" },
                {
                  title: "Download/Preview",
                  key: "download",
                  render: (record) => (
                    <Space size="middle">
                      <FileOpenOutlined
                        onClick={() => handlePdfPreview(`${apiUrl}/${record.file}`)}
                        style={{ cursor: "pointer" }}
                      />
                    </Space>
                  ),
                },
              ]}
            />
          ),
        };
      });

      setItems(items);
    } catch (error) {
      console.error("Error fetching contract data:", error);
    }
  };

  const handlePdfPreview = (fileUrl) => {
    try {
      window.open(fileUrl, "_blank");
    } catch (error) {
      console.error("Error handling file:", error);
    }
  };

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/AttendanceReport/Get-All-Departments`);
        const departmentNames = response.data.map((dept) => dept.department);
        setDepartments(departmentNames);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };
    fetchDepartments();
  }, [apiUrl]);

  const handleDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);
  };

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <div className="card">
            <div className="card-header" style={{ position: "relative" }}>
              <h5>Document Managers</h5>
            </div>
            <div className="card-body">
              <div className="col-md-12 d-flex justify-content-end">
                <div className="col-md-3 mb-3">
                  <select
                    className="form-select custom-select"
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                  >
                    <option value="All Departments">All Departments</option>
                    {departments.map((department) => (
                      <option key={department} value={department}>
                        {department}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row">
                <Collapse accordion items={items} />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default DocumentManager;
