import React from "react";
import "./Job.scss";
import "react-datepicker/dist/react-datepicker.css";
import SoftButton from "components/SoftButton";
import axios from "axios";
import DynamicOrganizationService from "../EmployeeApiService/JobInitiateService/DynamicOrganizationService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PropTypes from "prop-types";
import { updateDynamicInfo } from "../../layouts/Services/Actions/actions";
import { useDispatch, useSelector } from "react-redux";

export default function DynamicOrganization({ onNext, onBack, selectedEmail }) {
  const {
    dynamicFormData,
    dynamicFormDataError,
    setDynamicFormdataError,
    validateDynamicValidation,
    isLoading,
    handleDynamicChange,
  } = DynamicOrganizationService({ onNext, selectedEmail });
  const { initiateData, performanceData } = useSelector((state) => state);

  const dispatch = useDispatch();
  const handleNext = (e) => {
    e.preventDefault();
    const isValid = validateDynamicValidation(dynamicFormData, setDynamicFormdataError);
    if (isValid) {
      dispatch(updateDynamicInfo(dynamicFormData));
      console.warn("Initiate Data : ", initiateData);
      console.warn("Performance Data : ", performanceData);
      onNext();
    }
  };

  const handleBack = () => {
    onBack();
  };

  return (
    <div>
      <div className="card">
        <div className="card-header" style={{ position: "relative" }}>
          <h5>Driving the Dynamic Organization</h5>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-9 mb-4">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1" className="lab">
                  Valuing Diversity *
                  <p style={{ fontSize: "small" }}>Overall Performance Factor Rating</p>
                </label>
                <input
                  type="number"
                  placeholder="Enter Overall Performance Factor Rating here"
                  className={`form-control ${
                    dynamicFormDataError.valuing_DiversityError ? "is-invalid" : ""
                  }`}
                  name="valuing_Diversity"
                  value={dynamicFormData.valuing_Diversity}
                  onChange={handleDynamicChange}
                />
                {dynamicFormDataError.valuing_DiversityError && (
                  <div className="invalid-feedback invalid-message">
                    {dynamicFormDataError.valuing_DiversityError}
                  </div>
                )}
              </div>
            </div>
            <label style={{ textAlign: "left" }}>
              <i className="fa fa-star-o" aria-hidden="true"></i>&nbsp;Performance Evaluation
              Summary
            </label>
            <div className="col-md-9">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1" className="lab">
                  Overall Accountability/Goal Rating
                </label>
                <input
                  type="number"
                  className={`form-control ${
                    dynamicFormDataError.overall_Accountability_RatingError ? "is-invalid" : ""
                  }`}
                  name="overall_Accountability_Rating"
                  value={dynamicFormData.overall_Accountability_Rating}
                  onChange={handleDynamicChange}
                />
                {dynamicFormDataError.overall_Accountability_RatingError && (
                  <div className="invalid-feedback invalid-message">
                    {dynamicFormDataError.overall_Accountability_RatingError}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-9">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1" className="lab">
                  Overall Performance Factors Rating
                </label>
                <input
                  type="number"
                  className={`form-control ${
                    dynamicFormDataError.overall_Performance_RatingError ? "is-invalid" : ""
                  }`}
                  name="overall_Performance_Rating"
                  value={dynamicFormData.overall_Performance_Rating}
                  onChange={handleDynamicChange}
                />
                {dynamicFormDataError.overall_Performance_RatingError && (
                  <div className="invalid-feedback invalid-message">
                    {dynamicFormDataError.overall_Performance_RatingError}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-9">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1" className="lab">
                  Overall Dynamic Organization Rating
                </label>
                <input
                  type="number"
                  className={`form-control ${
                    dynamicFormDataError.overall_DynamicOrganization_RatingError ? "is-invalid" : ""
                  }`}
                  name="overall_DynamicOrganization_Rating"
                  value={dynamicFormData.overall_DynamicOrganization_Rating}
                  onChange={handleDynamicChange}
                />
                {dynamicFormDataError.overall_DynamicOrganization_RatingError && (
                  <div className="invalid-feedback invalid-message">
                    {dynamicFormDataError.overall_DynamicOrganization_RatingError}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-9">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1" className="lab">
                  TOTAL PERFORMANCE Rating
                </label>
                <input
                  type="number"
                  readOnly
                  className="form-control"
                  name="total_performance_Rating"
                  value={dynamicFormData.total_performance_Rating}
                  onChange={handleDynamicChange}
                />
              </div>
            </div>
            <label className="mb-3" style={{ textAlign: "left" }}>
              <i className="fa fa-star-o" aria-hidden="true"></i>&nbsp;Summary of Strengths and
              Development Needs
            </label>
            <div className="col-md-9 mb-4">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1" className="lab">
                  A. Associate Strengths *
                </label>
                <input
                  type="text"
                  placeholder="Enter Associate Strengths here"
                  className={`form-control ${
                    dynamicFormDataError.associate_StrengthsError ? "is-invalid" : ""
                  }`}
                  name="associate_Strengths"
                  value={dynamicFormData.associate_Strengths}
                  onChange={handleDynamicChange}
                />
                {dynamicFormDataError.associate_StrengthsError && (
                  <div className="invalid-feedback invalid-message">
                    {dynamicFormDataError.associate_StrengthsError}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-9 mb-4">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1" className="lab">
                  B. Associate Development Needs/Improvement Areas *
                </label>
                <input
                  type="text"
                  placeholder="Enter Associate Development Need here"
                  className={`form-control ${
                    dynamicFormDataError.associate_DevelopmentError ? "is-invalid" : ""
                  }`}
                  name="associate_Development"
                  value={dynamicFormData.associate_Development}
                  onChange={handleDynamicChange}
                />
                {dynamicFormDataError.associate_DevelopmentError && (
                  <div className="invalid-feedback invalid-message">
                    {dynamicFormDataError.associate_DevelopmentError}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-9 mb-4">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1" className="lab">
                  C. Action Plan(s) for Improvement *
                </label>
                <input
                  type="text"
                  placeholder="Enter Action Plan here"
                  className={`form-control ${
                    dynamicFormDataError.action_PlanError ? "is-invalid" : ""
                  }`}
                  name="action_Plan"
                  value={dynamicFormData.action_Plan}
                  onChange={handleDynamicChange}
                />
                {dynamicFormDataError.action_PlanError && (
                  <div className="invalid-feedback invalid-message">
                    {dynamicFormDataError.action_PlanError}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-9 mb-4">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1" className="lab">
                  D. Associate Comments *
                </label>
                <textarea
                  type="text"
                  rows={2}
                  placeholder="Type here..."
                  className={`form-control ${
                    dynamicFormDataError.associate_CommentsError ? "is-invalid" : ""
                  }`}
                  name="associate_Comments"
                  value={dynamicFormData.associate_Comments}
                  onChange={handleDynamicChange}
                />
                {dynamicFormDataError.associate_CommentsError && (
                  <div className="invalid-feedback invalid-message">
                    {dynamicFormDataError.associate_CommentsError}
                  </div>
                )}
              </div>
            </div>
            <p style={{ fontSize: "small" }}>
              I have received this completed performance evaluation form and have discussed it with
              my supervisor.
            </p>
            <div className="col-md-5 mb-4">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1" className="lab">
                  Associate Name *
                </label>
                <input
                  type="text"
                  placeholder="Associate Signature here"
                  className={`form-control ${
                    dynamicFormDataError.associate_NameError ? "is-invalid" : ""
                  }`}
                  name="associate_Name"
                  value={dynamicFormData.associate_Name}
                  onChange={handleDynamicChange}
                />
                {dynamicFormDataError.associate_NameError && (
                  <div className="invalid-feedback invalid-message">
                    {dynamicFormDataError.associate_NameError}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1" className="lab">
                  Supervisor Name *
                </label>
                <input
                  type="text"
                  placeholder="Supervisor Signature here"
                  className={`form-control ${
                    dynamicFormDataError.supervisor_NameError ? "is-invalid" : ""
                  }`}
                  name="supervisor_Name"
                  value={dynamicFormData.supervisor_Name}
                  onChange={handleDynamicChange}
                />
                {dynamicFormDataError.supervisor_NameError && (
                  <div className="invalid-feedback invalid-message">
                    {dynamicFormDataError.supervisor_NameError}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-9 mb-4">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1" className="lab">
                  Date *
                </label>
                <input
                  type="date"
                  className={`form-control ${dynamicFormDataError.DateError ? "is-invalid" : ""}`}
                  name="Date"
                  value={dynamicFormData.Date}
                  onChange={handleDynamicChange}
                />
                {dynamicFormDataError.DateError && (
                  <div className="invalid-feedback invalid-message">
                    {dynamicFormDataError.DateError}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-9">
              <SoftButton
                variant="outlined"
                style={{ float: "inline-end" }}
                color="info"
                size="small"
                onClick={handleNext}
              >
                Next
              </SoftButton>
              <SoftButton
                variant="outlined"
                style={{ float: "inline-end", marginRight: "10px" }}
                color="error"
                size="small"
                onClick={handleBack}
              >
                Back
              </SoftButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

DynamicOrganization.propTypes = {
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  selectedEmail: PropTypes.object.isRequired,
};
