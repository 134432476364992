import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Table } from "antd";
import SoftButton from "components/SoftButton";
import "./EmployeeDetail.scss";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "layouts/authentication/components/Footer";

function EmployeeDetail() {
  const { personalId } = useParams();
  const [employeeDetail, setEmployeeDetail] = useState(null);
  const [employeeEmergecnyDetail, setEmployeeEmergency] = useState(null);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleUserEdit = () => {
    navigate(`/humanResource/${personalId}`, { state: { employeeDetail } });
  };

  useEffect(() => {
    async function fetchEmployeeDetail() {
      try {
        const response = await axios.get(
          `${apiUrl}/api/PersonalInformation/GetAllByPersonalId/${personalId}`
        );
        setEmployeeDetail(response.data);
      } catch (error) {
        console.error("Error fetching employee detail:", error);
      }
    }

    fetchEmployeeDetail();
  }, [personalId]);

  useEffect(() => {
    async function fetchEmployeeEmergencnyDetail() {
      try {
        const response = await axios.get(
          `${apiUrl}/api/EmployeeEmergencyContact/GetAllByPersonalId/${personalId}`
        );
        setEmployeeEmergency(response.data);
      } catch (error) {
        console.error("Error fetching employee detail:", error);
      }
    }

    fetchEmployeeEmergencnyDetail();
  }, [personalId]);

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <div className="row">
            <div className="col-md-12">
              <div
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <h6
                  className="h6-employeedetail"
                  style={{ marginLeft: "15px", color: "cadetblue" }}
                >
                  User Detail View
                </h6>
                {/* <SoftButton onClick={handleUserEdit} variant="outlined" color="info" size="small">
                  <i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;Edit Details
                </SoftButton> */}
              </div>
              <div className="card cardSet">
                <div className="card-body">
                  {employeeDetail && (
                    <>
                      <div className="userDetail-card mb-2">
                        <h6 className="h6-employeedetail">Employee no #</h6>
                        <span className="h6-employeedetail">
                          {employeeDetail.personalInfo.employeeNumber}
                        </span>
                      </div>
                      <hr></hr>
                      <div className="userDetail-card mb-2">
                        <h6 className="h6-employeedetail">First Name</h6>
                        <span className="h6-employeedetail">
                          {employeeDetail.personalInfo.firstName}
                        </span>
                      </div>
                      <hr></hr>
                      <div className="userDetail-card mb-2">
                        <h6 className="h6-employeedetail">Last Name</h6>
                        <span className="h6-employeedetail">
                          {employeeDetail.personalInfo.lastName}
                        </span>
                      </div>
                      <hr></hr>
                      <div className="userDetail-card mb-2">
                        <h6 className="h6-employeedetail">Department</h6>
                        <span className="h6-employeedetail">{employeeDetail.job.team}</span>
                      </div>
                      <hr></hr>
                      <div className="userDetail-card mb-2">
                        <h6 className="h6-employeedetail">Job Title</h6>
                        <span className="h6-employeedetail">{employeeDetail.job.forJobApply}</span>
                      </div>
                      <hr></hr>
                      <div className="userDetail-card mb-2">
                        <h6 className="h6-employeedetail">Location</h6>
                        <span className="h6-employeedetail">
                          {employeeDetail.personalInfo.city}
                        </span>
                      </div>
                      <hr></hr>
                      <div className="userDetail-card mb-2">
                        <h6 className="h6-employeedetail">Hire Date</h6>
                        <span className="h6-employeedetail">
                          {employeeDetail.job.probationStartDate}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <div className="row">
            <div className="body flex-grow-1 px-3">
              <div
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <h6
                  className="h6-employeedetail"
                  style={{ marginLeft: "15px", color: "cadetblue" }}
                >
                  Employee Emergency Contact
                </h6>
              </div>
              <div className="card cardSet">
                <div className="card-body">
                  {employeeEmergecnyDetail ? (
                    <>
                      <div className="userDetail-card mb-2">
                        <h6 className="h6-employeedetail">Employee ID #</h6>
                        <span className="h6-employeedetail">
                          {employeeEmergecnyDetail.emergecnyContactInfo.personalId}
                        </span>
                      </div>
                      <hr></hr>
                      <div className="userDetail-card mb-2">
                        <h6 className="h6-employeedetail">Name</h6>
                        <span className="h6-employeedetail">
                          {employeeEmergecnyDetail.emergecnyContactInfo.name}
                        </span>
                      </div>
                      <hr></hr>
                      <div className="userDetail-card mb-2">
                        <h6 className="h6-employeedetail">Relation</h6>
                        <span className="h6-employeedetail">
                          {employeeEmergecnyDetail.emergecnyContactInfo.relation}
                        </span>
                      </div>
                      <hr></hr>
                      <div className="userDetail-card mb-2">
                        <h6 className="h6-employeedetail">Phone No</h6>
                        <span className="h6-employeedetail">
                          {employeeEmergecnyDetail.emergecnyContactInfo.phoneNo}
                        </span>
                      </div>
                      <hr></hr>
                      <div className="userDetail-card mb-2">
                        <h6 className="h6-employeedetail">Home Phone</h6>
                        <span className="h6-employeedetail">
                          {employeeEmergecnyDetail.emergecnyContactInfo.homePhone}
                        </span>
                      </div>
                      <hr></hr>
                      <div className="userDetail-card mb-2">
                        <h6 className="h6-employeedetail">Email</h6>
                        <span className="h6-employeedetail">
                          {employeeEmergecnyDetail.emergecnyContactInfo.email}
                        </span>
                      </div>
                      <hr></hr>
                      <div className="userDetail-card mb-2">
                        <h6 className="h6-employeedetail">Address Line 1</h6>
                        <span className="h6-employeedetail">
                          {employeeEmergecnyDetail.emergecnyContactInfo.address_one}
                        </span>
                      </div>
                      <hr></hr>
                      <div className="userDetail-card mb-2">
                        <h6 className="h6-employeedetail">Address Line 2</h6>
                        <span className="h6-employeedetail">
                          {employeeEmergecnyDetail.emergecnyContactInfo.address_two}
                        </span>
                      </div>
                      <hr></hr>
                      <div className="userDetail-card mb-2">
                        <h6 className="h6-employeedetail">City</h6>
                        <span className="h6-employeedetail">
                          {employeeEmergecnyDetail.emergecnyContactInfo.city}
                        </span>
                      </div>
                      <hr></hr>
                      <div className="userDetail-card mb-2">
                        <h6 className="h6-employeedetail">State</h6>
                        <span className="h6-employeedetail">
                          {employeeEmergecnyDetail.emergecnyContactInfo.state}
                        </span>
                      </div>
                      <hr></hr>
                      <div className="userDetail-card mb-2">
                        <h6 className="h6-employeedetail">Postal Code</h6>
                        <span className="h6-employeedetail">
                          {employeeEmergecnyDetail.emergecnyContactInfo.postalCode}
                        </span>
                      </div>
                      <hr></hr>
                    </>
                  ) : (
                    <div className="text-center" style={{ fontSize: "1rem" }}>
                      No Emergency contact added
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </DashboardLayout>
  );
}

export default EmployeeDetail;
