import SoftButton from "components/SoftButton";
import { Table } from "antd";
import { useState, useEffect } from "react";
import HardwareService from "layouts/APIServices/AssetsService/HardwareService";
import { Category, Description, Edit } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { updateHardwareInfo } from "../Services/Actions/actions";

function HardwareInputFields({ onNext, onBack, employeeDetail }) {
  const {
    hardwareformData,
    formDataError,
    setFormDataError,
    handleChange,
    validateValidation,
  } = HardwareService();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [hardware, setHardware] = useState(true);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleHardware = () => {
    setHardware(false);
  };
  const dispatch = useDispatch();
  const handleNext = (e) => {
    e.preventDefault();
    const isValid = validateValidation(hardwareformData, setFormDataError);
    if (isValid) {
      dispatch(updateHardwareInfo(hardwareformData));
      onNext();
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const success = await handleNext();
      if (success) {
        onNext();
      }
    } catch (error) {
      toast.error(error.message || "Added failed", {
        autoClose: 5000,
        style: { background: "lightcoral", color: "white" },
      });
    }
  };

  async function fetchData() {
    try {
      const response = await axios.get(`${apiUrl}/api/Assets/Get-Hardware-Assets`);
      const hardware = response.data;
      const updatedRows = hardware.map((info) => {
        function formatDateTime(dateTimeString) {
          const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          const date = new Date(dateTimeString);
          const month = months[date.getMonth()];
          const day = date.getDate();
          const year = date.getFullYear();
          let hours = date.getHours();
          const ampm = hours >= 12 ? "PM" : "AM";
          hours = hours % 12;
          hours = hours ? hours : 12;
          const minutes = date.getMinutes();
          return `${month} ${day < 10 ? "0" : ""}${day}, ${year} ${hours}:${
            minutes < 10 ? "0" : ""
          }${minutes} ${ampm}`;
        }

        return {
          key: info.hardwareAssetsId.toString(),
          Department: `${info.category}`,
          Description: `${info.description}`,
          Serial: info.serial,
          DateAssigned: <span>{formatDateTime(info.dateLoaned)}</span>,
          DateReturned: <span>{formatDateTime(info.dateReturned)}</span>,
          AssetsCost: info.assetsCost,
          Notes: info.notes,
        };
      });

      // Update dataSource state
      setDataSource(updatedRows);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const hardwarecol = [
    {
      title: "Department",
      dataIndex: "Department",
      key: "Department",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
    },
    {
      title: "Serial",
      dataIndex: "Serial",
      key: "Serial",
    },
    {
      title: "Date Assigned",
      dataIndex: "DateAssigned",
      key: "DateAssigned",
    },
    {
      title: "Date Returned",
      dataIndex: "DateReturned",
      key: "DateReturned",
    },
    {
      title: "Assets Cost",
      dataIndex: "AssetsCost",
      key: "AssetsCost",
    },
    {
      title: "Notes",
      dataIndex: "Notes",
      key: "Notes",
    },
  ];

  const handleBack = () => {
    setHardware(true);
  };

  return (
    <>
      <ToastContainer />
      {hardware && (
        <>
          <div className="col-md-12 mb-3">
            <SoftButton
              variant="outlined"
              style={{ float: "inline-end" }}
              color="info"
              size="small"
              onClick={handleHardware}
            >
              Add Hardware Assets
            </SoftButton>
          </div>
          <br></br>
          <div className="col-12">
            <h6>Overall Assigned Hardware Assets Data to Employees</h6>
            <Table
              className="table-responsive mt-3"
              bordered
              dataSource={dataSource}
              columns={hardwarecol}
              pagination={{ pageSize: 10 }}
            />
          </div>
        </>
      )}
      {!hardware && (
        <>
          <form onSubmit={onSubmit} encType="multipart/form-data">
            <div className="row">
              <div className="col-md-5">
                <div className="mb-3">
                  <label
                    htmlFor="exampleInput"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Department *
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Department here"
                    name="category"
                    disabled
                    value={hardwareformData.category}
                    onChange={handleChange}
                    className={`form-control ${formDataError.categoryError ? "is-invalid" : ""}`}
                  />
                  {formDataError.categoryError && (
                    <div className="invalid-feedback invalid-message">
                      {formDataError.categoryError}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <label
                    htmlFor="exampleInput"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Assets Cost *
                  </label>
                  <input
                    type="number"
                    min="0"
                    placeholder="Enter Assets cost here"
                    name="assetsCost"
                    value={hardwareformData.assetsCost}
                    onChange={handleChange}
                    className={`form-control ${formDataError.assetsCostError ? "is-invalid" : ""}`}
                  />
                  {formDataError.assetsCostError && (
                    <div className="invalid-feedback invalid-message">
                      {formDataError.assetsCostError}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-5">
                <div className="mb-3">
                  <label
                    htmlFor="exampleInput"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Serial *
                  </label>
                  <input
                    min="0"
                    type="number"
                    placeholder="Enter Serial here"
                    name="serial"
                    value={hardwareformData.serial}
                    onChange={handleChange}
                    className={`form-control ${formDataError.serialError ? "is-invalid" : ""}`}
                  />
                  {formDataError.serialError && (
                    <div className="invalid-feedback invalid-message">
                      {formDataError.serialError}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <label
                    htmlFor="exampleInput"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Date Assigned *
                  </label>
                  <input
                    type="date"
                    name="dateLoaned"
                    value={hardwareformData.dateLoaned}
                    onChange={handleChange}
                    className={`form-control ${formDataError.dateLoanedError ? "is-invalid" : ""}`}
                  />
                  {formDataError.dateLoanedError && (
                    <div className="invalid-feedback invalid-message">
                      {formDataError.dateLoanedError}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-5">
                <div className="mb-3">
                  <label
                    htmlFor="exampleInput"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Notes *
                  </label>
                  <input
                    type="text"
                    placeholder="Enter notes here"
                    name="notes"
                    value={hardwareformData.notes}
                    onChange={handleChange}
                    className={`form-control ${formDataError.notesError ? "is-invalid" : ""}`}
                  />
                  {formDataError.notesError && (
                    <div className="invalid-feedback invalid-message">
                      {formDataError.notesError}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <label
                    htmlFor="exampleInput"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Date Returned *
                  </label>
                  <input
                    type="date"
                    name="dateReturned"
                    value={hardwareformData.dateReturned}
                    onChange={handleChange}
                    className={`form-control ${
                      formDataError.dateReturnedError ? "is-invalid" : ""
                    }`}
                  />
                  {formDataError.dateReturnedError && (
                    <div className="invalid-feedback invalid-message">
                      {formDataError.dateReturnedError}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-9">
                <div className="mb-3">
                  <label
                    htmlFor="exampleInput"
                    className="form-label"
                    style={{ fontSize: "medium" }}
                  >
                    Description *
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Description here"
                    name="description"
                    value={hardwareformData.description}
                    onChange={handleChange}
                    className={`form-control ${formDataError.descriptionError ? "is-invalid" : ""}`}
                  />
                  {formDataError.descriptionError && (
                    <div className="invalid-feedback invalid-message">
                      {formDataError.descriptionError}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
          <div className="col-md-9">
            <SoftButton
              variant="outlined"
              style={{ float: "inline-end" }}
              color="info"
              size="small"
              onClick={handleNext}
            >
              Next
            </SoftButton>
            <SoftButton
              variant="outlined"
              style={{ float: "inline-end", marginRight: "10px" }}
              color="error"
              size="small"
              onClick={handleBack}
            >
              Back
            </SoftButton>
          </div>
        </>
      )}
    </>
  );
}

HardwareInputFields.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  employeeDetail: PropTypes.func.isRequired,
};

export default HardwareInputFields;
