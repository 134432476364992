import React, { useState, useEffect } from "react";
import { Chart } from "react-google-charts";
import SoftButton from "components/SoftButton";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

function Attendance() {
  const navigate = useNavigate();
  const viewAll = () => {
    navigate("/employeedashboard/attendance");
  };
  const [attendanceSummary, setAttendanceSummary] = useState(null);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;

  // Define custom colors for the chart
  useEffect(() => {
    const fetchAttendanceSummary = async () => {
      try {
        // Retrieve email from local storage
        const email = localStorage.getItem("loginEmail");

        if (!email) {
          throw new Error("Email not found in local storage.");
        }

        const response = await axios.get(
          `${apiUrl}/api/AttendanceReport/GetAttendanceSummaryByEmail?email=${encodeURIComponent(
            email
          )}`
        );

        // Update attendanceSummary state
        setAttendanceSummary(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchAttendanceSummary();
  }, [apiUrl]);

  // Define data for the pie chart
  const chartData = [
    ["Status", "Count"],
    ["Present", attendanceSummary ? attendanceSummary.presentCount : 0],
    ["Late Arrival", attendanceSummary ? attendanceSummary.lateArrivalCount : 0],
    ["Absent", attendanceSummary ? attendanceSummary.absentCount : 0],
  ];
  const colors = ["green", "#FF5733", "#faae20"];

  return (
    <div className="card" style={{ height: "100%", width: "100%" }}>
      <div className="card-header" style={{ position: "relative" }}>
        <h5>Attendance</h5>
      </div>
      <div className="card-body">
        <div className="row">
          <div style={{ width: "900px", height: "300px", textAlign: "center" }}>
            {!loading && attendanceSummary ? (
              <>
                <Chart
                  chartType="PieChart"
                  data={chartData}
                  options={{
                    pieHole: 0.4,
                    pieSliceText: "value",
                    tooltip: { text: "value" },
                    colors: ["green", "#faae20", "#FF5733"],
                  }}
                  width={"100%"}
                  height={"100%"}
                />
                <div className="col-md-12 d-flex justify-content-end">
                  <SoftButton
                    variant="outlined"
                    style={{ marginTop: "-40px" }}
                    color="info"
                    size="small"
                    onClick={viewAll}
                  >
                    <i className="fa fa-paper-plane" aria-hidden="true"></i>
                    &nbsp;&nbsp;&nbsp;&nbsp;View
                  </SoftButton>
                </div>
              </>
            ) : (
              <span style={{ fontSize: "medium" }}>No attendance data available yet!</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Attendance;
