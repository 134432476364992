import React, { useState, useMemo, useEffect } from "react";
import "./Job.scss";
import "react-datepicker/dist/react-datepicker.css";
import SoftButton from "components/SoftButton";
import axios from "axios";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { updatePerformanceInfo } from "../../layouts/Services/Actions/actions";
import PerformanceFactorService from "../EmployeeApiService/JobInitiateService/PerformanceFactorService";

export default function PerformanceFactor({ onNext, onBack, selectedEmail }) {

  const {
    performanceFormData,
    performanceFormDataError,
    setPerformFormdataError,
    validatePerformanceValidation,
    handlePerformanceChange,
  } = PerformanceFactorService({ onNext, selectedEmail });
  const { initiateData } = useSelector((state) => state);

  const dispatch = useDispatch();
  const handleNext = (e) => {
    e.preventDefault();
    const isValid = validatePerformanceValidation(performanceFormData, setPerformFormdataError);
    if (isValid) {
      dispatch(updatePerformanceInfo(performanceFormData));
      console.warn("Initiate Data : ", initiateData);
      onNext();
    }
  };

  const handleBack=()=>{
    onBack();
  }
  return (
    <div>
      <div className="card">
        <div className="card-header" style={{ position: "relative" }}>
          <h5>Performance Factors</h5>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-9 mb-4">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1" className="lab">
                  Job Knowledge/Technical Skills *
                  <p style={{ fontSize: "small" }}>As Evidenced By</p>
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    performanceFormDataError.jobKnowledgeError ? "is-invalid" : ""
                  }`}
                  name="jobKnowledge"
                  value={performanceFormData.jobKnowledge}
                  onChange={handlePerformanceChange}
                  placeholder="Enter evidence here"
                />
                {performanceFormDataError.jobKnowledgeError && (
                  <div className="invalid-feedback invalid-message">
                    {performanceFormDataError.jobKnowledgeError}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-9 mb-4">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1" className="lab">
                  Administration *<p style={{ fontSize: "small" }}>As Evidenced By</p>
                </label>
                <input
                  type="text"
                  placeholder="Enter evidence here"
                  className={`form-control ${
                    performanceFormDataError.administrationError ? "is-invalid" : ""
                  }`}
                  name="administration"
                  value={performanceFormData.administration}
                  onChange={handlePerformanceChange}
                />
                {performanceFormDataError.administrationError && (
                  <div className="invalid-feedback invalid-message">
                    {performanceFormDataError.administrationError}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-9 mb-4">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1" className="lab">
                  Decision-Making *<p style={{ fontSize: "small" }}>As Evidenced By</p>
                </label>
                <input
                  type="text"
                  placeholder="Enter evidence here"
                  className={`form-control ${
                    performanceFormDataError.decision_MakingError ? "is-invalid" : ""
                  }`}
                  name="decision_Making"
                  value={performanceFormData.decision_Making}
                  onChange={handlePerformanceChange}
                />
                {performanceFormDataError.decision_MakingError && (
                  <div className="invalid-feedback invalid-message">
                    {performanceFormDataError.decision_MakingError}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-9 mb-4">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1" className="lab">
                  Communication *<p style={{ fontSize: "small" }}>As Evidenced By</p>
                </label>
                <input
                  type="text"
                  placeholder="Enter evidence here"
                  className={`form-control ${
                    performanceFormDataError.communicationError ? "is-invalid" : ""
                  }`}
                  name="communication"
                  value={performanceFormData.communication}
                  onChange={handlePerformanceChange}
                />
                {performanceFormDataError.communicationError && (
                  <div className="invalid-feedback invalid-message">
                    {performanceFormDataError.communicationError}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-9 mb-4">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1" className="lab">
                  Initiative/Responsibility *<p style={{ fontSize: "small" }}>As Evidenced By</p>
                </label>
                <input
                  type="text"
                  placeholder="Enter evidence here"
                  className={`form-control ${
                    performanceFormDataError.initiativeError ? "is-invalid" : ""
                  }`}
                  name="initiative"
                  value={performanceFormData.initiative}
                  onChange={handlePerformanceChange}
                />
                {performanceFormDataError.initiativeError && (
                  <div className="invalid-feedback invalid-message">
                    {performanceFormDataError.initiativeError}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-9 mb-4">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1" className="lab">
                  Work Relationships *<p style={{ fontSize: "small" }}>As Evidenced By</p>
                </label>
                <input
                  type="text"
                  placeholder="Enter evidence here"
                  className={`form-control ${
                    performanceFormDataError.work_RelationshipsError ? "is-invalid" : ""
                  }`}
                  name="work_Relationships"
                  value={performanceFormData.work_Relationships}
                  onChange={handlePerformanceChange}
                />
                {performanceFormDataError.work_RelationshipsError && (
                  <div className="invalid-feedback invalid-message">
                    {performanceFormDataError.work_RelationshipsError}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-9 mb-4">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1" className="lab">
                  Flexibility *<p style={{ fontSize: "small" }}>As Evidenced By</p>
                </label>
                <input
                  type="text"
                  placeholder="Enter evidence here"
                  className={`form-control ${
                    performanceFormDataError.flexibilityError ? "is-invalid" : ""
                  }`}
                  name="flexibility"
                  value={performanceFormData.flexibility}
                  onChange={handlePerformanceChange}
                />
                {performanceFormDataError.flexibilityError && (
                  <div className="invalid-feedback invalid-message">
                    {performanceFormDataError.flexibilityError}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-9 mb-4">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1" className="lab">
                  Overall Performance Factor Rating *
                </label>
                <input
                  type="number"
                  placeholder="Enter Overall Performance Factor Rating here"
                  className={`form-control ${
                    performanceFormDataError.overall_PerformanceError ? "is-invalid" : ""
                  }`}
                  name="overall_Performance"
                  value={performanceFormData.overall_Performance}
                  onChange={handlePerformanceChange}
                />
                {performanceFormDataError.overall_PerformanceError && (
                  <div className="invalid-feedback invalid-message">
                    {performanceFormDataError.overall_PerformanceError}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-9">
              <SoftButton
                variant="outlined"
                style={{ float: "inline-end" }}
                color="info"
                size="small"
                onClick={handleNext}
              >
                Next
              </SoftButton>
              <SoftButton
                style={{ float: "inline-end", marginRight: "10px" }}
                variant="outlined"
                color="error"
                size="small"
                  onClick={handleBack}
              >
                Back
              </SoftButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
PerformanceFactor.propTypes = {
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  selectedEmail: PropTypes.object.isRequired,
};
