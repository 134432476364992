import React, { useState, useEffect } from "react";
import { Table, Checkbox, Button, message } from "antd";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { updateRoutes } from "../../routes"; // Import the updateRoutes function
import Footer from "layouts/authentication/components/Footer";

const AssignRole = () => {
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const { childManagerId } = useParams();
  const [permissions, setPermissions] = useState({
    Employees: { view: false },
    Hiring: { view: false },
    Report: { view: true },
    "Performance Management": { view: true },
    "Training and Development": { view: true },
    Complaints: { view: true },
    Compliance: { view: true },
  });

  useEffect(() => {
    fetchPermissions();
  }, []);

  const fetchPermissions = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/ChildManager/GetPermissions/${childManagerId}`
      );
      if (response.data) {
        const fetchedPermissions = response.data;
        setPermissions({
          ...permissions,
          Employees: { view: fetchedPermissions.employees },
          Hiring: { view: fetchedPermissions.hiring },
          Report: { view: fetchedPermissions.report },
          "Performance Management": { view: fetchedPermissions.performance },
          "Training and Development": { view: fetchedPermissions.training },
          Complaints: { view: fetchedPermissions.complaints },
          Compliance: { view: fetchedPermissions.compliance },
        });
      }
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  const handleCheckboxChange = (module, permission) => {
    setPermissions({
      ...permissions,
      [module]: {
        ...permissions[module],
        [permission]: !permissions[module][permission],
      },
    });
  };

  const handleSubmit = async () => {
    const permissionDto = {
      Employees: permissions.Employees.view,
      Hiring: permissions.Hiring.view,
      Report: permissions.Report.view,
      Performance: permissions["Performance Management"].view,
      Training: permissions["Training and Development"].view,
      Complaints: permissions.Complaints.view,
      Compliance: permissions.Compliance.view,
    };

    try {
      setIsLoading(true);
      if (childManagerId) {
        await axios.post(
          `${apiUrl}/api/ChildManager/UpdatePermissions/${childManagerId}`,
          permissionDto
        );
        message.success(`Role Permission Assigned successfully`);
        // Update routes dynamically after updating permissions
        await updateRoutes(childManagerId);
        navigate("/associate-managers");
      }
    } catch (error) {
      console.error("Error updating permissions:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      title: "Modules",
      dataIndex: "module",
      key: "module",
    },
    {
      title: "View",
      dataIndex: "view",
      key: "view",
      render: (text, record) => (
        <Checkbox
          checked={permissions[record.module].view}
          onChange={() => handleCheckboxChange(record.module, "view")}
        />
      ),
    },
  ];

  const data = Object.keys(permissions).map((module, index) => ({
    key: index,
    module,
    view: permissions[module].view,
  }));

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <div className="card">
            <div className="card-header" style={{ position: "relative" }}>
              <h5>Assign Roles</h5>
            </div>
            <div className="card-body">
              <div className="row mb-3">
                <Table columns={columns} dataSource={data} pagination={false} />
              </div>
              <div className="col-md-12 d-flex justify-content-end">
                <Button type="primary" onClick={handleSubmit}>
                  {isLoading ? "Submitting..." : "Assigned Permissions Role"}
                  {isLoading && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </Button>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AssignRole;
