import React, { useState, useEffect } from "react";
import { Table, message } from "antd";
import SoftBadge from "components/SoftBadge";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axios from "axios";
import { Link } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import SoftBox from "components/SoftBox";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Footer from "layouts/authentication/components/Footer";

function Performance() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("All Departments");
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);

  const updateStatus = async (personalId) => {
    try {
      await axios.post(`${apiUrl}/api/PersonalInformation/UpdateStatus`, { personalId });
      message.success("Status updated to In Process");
      fetchEmployees(selectedDepartment);
    } catch (error) {
      message.error("Failed to update status");
    }
  };

  const columns = [
    {
      title: "Employee_ID",
      dataIndex: ["personalInformation", "personalId"],
      key: "personalId",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => {
        const fullName = `${record.personalInformation.firstName} ${record.personalInformation.lastName}`;
        return fullName;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (_, record) => {
        const email = `${record.personalInformation.email}`;
        return email;
      },
    },
    {
      title: "Reports_To",
      dataIndex: "manager",
      key: "manager",
    },
    {
      title: "Evaluated_By",
      dataIndex: "manager",
      key: "manager",
    },
    {
      title: "Department",
      dataIndex: "team",
      key: "team",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        const status = record.personalInformation.status;
        let badgeContent, color, onClick;

        switch (status) {
          case 0:
            badgeContent = "Ask to initiate";
            color = "warning";
            onClick = () => updateStatus(record.personalInformation.personalId);
            break;
          case 1:
            badgeContent = "In process";
            color = "info";
            break;
          case 2:
            badgeContent = "Completed";
            color = "success";
            break;
          default:
            badgeContent = "Unknown status";
            color = "default";
        }

        return (
          <SoftBadge
            variant="gradient"
            badgeContent={badgeContent}
            color={color}
            size="xs"
            container
            onClick={status === 0 ? onClick : null}
            style={{ cursor: status === 0 ? "pointer" : "default" }}
          />
        );
      },
    },
    {
      title: "Score",
      dataIndex: "scoreAction",
      key: "scoreAction",
      render: (_, record) => {
        const performance =
          record.personalInformation.total_performance_Rating !== null
            ? record.personalInformation.total_performance_Rating
            : "N/A";

        return (
          <span style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <span>{performance}</span>
          </span>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (_, record) => {
        return (
          <span style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Link to={`/performance/${record.personalInformation.email}`}>
              <EyeOutlined className="eyeOutlined" style={{ marginLeft: "10px" }} />
            </Link>
          </span>
        );
      },
    },
  ];

  const fetchEmployees = async (department = "All Departments") => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/api/Job/GetEmployees-AllManagers`);
      const data = await response.json();
      const filteredData =
        department === "All Departments"
          ? data
          : data.filter((employee) => employee.team === department);
      setEmployees(filteredData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/AttendanceReport/Get-All-Departments`);
        const departmentNames = response.data.map((dept) => dept.department);
        setDepartments(departmentNames);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    fetchDepartments();
  }, [apiUrl]);

  const handleDepartmentChange = (e) => {
    const selectedDept = e.target.value;
    setSelectedDepartment(selectedDept);
    fetchEmployees(selectedDept);
  };

  const handleDownloadReport = () => {
    const filteredData = employees.filter(
      (employee) => selectedDepartment === "All Departments" || employee.team === selectedDepartment
    );

    const doc = new jsPDF();

    doc.setFontSize(18);
    doc.text("Employee Performance Report", 14, 22);

    const lineY = 28;
    const lineWidth = 0.5;
    const lineColor = [192, 192, 192];
    doc.setLineWidth(lineWidth);
    doc.setDrawColor(...lineColor);
    doc.line(14, lineY, 200, lineY);

    const marginTop = 3;
    const marginTopEOM = 2;
    doc.setFontSize(12);
    doc.text("Employee: All - All employees selected", 14, 32 + marginTop);

    // Get the current date
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString();

    doc.text(`Report Date: ${formattedDate}`, 14, 42 + marginTopEOM);
    doc.text("Exceptions: No Deletions", 14, 52);
    doc.text(`Department: ${selectedDepartment}`, 14, 59);

    const headers = [
      [
        "Employee ID",
        "Employee Name",
        "Email",
        "Manager",
        "Evaluated By",
        "Department",
        "Status",
        "Performance",
      ],
    ];
    const data = filteredData.map((employee) => {
      const employeeId = employee.personalInformation.personalId;
      const employeeName = `${employee.personalInformation.firstName} ${employee.personalInformation.lastName}`;
      const employeeEmail = employee.personalInformation.email;
      const manager = employee.manager;
      const evaluated = employee.manager;
      const department = employee.team || "N/A";
      const status =
        employee.personalInformation.status === 0
          ? "Ask to initiate"
          : employee.personalInformation.status === 1
          ? "In process"
          : employee.personalInformation.status === 2
          ? "Completed"
          : "Unknown";
      const performance =
        employee.personalInformation.total_performance_Rating !== null
          ? employee.personalInformation.total_performance_Rating
          : "N/A";

      return [
        employeeId,
        employeeName,
        employeeEmail,
        manager,
        evaluated,
        department,
        status,
        performance,
      ];
    });

    doc.autoTable({
      startY: 62,
      head: headers,
      body: data,
      headerStyles: {
        fillColor: [192, 192, 192],
        fontSize: 8,
      },
      styles: {
        fontSize: 8,
      },
    });

    doc.save(`employee_performance_report_${selectedDepartment}.pdf`);
  };

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <div className="card">
            <div className="card-header" style={{ position: "relative" }}>
              <h5>Performance</h5>
            </div>
            <div className="card-body">
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <SoftBox color="text" style={{ marginRight: "20px" }}>
                  <div style={{ display: "inline-block" }}>
                    <SoftButton
                      variant="gradient"
                      color="info"
                      size="small"
                      onClick={handleDownloadReport}
                    >
                      <i className="fa fa-download" aria-hidden="true"></i>&nbsp;Download Employee
                      Reports
                    </SoftButton>
                  </div>
                </SoftBox>
                <div className="mb-3" style={{ display: "inline-block" }}>
                  <select
                    className="form-select form-select-sm"
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                  >
                    <option value="All Departments">All Departments</option>
                    {departments.map((department) => (
                      <option key={department} value={department}>
                        {department}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row mb-3">
                <Table
                  className="table-responsive"
                  columns={columns}
                  dataSource={employees}
                  loading={loading}
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Performance;
