import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function InitiateService({ onNext, selectedEmail }) {
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const [formData, setFormData] = useState({
    accountAbilities_one: "",
    personalId: null,
    employeeEmail: "",
    weight_one: "",
    rating_one: "",
    accountAbilities_two: "",
    weight_two: "",
    rating_two: "",
    accountAbilities_three: "",
    weight_three: "",
    rating_three: "",
    accountAbilities_four: "",
    weight_four: "",
    rating_four: "",
    accountAbilities_five: "",
    weight_five: "",
    rating_five: "",
    accountAbilities_six: "",
    weight_six: "",
    rating_six: "",
  });

  useEffect(() => {
  
    if (selectedEmail && selectedEmail.email && selectedEmail.personalId !== null) {
      console.warn("Selected Email:", selectedEmail.email);
      console.warn("Selected PersonalId:", selectedEmail.personalId);
  
      setFormData(prevState => {
        const updatedState = {
          ...prevState,
          employeeEmail: selectedEmail.email,
          personalId: selectedEmail.personalId,
        };
        return updatedState;
      });
    }
  }, [selectedEmail]);
  

  const handleJobChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or target is undefined:", e);
      return;
    }
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  return {
    formData,
    setFormData,
    onNext,
    handleJobChange,
  };
}

export default InitiateService;
