import { Table } from "antd";
import { EyeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import DashboardLayout from "../LayoutNavbar/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../LayoutNavbar/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useParams } from "react-router-dom";

function EmployeeComplaintDetail() {
  const { complaintId } = useParams();

  const apiUrl = process.env.REACT_APP_API_URL;
  const [complaintDetail, setComplaintDetail] = useState(null);

  useEffect(() => {
    async function fetchComplaintDetail() {
      try {
        const response = await axios.get(
          `${apiUrl}/api/Complaint/GetAllByCompaintId/${complaintId}`
        );
        setComplaintDetail(response.data);
      } catch (error) {
        console.error("Error fetching employee detail:", error);
      }
    }

    fetchComplaintDetail();
  }, [complaintId]);

  const getStatusString = (status) => {
    switch (status) {
      case 0:
        return "Pending";
      case 1:
        return "Resolved";
      // case 2:
      //   return "Resolved";
      case 2:
        return "Rejected";
      default:
        return "Unknown";
    }
  };

  function formatDateTime(dateTimeString) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date(dateTimeString);
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutes = date.getMinutes();
    return `${month} ${day < 10 ? "0" : ""}${day}, ${year} ${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${ampm}`;
  }

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <ToastContainer />
        <div className="body flex-grow-1 px-3">
          <div className="row">
            <div className="col-md-8">
              <div
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <h6
                  className="h6-trainingdetail"
                  style={{ marginLeft: "15px", color: "cadetblue" }}
                >
                  Complaint Detail
                </h6>
              </div>
              {complaintDetail && (
                <>
                  <div className="card cardSet">
                    <div className="card-body">
                      <div className="trainingDetail-card mb-2">
                        <h6 className="h6-trainingdetail">First Name </h6>
                        <span className="h6-trainingdetail">
                          {complaintDetail.complaintInfo.firstName}
                        </span>
                      </div>
                      <div className="trainingDetail-card mb-2">
                        <h6 className="h6-trainingdetail">Last Name </h6>
                        <span className="h6-trainingdetail">
                          {complaintDetail.complaintInfo.lastName}
                        </span>
                      </div>
                      <div className="trainingDetail-card mb-2">
                        <h6 className="h6-trainingdetail"> Email </h6>
                        <span className="h6-trainingdetail">
                          {complaintDetail.complaintInfo.email}
                        </span>
                      </div>
                      <div className="trainingDetail-card mb-2">
                        <h6 className="h6-trainingdetail">Phone No </h6>
                        <span className="h6-trainingdetail">
                          {complaintDetail.complaintInfo.phoneNo}
                        </span>
                      </div>
                      <div className="trainingDetail-card mb-2">
                        <h6 className="h6-trainingdetail">Complaint Name </h6>
                        <span className="h6-trainingdetail">
                          {complaintDetail.complaintInfo.complaintName}
                        </span>
                      </div>
                      <div className="trainingDetail-card mb-2">
                        <h6 className="h6-trainingdetail">Complaint Against</h6>
                        <span className="h6-trainingdetail">
                          {complaintDetail.complaintInfo.complaintAgainst}
                        </span>
                      </div>
                      <div className="trainingDetail-card mb-2">
                        <h6 className="h6-trainingdetail">Status</h6>
                        <span className="h6-trainingdetail">
                          {" "}
                          {getStatusString(complaintDetail.complaintInfo.status)}
                        </span>
                      </div>
                      <div className="trainingDetail-card mb-2">
                        <h6 className="h6-trainingdetail">Requested Date</h6>
                        <span className="h6-trainingdetail">
                          {formatDateTime(complaintDetail.complaintInfo.complaintDate)}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default EmployeeComplaintDetail;
