import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

function usePersonalInformationService(onNext) {
  const [isLoading, setIsLoading] = useState(false);
  const { empId } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL

  const [formData, setFormData] = useState({
    personalId: 0,
    image: "",
    employeeNumber: "",
    EXT: "",
    workEmail: "",
  });

  const [employeeData, setEmployeeData] = useState({
    employeeId: "",
    firstName: "",
    lastName: "",
    city: "",
    state: "",
    dob: "",
    disabilities: "",
    gender: "",
    maritalStatus: "",
    ssn: "",
    nationality: "",
    streetAddress: "",
    postalCode: "",
    country: "",
    telePhone: "",
    mobileno: "",
    email: "",
    workAuthorizationUS:"",
    sponsorshipNeeded:"",
    tenYearsStrategicSuccess:"",
    highValueDealSuccess:"",
    teamLeadershipProven:"",
    martechAdtechExperience:"",
    linkedInProfile:"",
    hispanic_Latino:"",
    veteranStatus:"",
  });

  useEffect(() => {
    fetchEmployeeById(empId);
    fetchEmployeeJobData(empId);
  }, [empId]);

  async function fetchEmployeeById(empId) {
    try {
      const response = await axios.get(
        `${apiUrl}/api/PersonalInformation/GetEmployeesById/${empId}`
      );
      const responseData = response.data;

      // Check if employeeJobApplications exists and is not empty
      if (responseData.employeeJobApplications && responseData.employeeJobApplications.length > 0) {
        // Assuming you only want data from the first employee
        const firstEmployee = responseData.employeeJobApplications[0];

        const updatedFormData = { ...employeeData };
        if (firstEmployee.firstName && firstEmployee.lastName) {
          updatedFormData.employeeId = firstEmployee.employeeId;
          updatedFormData.firstName = firstEmployee.firstName;
          updatedFormData.lastName = firstEmployee.lastName;
          updatedFormData.dob = firstEmployee.dob;
          updatedFormData.disabilities = firstEmployee.disabilities;
          updatedFormData.gender = firstEmployee.gender;
          updatedFormData.maritalStatus = firstEmployee.maritalStatus;
          updatedFormData.ssn = firstEmployee.ssn;
          updatedFormData.nationality = firstEmployee.nationality;
          updatedFormData.email = firstEmployee.email;
          updatedFormData.mobileno = firstEmployee.mobileno;
          updatedFormData.telePhone = firstEmployee.telePhone;
          updatedFormData.streetAddress = firstEmployee.streetAddress;
          updatedFormData.city = firstEmployee.city;
          updatedFormData.state = firstEmployee.state;
          updatedFormData.postalCode = firstEmployee.postalCode;
          updatedFormData.country = firstEmployee.country;
          updatedFormData.workAuthorizationUS = firstEmployee.workAuthorizationUS;
          updatedFormData.sponsorshipNeeded = firstEmployee.sponsorshipNeeded;
          updatedFormData.tenYearsStrategicSuccess = firstEmployee.tenYearsStrategicSuccess;
          updatedFormData.highValueDealSuccess = firstEmployee.highValueDealSuccess;
          updatedFormData.teamLeadershipProven = firstEmployee.teamLeadershipProven;
          updatedFormData.martechAdtechExperience = firstEmployee.martechAdtechExperience;
          updatedFormData.linkedInProfile = firstEmployee.linkedInProfile;
          updatedFormData.hispanic_Latino = firstEmployee.hispanic_Latino;
          updatedFormData.veteranStatus = firstEmployee.veteranStatus;
        } else {
          console.error("Some required fields are missing in the fetched data.");
        }
        setEmployeeData(updatedFormData);
        // setJobtitle(updatedFormData);
      } else {
        console.error("No employee data found.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async function fetchEmployeeJobData(empId) {
    try {
      const response = await axios.get(
        `${apiUrl}/api/PersonalInformation/GetEmployeesById/${empId}`
      );
      const responseData = response.data;
      if (responseData.employeeJobApplications && responseData.employeeJobApplications.length > 0) {
        const firstEmployee = responseData.employeeJobApplications[0];
        const updatedFormData = { ...jobFormData };
        updatedFormData.forJobApply = firstEmployee.forJobApply;
        setJobFormData(updatedFormData);
      } else {
        console.error("No employee data found.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const [formDataError, setFormDataError] = useState({
    employeeNumberError: "",
    imageError: "",
    // firstNameError: "",
    // middleNameError: "",
    // lastNameError: "",
    DOBError: "",
    yourAgeError: "",
    disabilitiesError: "",
    genderError: "",
    maritalStatusError: "",
    SSNError: "",
    nationalityError: "",
    bankAccountNumebrError: "",
    streetAddress1Error: "",
    streetAddress2Error: "",
    // cityError: "",
    // stateError: "",
    postalCodeError: "",
    countryError: "",
    workPhoneError: "",
    EXTError: "",
    mobilenoError: "",
    homePhoneError: "",
    workEmailError: "",
    homeEmailError: "",
    institutionError: "",
    degreeError: "",
    major_specializationError: "",
    CGPAError: "",
    startDateError: "",
    endDateError: "",
  });

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFormData((prevData) => ({
        ...prevData,
        image: selectedFile,
      }));
      setFormDataError((prevState) => ({
        ...prevState,
        imageError: "",
      }));
    } else {
      setFormDataError((prevState) => ({
        ...prevState,
        imageError: "Image is required.",
      }));
    }
  };
  

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    // Check if the birthday has occurred this year
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      return age - 1;
    } else {
      return age;
    }
  };

  const handleChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or target is undefined:", e);
      return;
    }

    const { name, value } = e.target;

    if (value.trim() !== "") {
      setFormDataError((prevState) => ({
        ...prevState,
        [`${name}Error`]: "",
      }));
    } else {
      // Set the error message if the value is empty
      setFormDataError((prevState) => ({
        ...prevState,
        [`${name}Error`]: `${name} is required.`,
      }));
    }

    if (name === "DOB" || name === "startDate") {
      const selectedDate = new Date(value);
      const currentDate = new Date();
      if (selectedDate >= currentDate) {
        setFormDataError((prevState) => ({
          ...prevState,
          [`${name}Error`]: "Please select a date before today.",
        }));
        return;
      }
      const age = calculateAge(value);
      if (age !== null) {
        setFormData((prevData) => ({
          ...prevData,
          yourAge: age,
        }));
      }
    }

    // Clear DOB and startDate errors if a valid date is selected
    setFormDataError({ ...formDataError, [`${name}Error`]: "" });

    if (
      (name === "employeeNumber" || name === "yourAge" || name === "EXT") &&
      !/^\d*\.?\d*$/.test(value)
    ) {
      // If the entered value is not a positive integer or decimal number, don't update state
      return;
    }

    // Update state for other fields
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const fetchLastEmployeeNumber = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/PersonalInformation/GetLastEmployeeNumber`);
        if (response.status === 200) {
          const nextEmployeeNumber = response.data; // Assuming this is the new employee number like 1001
          setFormData((prevData) => ({
            ...prevData,
            employeeNumber: nextEmployeeNumber,
          }));
        }
      } catch (error) {
        console.error("Error fetching employee number:", error);
        setFormDataError((prevState) => ({
          ...prevState,
          employeeNumberError: "Error fetching employee number",
        }));
      }
    };

    fetchLastEmployeeNumber();
  }, []);

  const handleemployeeDataChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or target is undefined:", e);
      return;
    }
    const { name, value } = e.target;
    // Clear DOB and startDate errors if a valid date is selected
    setFormDataError({ ...formDataError, [`${name}Error`]: "" });
    // Update state for other fields
    setEmployeeData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  function validatePersonalValidation(formData, setFormDataError) {
    let hasError = false;

    // Check each field for emptiness or invalidity
    Object.keys(formData).forEach((fieldName) => {
      if (
        (typeof formData[fieldName] === "string" &&
          (!formData[fieldName] || !formData[fieldName].trim())) || // For string fields
        (typeof formData[fieldName] === "number" &&
          (isNaN(formData[fieldName]) || formData[fieldName] === null)) // For numeric fields
      ) {
        const fieldNameWithSpaces = fieldName
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase());
        setFormDataError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: `${fieldNameWithSpaces} is required.`,
        }));
        hasError = true;
      }
    });

    // Check if any error occurred
    if (hasError) {
      return false;
    }

    // Check if all fields are filled out
    const isAllFieldsFilled = Object.values(formData).every(
      (value) => value !== null && value !== undefined && value !== ""
    );

    if (!isAllFieldsFilled) {
      // If any field is empty, display an error message
      setFormDataError((prevState) => ({
        ...prevState,
        genericError: "Please fill out all fields.",
      }));
      return false;
    }

    return true;
  }

  // ---------------------

  const [jobFormData, setJobFormData] = useState({
    // Job component fields
    // personalId:0,
    forJobApply:"",
    hiringDate: "",
    team: "",
    manager: "",
    probationStartDate: "",
    probationEndDate: "",
  });

  const [jobFormDataError, setJobFormDataError] = useState({
    // Job component error fields
    jobTitleError: "",
    hiringDateError: "",
    teamError: "",
    managerError: "",
    probationStartDateError: "",
    probationEndDateError: "",
  });

  const handleJobChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or target is undefined:", e);
      return;
    }

    const { name, value } = e.target;

    if (name === "hiringDate" || name === "probationStartDate") {
      const selectedDate = new Date(value);
      const currentDate = new Date();
      if (selectedDate >= currentDate) {
        setJobFormDataError((prevState) => ({
          ...prevState,
          [`${name}Error`]: "Please select a date before today.",
        }));
        return;
      }
    }

    setJobFormDataError({ ...jobFormDataError, [`${name}Error`]: "" });
    setJobFormData({ ...jobFormData, [name]: value });
  };

  function validateJobValidation(jobFormData, setJobFormDataError) {
    let hasError = false;

    Object.keys(jobFormData).forEach((fieldName) => {
      if (
        (typeof jobFormData[fieldName] === "string" &&
          (!jobFormData[fieldName] || !jobFormData[fieldName].trim())) || // For string fields
        (typeof jobFormData[fieldName] === "number" &&
          (isNaN(jobFormData[fieldName]) || jobFormData[fieldName] === null)) // For numeric fields
      ) {
        const fieldNameWithSpaces = fieldName
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase());
        setJobFormDataError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: `${fieldNameWithSpaces} is required.`,
        }));
        hasError = true;
      }
    });

    if (hasError) {
      return false;
    }

    const allFieldsFilled = Object.values(jobFormData).every((value) => {
      return (
        (typeof value === "string" && value.trim() !== "") ||
        (typeof value === "number" && !isNaN(value))
      );
    });

    if (!allFieldsFilled) {
      setJobFormDataError((prevState) => ({
        ...prevState,
        commonError: "Please fill in all fields before saving.",
      }));
      return false;
    }

    return true;
  }

  // --------------------

  const [emergencyFormData, setEmergencyFormData] = useState({
    Name: "",
    relationShip: "",
    workPhone: "",
    ext: "",
    mobileNo: "",
    email: "",
    streetAddress1: "",
    streetAddress2: "",
    city: "",
    state: "",
    postalCode: "",
    Country: "",
  });

  const [emergencyFormDataError, setEmergencyFormDataError] = useState({
    NameError: "",
    relationShipError: "",
    workPhoneError: "",
    extError: "",
    mobileNoError: "",
    emailError: "",
    streetAddress1Error: "",
    streetAddress2Error: "",
    cityError: "",
    stateError: "",
    postalCodeError: "",
    CountryError: "",
  });

  const handleEmergencyChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or target is undefined:", e);
      return;
    }

    const { name, value } = e.target;
    if ((name === "ext" || name === "postalCode") && !/^\d*\.?\d*$/.test(value)) {
      // If the entered value is not a positive integer or decimal number, don't update state
      return;
    }

    setEmergencyFormDataError({ ...emergencyFormDataError, [`${name}Error`]: "" });
    setEmergencyFormData({ ...emergencyFormData, [name]: value });
  };

  function validateEmergencyValidation(emergencyFormData, setEmergencyFormDataError) {
    let hasError = false;

    Object.keys(emergencyFormData).forEach((fieldName) => {
      if (
        (typeof emergencyFormData[fieldName] === "string" &&
          (!emergencyFormData[fieldName] || !emergencyFormData[fieldName].trim())) || // For string fields
        (typeof emergencyFormData[fieldName] === "number" &&
          (isNaN(emergencyFormData[fieldName]) || emergencyFormData[fieldName] === null)) // For numeric fields
      ) {
        const fieldNameWithSpaces = fieldName
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase());
        setEmergencyFormDataError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: `${fieldNameWithSpaces} is required.`,
        }));
        hasError = true;
      }
    });

    if (hasError) {
      return false;
    }

    const allFieldsFilled = Object.values(emergencyFormData).every((value) => {
      return (
        (typeof value === "string" && value.trim() !== "") ||
        (typeof value === "number" && !isNaN(value))
      );
    });

    if (!allFieldsFilled) {
      setEmergencyFormDataError((prevState) => ({
        ...prevState,
        commonError: "Please fill in all fields before saving.",
      }));
      return false;
    }

    return true;
  }

  // ----------------------------------

  const [benefitDependent, setBenefitDependent] = useState({
    Name: "",
    relationShip: "",
    Gender: "",
    SSN: "",
    DOB: "",
  });

  const [benefitDependentError, setBenefitDependentError] = useState({
    NameError: "",
    relationShipError: "",
    GenderError: "",
    SSNError: "",
    DOBError: "",
  });

  const handleBenefitDependentChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or target is undefined:", e);
      return;
    }

    const { name, value } = e.target;

    if (name === "DOB") {
      const selectedDate = new Date(value);
      const currentDate = new Date();
      if (selectedDate >= currentDate) {
        setBenefitDependentError((prevState) => ({
          ...prevState,
          [`${name}Error`]: "Please select a date before today.",
        }));
        return;
      }
    }

    setBenefitDependentError({ ...benefitDependentError, [`${name}Error`]: "" });
    setBenefitDependent({ ...benefitDependent, [name]: value });
  };

  function validateBenefitDependentValidation(benefitDependent, setBenefitDependentError) {
    let hasError = false;

    Object.keys(benefitDependent).forEach((fieldName) => {
      if (
        (typeof benefitDependent[fieldName] === "string" &&
          (!benefitDependent[fieldName] || !benefitDependent[fieldName].trim())) || // For string fields
        (typeof benefitDependent[fieldName] === "number" &&
          (isNaN(benefitDependent[fieldName]) || benefitDependent[fieldName] === null)) // For numeric fields
      ) {
        const fieldNameWithSpaces = fieldName
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase());
        setBenefitDependentError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: `${fieldNameWithSpaces} is required.`,
        }));
        hasError = true;
      }
    });

    if (hasError) {
      return false;
    }

    const allFieldsFilled = Object.values(benefitDependent).every((value) => {
      return (
        (typeof value === "string" && value.trim() !== "") ||
        (typeof value === "number" && !isNaN(value))
      );
    });

    if (!allFieldsFilled) {
      setBenefitDependentError((prevState) => ({
        ...prevState,
        commonError: "Please fill in all fields before saving.",
      }));
      return false;
    }

    return true;
  }

  // ----------------------------------

  debugger;
  const [uploadD, setUploadD] = useState({
    docfile: null,
  });

  const [uploadDError, setUploadDError] = useState({
    docfileError: "",
  });

  const handleDocumentChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or target is undefined:", e);
      return;
    }

    const { name, value } = e.target;

    if (name === "uploadedDate") {
      const selectedDate = new Date(value);
      const currentDate = new Date();
      if (selectedDate >= currentDate) {
        setUploadDError((prevState) => ({
          ...prevState,
          [`${name}Error`]: "Please select a date before today.",
        }));
        return;
      }
    }

    setUploadDError({ ...uploadDError, [`${name}Error`]: "" });
    setUploadD({ ...uploadD, [name]: value });
  };

  function validateDocumentValidation(uploadD, setUploadDError) {
    let hasError = false;

    Object.keys(uploadD).forEach((fieldName) => {
      if (
        (typeof uploadD[fieldName] === "string" &&
          (!uploadD[fieldName] || !uploadD[fieldName].trim())) || // For string fields
        (typeof uploadD[fieldName] === "number" &&
          (isNaN(uploadD[fieldName]) || uploadD[fieldName] === null)) // For numeric fields
      ) {
        const fieldNameWithSpaces = fieldName
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase());
        setUploadDError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: `${fieldNameWithSpaces} is required.`,
        }));
        hasError = true;
      }
    });

    if (hasError) {
      return false;
    }

    const isAllFieldsFilled = Object.values(uploadD).every(
      (value) => value !== null && value !== undefined && value !== ""
    );

    if (!isAllFieldsFilled) {
      setUploadDError((prevState) => ({
        ...prevState,
        commonError: "Please fill in all fields before saving.",
      }));
      return false;
    }

    return true;
  }

  // const handleUploadDocChange = (e) => {
  //   debugger;
  //   setUploadD({
  //     docfile: e.target.files[0],
  //   });
  // };

  return {
    uploadD,
    uploadDError,
    setUploadD,
    setUploadDError,
    setUploadD,
    // handleUploadDocChange,
    formData,
    setFormData,
    isLoading,
    formDataError,
    setFormDataError,
    jobFormData,
    setJobFormData,
    jobFormDataError,
    setJobFormDataError,
    emergencyFormData,
    setEmergencyFormData,
    emergencyFormDataError,
    setEmergencyFormDataError,
    benefitDependent,
    setBenefitDependent,
    benefitDependentError,
    setBenefitDependentError,
    onNext,
    validatePersonalValidation,
    validateJobValidation,
    validateEmergencyValidation,
    validateBenefitDependentValidation,
    validateDocumentValidation,
    handleChange,
    handleImageChange,
    handleJobChange,
    handleEmergencyChange,
    handleBenefitDependentChange,
    handleDocumentChange,
    fetchEmployeeById,
    employeeData,
    handleemployeeDataChange,
    fetchEmployeeJobData,
  };
}

export default usePersonalInformationService;
