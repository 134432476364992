import React, { useState, useEffect } from "react";
import { Chart } from "react-google-charts";
import SoftButton from "components/SoftButton";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import "./attendance.scss";
import routes from "routes";
import { Button } from "antd";

function Attendance() {
  const navigate = useNavigate();
  const viewAll = () => {
    navigate("/reportsAttendance");
  };

  const [attendanceSummary, setAttendanceSummary] = useState(null);
  const [loading, setLoading] = useState(true);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("All Departments");
  const apiUrl = process.env.REACT_APP_API_URL;

  // Check if the report route is available
  const isReportRouteAvailable = routes.some((route) => route.key === "report");

  useEffect(() => {
    const fetchAttendanceSummary = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/api/AttendanceReport/getAllCheckIn`);
        const checkIns = response.data;
        setAttendanceSummary(checkIns);
      } catch (error) {
        console.log("Failed to fetch attendance data.");
      } finally {
        setLoading(false);
      }
    };
    fetchAttendanceSummary();
  }, [apiUrl]);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/AttendanceReport/Get-All-Departments`);
        const departmentNames = response.data.map((dept) => dept.department);
        setDepartments(departmentNames);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };
    fetchDepartments();
  }, [apiUrl]);

  const handleDepartmentChange = (event) => {
    setSelectedDepartment(event.target.value);
  };

  const filteredAttendance = attendanceSummary
    ? selectedDepartment === "All Departments"
      ? attendanceSummary
      : attendanceSummary.filter((checkIn) => checkIn.department === selectedDepartment)
    : [];

  const chartData = [
    ["Status", "Count"],
    ["Present", filteredAttendance.filter((checkIn) => checkIn.status === "Present").length],
    [
      "Late Arrival",
      filteredAttendance.filter((checkIn) => checkIn.status === "Late Arrival").length,
    ],
    ["Absent", filteredAttendance.filter((checkIn) => checkIn.status === "Absent").length],
  ];

  return (
    <div className="card" style={{ height: "100%", width: "100%" }}>
      <div className="card-header" style={{ position: "relative" }}>
        <h5>Attendance</h5>
      </div>
      <div className="card-body">
        <div className="row">
          <div style={{ width: "900px", height: "300px", textAlign: "center" }}>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Oval
                  height={40}
                  width={40}
                  color="#4fa94d"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#4fa94d"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
            ) : attendanceSummary ? (
              <>
                <div className="col-md-12 d-flex justify-content-end">
                  <div className="col-md-3 mb-3">
                    <select
                      className="form-select custom-select"
                      value={selectedDepartment}
                      onChange={handleDepartmentChange}
                    >
                      <option value="All Departments">All Departments</option>
                      {departments.map((department) => (
                        <option key={department} value={department}>
                          {department}
                        </option>
                      ))}
                    </select>
                  </div>
                  &nbsp;&nbsp;&nbsp;
                  {isReportRouteAvailable && (
                    <Button type="primary" onClick={viewAll} className="custom-button">
                      <i className="fa fa-paper-plane" aria-hidden="true"></i>
                      &nbsp;&nbsp;&nbsp;&nbsp;View All
                    </Button>
                  )}
                </div>

                {filteredAttendance.length > 0 ? (
                  <Chart
                    chartType="PieChart"
                    data={chartData}
                    options={{
                      pieHole: 0.4,
                      pieSliceText: "value",
                      tooltip: { text: "value" },
                      colors: ["green", "#faae20", "#FF5733"],
                    }}
                    style={{ marginTop: "-10px" }}
                    width={"100%"}
                    height={"90%"}
                  />
                ) : (
                  <h6 style={{ fontSize: "medium", textAlign: "center" }}>
                    No data available for the selected department.
                  </h6>
                )}
              </>
            ) : (
              <span style={{ fontSize: "medium", textAlign: "center" }}>
                No attendance data available yet!
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Attendance;
