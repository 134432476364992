import React, { useEffect, useState } from "react";
import "./DocumentsStorage.scss"; // Ensure this CSS file contains the necessary styles
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import NDA from "../../assets/DownloadPDFfile/NDA.docx";
import NCA from "../../assets/DownloadPDFfile/NCA.docx";
import EmployeeAgreement from "../../assets/DownloadPDFfile/Employment Agreement.docx";
import I9 from "../../assets/DownloadPDFfile/i-9.pdf";
import W4 from "../../assets/DownloadPDFfile/fw4.pdf";
import HRPolicy from "../../assets/DownloadPDFfile/HR Policy.pdf";
import OfferLetter from "../../assets/DownloadPDFfile/Offer Letter.pdf";
import { Card, Form, Input, Select, Upload, Button, Tooltip, Row, Col } from "antd";
import axios from "axios";
import { UploadOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const { Option } = Select;

export default function DocumentsStore() {
  const [employeeNumbers, setEmployeeNumbers] = useState([]);
  const [selectedEmployeeNumber, setSelectedEmployeeNumber] = useState(null);
  const [form] = Form.useForm(); // Ant Design form instance
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchEmployeeNumbers = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/PersonalInformation/Get-All-EmployeeNumbers`
        );
        setEmployeeNumbers(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        console.error("Error fetching employee numbers:", error);
        setEmployeeNumbers([]);
      }
    };
    fetchEmployeeNumbers();
  }, [apiUrl]);

  const handleEmployeeNumberChange = (value) => {
    setSelectedEmployeeNumber(value);
    fetchEmployeeData(value);
  };

  const fetchEmployeeData = async (value) => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/PersonalInformation/GetByEmployeeNumber/${value}`
      );

      // Check if the response contains data
      if (response.data) {
        const employee = response.data;
        form.setFieldsValue({
          employeeId: employee.employeeId,
          name: `${employee.firstName} ${employee.lastName}`,
          email: employee.email,
          jobTitle: employee.jobTitle,
          department: employee.department,
        });
      } else {
        form.resetFields();
      }
    } catch (error) {
      console.error("Error fetching employee details:", error);
      form.resetFields();
    }
  };

  const [files, setFiles] = useState({
    i_nine: null,
    w_four: null,
    hrpolicy: null,
  });

  const handleFileChange =
    (fileType) =>
    ({ fileList }) => {
      const file = fileList[0]?.originFileObj;
      if (file) {
        const maxSize = fileType === "hrpolicy" ? 40 * 1024 * 1024 : 2 * 1024 * 1024; // 100 MB for hrpolicy, 2 MB for others
        if (file.size > maxSize) {
          toast.error(`File size exceeds the limit of ${maxSize / (1024 * 1024)} MB.`);
          return;
        }
        setFiles((prev) => ({
          ...prev,
          [fileType]: file,
        }));
      } else {
        setFiles((prev) => ({
          ...prev,
          [fileType]: null,
        }));
      }
    };

  const handleFormSubmit = async (values) => {
    if (!selectedEmployeeNumber) {
      console.log("No employee number selected");
      return;
    }

    const formData = new FormData();
    formData.append("employeeId", values.employeeId);
    formData.append("name", values.name);
    formData.append("email", values.email);
    formData.append("department", values.department);
    formData.append("jobtitle", values.jobTitle);
    formData.append("i_nine", files.i_nine);
    formData.append("w_four", files.w_four);
    formData.append("hrpolicy", files.hrpolicy);
    formData.append("status", "Document sent but not received");

    try {
      setLoading(true);
      const response = await axios.post(
        `${apiUrl}/api/EmployeeDocuments/AddEmployeeDocument`, // Your API endpoint
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success("Documents sent successfully!");
      form.resetFields();
      setFiles({ i_nine: null, w_four: null, hrpolicy: null });
      setSelectedEmployeeNumber(null);
    } catch (error) {
      console.error("Error submitting form:", error);

      // Check if the error response exists
      if (error.response) {
        // Display the specific error message returned from the backend
        const errorMessage = error.response.data;
        toast.error(errorMessage);
      } else {
        // Handle other types of errors
        toast.error("An unexpected error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const isSubmitDisabled = !selectedEmployeeNumber;

  const handlePdfPreview = async (fileUrl) => {
    try {
      const extension = fileUrl.split(".").pop().toLowerCase();
      if (extension === "pdf") {
        window.open(fileUrl, "_blank");
      } else {
        window.open(fileUrl, "_blank");
      }
    } catch (error) {
      console.error("Error handling file:", error);
    }
  };

  const items = [
    { id: 1, title: "NDA", pdfUrl: NDA, color: "#FF6F61" },
    { id: 2, title: "NCA", pdfUrl: NCA, color: "#6B5B95" },
    { id: 3, title: "Employee Agreement", pdfUrl: EmployeeAgreement, color: "#88B04B" },
    { id: 4, title: "I-9", pdfUrl: I9, color: "rgb(104 11 13)" },
    { id: 5, title: "W-4", pdfUrl: W4, color: "rgb(20 128 32)" },
    { id: 6, title: "Offer Letter", pdfUrl: OfferLetter, color: "rgb(221 207 28)" },
    { id: 6, title: "HR Policy", pdfUrl: HRPolicy, color: "rgb(68 131 192)" },
  ];

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <ToastContainer />
          <Card title="Company Documents" bordered={false}>
            <div className="document-storage-solutions">
              <div className="document-list">
                {items.map((item) => (
                  <div
                    className="document-item"
                    key={item.id}
                    style={{ backgroundColor: item.color }}
                    onClick={() => handlePdfPreview(item.pdfUrl)}
                  >
                    <div className="document-content">
                      <h5>{item.title}</h5>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Card>

          {/* New Card with Form Fields */}
          <Card title="Employee Documents" bordered={false} style={{ marginTop: 20 }}>
            <Form
              form={form}
              layout="vertical"
              initialValues={{ employeeNumber: "" }}
              onFinish={handleFormSubmit}
            >
              <div className="row">
                <div className="col-md-6">
                  <Form.Item
                    label="Employee Number"
                    name="employeeNumber"
                    rules={[{ required: true, message: "Please select an employee number!" }]}
                  >
                    <Select
                      showSearch
                      allowClear
                      placeholder="Select a person"
                      onChange={handleEmployeeNumberChange}
                      value={selectedEmployeeNumber || undefined}
                    >
                      {employeeNumbers.map((number) => (
                        <Option key={number} value={number}>
                          {number}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-md-5">
                  <Form.Item
                    label="Employee ID"
                    name="employeeId"
                    rules={[{ required: true, message: "Please enter Employee ID!" }]}
                  >
                    <Input placeholder="Enter employee ID" disabled />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Form.Item
                    label="Employee Name"
                    name="name"
                    rules={[{ required: true, message: "Please enter Employee Name!" }]}
                  >
                    <Input placeholder="Enter Name" disabled />
                  </Form.Item>
                </div>
                <div className="col-md-5">
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, type: "email", message: "Please enter your email!" }]}
                  >
                    <Input placeholder="Enter email" disabled />
                  </Form.Item>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Form.Item
                    label="Job Title"
                    name="jobTitle"
                    rules={[{ required: true, message: "Enter job title!" }]}
                  >
                    <Input placeholder="Enter job title" disabled />
                  </Form.Item>
                </div>
                <div className="col-md-5">
                  <Form.Item
                    label="Department"
                    name="department"
                    rules={[{ required: true, message: "Please enter department!" }]}
                  >
                    <Input placeholder="Enter department" disabled />
                  </Form.Item>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <Form.Item
                    label="Upload I-9 File"
                    name="i_nine"
                    rules={[
                      {
                        required: true,
                        validator: async (_, value) => {
                          console.log("File value:", value); // Log the value for debugging

                          if (
                            !value ||
                            !Array.isArray(value.fileList) ||
                            value.fileList.length === 0
                          ) {
                            return Promise.reject(new Error("Please upload the I-9 file!"));
                          }

                          const file = value.fileList[0].originFileObj; // Access the file correctly
                          const maxSize = 2 * 1024 * 1024; // 2 MB

                          if (file.size > maxSize) {
                            return Promise.reject(
                              new Error(
                                `Please select a file with size less than or equal to ${
                                  maxSize / (1024 * 1024)
                                } MB.`
                              )
                            );
                          }

                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Upload
                      beforeUpload={() => false}
                      onChange={handleFileChange("i_nine")}
                      maxCount={1}
                    >
                      <Button icon={<UploadOutlined />}>Upload I-9 File</Button>
                    </Upload>
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    label="Upload W-4 File"
                    name="w_four"
                    rules={[
                      {
                        required: true,
                        validator: async (_, value) => {
                          console.log("File value:", value); // Log the value for debugging

                          if (
                            !value ||
                            !Array.isArray(value.fileList) ||
                            value.fileList.length === 0
                          ) {
                            return Promise.reject(new Error("Please upload the W-4 file!"));
                          }

                          const file = value.fileList[0].originFileObj; // Access the file correctly
                          const maxSize = 2 * 1024 * 1024; // 2 MB

                          if (file.size > maxSize) {
                            return Promise.reject(
                              new Error(
                                `Please select a file with size less than or equal to ${
                                  maxSize / (1024 * 1024)
                                } MB.`
                              )
                            );
                          }

                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Upload
                      beforeUpload={() => false}
                      onChange={handleFileChange("w_four")}
                      maxCount={1}
                    >
                      <Button icon={<UploadOutlined />}>Upload W-4 File</Button>
                    </Upload>
                  </Form.Item>
                </div>
                <div className="col-md-4">
                  <Form.Item
                    label="Upload HR Policy File"
                    name="hrpolicy"
                    rules={[{ required: true, message: "Please Upload HR Policy file!" }]}
                  >
                    <Upload
                      beforeUpload={() => false}
                      onChange={handleFileChange("hrpolicy")}
                      maxCount={1}
                    >
                      <Button icon={<UploadOutlined />}>Upload HR Policy File</Button>
                    </Upload>
                  </Form.Item>
                </div>
              </div>
              <Form.Item>
                <Tooltip title={isSubmitDisabled ? "Select an employee number" : ""}>
                  <span>
                    <Button type="primary" htmlType="submit" disabled={isSubmitDisabled}>
                    {loading ? "Submitting..." : "Submit"}
                    {loading && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    </Button>
                  </span>
                </Tooltip>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </div>
    </DashboardLayout>
  );
}
