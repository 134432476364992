import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Table } from "antd";
import "./Hiring.scss";
import { DesktopMacOutlined, LocationCityRounded } from "@mui/icons-material";
import SoftButton from "components/SoftButton";
import { Link } from "react-router-dom";
import hiringService from "../APIServices/HiringProcessService";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Select, Tooltip } from "antd";
import moment from "moment";
import Footer from "layouts/authentication/components/Footer";

function Hiring() {
  const {
    formData,
    formDataError,
    handleChange,
    handleJobDescriptionChange,
    dataSource,
    loading,
    handleLocationSelection,
    handleShow,
    handleAddJob,
    show,
    isLoading,
    selectedLocation,
    userName,
    employeeDetails,
    setFormData,
    childManagers
  } = hiringService();

  const loginType = localStorage.getItem("loginType");

  const columns = [
    {
      title: "Post_Name",
      dataIndex: "PositionName",
      key: "PositionName",
      render: (text) => (
        <Tooltip title={text}>
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: 100,
            }}
          >
            {text.length > 10 ? `${text.slice(0, 10)}...` : text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Department",
      dataIndex: "Department",
      key: "Department",
      render: (text) => (
        <Tooltip title={text}>
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: 100,
            }}
          >
            {text.length > 5 ? `${text.slice(0, 5)}...` : text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Created_On",
      dataIndex: "CreatedOn",
      key: "CreatedOn",
      render: (text) => (
        <Tooltip title={moment(text).format("MMMM D, YYYY")}>
          <span
            style={{
              maxWidth: 100,
              display: "inline-block",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {moment(text).format("MMMM D").slice(0, 8)}...
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      render: (text) => (
        <Tooltip title={text}>
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: 100,
            }}
          >
            {text.length > 4 ? `${text.slice(0, 4)}...` : text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Skills",
      dataIndex: "Skills",
      key: "Skills",
      render: (text) => (
        <Tooltip title={text}>
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: 100,
            }}
          >
            {text.length > 17 ? `${text.slice(0, 17)}...` : text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "View Candidate",
      dataIndex: "ViewCandidate",
      key: "ViewCandidate",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
    },
  ];

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <ToastContainer />
        <div className="body flex-grow-1 px-3">
          {show && (
            <div className="card">
              <div className="card-header" style={{ position: "relative" }}>
                <h5>Hiring</h5>
                <Link to="/viewcareer" type="link" className="underline-link">
                  <div style={{ textDecoration: "underline" }}>View Career Page</div>
                </Link>
              </div>
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-md-12 mb-3">
                    <SoftButton variant="outlined" color="info" size="small" onClick={handleShow}>
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;Add Job Opening
                    </SoftButton>
                  </div>
                  <Table
                    className="table-responsive"
                    dataSource={dataSource}
                    columns={columns}
                    loading={loading}
                    pagination={{ pageSize: 10 }}
                  />
                </div>
              </div>
            </div>
          )}
          {!show && (
            <div className="card">
              <div className="card-header" style={{ position: "relative" }}>
                <h5>Job Information</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-3 mb-3">
                    <div className="form-group">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Posting Title *
                      </label>
                      <input
                        type="text"
                        placeholder="Enter posting title here"
                        name="postingJobTitle"
                        value={formData.postingJobTitle}
                        onChange={handleChange}
                        className={`form-control ${
                          formDataError.postingJobTitleError ? "is-invalid" : ""
                        }`}
                      />
                      {formDataError.postingJobTitleError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.postingJobTitleError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Budget Approved *
                      </label>
                      <select
                        aria-label="Default select example"
                        name="budgetApproved"
                        value={formData.budgetApproved}
                        onChange={handleChange}
                        className={`form-select ${
                          formDataError.budgetApprovedError ? "is-invalid" : ""
                        }`}
                      >
                        <option value=""> Please Select </option>
                        <option value="Yes"> Yes </option>
                        <option value="No"> No </option>
                      </select>
                      {formDataError.budgetApprovedError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.budgetApprovedError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3 mb-3">
                    <div className="form-group">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Recruiter *
                      </label>
                      <input
                        type="text"
                        name="postingJobTitle"
                        value={formData.recruiter}
                        disabled
                        onChange={handleChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Job Status *
                      </label>
                      <select
                        aria-label="Default select example"
                        name="jobStatus"
                        value={formData.jobStatus}
                        onChange={handleChange}
                        className={`form-select ${
                          formDataError.jobStatusError ? "is-invalid" : ""
                        }`}
                      >
                        <option value=""> Please Select </option>
                        <option value="Draft"> Draft </option>
                        <option value="Open"> Open </option>
                        <option value="On Hold"> On Hold </option>
                        <option value="Filled"> Filled </option>
                        <option value="Canceled"> Canceled </option>
                      </select>
                      {formDataError.jobStatusError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.jobStatusError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <div className="form-group">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Hiring Manager *
                      </label>
                      <select
                        aria-label="Default select example"
                        value={formData.hiringLead}
                        name="hiringLead"
                        onChange={handleChange}
                        className={`form-select ${
                          formDataError.hiringLeadError ? "is-invalid" : ""
                        }`}
                      >
                        <option value="">Select Hiring Manager</option>
                        {loginType === "HR" && (
                          <>
                            <option value={userName}>{userName} HR Manager</option>
                            {childManagers.map((manager, index) => (
                              <option key={index} value={manager.name}>
                                {manager.name} Associate Manager
                              </option>
                            ))}
                          </>
                        )}
                        {loginType !== "HR" && (
                          <option value={userName}>{userName} Associate Manager</option>
                        )}
                        {employeeDetails.map((employee, index) => (
                          <>
                            <option key={index} value={employee.name}>
                              {employee.name ? employee.name : "No Name Available"}
                            </option>
                          </>
                        ))}
                      </select>
                      {formDataError.hiringLeadError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.hiringLeadError}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Department *
                      </label>
                      <select
                        aria-label="Default select example"
                        name="department"
                        value={formData.department}
                        onChange={handleChange}
                        className={`form-select ${
                          formDataError.departmentError ? "is-invalid" : ""
                        }`}
                      >
                        <option value=""> Please Select </option>
                        <option value="Customer Success"> Customer Success </option>
                        <option value="Finance"> Finance </option>
                        <option value="Human Resources"> Human Resources </option>
                        <option value="IT"> IT </option>
                        <option value="Marketing"> Marketing </option>
                        <option value="Operations"> Operations </option>
                        <option value="Product"> Product </option>
                        <option value="Sales"> Sales </option>
                      </select>
                      {formDataError.departmentError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.departmentError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Employment Type *
                      </label>
                      <select
                        aria-label="Default select example"
                        name="employementType"
                        value={formData.employementType}
                        onChange={handleChange}
                        className={`form-select ${
                          formDataError.employementTypeError ? "is-invalid" : ""
                        }`}
                      >
                        <option value=""> Please Select </option>
                        <option value="Contractor"> Contractor </option>
                        <option value="Full-Time"> Full-Time </option>
                        <option value="Intern"> Intern </option>
                        <option value="Part-Time"> Part-Time </option>
                      </select>
                      {formDataError.employementTypeError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.employementTypeError}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Maximum Experience *
                      </label>
                      <select
                        aria-label="Default select example"
                        name="maximumExperience"
                        value={formData.maximumExperience}
                        onChange={handleChange}
                        className={`form-select ${
                          formDataError.maximumExperienceError ? "is-invalid" : ""
                        }`}
                      >
                        <option value=""> Please Select </option>
                        <option value="Entry-Level (1 - 2 years)">
                          {" "}
                          Entry-Level (1 - 2 years){" "}
                        </option>
                        <option value="Mid-Level (2 - 3 years)"> Mid-Level (2 - 3 years)</option>
                        <option value="Experienced (3 - 5 years)">
                          {" "}
                          Experienced (3 - 5 years)
                        </option>
                        <option value="Manager/Supervisor (5 - 6 years)">
                          {" "}
                          Manager/Supervisor (5 - 6 years)
                        </option>
                        <option value="Senior Manager/Supervisor (6 - 7 years)">
                          {" "}
                          Senior Manager/Supervisor (6 - 7 years)
                        </option>
                        <option value="Executive (7 - 8 years)"> Executive (7 - 8 years)</option>
                        <option value="Senior Executive (8 - 10 years)">
                          {" "}
                          Senior Executive (8 - 10 years)
                        </option>
                      </select>
                      {formDataError.maximumExperienceError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.maximumExperienceError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <div className="form-group">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Compensation *
                      </label>
                      <input
                        type="text"
                        placeholder="Enter compensation here"
                        name="compensation"
                        value={formData.compensation}
                        onChange={handleChange}
                        disabled={formData.budgetApproved === "No"}
                        className={`form-control ${
                          formDataError.compensationError ? "is-invalid" : ""
                        }`}
                      />
                      {formDataError.compensationError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.compensationError}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-9 mb-3">
                    <div className="form-group">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Skills *
                      </label>
                      <Select
                        mode="tags"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Please add your skills"
                        value={
                          Array.isArray(formData.skills)
                            ? formData.skills
                            : formData.skills
                            ? formData.skills.split(",").map((skill) => skill.trim())
                            : []
                        }
                        onChange={(value) => setFormData({ ...formData, skills: value })}
                        className={formDataError.skillsError ? "is-invalid" : ""}
                      />

                      {formDataError.skillsError && (
                        <div className="invalid-feedback invalid-message">
                          {formDataError.skillsError}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-9 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInput"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Job Description *
                      </label>
                      <ReactQuill
                        value={formData.jobDescription}
                        onChange={handleJobDescriptionChange}
                        name="jobDescription"
                        modules={{
                          toolbar: [
                            [{ header: "1" }, { header: "2" }, { font: [] }],
                            [{ size: [] }],
                            ["bold", "italic", "underline", "strike", "blockquote"],
                            [
                              { list: "ordered" },
                              { list: "bullet" },
                              { indent: "-1" },
                              { indent: "+1" },
                            ],
                            ["link", "image", "video"],
                            ["clean"],
                          ],
                        }}
                        formats={[
                          "header",
                          "font",
                          "size",
                          "bold",
                          "italic",
                          "underline",
                          "strike",
                          "blockquote",
                          "list",
                          "bullet",
                          "indent",
                          "link",
                          "image",
                          "video",
                        ]}
                        placeholder="Write something..."
                      />
                      {formDataError.jobDescriptionError && ( // Display error if there's an error
                        <div style={{ color: "red" }}>{formDataError.jobDescriptionError}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                        style={{ fontSize: "medium" }}
                      >
                        Job Location *
                      </label>
                      <br></br>
                      <button
                        type="button"
                        onClick={() => handleLocationSelection("In Office")}
                        // className={`btn ${
                        //   selectedLocation === "In Office" ? "btn-success" : "btn-outline-success"
                        // }`}
                        className={`btn ${
                          selectedLocation === "In Office" ? "btn-success" : "btn-outline-success"
                        } ${
                          formDataError.jobLocationTypeError || formDataError.jobInternalCodeError
                            ? "btn-danger"
                            : ""
                        } ${
                          formDataError.jobLocationTypeError || formDataError.jobInternalCodeError
                            ? "text-dark"
                            : ""
                        } ${
                          formDataError.jobLocationTypeError || formDataError.jobInternalCodeError
                            ? "error-hover"
                            : "normal-hover"
                        }`}
                        disabled={selectedLocation === "In Office"}
                      >
                        <LocationCityRounded /> In Office
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <button
                        type="button"
                        onClick={() => handleLocationSelection("Hybrid")}
                        // className={`btn ${
                        //   selectedLocation === "Hybrid" ? "btn-success" : "btn-outline-success"
                        // }`}
                        className={`btn ${
                          selectedLocation === "Hybrid" ? "btn-success" : "btn-outline-success"
                        } ${
                          formDataError.jobLocationTypeError || formDataError.jobInternalCodeError
                            ? "btn-danger"
                            : ""
                        } ${
                          formDataError.jobLocationTypeError || formDataError.jobInternalCodeError
                            ? "text-dark"
                            : ""
                        } ${
                          formDataError.jobLocationTypeError || formDataError.jobInternalCodeError
                            ? "error-hover"
                            : "normal-hover"
                        }`}
                        disabled={selectedLocation === "Hybrid"}
                      >
                        <LocationCityRounded /> Hybrid
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <button
                        type="button"
                        onClick={() => handleLocationSelection("Remote")}
                        // className={`btn ${
                        //   selectedLocation === "Remote" ? "btn-success" : "btn-outline-success"
                        // }`}
                        className={`btn ${
                          selectedLocation === "Remote" ? "btn-success" : "btn-outline-success"
                        } ${
                          formDataError.jobLocationTypeError || formDataError.jobInternalCodeError
                            ? "btn-danger"
                            : ""
                        } ${
                          formDataError.jobLocationTypeError || formDataError.jobInternalCodeError
                            ? "text-dark"
                            : ""
                        } ${
                          formDataError.jobLocationTypeError || formDataError.jobInternalCodeError
                            ? "error-hover"
                            : "normal-hover"
                        }`}
                        disabled={selectedLocation === "Remote"}
                      >
                        <DesktopMacOutlined /> Remote
                      </button>
                    </div>
                  </div>
                </div>
                {selectedLocation && (
                  <>
                    <div className="row">
                      <div className="col-md-4 mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                            style={{ fontSize: "medium" }}
                          >
                            Location *
                          </label>
                          <select
                            aria-label="Default select example"
                            name="jobLocationType"
                            value={formData.jobLocationType}
                            onChange={handleChange}
                            className={`form-select ${
                              formDataError.jobLocationTypeError ? "is-invalid" : ""
                            }`}
                          >
                            <option value=""> Please Select </option>
                            <option value="California, Los Angeles">
                              {" "}
                              California, Los Angeles{" "}
                            </option>
                            <option value="Texas"> Texas, Houston </option>
                            <option value="New York"> New York City (NYC) </option>
                            <option value="Florida, Miami"> Florida, Miami </option>
                            <option value="Illinois, Chicago"> Illinois, Chicago </option>
                          </select>
                          {formDataError.jobLocationTypeError && (
                            <div className="invalid-feedback invalid-message">
                              {formDataError.jobLocationTypeError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <div className="form-group">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                            style={{ fontSize: "medium" }}
                          >
                            Job Internal Code *
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Job internal code here"
                            name="jobInternalCode"
                            value={formData.jobInternalCode}
                            onChange={handleChange}
                            className={`form-control ${
                              formDataError.jobInternalCodeError ? "is-invalid" : ""
                            }`}
                          />
                          {formDataError.jobInternalCodeError && (
                            <div className="invalid-feedback invalid-message">
                              {formDataError.jobInternalCodeError}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="col-md-9">
                  <SoftButton
                    variant="outlined"
                    style={{ float: "inline-end" }}
                    color="info"
                    size="small"
                    onClick={handleAddJob}
                  >
                    {isLoading ? "Submitting..." : "Submit"}
                    {isLoading && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </SoftButton>
                </div>
              </div>
            </div>
          )}
          <Footer />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Hiring;
