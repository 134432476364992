import React, { useState, useEffect, useCallback } from "react";
import { Chart } from "react-google-charts";
import DashboardLayout from "../LayoutNavbar/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../LayoutNavbar/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import "./Attendence.scss";
import { Table } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Oval } from "react-loader-spinner";

function EmployeeAttendance() {
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [checkIn, setCheckIn] = useState(false);
  const [checkOut, setCheckout] = useState(false);
  const [history, setHistory] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [checkInTime, setCheckInTime] = useState(null);
  const [attendanceRecords, setAttendanceRecords] = useState([]);
  const [checkInId, setCheckInId] = useState(null);

  useEffect(() => {
    const fetchAttendanceData = async () => {
      try {
        const loginEmail = localStorage.getItem("loginEmail");
        const response = await axios.get(
          `${apiUrl}/api/AttendanceReport/GetAttendanceByEmail?email=${loginEmail}`
        );

        // Ensure the correct property name is used to access the data
        setAttendanceData(response.data.attendanceRecords);
      } catch (error) {
        console.error("Error fetching attendance data:", error);
      }
    };
    fetchAttendanceData();
  }, [apiUrl]);

  function formatDateTime(dateTimeString) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date(dateTimeString);
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutes = date.getMinutes();
    return `${month} ${day < 10 ? "0" : ""}${day}, ${year} ${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${ampm}`;
  }

  const handleCheckIn = () => {
    const checkInDateTime = getLocalDateTime();
    setCheckIn(true);
    setFormData({
      ...formData,
      checkInDateTime,
      status: "Present",
    });
    setCheckInTime(checkInDateTime); // Update check-in time
    setHistory(false);
  };
  const hide = () => {
    setCheckIn(false);
  };
  const CheckOuthide = () => {
    setCheckout(false);
  };

  const handleHistory = async () => {
    setIsLoadingPage(true); // Show full page loader

    try {
      const loginEmail = localStorage.getItem("loginEmail");
      const response = await axios.get(
        `${apiUrl}/api/AttendanceReport/GetAttendanceByEmail?email=${loginEmail}`
      );

      // Ensure the correct property name is used to access the data
      setAttendanceData(response.data.attendanceRecords);
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    } finally {
      setIsLoadingPage(false); // Hide full page loader
    }

    setHistory(true); // Show history component
    setCheckIn(false);
  };

  const handleCheckOut = () => {
    const now = new Date();
    const checkInDate = new Date(checkInTime);
    if (now - checkInDate < 60 * 1000) {
      toast.error("Check Out time is not valid. Please wait for one minute after Check In.");
      return;
    }
    setCheckout(true);
    setFormDataCheckOut({
      ...formDataCheckOut,
      checkOutDateTime: getLocalDateTime(),
    });
    setHistory(false);
    setCheckIn(false);
  };

  const historyhide = () => {
    setHistory(false);
  };
  const getLocalDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // months are 0-indexed
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const [formData, setFormData] = useState({
    checkInDateTime: getLocalDateTime(),
    email: "",
    status: "Present",
    firstName: "",
    lastName: "",
    personalId: "",
    department: "",
  });

  useEffect(() => {
    fetchEmployees();
  }, []);

  async function fetchEmployees() {
    try {
      const loginEmail = localStorage.getItem("loginEmail");
      if (loginEmail) {
        const response = await axios.get(
          `${apiUrl}/api/Job/GetEmployee-JobDataby-Email/${loginEmail}`
        );
        const responseData = response.data;
        const { firstName, lastName, personalId } = responseData.personalInformation;
        // console.warn("Fetched Employee Data:", { firstName, lastName, personalId });
        setFormData((prevData) => ({
          ...prevData,
          firstName,
          lastName,
          personalId,
        }));
      } else {
        console.error("User email not found in local storage");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const email = localStorage.getItem("loginEmail");
        const response = await axios.get(
          `${apiUrl}/api/ExpenseManagement/GetEmployee-department/${email}`
        );

        // Ensure response.data.getemployee is not empty and access the department field
        if (response.data.getemployee.length > 0) {
          const departmentName = response.data.getemployee[0].hiringDepartment;
          setFormData((prevFormData) => ({
            ...prevFormData,
            department: departmentName,
          }));
        } else {
          console.error("No department data found for the logged-in user.");
        }
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    fetchDepartments();
  }, [apiUrl]);

  useEffect(() => {
    const loginEmail = localStorage.getItem("loginEmail");
    if (loginEmail) {
      setFormData((prevState) => ({
        ...prevState,
        email: loginEmail,
      }));
    }
  }, []);
  useEffect(() => {
    const loginEmail = localStorage.getItem("loginEmail");
    if (loginEmail) {
      setFormDataCheckOut((prevState) => ({
        ...prevState,
        email: loginEmail,
      }));
    }
  }, []);

  const handleChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or target is undefined:", e);
      return;
    }

    const { name, value } = e.target;

    // Update formData state
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Clear the error message if the value is not empty
    if (value.trim() !== "") {
      setFormDataError((prevState) => ({
        ...prevState,
        [`${name}Error`]: "",
      }));
    } else {
      // Set the error message if the value is empty
      setFormDataError((prevState) => ({
        ...prevState,
        [`${name}Error`]: `${name} is required.`,
      }));
    }
  };

  const handleAddCheckIn = async () => {
    try {
      setIsLoading(true);
      const updatedFormData = { ...formData };
      const response = await axios.post(
        `${apiUrl}/api/AttendanceReport/AddCheckIn`,
        updatedFormData
      );
      toast.success("Check In successfully");
      setCheckIn(false);
      setHistory(false);
      fetchAttendanceSummary();
      fetchAttendanceRecords();
    } catch (error) {
      console.error("Error Check In:", error);
      toast.error("Failed to Check In");
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      title: "Check In Time",
      dataIndex: "checkInDateTime",
      key: "checkInDateTime",
      render: (text) => formatDateTime(text),
    },
    {
      title: "Check Out Time",
      dataIndex: "checkOutDateTime",
      key: "checkOutDateTime",
      render: (text) => (text === "Not checked out" ? text : formatDateTime(text)),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Worked Duration",
      dataIndex: "workedDuration",
      key: "workedDuration",
    },
  ];

  const [attendanceSummary, setAttendanceSummary] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchAttendanceSummary = useCallback(async () => {
    try {
      // Retrieve email from local storage
      const email = localStorage.getItem("loginEmail");

      if (!email) {
        throw new Error("Email not found in local storage.");
      }

      const response = await axios.get(
        `${apiUrl}/api/AttendanceReport/GetAttendanceSummaryByEmail?email=${encodeURIComponent(
          email
        )}`
      );

      // Update attendanceSummary state
      setAttendanceSummary(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching attendance summary:", error);
    }
  }, [apiUrl]);

  useEffect(() => {
    fetchAttendanceSummary();
  }, [fetchAttendanceSummary]);

  // Define data for the pie chart
  const chartData = [
    ["Status", "Count"],
    ["Present", attendanceSummary ? attendanceSummary.presentCount : 0],
    ["Late Arrival", attendanceSummary ? attendanceSummary.lateArrivalCount : 0],
    ["Absent", attendanceSummary ? attendanceSummary.absentCount : 0],
  ];

  useEffect(() => {
    fetchAttendanceRecords();
  }, []); // Empty dependency array ensures this runs only once on mount

  const fetchAttendanceRecords = async () => {
    try {
      const loginEmail = localStorage.getItem("loginEmail");
      const response = await axios.get(
        `${apiUrl}/api/AttendanceReport/GetAttendanceByEmail?email=${loginEmail}&_=${new Date().getTime()}`
      );

      if (response.data && response.data.attendanceRecords) {
        // Sort records by checkInDateTime to get the latest record first
        const sortedRecords = response.data.attendanceRecords.sort(
          (a, b) => new Date(b.checkInDateTime) - new Date(a.checkInDateTime)
        );

        const records = sortedRecords.map((record) => ({
          checkInId: record.checkInId,
          checkInStatus: record.checkInStatus,
          ...record,
        }));

        setAttendanceRecords(records);

        // Set checkInId from the latest record if available
        if (records.length > 0) {
          setCheckInId(records[0].checkInId);
        } else {
          setCheckInId(null); // No records found, set checkInId to null or handle accordingly
        }
      } else {
        console.error("No attendance records found in the response.");
        // Handle no records found scenario
      }
    } catch (error) {
      console.error("Error fetching attendance records:", error);
      // Handle error if needed
    }
  };

  useEffect(() => {
    if (checkInId !== null) {
      setFormDataCheckOut((prevState) => ({
        ...prevState,
        checkInId: checkInId,
      }));
    }
  }, [checkInId]);

  const [formDataCheckOut, setFormDataCheckOut] = useState({
    checkOutDateTime: getLocalDateTime(),
    email: "", // Assuming you set the email elsewhere in your component
    checkInId: null,
  });

  const handleAddCheckOut = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      // Ensure checkInId is defined and valid before making the POST request
      if (formDataCheckOut.checkInId !== null) {
        const response = await axios.post(
          `${apiUrl}/api/AttendanceReport/AddCheckOut?checkInId=${formDataCheckOut.checkInId}`,
          formDataCheckOut // Ensure formDataCheckOut is correctly defined
        );
        toast.success("Check Out successfully");
        setCheckout(false);
        setHistory(false);
        fetchAttendanceSummary();
        fetchAttendanceRecords();
      } else {
        console.error("No valid checkInId available.");
        toast.error("Failed to Check Out. Invalid checkInId.");
      }
    } catch (error) {
      console.error("Error Check Out:", error);
      toast.error("Failed to Check Out");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <ToastContainer />

        <div className="body flex-grow-1 px-3">
          <div className="card mb-3">
            <div className="card-header" style={{ position: "relative" }}>
              <h5>Attendance Work from home</h5>
            </div>
            <div className="card-body">
              {isLoadingPage ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "400px" }}
                >
                  <Oval height={80} width={80} color="blue" />
                </div>
              ) : (
                <div className="row">
                  <div className="col-md-6" style={{ height: "300px" }}>
                    {!loading && attendanceSummary ? (
                      <Chart
                        chartType="PieChart"
                        data={chartData}
                        options={{
                          pieHole: 0.4,
                          pieSliceText: "value",
                          tooltip: { text: "value" },
                          colors: ["green", "#faae20", "#FF5733"],
                        }}
                        width={"100%"}
                        height={"100%"}
                      />
                    ) : (
                      <span style={{ fontSize: "medium" }}>No Check In yet!</span>
                    )}
                  </div>
                  <div className="btncheckin">
                    {attendanceRecords.length > 0 ? (
                      <React.Fragment>
                        {attendanceRecords[0].checkInStatus === 0 ? (
                          <SoftButton
                            variant="outlined"
                            color="info"
                            size="small"
                            onClick={() => handleCheckOut(attendanceRecords[0].checkInId)}
                          >
                            <i className="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;Check Out
                          </SoftButton>
                        ) : (
                          <SoftButton
                            variant="outlined"
                            color="info"
                            size="small"
                            onClick={() => handleCheckIn(attendanceRecords[0].checkInId)}
                          >
                            <i className="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;Check In
                          </SoftButton>
                        )}
                      </React.Fragment>
                    ) : (
                      <SoftButton
                        variant="outlined"
                        color="info"
                        size="small"
                        onClick={handleCheckIn} // Adjust this according to your requirement
                      >
                        <i className="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;Check In
                      </SoftButton>
                    )}
                  </div>

                  <div className="history">
                    <SoftButton
                      variant="outlined"
                      color="info"
                      size="small"
                      onClick={handleHistory}
                    >
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;History
                    </SoftButton>
                  </div>
                </div>
              )}
            </div>
          </div>
          {checkIn && (
            <div className="card mb-3">
              <div className="card-header" style={{ position: "relative" }}>
                <h5>Check In</h5>
                <button className="btn btn-outline-danger btncancel" onClick={hide}>
                  X
                </button>
              </div>
              <div className="card-body">
                <div className="col-md-5">
                  <div className="mb-3">
                    <label
                      htmlFor="department"
                      className="form-label"
                      style={{ fontSize: "medium" }}
                    >
                      Department *
                    </label>
                    <input
                      type="text"
                      placeholder="Enter department here"
                      disabled
                      name="department"
                      value={formData.department}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInput"
                      className="form-label"
                      style={{ fontSize: "medium" }}
                    >
                      Check In*
                    </label>
                    <input
                      readOnly
                      type="datetime-local"
                      name="checkInDateTime"
                      value={formData.checkInDateTime}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <SoftButton
                    style={{ float: "inline-end" }}
                    variant="outlined"
                    color="info"
                    size="small"
                    onClick={handleAddCheckIn}
                  >
                    <i className="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;
                    {isLoading ? "Submitting..." : "Submit"}
                    {isLoading && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </SoftButton>
                </div>
              </div>
            </div>
          )}
          {history && (
            <div className="card mb-3">
              <div className="card-header" style={{ position: "relative" }}>
                <h5>History</h5>
                <button className="btn btn-outline-danger btncancel" onClick={historyhide}>
                  X
                </button>
              </div>
              <div className="card-body">
                <Table
                  className="table-responsive"
                  bordered
                  dataSource={attendanceData}
                  columns={columns}
                  pagination={{ pageSize: 10 }}
                />
              </div>
            </div>
          )}
          {checkOut && (
            <div className="card mb-3">
              <div className="card-header" style={{ position: "relative" }}>
                <h5>Check Out</h5>
                <button className="btn btn-outline-danger btncancel" onClick={CheckOuthide}>
                  X
                </button>
              </div>
              <div className="card-body">
                <div className="col-md-5">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInput"
                      className="form-label"
                      style={{ fontSize: "medium" }}
                    >
                      Check Out*
                    </label>
                    <input
                      readOnly
                      type="datetime-local"
                      name="checkInDateTime"
                      value={formDataCheckOut.checkOutDateTime}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <SoftButton
                    style={{ float: "inline-end" }}
                    variant="outlined"
                    color="info"
                    size="small"
                    onClick={handleAddCheckOut}
                  >
                    <i className="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;
                    {isLoading ? "Submitting..." : "Submit"}
                    {isLoading && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </SoftButton>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}

export default EmployeeAttendance;
