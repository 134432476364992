import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Select, Checkbox, Button, Spin, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import jsPDF from "jspdf";
import "jspdf-autotable";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "layouts/authentication/components/Footer";
const { Option } = Select;

const EmployeeFinance = () => {
  const [token, setToken] = useState("");
  const [employees, setEmployees] = useState([]);
  const [month, setMonth] = useState(new Date().getMonth() + 1); // Current month (1-12)
  const [year, setYear] = useState(new Date().getFullYear()); // Current year
  const [loading, setLoading] = useState(false); // Initial loading state

  useEffect(() => {
    const authenticateAndFetchData = async () => {
      try {
        setLoading(true);
        const tokenResponse = await axios.post(
          "https://soft.recipe4foodies.com/api/Account/Login",
          {
            email: "humanresources@gmail.com",
            password: "password",
          }
        );
        const jwtToken = tokenResponse.data.token;
        setToken(jwtToken);

        // Once token is obtained, fetch employee data for the initial month and year
        await fetchEmployees(month, year, jwtToken);
      } catch (error) {
        console.error("Error during authentication or data fetch:", error);
      } finally {
        setLoading(false); // Set loading to false after authentication and data fetch is complete
      }
    };

    authenticateAndFetchData();
  }, []); // Dependency array is empty to run only once on mount

  const fetchEmployees = async (selectedMonth, selectedYear, token) => {
    // Set loading to true when fetching data starts
    try {
      setLoading(true);
      const response = await axios.get(
        `https://soft.recipe4foodies.com/api/Finance/Employees-List?month=${selectedMonth}&year=${selectedYear}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEmployees(response.data.employees);
    } catch (error) {
      console.error("Error fetching employees:", error);
    } finally {
      setLoading(false); // Set loading to false when fetching data completes
    }
  };

  useEffect(() => {
    // Fetch employees whenever month or year changes
    if (token) {
      fetchEmployees(month, year, token);
    }
  }, [token, month, year]); // Dependencies include token, month, and year

  const handleDownload = async (employeeId) => {

    try {
      const response = await axios.get(
        `https://soft.recipe4foodies.com/api/Finance/Report?employeeId=${employeeId}&month=${month}&year=${year}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const reportData = response.data;

      // Generate PDF report
      const doc = new jsPDF();
      doc.setFontSize(18);
      doc.text("Employee Finance Report", 14, 22);

      const lineY = 28;
      const lineWidth = 0.5;
      const lineColor = [192, 192, 192];
      doc.setLineWidth(lineWidth);
      doc.setDrawColor(...lineColor);
      doc.line(14, lineY, 200, lineY);

      const marginTop = 3;
      const marginTopEOM = 2;
      doc.setFontSize(12);
      doc.text(`Employee ID: ${reportData.employeeId}`, 14, 32 + marginTop);
      doc.text(`Name: ${reportData.name}`, 14, 38 + marginTop);
      doc.text(`Email: ${reportData.email}`, 14, 44 + marginTop);
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const monthName = monthNames[month - 1];
      doc.text(`Report Date: ${monthName} ${year}`, 14, 51 + marginTopEOM);
      doc.text(`Total Sessions: ${reportData.totalSessions}`, 14, 59);
      doc.text(`Payment Status: ${reportData.isPaid ? "Paid" : "Un-Paid"}`, 14, 65);
      doc.text(`Role: ${reportData.role}`, 14, 72);

      // Check if there are sessions to display
      if (reportData.sessions && reportData.sessions.length > 0) {
        const headers = [["Session Mode", "Session Date & Time", "Session Amount"]];

        const data =
          reportData.sessions && reportData.sessions.length > 0
            ? reportData.sessions.map((session, index) => [
                session.sessionMode,
                new Date(session.dateTime).toLocaleString(),
                session.amount,
              ])
            : [["", ""]];

        // Calculate the total session amount
        const totalSessionAmount = reportData.sessions.reduce(
          (sum, session) => sum + session.amount,
          0
        );

        // Add a row for the total session amount
        data.push(["Total Amount", "", totalSessionAmount]);

        doc.autoTable({
          startY: 78,
          head: headers,
          body: data,
          headerStyles: {
            fillColor: [192, 192, 192],
            fontSize: 8,
          },
          columnStyles: {
            6: { cellWidth: "auto" }, // Adjust the width of the 'Session' column as needed
          },
          styles: {
            fontSize: 8,
            overflow: "linebreak", // Enable line breaks within cells
          },
        });
      } else {
        // If there are no sessions, display a message
        const headers = [["Name", "Email", "Session"]];
        const data = [[reportData.name, reportData.email, "No session data available"]];

        doc.autoTable({
          startY: 68,
          head: headers,
          body: data,
          headerStyles: {
            fillColor: [192, 192, 192],
            fontSize: 8,
          },
          columnStyles: {
            6: { cellWidth: "auto" }, // Adjust the width of the 'Session' column as needed
          },
          styles: {
            fontSize: 8,
            overflow: "linebreak", // Enable line breaks within cells
          },
        });
      }

      doc.save(
        `employee_finance_report_${reportData.name}_${reportData.month}_${reportData.year}.pdf`
      );
    } catch (error) {
      console.error(`Error downloading data for employee ID ${employeeId}:`, error);
      message.error(`Failed to download data for employee ID ${employeeId}.`);
    }
  };

  const handleCheckboxChange = async (employeeId, checked) => {

    try {
      setLoading(true); // Set loading to true when marking as paid starts
      const response = await axios.put(
        `https://soft.recipe4foodies.com/api/Finance/Mark-As-Paid?employeeId=${employeeId}&month=${month}&year=${year}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Update the local state to reflect the change
      const updatedEmployees = employees.map((emp) =>
        emp.employeeId === employeeId ? { ...emp, isPaid: true } : emp
      );
      setEmployees(updatedEmployees);
    } catch (error) {
      console.error(`Error marking employee ID ${employeeId} as paid:`, error);
      message.error(`Failed to mark employee ID ${employeeId} as paid.`);
    } finally {
      setLoading(false); // Set loading to false when marking as paid completes
    }
  };

  const columns = [
    { title: "Employee ID", dataIndex: "employeeId", key: "employeeId" },
    { title: "Name", dataIndex: "name", key: "name", render: (text) => text || "N/A" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Role", dataIndex: "role", key: "role" },
    {
      title: "Paid",
      dataIndex: "isPaid",
      key: "isPaid",
      render: (text) => (text ? "Paid" : "Un-Paid"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Checkbox
          checked={record.isPaid}
          disabled={record.isPaid} // Disable checkbox if already paid
          onChange={(e) => handleCheckboxChange(record.employeeId, e.target.checked)}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Button type="link" onClick={() => handleDownload(record.employeeId)}>
          <DownloadOutlined />
        </Button>
      ),
    },
  ];

  const handleMultipleDownload = async () => {
    try {
      const response = await axios.get(
        `https://soft.recipe4foodies.com/api/Finance/Employees-Report?month=${month}&year=${year}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const reportData = response.data;

      if (!reportData || reportData.length === 0) {
        console.error("No report data available");
        message.error("No report data available.");
        return;
      }

      // Generate PDF report
      const doc = new jsPDF();
      doc.setFontSize(18);
      doc.text("Employee Finance Report", 14, 22);

      const lineY = 28;
      const lineWidth = 0.5;
      const lineColor = [192, 192, 192];
      doc.setLineWidth(lineWidth);
      doc.setDrawColor(...lineColor);
      doc.line(14, lineY, 200, lineY);
      const marginTopEOM = 2;
      doc.setFontSize(12);
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const monthName = monthNames[month - 1];
      doc.text(`Report Date: ${monthName}, ${year}`, 14, 35 + marginTopEOM);
      const headers = [
        [
          "Employee ID",
          "Name",
          "Email",
          "Role",
          "Total Sessions",
          "Total Amount",
          "Payment Status",
        ],
      ];

      // Correctly map the data from the reportData array
      const data = reportData.map((employee) => [
        employee.employeeId,
        employee.name,
        employee.email,
        employee.role,
        employee.totalSessions,
        employee.amount,
        employee.isPaid ? "Paid" : "Un-Paid",
      ]);

      doc.autoTable({
        startY: 40,
        head: headers,
        body: data,
        headerStyles: {
          fillColor: [192, 192, 192],
          fontSize: 8,
        },
        columnStyles: {
          6: { cellWidth: "auto" }, // Adjust the width of the 'Session' column as needed
        },
        styles: {
          fontSize: 8,
          overflow: "linebreak", // Enable line breaks within cells
        },
      });

      doc.save(`employee_finance_report_${monthName}_${year}.pdf`);
    } catch (error) {
      console.error("Error downloading data:", error);
      message.error("Failed to download data.");
    }
  };

  return (
    <DashboardLayout>
      <Spin
        spinning={loading}
        size="large"
        style={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <DashboardNavbar />
          <div className="body flex-grow-1 px-3">
            <div className="card">
              <div className="card-header" style={{ position: "relative" }}>
                <h5>Finance Report</h5>
              </div>
              <div className="card-body">
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Select
                    value={month}
                    onChange={(value) => setMonth(value)}
                    style={{ width: 120, marginRight: 20 }}
                  >
                    {Array.from({ length: 12 }, (_, i) => (
                      <Option key={i + 1} value={i + 1}>{`Month ${i + 1}`}</Option>
                    ))}
                  </Select>
                  <Select
                    value={year}
                    onChange={(value) => setYear(value)}
                    style={{ width: 120, marginRight: 20 }}
                  >
                    {Array.from({ length: 10 }, (_, i) => (
                      <Option key={2020 + i} value={2020 + i}>{`Year ${2020 + i}`}</Option>
                    ))}
                  </Select>
                  <SoftBox color="text" style={{ marginRight: "20px" }}>
                    <div style={{ display: "inline-block" }}>
                      <SoftButton
                        variant="gradient"
                        color="info"
                        size="small"
                        onClick={handleMultipleDownload}
                      >
                        <i className="fa fa-download" aria-hidden="true"></i>&nbsp;Download Finance
                        Reports
                      </SoftButton>
                    </div>
                  </SoftBox>
                </div>
                <br></br>
                <div className="row mb-3">
                  <Table
                    className="table-responsive"
                    columns={columns}
                    dataSource={employees}
                    rowKey="employeeId"
                  />
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </Spin>
    </DashboardLayout>
  );
};

export default EmployeeFinance;
