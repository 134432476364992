import React, { useEffect, useState } from "react";
import "antd/dist/antd";
import "../../Onboarding/Onboarding.scss";
import SoftButton from "components/SoftButton";
import hiringOnboardingService from "../../APIServices/ViewCandidateService/HiringOnboardingService";
import { ToastContainer } from "react-toastify";
import OnboardingTasks from "./OnboardingTasks"; // Import the new component
import { Modal } from "antd";

const HiringOnboarding = () => {
  const { formData, setFormData, handleSubmit, handleNavigation, isSubmitted } =
    hiringOnboardingService();
  const [isModalVisible, setIsModalVisible] = useState(false);
  useEffect(() => {
    // Show modal if already onboarded
    if (isSubmitted) {
      setIsModalVisible(true);
    }
  }, [isSubmitted]);
  return (
    <>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <ToastContainer />
        <div className="body flex-grow-1 px-3">
          <Modal
            visible={isModalVisible}
            closable={false}
            footer={null}
            centered
            bodyStyle={{ height: "80px", textAlign: "center" }}
          >
            <p style={{ textAlign: "center", fontSize: "18px" }}>
              You have already onboarded this employee
            </p>
            {isSubmitted && (
              <SoftButton
                variant="outlined"
                style={{ float: "inline-end", marginLeft: "10px" }}
                color="info"
                size="small"
                onClick={handleNavigation}
              >
                Personal Info
              </SoftButton>
            )}
          </Modal>
          {!isSubmitted && (
            <>
              <div className="card">
                <div className="card-header" style={{ position: "relative" }}>
                  <b>New Hire Onboarding Checklist</b>
                </div>
                <div className="card-body">
                  <OnboardingTasks formData={formData} setFormData={setFormData} />

                  <div className="col-md-12">
                    {!isSubmitted && (
                      <SoftButton
                        variant="outlined"
                        style={{ float: "inline-end" }}
                        color="info"
                        size="small"
                        onClick={handleSubmit}
                      >
                        Submit
                      </SoftButton>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default HiringOnboarding;
