import React, { useState, useEffect } from "react";
import DashboardLayout from "../empNavbar/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../empNavbar/Navbars/DashboardNavbar";
import axios from "axios";
import SoftButton from "components/SoftButton";
import { FileOpenOutlined } from "@mui/icons-material";
import { Table, Button, Space, Tooltip } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ShortListEmployeeForm() {
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [employeeInterview, setEmployeeInterview] = useState([]);
  const [employeeContract, setEmployeeContract] = useState([]);
  const [selectedPdf, setSelectedPdf] = useState("");
  const [showNavbar, setShowNavbar] = useState(true);
  const [submittedEmployees, setSubmittedEmployees] = useState({});
  const [isTimeSubmitted, setIsTimeSubmitted] = useState(false);
  const [isContractSubmitted, setIsContractSubmitted] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const [isAllFilesSelected, setIsAllFilesSelected] = useState(false);
  const requiredFileTypes = [
    "ndafile",
    "ncafile",
    "employeeAgreement",
    "i_nine",
    "w_four",
    "offerLetter",
  ];

  useEffect(() => {
    fetchEmployeesByEmail();
    fetchEmployeeData();
    fetchContractByEmail();
    fetchSelectedDateTime;
  }, []);

  const [selectedTime, setSelectedTime] = useState("");

  const [employeeData, setEmployeeData] = useState(null);

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const userEmail = localStorage.getItem("userEmail");
        const response = await axios.get(
          `${apiUrl}/api/EmployeeJobApplication/GetEmployeeByEmail/${userEmail}`
        );
        setEmployeeData(response.data); // Store the API data in state
      } catch (error) {
        console.error("Error fetching employee data", error);
      }
    };

    fetchEmployeeData();
  }, []);

  useEffect(() => {
    if (employeeInterview.length > 0) {
      setSelectedTime(employeeInterview[0].firstDateTime);
    }
  }, [employeeInterview]);

  async function fetchEmployeeData() {
    try {
      const userEmail = localStorage.getItem("userEmail");
      if (userEmail) {
        const response = await axios.get(
          `${apiUrl}/api/EmployeeInterview/GetInterviewByEmployeeEmail/${userEmail}`
        );
        const responseData = response.data;

        // Update state with the fetched employee data
        setEmployeeInterview(responseData);
      } else {
        throw new Error("User email not found in local storage");
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
      // setError(error);
    } finally {
      // setLoading(false);
    }
  }

  async function employeeAction(email, firstName, lastName, forJobApply, password) {
    try {
      // Display warning message about sending
      // displayWarningMessage();
      debugger;
      const emailData = {
        email: email,
        firstName: firstName,
        lastName: lastName,
        forJobApply: forJobApply,
        password: password,
      };

      await axios.post(`${apiUrl}/api/EmployeeJobApplication/EmployeeAction`, emailData);
      toast.success("Your response send to the organization thanks");
    } catch (error) {
      console.error("Error sending email:", error);
    }
  }

  async function contractAcceptance(
    email,
    employeeId,
    status,
    ndafile,
    ncafile,
    employeeAgreement,
    i_nine,
    w_four,
    offerLetter
  ) {
    try {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("employeeId", employeeId);
      formData.append("status", status);
      formData.append("ndafile", ndafile);
      formData.append("ncafile", ncafile);
      formData.append("employeeAgreement", employeeAgreement);
      formData.append("i_nine", i_nine);
      formData.append("w_four", w_four);
      formData.append("offerLetter", offerLetter);
      await axios.post(`${apiUrl}/api/EmployeeInterview/Contract-Acceptance`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      toast.success("Your contract response sended");
      setIsContractSubmitted(true);
    } catch (error) {
      toast.error("Please select all files");
    }
  }

  const handleShortlistClick = async (employee) => {
    await employeeAction(
      employee.email,
      employee.firstName,
      employee.lastName,
      employee.forJobApply,
      employee.password
    );
    setSubmittedEmployees((prev) => ({ ...prev, [employee.id]: true }));
  };

  async function fetchEmployeesByEmail() {
    try {
      const userEmail = localStorage.getItem("userEmail");
      if (userEmail) {
        const response = await axios.get(
          `${apiUrl}/api/EmployeeJobApplication/GetEmployeeByEmail/${userEmail}`
        );
        const responseData = response.data;

        if (Array.isArray(responseData)) {
          setEmployeeDetails(responseData);
        } else {
          setEmployeeDetails([responseData]);
        }
      } else {
        console.error("User email not found in local storage");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async function fetchContractByEmail() {
    try {
      const userEmail = localStorage.getItem("userEmail");
      if (userEmail) {
        const response = await axios.get(
          `${apiUrl}/api/EmployeeInterview/GetContractByEmail/${userEmail}`
        );
        const responseData = response.data;
        const modifiedResponseData = {
          ...responseData,
          contractId: responseData.contractId,
        };
        // setEmployeeContract(responseData);
        setEmployeeContract(modifiedResponseData);
      } else {
        console.error("User email not found in local storage");
      }
    } catch (error) {
      console.error("Error fetching contract data:", error);
    }
  }

  function formatDateTime(dateTimeString) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date(dateTimeString);
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutes = date.getMinutes();
    return `${month} ${day < 10 ? "0" : ""}${day}, ${year} ${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${ampm}`;
  }

  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [selectedDateTime, setSelectedDateTime] = useState(null);

  // Function to handle when the user selects a time
  const handleTimeSelect = (employeeId, event) => {
    setSelectedEmployeeId(employeeId); // Store the selected employee ID
    setSelectedDateTime(event.target.value); // Update the selectedTime state with the value of the selected time
  };

  const handleSelectedTime = async () => {
    try {
      if (!selectedDateTime) {
        toast.error("please select time.");
        return;
      }

      // Make API call to send the selected time to the backend
      await axios.post(`${apiUrl}/api/EmployeeInterview/selected-schedule`, {
        employeeId: selectedEmployeeId,
        selectedDateTime: selectedDateTime,
      });
      toast.success("Your Interview Schedule is send to the Organization. Thanks!");
      setIsTimeSubmitted(true);
      localStorage.setItem("istimeSubmitted", true);
    } catch (error) {
      console.error("Error handling interview:", error);
    }
  };

  const [selectedDateTimeshow, setSelectedDateTimeshow] = useState(null);
  debugger;
  async function fetchSelectedDateTime() {
    try {
      debugger;
      const userEmail = localStorage.getItem("userEmail");
      if (userEmail) {
        const response = await axios.get(
          `${apiUrl}/api/EmployeeInterview/GetSelectedDateByEmployeeId/${userEmail}`
        );
        const responseData = response.data;

        if (responseData) {
          setSelectedDateTimeshow([responseData]); // Wrap responseData inside an array
        } else {
          setSelectedDateTimeshow([]); // No data found
        }
      } else {
        throw new Error("User email not found in local storage");
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
      // setError(error);
    } finally {
      // setLoading(false);
    }
  }
  const handlePdfPreview = async (fileUrl) => {
    try {
      // Get the file extension
      const extension = fileUrl.split(".").pop().toLowerCase();

      if (extension === "pdf") {
        // If PDF, set the selected PDF state and open modal for preview
        setSelectedPdf(fileUrl);
        setShowNavbar(false);
        const modal = new bootstrap.Modal(document.getElementById("staticBackdrop"));
        modal.show();
      } else {
        // If not PDF, download the file
        window.open(fileUrl, "_blank");
      }
    } catch (error) {
      console.error("Error handling file:", error);
    }
  };

  const handleContractChange = (e, fileType) => {
    const file = e.target.files[0];
    const allowedExtensions = [".pdf", ".PDF", ".docx", ".DOCX", ".doc", ".DOC"];

    if (file) {
      const fileExtension = file.name.split(".").pop();
      if (!allowedExtensions.includes("." + fileExtension)) {
        alert("Unsupported file type. Only PDF and DOCX files are allowed.");
        return;
      }

      setEmployeeContract((prev) => {
        const updatedContract = {
          ...prev,
          [fileType]: file,
        };

        // Check if all required files are selected
        const allFilesSelected = requiredFileTypes.some((type) => updatedContract[type] != null);

        console.log("Updated Contract:", updatedContract);
        console.log("All Files Selected:", allFilesSelected);

        // Update state and ensure the component re-renders
        setIsAllFilesSelected(allFilesSelected);
        return updatedContract;
      });
    }
  };

  const columns = [
    {
      title: "File Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Download/Preview",
      key: "download",
      render: (record) => (
        <Space size="middle">
          <FileOpenOutlined
            onClick={() => handlePdfPreview(`${apiUrl}/${record.file}`)}
            style={{ cursor: "pointer" }}
          />
        </Space>
      ),
    },
    {
      title: "Upload",
      key: "upload",
      render: (record) => (
        <Space size="middle">
          <input
            type="file"
            onChange={(e) => handleContractChange(e, record.key)}
            accept=".pdf,.PDF,.docx,.DOCX,.doc,.DOC"
          />
        </Space>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        {showNavbar && <DashboardNavbar />}
        <div className="body flex-grow-1 px-3">
          <ToastContainer />
          <div className="card">
            <div className="card-header" style={{ position: "relative" }}>
              <h5>Interview Invitation</h5>
            </div>
            <div className="card-body">
              {employeeInterview &&
              employeeInterview.firstDateTime &&
              !employeeContract.ndafile &&
              !employeeContract.ncafile &&
              !employeeContract.employeeAgreement &&
              !employeeContract.i_nine &&
              !employeeContract.w_four ? (
                <div key={employeeInterview.interviewId} className="row">
                  {employeeInterview.status === 3 ? (
                    <>
                      {!isTimeSubmitted ? (
                        <>
                          <p style={{ fontSize: "medium" }}>
                            Dear{" "}
                            <strong>
                              {employeeInterview.firstName + " " + employeeInterview.lastName}
                            </strong>
                            , please select a time so that we can call you for an interview.
                          </p>
                          <div>
                            <input
                              type="radio"
                              id="firstDateTime"
                              name="interviewTime"
                              value={employeeInterview.firstDateTime}
                              checked={selectedTime === employeeInterview.firstDateTime}
                              onChange={(event) => {
                                setSelectedTime(event.target.value);
                                handleTimeSelect(employeeInterview.employeeId, event);
                              }}
                              style={{ marginRight: "10px" }}
                            />
                            <label htmlFor="firstDateTime" style={{ fontSize: "medium" }}>
                              First Time :: ({formatDateTime(employeeInterview.firstDateTime)})
                            </label>
                          </div>
                          <div>
                            <input
                              type="radio"
                              id="secondDateTime"
                              name="interviewTime"
                              value={employeeInterview.secondDateTime}
                              onChange={(event) =>
                                handleTimeSelect(employeeInterview.employeeId, event)
                              }
                              style={{ marginRight: "10px" }}
                            />
                            <label htmlFor="secondDateTime" style={{ fontSize: "medium" }}>
                              Second Time :: ({formatDateTime(employeeInterview.secondDateTime)})
                            </label>
                          </div>
                          <div>
                            <input
                              type="radio"
                              id="thirdDateTime"
                              name="interviewTime"
                              value={employeeInterview.thirdDateTime}
                              onChange={(event) =>
                                handleTimeSelect(employeeInterview.employeeId, event)
                              }
                              style={{ marginRight: "10px" }}
                            />
                            <label htmlFor="thirdDateTime" style={{ fontSize: "medium" }}>
                              Third Time :: ({formatDateTime(employeeInterview.thirdDateTime)})
                            </label>
                          </div>
                          <div className="col-md-10">
                            <SoftButton
                              variant="outlined"
                              color="info"
                              size="small"
                              onClick={handleSelectedTime}
                              disabled={isTimeSubmitted}
                            >
                              Submit
                            </SoftButton>
                          </div>
                        </>
                      ) : (
                        <div className="col-md-10">
                          <p style={{ fontSize: "medium", marginTop: "10px", color: "orange" }}>
                            Your interview schedule has been sent to the Datagonomix team. Please
                            wait for the next step.
                          </p>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="col-md-10">
                      <p style={{ fontSize: "medium", marginTop: "10px", color: "orange" }}>
                        Your interview schedule has been sent to the Datagonomix team. Please wait
                        for the next step.
                      </p>
                    </div>
                  )}
                </div>
              ) : null}

              {!employeeContract.ndafile &&
              !employeeContract.ncafile &&
              !employeeContract.employeeAgreement &&
              !employeeContract.i_nine &&
              !employeeContract.w_four &&
              !employeeContract.offerLetter &&
              (!employeeInterview || !employeeInterview.firstDateTime) ? (
                <div>
                  {employeeDetails.map((employee) => (
                    <div key={employee.key} className="row">
                      {employee.status === 1 ? (
                        <>
                          {!submittedEmployees[employee.id] ? (
                            <>
                              <div className="col-md-10">
                                <div className="form-group">
                                  <p style={{ fontSize: "medium" }}>
                                    Dear{" "}
                                    <strong>
                                      {employee.firstName} {employee.lastName}
                                    </strong>
                                    ,<br />
                                    <br />I hope this message finds you well.<br></br>
                                    <br></br>I am writing to inform you that you have been selected
                                    for an interview for the <strong>{employee.forJobApply}</strong>{" "}
                                    role at Datagonomix on{" "}
                                    <strong>{formatDateTime(employee.dateAvailable)}</strong>. Your
                                    qualifications and experience have impressed us, and we believe
                                    you would make a valuable addition to our team. To proceed with
                                    the interview process, we kindly request your acceptance of this
                                    invitation. Once confirmed, we will provide you with further
                                    details regarding the next steps and scheduling.
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-10">
                                <SoftButton
                                  variant="outlined"
                                  color="info"
                                  size="small"
                                  onClick={() => handleShortlistClick(employee)}
                                  disabled={submittedEmployees[employee.id]}
                                >
                                  Submit
                                </SoftButton>
                              </div>
                            </>
                          ) : (
                            <div className="col-md-10">
                              <p style={{ fontSize: "medium", color: "orange", marginTop: "10px" }}>
                                Your request has been sent to the Datagonomix team. Please wait for
                                the next interview step.
                              </p>
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="col-md-10">
                          <p style={{ fontSize: "medium", color: "orange" }}>
                            Your request has been sent to the Datagonomix team. Please wait for the
                            next interview step.
                          </p>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : null}

              {employeeContract && (
                <div className="row">
                  {employeeContract.employeeJobApplication?.status === 6 ? (
                    <>
                      {!isContractSubmitted ? (
                        <>
                          <div className="row mb-3">
                            <Table
                              className="table-responsive"
                              dataSource={[
                                employeeContract.ndafile && {
                                  key: "ndafile",
                                  name: "NDA File",
                                  file: employeeContract.ndafile,
                                },
                                employeeContract.ncafile && {
                                  key: "ncafile",
                                  name: "NCA File",
                                  file: employeeContract.ncafile,
                                },
                                employeeContract.employeeAgreement && {
                                  key: "employeeAgreement",
                                  name: "Employee Agreement",
                                  file: employeeContract.employeeAgreement,
                                },
                                employeeContract.i_nine && {
                                  key: "i_nine",
                                  name: "I-9 File",
                                  file: employeeContract.i_nine,
                                },
                                employeeContract.w_four && {
                                  key: "w_four",
                                  name: "W-9 File",
                                  file: employeeContract.w_four,
                                },
                                employeeContract.offerLetter && {
                                  key: "offerLetter",
                                  name: "Offer Letter",
                                  file: employeeContract.offerLetter,
                                },
                              ].filter(Boolean)} // Remove null entries
                              pagination={false}
                              columns={columns}
                            />
                          </div>

                          <div className="col-md-12">
                            <Tooltip
                              title={!isAllFilesSelected ? "Please select files" : ""}
                              disableHoverListener={isAllFilesSelected || isContractSubmitted} // Prevent tooltip when not needed
                              placement="top" // Adjust placement as needed
                            >
                              <span>
                                <Button
                                  variant="outlined"
                                  type="primary"
                                  disabled={!isAllFilesSelected || isContractSubmitted}
                                  onClick={() =>
                                    contractAcceptance(
                                      employeeDetails[0].email,
                                      employeeDetails[0].employeeId,
                                      employeeContract.status,
                                      employeeContract.ndafile,
                                      employeeContract.ncafile,
                                      employeeContract.employeeAgreement,
                                      employeeContract.i_nine,
                                      employeeContract.w_four,
                                      employeeContract.offerLetter
                                    )
                                  }
                                >
                                  Submit
                                </Button>
                              </span>
                            </Tooltip>
                          </div>
                        </>
                      ) : (
                        <div className="col-md-12">
                          <p style={{ fontSize: "medium", color: "orange" }}>
                            Your files have been sent to the Datagonomix team. Please wait for the
                            next step.
                          </p>
                        </div>
                      )}
                    </>
                  ) : (
                    ![1, 2, 3, 4, 5].includes(employeeData?.status) && ( // Hide message if status is 1, 2, 3, 4, or 5
                      <div className="col-md-12">
                        <p style={{ fontSize: "medium", color: "orange" }}>
                          Your files have been sent to the Datagonomix team. Please wait for the
                          next step.
                        </p>
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <embed src={selectedPdf} type="application/pdf" width="100%" height="450px" />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setShowNavbar(true)}
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default ShortListEmployeeForm;
