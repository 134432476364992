import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function ComplainceService() {
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    policyName: "",
    policyAction: null,
  });

  const handleChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or target is undefined:", e);
      return;
    }

    const { name, value } = e.target;

    // Clear the error message if the value is not empty
    if (value.trim() !== "") {
      setFormDataError((prevState) => ({
        ...prevState,
        [`${name}Error`]: "",
      }));
    } else {
      // Set the error message if the value is empty
      setFormDataError((prevState) => ({
        ...prevState,
        [`${name}Error`]: `${name} is required.`,
      }));
    }

    // Update compensation field to $0 if budgetApproved is 'No'
    if (name === "budgetApproved" && value === "No") {
      setFormData({ ...formData, [name]: value, compensation: "$0" });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleUploadDocChange = (e) => {
    const file = e.target.files[0];
    const allowedExtensions = [".pdf", ".PDF", ".docx", ".DOCX", ".doc", ".DOC"];

    if (!file) {
      setFormDataError({ policyActionError: "Cover Letter is required." });
      return;
    }

    const fileExtension = file.name.split(".").pop();
    if (!allowedExtensions.includes("." + fileExtension)) {
      setFormDataError({
        policyActionError: "Unsupported file type. Only PDF and DOCX files are allowed.",
      });
      setFormData({ ...formData, policyAction: null }); // Update the state with null for policyAction
      return;
    }

    setFormDataError({ policyActionError: "" });
    setFormData({ ...formData, policyAction: file }); // Update the state with the selected file for policyAction
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateValidation(formData, setFormDataError);
    if (isValid) {
      const formDataToSend = new FormData();
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }

      try {
        setIsLoading(true);
        const response = await fetch(`${apiUrl}/api/Complaince/Add-Complaince`, {
          method: "POST",
          body: formDataToSend,
        });
        if (response.ok) {
          toast.success("Complaince submitted successfully!");
          navigate("/compliance");
        } else {
          const responseData = await response.json();
          toast.error("Failed to submit Complaince. Please try again");
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const [formDataError, setFormDataError] = useState({
    policyNameError: "",
    policyActionError: null,
  });

  const clearFields = () => {
    // Reset the form data to initial values
    setFormData({
      policyName: "",
      policyAction: null,
    });
  };

  function validateValidation(formData, setFormDataError) {
    let hasError = false;

    // Check each field for emptiness or invalidity
    Object.keys(formData).forEach((fieldName) => {
      if (
        (typeof formData[fieldName] === "string" &&
          (!formData[fieldName] || !formData[fieldName].trim())) || // For string fields
        (typeof formData[fieldName] === "number" &&
          (isNaN(formData[fieldName]) || formData[fieldName] === null)) // For numeric fields
      ) {
        const fieldNameWithSpaces = fieldName
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase());
        setFormDataError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: `${fieldNameWithSpaces} is required.`,
        }));
        hasError = true;
      } else {
        // Clear the error message if the field is not empty
        setFormDataError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: "", // Clear the error message
        }));
      }
    });

    // Check if any error occurred
    if (hasError) {
      return false;
    }

    // Check if all fields are filled out
    const isAllFieldsFilled = Object.values(formData).every(
      (value) => value !== null && value !== undefined && value !== ""
    );

    if (!isAllFieldsFilled) {
      // If any field is empty, display an error message
      setFormDataError((prevState) => ({
        ...prevState,
        genericError: "Please fill out all fields.",
      }));
      return false;
    }

    return true;
  }

  return {
    formData,
    setFormData,
    formDataError,
    setFormDataError,
    handleChange,
    handleUploadDocChange,
    clearFields,
    validateValidation,
    isLoading,
    handleSubmit,
  };
}

export default ComplainceService;
