import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import SoftButton from "components/SoftButton";
import { Delete, Edit } from "@mui/icons-material";

function hiringProcessService() {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [disableJobType, setDisableJobType] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [userName, setUserName] = useState("");
  const [childManagers, setChildManagers] = useState("");
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const loginType = localStorage.getItem("loginType");

  useEffect(() => {
    fetchData();
    fetchEmployees();
  }, []);

  const [formData, setFormData] = useState({
    postingJobTitle: "",
    budgetApproved: "Yes",
    jobStatus: "",
    recruiter: "",
    hiringLead: "",
    department: "",
    employementType: "",
    maximumExperience: "",
    compensation: "",
    jobLocationType: "",
    jobInternalCode: "",
    jobDescription:
      "We provides equal employment opportunities to all employees and applicants for employment and prohibits discrimination and harassment of any type. Datagonomix considers applicants for employment without regard to race, color, religion, age, sex, national origin, disability status, genetic information, protected veteran status, sexual orientation, gender, gender identity or expression, or any other characteristic protected by federal, state, provincial or local laws.",
    skills: [],
  });

  const handleChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or target is undefined:", e);
      return;
    }

    const { name, value } = e.target;

    // Clear the error message if the value is not empty
    if (value.trim() !== "") {
      setFormDataError((prevState) => ({
        ...prevState,
        [`${name}Error`]: "",
      }));
    } else {
      // Set the error message if the value is empty
      setFormDataError((prevState) => ({
        ...prevState,
        [`${name}Error`]: `${name} is required.`,
      }));
    }

    // Update compensation field to $0 if budgetApproved is 'No'
    if (name === "budgetApproved" && value === "No") {
      setFormData({ ...formData, [name]: value, compensation: "$0" });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleJobDescriptionChange = (content) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      jobDescription: content,
    }));

    // Check if the content is empty and update the error message accordingly
    if (!content.trim()) {
      setFormDataError((prevState) => ({
        ...prevState,
        jobDescriptionError: "Job Description is required.",
      }));
    } else {
      setFormDataError((prevState) => ({
        ...prevState,
        jobDescriptionError: "",
      }));
    }
  };

  const [formDataError, setFormDataError] = useState({
    postingJobTitleError: "",
    budgetApprovedError: "",
    jobStatusError: "",
    hiringLeadError: "",
    departmentError: "",
    employementTypeError: "",
    maximumExperienceError: "",
    compensationError: "",
    jobLocationTypeError: "",
    // jobTypeError: "",
    jobInternalCodeError: "",
    jobDescriptionError: "",
    skillsError: "",
  });

  const clearFields = () => {
    // Reset the form data to initial values
    setFormData({
      postingJobTitle: "",
      budgetApproved: "",
      jobStatus: "",
      hiringLead: "",
      department: "",
      employementType: "",
      maximumExperience: "",
      jobDescription: "",
    });
  };

  function validateValidation(formData, setFormDataError) {
    let hasError = false;
  
    // Check each field for emptiness or invalidity
    Object.keys(formData).forEach((fieldName) => {
      if (
        (typeof formData[fieldName] === "string" && (!formData[fieldName] || !formData[fieldName].trim())) || // For string fields
        (typeof formData[fieldName] === "number" && (isNaN(formData[fieldName]) || formData[fieldName] === null)) || // For numeric fields
        (Array.isArray(formData[fieldName]) && formData[fieldName].length === 0) // For array fields like skills
      ) {
        const fieldNameWithSpaces = fieldName
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase());
        setFormDataError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: `${fieldNameWithSpaces} is required.`,
        }));
        hasError = true;
      } else {
        // Clear the error message if the field is not empty
        setFormDataError((prevState) => ({
          ...prevState,
          [`${fieldName}Error`]: "", // Clear the error message
        }));
      }
    });
  
    // Check if any error occurred
    if (hasError) {
      return false;
    }
  
    // Check if all fields are filled out
    const isAllFieldsFilled = Object.values(formData).every(
      (value) => value !== null && value !== undefined && value !== ""
    );
  
    if (!isAllFieldsFilled) {
      // If any field is empty, display an error message
      setFormDataError((prevState) => ({
        ...prevState,
        genericError: "Please fill out all fields.",
      }));
      return false;
    }
  
    return true;
  }
  

  const handleShow = () => {
    setShow(false);
    setSelectedJob(null); // Reset selected job when showing form
  };

  const handleAddJob = async () => {
    if (!selectedJob) {
      // Adding a new job
      const isValid = validateValidation(formData, setFormDataError);
      if (isValid) {
        try {
          setIsLoading(true);
          const updatedFormData = {
            ...formData,
            jobType: selectedLocation,
            skills: formData.skills ? formData.skills : [], // Convert array to comma-separated string if necessary
          };
          const response = await axios.post(
            `${apiUrl}/api/HiringProcess/AddPosting-Job`,
            updatedFormData
          );
          clearFields();
          toast.success("Job added successfully");
          setShow(true);
          await fetchData();
        } catch (error) {
          toast.error("Failed to add job.");
          console.error("Error adding job:", error);
        } finally {
          setIsLoading(false);
          setLoading(false);
        }
      }
    } else {
      // Editing an existing job
      try {
        setIsLoading(true);
        const updatedFormData = {
          ...formData,
          skills: formData.skills ? formData.skills : [],
        };
        const response = await axios.put(
          `${apiUrl}/api/HiringProcess/EditPosting-Job/${selectedJob.postingJobId}`,
          updatedFormData
        );
        clearFields();
        toast.success("Job updated successfully");
        setShow(true);
        await fetchData();
      } catch (error) {
        toast.error("Failed to update job.");
        console.error("Error updating job:", error);
      } finally {
        setIsLoading(false);
        setLoading(false);
      }
    }
  };

  const handleEdit = (job) => {
    setSelectedJob(job);
    setFormData({
      ...job,
      skills: job.skills ? job.skills.split(",").map((skill) => skill.trim()) : []
    });
    setShow(false);
  };
  

  const handleDelete = async (postingJobId) => {
    try {
      const confirmed = window.confirm("Are you sure you want to terminate this employee?");
      if (!confirmed) {
        return;
      }
      const response = await axios.delete(
        `${apiUrl}/api/HiringProcess/Post-Termination/${postingJobId}`
      );
      fetchData();
      toast.success("Post successfully terminated.");
    } catch (error) {
      toast.error("Failed to terminate job.");
    }
  };

  const handleLocationSelection = (location) => {
    setSelectedLocation(location);
    setDisableJobType(true); // Disable job type when a location is selected
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/HiringProcess/Getposting-Detail`);
      const responseData = response.data;
      const postingDetail = responseData.postingDetail;

      // Generate rows based on the fetched data
      const updatedRows = postingDetail.map((info, index) => {
        // Function to format the date and time
        function formatDateTime(dateTimeString) {
          const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          const date = new Date(dateTimeString);
          const month = months[date.getMonth()];
          const day = date.getDate();
          const year = date.getFullYear();
          let hours = date.getHours();
          const ampm = hours >= 12 ? "PM" : "AM";
          hours = hours % 12;
          hours = hours ? hours : 12;
          const minutes = date.getMinutes();
          return `${month} ${day < 10 ? "0" : ""}${day}, ${year} ${hours}:${
            minutes < 10 ? "0" : ""
          }${minutes} ${ampm}`;
        }

        return {
          key: info.postingJobId.toString(),
          PositionName: `${info.postingJobTitle}`,
          Department: `${info.department}`,
          CreatedOn: <span>{formatDateTime(info.createdOn)}</span>,
          Status: info.jobStatus,
          Skills: info.skills, // Now this will be a string
          ViewCandidate: (
            <SoftButton
              variant="outlined"
              style={{ float: "inline-end" }}
              color="info"
              size="small"
              // onClick={handleShowViewCandidate}
            >
              <Link to={`/viewcandidate/${info.postingJobId}`}>View Candidate</Link>
            </SoftButton>
          ),
          Action: (
            <span
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Edit className="hiOutlined" onClick={() => handleEdit(info)} />
              <Delete className="hiOutlined" onClick={() => handleDelete(info.postingJobId)} />
            </span>
          ),
        };
      });

      // Update dataSource state
      setDataSource(updatedRows);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Stop loading after fetching data
    }
  };

  useEffect(() => {
    const fetchUserNameByEmail = async (email, loginType) => {
      try {
        const endpoint = loginType === "HR" ? "Auth/GetUserByEmail" : "ChildManager/GetChildManagerByEmail";
        const response = await axios.get(`${apiUrl}/api/${endpoint}/${encodeURIComponent(email)}`);
        if (response.status === 200) {
          const { userName } = response.data;
          setFormData((prevFormData) => ({
            ...prevFormData,
            recruiter: userName,
          }));
          setUserName(userName);
        } else {
          console.error("Unexpected status code:", response.status);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchChildManagers = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/ChildManager/GetAllChildManagers`);
        if (response.status === 200) {
          setChildManagers(response.data);
        } else {
          console.error("Unexpected status code:", response.status);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const email = localStorage.getItem("hremail");
    if (email) {
      fetchUserNameByEmail(email, loginType);
    }

    if (loginType === "HR") {
      fetchChildManagers();
    }
  }, [loginType, setFormData]);

  async function fetchEmployees() {
    try {
      const response = await axios.get(`${apiUrl}/api/PersonalInformation/Get-All`);
      const responseData = response.data;
      const personalInfo = responseData.personalInfo;

      const updatedRows = personalInfo.map((info) => ({
        name: info.firstName && info.lastName ? `${info.firstName} ${info.lastName}` : null,
      }));

      setEmployeeDetails(updatedRows);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  return {
    formData,
    setFormData,
    formDataError,
    setFormDataError,
    handleChange,
    clearFields,
    validateValidation,
    handleJobDescriptionChange,
    dataSource,
    loading,
    handleLocationSelection,
    handleShow,
    handleAddJob,
    show,
    isLoading,
    selectedLocation,
    userName,
    employeeDetails,
    setFormData,
    childManagers
  };
}

export default hiringProcessService;
