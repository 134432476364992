import React, { useEffect, useState } from "react";
import axios from "axios";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Table, Collapse, Form, Input, Button, Upload, message, Space } from "antd";
import { FileOpenOutlined } from "@mui/icons-material";
import SoftButton from "components/SoftButton";
import moment from "moment"; // Import moment library for date validation
import Footer from "layouts/authentication/components/Footer";

const { Panel } = Collapse;
const { Item } = Form;

export default function SalarySlip() {
  const [items, setItems] = useState([]);
  const [salarySlips, setSalarySlips] = useState([]);
  const [showFields, setShowFields] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    personalId: "",
    department: "",
    fromDate: "",
    endDate: "",
    salarySlip: null,
  });
  const [formErrors, setFormErrors] = useState({
    fromDate: false,
    endDate: false,
    salarySlip: false,
    endDateInvalid: false, // New state for end date validation
  });
  const [formDirty, setFormDirty] = useState({
    fromDate: false,
    endDate: false,
    salarySlip: false,
  });
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchPersonalInformation();
  }, []);

  const fetchPersonalInformation = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/PersonalInformation/Get-All-PersonalInfo`);
      if (!Array.isArray(response.data)) {
        throw new Error("Invalid personal information data format");
      }

      const personalInfo = response.data;

      const enhancedPersonalInfo = await Promise.all(
        personalInfo.map(async (info) => {
          const department = await fetchDepartmentByEmployeeId(info.employeeId);
          return {
            ...info,
            department: department,
          };
        })
      );

      setItems(enhancedPersonalInfo);

      // Select the first item for setting form data initially
      if (enhancedPersonalInfo.length > 0) {
        const firstInfo = enhancedPersonalInfo[0];
        setFormData({
          email: firstInfo.email,
          firstName: firstInfo.firstName,
          lastName: firstInfo.lastName,
          personalId: firstInfo.personalId.toString(),
          department: firstInfo.department,
          fromDate: "",
          endDate: "",
          salarySlip: null,
        });
      }
    } catch (error) {
      console.error("Error fetching personal information data:", error);
    }
  };

  const fetchDepartmentByEmployeeId = async (employeeId) => {
    try {
      const departmentResponse = await axios.get(
        `${apiUrl}/api/PersonalInformation/Get-Department-By-EmployeeId/${employeeId}`
      );
      return departmentResponse.data;
    } catch (error) {
      console.error(`Error fetching department for employee ID ${employeeId}:`, error);
      return null;
    }
  };

  const handleUploadDocChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      salarySlip: file,
    });
    setFormErrors({
      ...formErrors,
      salarySlip: !file, // Set error if file is not selected
    });
  };

  const handleChange = (key, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: value,
    }));

    // Mark field as dirty to show validation message on submit
    setFormDirty({
      ...formDirty,
      [key]: true,
    });

    // Validate dates on change
    let newFormErrors = { ...formErrors };
    if (key === "fromDate") {
      newFormErrors.fromDate = !isValidDate(value);
      if (isValidDate(value) && isValidDate(formData.endDate)) {
        newFormErrors.endDateInvalid = new Date(value) > new Date(formData.endDate);
      }
    } else if (key === "endDate") {
      newFormErrors.endDate = !isValidDate(value);
      if (isValidDate(value) && isValidDate(formData.fromDate)) {
        newFormErrors.endDateInvalid = new Date(formData.fromDate) > new Date(value);
      }
    }
    setFormErrors(newFormErrors);
  };

  const isValidDate = (dateString) => {
    return moment(dateString, "YYYY-MM-DD", true).isValid();
  };

  const handleSubmit = async () => {
    let hasError = false;

    // Validate form before submission
    if (!isValidDate(formData.fromDate) || !isValidDate(formData.endDate)) {
      hasError = true;
      setFormErrors({
        fromDate: !isValidDate(formData.fromDate),
        endDate: !isValidDate(formData.endDate),
        salarySlip: !formData.salarySlip,
        endDateInvalid: new Date(formData.fromDate) > new Date(formData.endDate),
      });
    }

    if (!formData.salarySlip) {
      hasError = true;
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        salarySlip: true,
      }));
    }

    if (formErrors.endDateInvalid) {
      hasError = true;
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        endDateInvalid: true,
      }));
    }

    if (hasError) {
      message.error("Please fill out all required fields correctly.");
      return;
    }

    // Check for duplicate dates
    const isDuplicate = salarySlips.some(
      (slip) => slip.fromDate === formData.fromDate && slip.endDate === formData.endDate
    );

    if (isDuplicate) {
      message.error("A salary slip with the same from date and end date already exists.");
      return;
    }

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("email", formData.email);
      formDataToSend.append("firstName", formData.firstName);
      formDataToSend.append("lastName", formData.lastName);
      formDataToSend.append("personalId", formData.personalId);
      formDataToSend.append("department", formData.department);
      formDataToSend.append("fromDate", formData.fromDate);
      formDataToSend.append("endDate", formData.endDate);
      formDataToSend.append("salarySlip", formData.salarySlip);

      const response = await axios.post(`${apiUrl}/api/SalarySlip/Salary-Slip`, formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      message.success("Salary slip uploaded successfully");
      setShowFields(false);
      await fetchSalarySlipsByPersonalId(formData.personalId);
    } catch (error) {
      console.error("Error uploading salary slip:", error);
      message.error("Failed to upload salary slip.");
    }
  };

  const handleCancel = () => {
    setShowFields(false);
  };

  const onPanelClick = async (personalId) => {
    // Find the selected employee's data
    const selectedEmployee = items.find((item) => item.personalId === personalId);
    if (selectedEmployee) {
      // Update form data, preserving existing fromDate and endDate
      setFormData({
        ...formData,
        email: selectedEmployee.email,
        firstName: selectedEmployee.firstName,
        lastName: selectedEmployee.lastName,
        personalId: selectedEmployee.personalId.toString(),
        department: selectedEmployee.department,
      });
      await fetchSalarySlipsByPersonalId(selectedEmployee.personalId);
    }
  };

  const fetchSalarySlipsByPersonalId = async (personalId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/SalarySlip/GetSalarySlipByPersonalId/${personalId}`
      );
      const slipsWithUrl = response.data.result.map((slip) => ({
        ...slip,
        fileUrl: `${apiUrl}/${slip.file}`,
      }));
      setSalarySlips(slipsWithUrl);
    } catch (error) {
      console.error("Error fetching salary slips:", error);
      setSalarySlips([]);
    }
  };

  const salarySlipColumns = [
    { title: "Department", dataIndex: "department", key: "department" },
    {
      title: "Date Range",
      key: "dateRange",
      render: (text, record) => {
        const fromDate = new Date(record.fromDate).toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
        });
        const endDate = new Date(record.endDate).toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
        });
        return <span>{`${fromDate} - ${endDate}`}</span>;
      },
    },
    {
      title: "Salary Slip",
      dataIndex: "salarySlip",
      key: "salarySlip",
      render: (fileUrl) => (
        <Space size="middle">
          <FileOpenOutlined
            onClick={() => handlePdfPreview(`${apiUrl}/${fileUrl}`)}
            style={{ cursor: "pointer" }}
          />
        </Space>
      ),
    },
  ];

  const handlePdfPreview = (fileUrl) => {
    window.open(fileUrl, "_blank"); // Opens the file in a new tab
  };

  const handleShowFields = () => {
    setShowFields(true);
  };

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <div className="card">
            <div className="card-header" style={{ position: "relative" }}>
              <h5>Salary Slip</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <Collapse accordion>
                  {items.map((info, index) => (
                    <Panel
                      key={index}
                      header={`${info.firstName} ${info.lastName} (Employee ID: ${info.employeeId})`}
                      onClick={() => onPanelClick(info.personalId)}
                    >
                      {showFields && (
                        <>
                          <Form layout="vertical">
                            <div className="row">
                              <div className="col-md-5">
                                <Form.Item hidden label="Personal Id">
                                  <Input
                                    type="text"
                                    name="personalId"
                                    disabled
                                    value={formData.personalId}
                                    onChange={(e) =>
                                      setFormData({ ...formData, personalId: e.target.value })
                                    }
                                  />
                                </Form.Item>
                              </div>

                              <div className="col-md-4">
                                <Item hidden label="First Name">
                                  <Input
                                    type="text"
                                    name="firstName"
                                    disabled
                                    value={formData.firstName}
                                    onChange={(e) =>
                                      setFormData({ ...formData, firstName: e.target.value })
                                    }
                                  />
                                </Item>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-5">
                                <Item hidden label="Last Name">
                                  <Input
                                    type="text"
                                    name="lastName"
                                    disabled
                                    value={formData.lastName}
                                    onChange={(e) =>
                                      setFormData({ ...formData, lastName: e.target.value })
                                    }
                                  />
                                </Item>
                              </div>
                              <div className="col-md-4">
                                <Item hidden label="Email">
                                  <Input
                                    type="text"
                                    name="email"
                                    disabled
                                    value={formData.email}
                                    onChange={(e) =>
                                      setFormData({ ...formData, email: e.target.value })
                                    }
                                  />
                                </Item>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-5">
                                <Item
                                  label="From Date"
                                  validateStatus={formErrors.fromDate ? "error" : ""}
                                  help={
                                    formErrors.fromDate
                                      ? "Please enter a valid date (YYYY-MM-DD)"
                                      : ""
                                  }
                                >
                                  <Input
                                    type="date"
                                    name="fromDate"
                                    value={formData.fromDate}
                                    onChange={(e) => handleChange("fromDate", e.target.value)}
                                  />
                                </Item>
                              </div>
                              <div className="col-md-4">
                                <Item
                                  label="To Date"
                                  validateStatus={
                                    formErrors.endDate || formErrors.endDateInvalid ? "error" : ""
                                  }
                                  help={
                                    formErrors.endDate
                                      ? "Please enter a valid date (YYYY-MM-DD)"
                                      : formErrors.endDateInvalid
                                      ? "End date cannot be earlier than start date"
                                      : ""
                                  }
                                >
                                  <Input
                                    type="date"
                                    name="endDate"
                                    value={formData.endDate}
                                    onChange={(e) => handleChange("endDate", e.target.value)}
                                  />
                                </Item>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-9">
                                <Item
                                  label="Salary Slip"
                                  validateStatus={formErrors.salarySlip ? "error" : ""}
                                  help={
                                    formErrors.salarySlip ? "Please upload a salary slip file" : ""
                                  }
                                >
                                  <Input
                                    type="file"
                                    accept=".pdf,.PDF,.docx,.DOCX,.doc,.DOC"
                                    onChange={handleUploadDocChange}
                                  />
                                </Item>
                              </div>
                            </div>
                            <Item>
                              <div className="row">
                                <div className="col-md-9">
                                  <SoftButton
                                    variant="outlined"
                                    style={{ float: "right" }}
                                    color="info"
                                    size="small"
                                    onClick={handleSubmit}
                                  >
                                    Submit
                                  </SoftButton>
                                  <SoftButton
                                    variant="outlined"
                                    style={{ float: "right", marginRight: "10px" }}
                                    color="error"
                                    size="small"
                                    onClick={handleCancel}
                                  >
                                    Cancel
                                  </SoftButton>
                                </div>
                              </div>
                            </Item>
                          </Form>
                        </>
                      )}
                      {!showFields && (
                        <>
                          <SoftButton
                            variant="outlined"
                            style={{ float: "right", marginRight: "10px" }}
                            color="info"
                            size="small"
                            onClick={handleShowFields}
                          >
                            Upload Salary Slip
                          </SoftButton>
                          <br></br>
                          <Table
                            dataSource={salarySlips}
                            columns={salarySlipColumns}
                            rowKey="id"
                            style={{ marginTop: "20px" }}
                          />
                        </>
                      )}
                    </Panel>
                  ))}
                </Collapse>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </DashboardLayout>
  );
}
