import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "./Personal.scss";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { CInputGroup } from "@coreui/react";
import countryList from "react-select-country-list";
import SoftButton from "components/SoftButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import choosefile from "../../assets/images/uploadfile.png";
import personalInformationService from "../APIServices/PersonalInformationService";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { updatePersonalInfo } from "../Services/Actions/actions";
import Footer from "layouts/authentication/components/Footer";

function Personal({ onNext, employeeDetail }) {
  const {
    formData,
    setFormData,
    formDataError,
    setFormDataError,
    handleChange,
    handleImageChange,
    validatePersonalValidation,
    employeeData,
    handleemployeeDataChange,
  } = personalInformationService();

  const { empId } = useParams(); // Get employeeId from URL params
  const [employeeExists, setEmployeeExists] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const checkEmployeeExists = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/api/PersonalInformation/CheckEmployeeExistsInPersonal/${empId}`
        );
        if (response.ok) {
          const data = await response.json();
          setEmployeeExists(data.exists);
        } else {
          // Handle non-200 status codes
          const errorData = await response.json();
          throw new Error(`HTTP ${response.status} - ${errorData.message}`);
        }
      } catch (error) {
        console.error("Error checking employee existence:", error);
        toast.error("Error checking employee existence");
      }
    };

    if (empId) {
      checkEmployeeExists();
    }
  }, [empId]);

  useEffect(() => {
    // Populate formData with employee data when editing
    if (employeeDetail) {
      const { personalInfo, job, emergency, benefits } = employeeDetail;
      setFormData({
        ...formData,
        personalId: personalInfo.personalId,
        image: personalInfo.image,
        employeeNumber: personalInfo.employeeNumber,
        firstName: personalInfo.firstName,
        lastName: personalInfo.lastName,
        DOB: personalInfo.dob,
        yourAge: personalInfo.yourAge,
        disabilities: personalInfo.disabilities,
        gender: personalInfo.gender,
        maritalStatus: personalInfo.maritalStatus,
        SSN: personalInfo.ssn,
        nationality: personalInfo.nationality,
        bankAccountNumebr: personalInfo.bankAccountNumebr,
        streetAddress1: personalInfo.streetAddress1,
        streetAddress2: personalInfo.streetAddress2,
        city: personalInfo.city,
        state: personalInfo.state,
        postalCode: personalInfo.postalCode,
        country: personalInfo.country,
        workPhone: personalInfo.workPhone, // Assuming you want emergency contact's work phone
        EXT: personalInfo.ext,
        mobileno: personalInfo.mobileno,
        homePhone: personalInfo.homePhone,
        workEmail: personalInfo.workEmail,
        homeEmail: personalInfo.homeEmail,
        institution: personalInfo.institution,
        degree: personalInfo.degree,
        major_specialization: personalInfo.major_specialization,
        CGPA: personalInfo.cgpa,
        startDate: personalInfo.startDate,
        endDate: personalInfo.endDate,
      });
    }
  }, [employeeDetail]);

  const dispatch = useDispatch();
  const handleNext = () => {
    const isValid = validatePersonalValidation(formData, setFormDataError);
    if (isValid == false) {
      toast.error("Fields Required");
    } else {
      dispatch(updatePersonalInfo(formData, employeeData));
      onNext();
    }
  };

  const options = useMemo(() => countryList().getData(), []);
  const [showAdd, setShowAdd] = useState(true);

  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageClick = () => {
    document.getElementById("fileInput").click();
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setSelectedImage(URL.createObjectURL(selectedFile));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const success = await handleNext();
      if (success) {
        onNext();
        toast.success("Data has Been Added Successfully", {
          autoClose: 5000,
          style: { background: "lightgreen", color: "white" },
        });
      }
    } catch (error) {
      toast.error(error.message || "Added failed", {
        autoClose: 5000,
        style: { background: "lightcoral", color: "white" },
      });
    }
  };

  return (
    <div className="body flex-grow-1 px-3">
      {showAdd && (
        <div className="card">
          <div className="card-header" style={{ position: "relative" }}>
            <h5>Personal Info</h5>
          </div>
          <div className="card-body">
            {employeeExists ? (
              <span style={{ fontSize: "medium", color: "red" }}>
                This personal data Already Added
              </span>
            ) : (
              <>
                <form onSubmit={onSubmit} encType="multipart/form-data">
                  <ToastContainer />
                  <div className="row">
                    <label className="mb-3" style={{ textAlign: "left" }}>
                      <i className="fa fa-info-circle" aria-hidden="true"></i>&nbsp;BASIC
                      INFORMATION
                    </label>
                    <input
                      type="hidden"
                      value={formData.personalId}
                      className="form-control"
                      onChange={handleChange}
                    />
                    <div className="row">
                      <div className="col-md-4 mb-3">
                        <div className="mb-3">
                          <div style={{ position: "relative", display: "inline-block" }}>
                            <label
                              htmlFor="exampleInput"
                              className="form-label"
                              style={{ fontSize: "medium" }}
                            >
                              Upload File *
                            </label>
                            <br></br>
                            {selectedImage ? (
                              <img
                                src={selectedImage}
                                alt="Selected Avatar"
                                style={{
                                  height: "100px",
                                  width: "100px",
                                  cursor: "pointer",
                                  borderRadius: "50%",
                                }}
                              />
                            ) : (
                              <img
                                src={choosefile}
                                alt="Avatar"
                                style={{
                                  height: "100px",
                                  width: "100px",
                                  cursor: "pointer",
                                  border: `2px dashed ${
                                    formDataError.imageError ? "red" : "black"
                                  }`,
                                }}
                                onClick={handleImageClick}
                              />
                            )}
                            <input
                              type="file"
                              id="fileInput"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                opacity: 0,
                                cursor: "pointer",
                              }}
                              onChange={(e) => {
                                handleFileChange(e);
                                handleImageChange(e);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-5 mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInput"
                            className="form-label"
                            style={{ fontSize: "medium" }}
                          >
                            Employee Number *
                          </label>
                          <input
                            type="text"
                            name="employeeNumber"
                            value={formData.employeeNumber}
                            onChange={handleChange}
                            className={`form-control ${
                              formDataError.employeeNumberError ? "is-invalid" : ""
                            }`}
                            disabled // Disable the input field
                          />
                          {formDataError.employeeNumberError && (
                            <div className="invalid-feedback invalid-message">
                              {formDataError.employeeNumberError}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-5 mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInput"
                            className="form-label"
                            style={{ fontSize: "medium" }}
                          >
                            First Name *
                          </label>

                          <input
                            type="text"
                            placeholder="Enter first name"
                            name="firstName"
                            readOnly
                            value={employeeData.firstName}
                            onChange={handleemployeeDataChange}
                            className={`form-control ${
                              formDataError.firstNameError ? "is-invalid" : ""
                            }`}
                          />
                          {formDataError.firstNameError && (
                            <div className="invalid-feedback invalid-message">
                              {formDataError.firstNameError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInput"
                            className="form-label"
                            style={{ fontSize: "medium" }}
                          >
                            Last Name *
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Last Name here"
                            name="lastName"
                            readOnly
                            value={employeeData.lastName}
                            onChange={handleemployeeDataChange}
                            className={`form-control ${
                              formDataError.lastNameError ? "is-invalid" : ""
                            }`}
                          />
                          {formDataError.lastNameError && (
                            <div className="invalid-feedback invalid-message">
                              {formDataError.lastNameError}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-5 mb-3">
                        <div className="form-group">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                            style={{ fontSize: "medium" }}
                          >
                            Date of Birth
                          </label>
                          <br></br>
                          <input
                            name="dob"
                            readOnly
                            value={employeeData.dob}
                            onChange={handleChange}
                            type="date"
                            className={`form-control ${formDataError.DOBError ? "is-invalid" : ""}`}
                          />

                          {formDataError.DOBError && (
                            <div className="invalid-feedback invalid-message">
                              {formDataError.DOBError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                            style={{ fontSize: "medium" }}
                          >
                            Disabilities *
                          </label>
                          <select
                            name="disabilities"
                            disabled
                            value={employeeData.disabilities}
                            onChange={handleChange}
                            className={`form-select form-select-sm ${
                              formDataError.disabilitiesError ? "is-invalid" : ""
                            }`}
                            aria-label="Default select example"
                          >
                            <option value=""> Please Select </option>
                            <option value="Mentally"> Mentally </option>
                            <option value="Physically"> Physically </option>
                            <option value="Others"> Others </option>
                          </select>
                          {formDataError.disabilitiesError && (
                            <div className="invalid-feedback invalid-message">
                              {formDataError.disabilitiesError}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-5 mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                            style={{ fontSize: "medium" }}
                          >
                            Gender *
                          </label>
                          <select
                            name="gender"
                            disabled
                            value={employeeData.gender}
                            onChange={handleChange}
                            className={`form-select form-select-sm ${
                              formDataError.genderError ? "is-invalid" : ""
                            }`}
                            aria-label="Default select example"
                          >
                            <option value=""> Please Select </option>
                            <option value="Male"> Male </option>
                            <option value="Female"> Female </option>
                            <option value="UnSpecified"> UnSpecified </option>
                            <option value="Do not wish to disclosed">
                              {" "}
                              Do not wish to disclosed{" "}
                            </option>
                          </select>
                          {formDataError.genderError && (
                            <div className="invalid-feedback invalid-message">
                              {formDataError.genderError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                            style={{ fontSize: "medium" }}
                          >
                            Marital Status *
                          </label>
                          <select
                            name="maritalStatus"
                            disabled
                            value={employeeData.maritalStatus}
                            onChange={handleChange}
                            className={`form-select form-select-sm ${
                              formDataError.maritalStatusError ? "is-invalid" : ""
                            }`}
                            aria-label="Default select example"
                          >
                            <option value=""> Please Select </option>
                            <option value="Single"> Single </option>
                            <option value="Married"> Married </option>
                            <option value="Divorced"> Divorced </option>
                            <option value="Widow"> Widow </option>
                          </select>
                          {formDataError.maritalStatusError && (
                            <div className="invalid-feedback invalid-message">
                              {formDataError.maritalStatusError}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 mb-3">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                          style={{ fontSize: "medium" }}
                        >
                          Nationality
                        </label>
                        <CInputGroup className="mb-3">
                          <select
                            name="nationality"
                            readOnly
                            value={employeeData.nationality}
                            onChange={handleChange}
                            className={`form-control ${
                              formDataError.nationalityError ? "is-invalid" : ""
                            }`}
                            aria-label=".form-select-sm example"
                            defaultValue="US"
                          >
                            <option value="">Please select Nationality</option>
                            {options.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          {formDataError.nationalityError && (
                            <div className="invalid-feedback invalid-message">
                              {formDataError.nationalityError}
                            </div>
                          )}
                        </CInputGroup>
                      </div>
                    </div>
                  </div>
                  <hr></hr>
                  <div className="row">
                    <label className="mb-3" style={{ textAlign: "left" }}>
                      <i className="fa fa-home" aria-hidden="true"></i>&nbsp;ADDRESS
                    </label>
                    <div className="row">
                      <div className="col-md-9 mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlTextarea1"
                            className="form-label"
                            style={{ fontSize: "medium" }}
                          >
                            Street Address
                          </label>
                          <input
                            name="streetAddress"
                            readOnly
                            value={employeeData.streetAddress}
                            onChange={handleChange}
                            type="text"
                            className={`form-control ${
                              formDataError.streetAddressError ? "is-invalid" : ""
                            }`}
                            placeholder="Enter Street Address here"
                          />
                          {formDataError.streetAddress1Error && (
                            <div className="invalid-feedback invalid-message">
                              {formDataError.streetAddressError}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                            style={{ fontSize: "medium" }}
                          >
                            City
                          </label>
                          <input
                            name="city"
                            readOnly
                            value={employeeData.city}
                            onChange={handleChange}
                            type="text"
                            className={`form-control ${
                              formDataError.cityError ? "is-invalid" : ""
                            }`}
                            placeholder="Enter City here"
                          />
                          {formDataError.cityError && (
                            <div className="invalid-feedback invalid-message">
                              {formDataError.cityError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                            style={{ fontSize: "medium" }}
                          >
                            State
                          </label>
                          <input
                            name="state"
                            readOnly
                            value={employeeData.state}
                            onChange={handleChange}
                            type="text"
                            className={`form-control ${
                              formDataError.stateError ? "is-invalid" : ""
                            }`}
                            placeholder="Enter State / Province here"
                          />
                          {formDataError.stateError && (
                            <div className="invalid-feedback invalid-message">
                              {formDataError.stateError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                            style={{ fontSize: "medium" }}
                          >
                            Postal / Zip Code
                          </label>
                          <input
                            name="postalCode"
                            readOnly
                            value={employeeData.postalCode}
                            onChange={handleChange}
                            type="text"
                            className={`form-control ${
                              formDataError.postalCodeError ? "is-invalid" : ""
                            }`}
                            placeholder="Enter Postal / Zip Code here"
                          />
                          {formDataError.postalCodeError && (
                            <div className="invalid-feedback invalid-message">
                              {formDataError.postalCodeError}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-9 mb-3">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                          style={{ fontSize: "medium" }}
                        >
                          Country
                        </label>
                        <CInputGroup className="mb-3">
                          <select
                            name="country"
                            disabled
                            value={employeeData.country}
                            onChange={handleChange}
                            className={`form-select form-select-sm ${
                              formDataError.countryError ? "is-invalid" : ""
                            }`}
                            aria-label=".form-select-sm example"
                            defaultValue="US"
                          >
                            <option value="">Please select</option>
                            {options.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          {formDataError.countryError && (
                            <div className="invalid-feedback invalid-message">
                              {formDataError.countryError}
                            </div>
                          )}
                        </CInputGroup>
                      </div>
                    </div>
                  </div>
                  <hr></hr>
                  <div className="row">
                    <label className="mb-3" style={{ textAlign: "left" }}>
                      <i className="fa fa-phone-square" aria-hidden="true"></i>&nbsp;CONTACT
                    </label>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                            style={{ fontSize: "large" }}
                          >
                            Mobile Phone
                          </label>
                          <CInputGroup className="mb-3">
                            <PhoneInput
                              name="mobileno"
                              disabled
                              value={employeeData.mobileno}
                              // onChange={handleChange}

                              onChange={(value) => handleChange(value, "mobileno")}
                              onBlur={handleChange}
                              className={`col-md-12 ${
                                formDataError.mobilenoError ? "is-invalid" : ""
                              }`}
                              defaultCountry="us"
                              inputStyle={{ width: "420px" }}
                            />
                            {formDataError.mobilenoError && (
                              <div className="invalid-feedback invalid-message">
                                {formDataError.mobilenoError}
                              </div>
                            )}
                          </CInputGroup>
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInput"
                            className="form-label"
                            style={{ fontSize: "medium" }}
                          >
                            EXT *
                          </label>
                          <input
                            name="EXT"
                            value={formData.EXT}
                            onChange={handleChange}
                            type="number"
                            className={`form-control ${formDataError.EXTError ? "is-invalid" : ""}`}
                            placeholder="Enter EXT here"
                          />
                          {formDataError.EXTError && (
                            <div className="invalid-feedback invalid-message">
                              {formDataError.EXTError}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-9 mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInput"
                            className="form-label"
                            style={{ fontSize: "medium" }}
                          >
                            Work Email *
                          </label>
                          <input
                            name="workEmail"
                            value={formData.workEmail}
                            onChange={handleChange}
                            type="text"
                            className={`form-control ${
                              formDataError.workEmailError ? "is-invalid" : ""
                            }`}
                            placeholder="Enter work email here"
                          />
                          {formDataError.workEmailError && (
                            <div className="invalid-feedback invalid-message">
                              {formDataError.workEmailError}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-9 mb-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInput"
                            className="form-label"
                            style={{ fontSize: "medium" }}
                          >
                            Home Email *
                          </label>
                          <input
                            name="email"
                            readOnly
                            value={employeeData.email}
                            onChange={handleChange}
                            type="text"
                            className={`form-control ${
                              formDataError.homeEmailError ? "is-invalid" : ""
                            }`}
                            placeholder="Enter home email here"
                          />
                          {formDataError.emailError && (
                            <div className="invalid-feedback invalid-message">
                              {formDataError.emailError}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-9 mb-3">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                          style={{ fontSize: "medium" }}
                        >
                          Work Authorization US
                          <i
                            className="fa fa-info-circle"
                            title="Are you authorized to work lawfully in the united states for Demand base?"
                            style={{ marginLeft: "5px", cursor: "pointer" }}
                            aria-hidden="true"
                          ></i>
                        </label>
                        <select
                          disabled
                          aria-label="Default select example"
                          name="workAuthorizationUS"
                          value={employeeData.workAuthorizationUS}
                          onChange={handleChange}
                          className="form-select"
                        >
                          <option value=""> Please Select </option>
                          <option value="Yes"> Yes </option>
                          <option value="NO"> No </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-9 mb-3">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                          style={{ fontSize: "medium" }}
                        >
                          Sponsorship Needed
                          <i
                            className="fa fa-info-circle"
                            title="Will you now or in the future require Demandbase to commence ('sponsor') an immigration case in order to employ you (for example, H-1B, OPT, F1, J1, TN or other employment-based immigration case)? This is sometimes called 'sponsorship' for an employment-based visa status."
                            style={{ marginLeft: "5px", cursor: "pointer" }}
                            aria-hidden="true"
                          ></i>
                        </label>
                        <select
                          disabled
                          aria-label="Default select example"
                          name="sponsorshipNeeded"
                          value={employeeData.sponsorshipNeeded}
                          onChange={handleChange}
                          className="form-select"
                        >
                          <option value=""> Please Select </option>
                          <option value="Yes"> Yes </option>
                          <option value="NO"> No </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-9 mb-3">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                          style={{ fontSize: "medium" }}
                        >
                          10 Years Strategic Success
                          <i
                            className="fa fa-info-circle"
                            title="Do you have at least 10 years of demonstrated success developing business strategy and building strategic ecosystem with global enterprises?"
                            style={{ marginLeft: "5px", cursor: "pointer" }}
                            aria-hidden="true"
                          ></i>
                        </label>
                        <select
                          disabled
                          aria-label="Default select example"
                          name="tenYearsStrategicSuccess"
                          value={employeeData.tenYearsStrategicSuccess}
                          onChange={handleChange}
                          className="form-select"
                        >
                          <option value=""> Please Select </option>
                          <option value="Yes"> Yes </option>
                          <option value="NO"> No </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-9 mb-3">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                          style={{ fontSize: "medium" }}
                        >
                          High Value Deal Success
                          <i
                            className="fa fa-info-circle"
                            title="Have you had success facilitating six-to-seven figure deals in large organizations with complex sales cycles?"
                            style={{ marginLeft: "5px", cursor: "pointer" }}
                            aria-hidden="true"
                          ></i>
                        </label>
                        <select
                          disabled
                          aria-label="Default select example"
                          name="highValueDealSuccess"
                          value={employeeData.highValueDealSuccess}
                          onChange={handleChange}
                          className="form-select"
                        >
                          <option value=""> Please Select </option>
                          <option value="Yes"> Yes </option>
                          <option value="NO"> No </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-9 mb-3">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                          style={{ fontSize: "medium" }}
                        >
                          Team Leadership Proven
                          <i
                            className="fa fa-info-circle"
                            title="Do you have a proven ability to hire, mentor and retain a team?"
                            style={{ marginLeft: "5px", cursor: "pointer" }}
                            aria-hidden="true"
                          ></i>
                        </label>
                        <select
                          disabled
                          aria-label="Default select example"
                          name="teamLeadershipProven"
                          value={employeeData.teamLeadershipProven}
                          onChange={handleChange}
                          className="form-select"
                        >
                          <option value=""> Please Select </option>
                          <option value="Yes"> Yes </option>
                          <option value="NO"> No </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-9 mb-3">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                          style={{ fontSize: "medium" }}
                        >
                          Martech/Adtech Experience
                          <i
                            className="fa fa-info-circle"
                            title="Do you have a proven ability to hire, mentor and retain a team?"
                            style={{ marginLeft: "5px", cursor: "pointer" }}
                            aria-hidden="true"
                          ></i>
                        </label>
                        <select
                          disabled
                          aria-label="Default select example"
                          name="martechAdtechExperience"
                          value={employeeData.martechAdtechExperience}
                          onChange={handleChange}
                          className="form-select"
                        >
                          <option value=""> Please Select </option>
                          <option value="Yes"> Yes </option>
                          <option value="NO"> No </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-9 mb-3">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                          style={{ fontSize: "medium" }}
                        >
                          Are you hispanic/Latino? *
                        </label>
                        <select
                          disabled
                          aria-label="Default select example"
                          name="hispanic_Latino"
                          value={employeeData.hispanic_Latino}
                          onChange={handleChange}
                          className="form-select"
                        >
                          <option value=""> Please Select </option>
                          <option value="Yes"> Yes </option>
                          <option value="NO"> No </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-9 mb-3">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                          style={{ fontSize: "medium" }}
                        >
                          Veteran Status *
                          <i
                            className="fa fa-info-circle"
                            title="1: If you believe you belong to any of the categories of protected veterans listed below, please indicate by making the appropriate selection. As a government contractor subject to the Vietnam Era Veterans Readjustment Assistance Act (VEVRAA), we request this information in order to measure the effectiveness of the outreach and positive recruitment efforts we undertake pursuant to VEVRAA. Classification of protected categories is as follows:
                                  2: A 'disabled veteran' is one of the following: a veteran of the U.S. military, ground, naval or air service who is entitled to compensation (or who but for the receipt of military retired pay would be entitled to compensation) under laws administered by the Secretary of Veterans Affairs; or a person who was discharged or released from active duty because of a service-connected disability.
                                  3: A 'recently separated veteran' means any veteran during the three-year period beginning on the date of such veteran's discharge or release from active duty in the U.S. military, ground, naval, or air service.
                                  4: An 'active duty wartime or campaign badge veteran' means a veteran who served on active duty in the U.S. military, ground, naval or air service during a war, or in a campaign or expedition for which a campaign badge has been authorized under the laws administered by the Department of Defense.
                                  5: An 'Armed forces service medal veteran' means a veteran who, while serving on active duty in the U.S. military, ground, naval or air service, participated in a United States military operation for which an Armed Forces service medal was awarded pursuant to Executive Order 12985."
                            style={{ marginLeft: "5px", cursor: "pointer" }}
                            aria-hidden="true"
                          ></i>
                        </label>
                        <select
                          disabled
                          aria-label="Default select example"
                          name="veteranStatus"
                          value={employeeData.veteranStatus}
                          onChange={handleChange}
                          className="form-select"
                        >
                          <option value=""> Please Select </option>
                          <option value="Yes"> Yes </option>
                          <option value="NO"> No </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="col-md-9 mb-3">
                  <SoftButton
                    variant="outlined"
                    style={{ float: "inline-end" }}
                    color="info"
                    size="small"
                    onClick={handleNext}
                    disabled={employeeExists}
                    type="button"
                  >
                    {employeeExists ? "This personal data Already Added" : "Next"}
                  </SoftButton>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}
Personal.propTypes = {
  onNext: PropTypes.func.isRequired,
  employeeDetail: PropTypes.object.isRequired,
};
export default Personal;
