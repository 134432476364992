import React, { useState, useEffect } from "react";
import axios from "axios";
import SoftTypography from "components/SoftTypography";
import { Link } from "react-router-dom";
import { Button } from "antd";

export default function Data() {
  const apiUrl = process.env.REACT_APP_API_URL
  const [columns, setColumns] = useState([
    { title: "Employee Photo", name: "Employee_Photo", align: "center" },
    { title: "Employee", name: "Employee_No", align: "center", width: "10px" },
    { title: "Name", name: "Name", align: "center" },
    { title: "JobTitle", name: "Job_Title", align: "center" },
    { title: "Location", name: "Location", align: "center" },
    { title: "HireDate", name: "Hire_Date", align: "center" },
    { title: "Terminate", name: "Terminate", align: "center" },
  ]);
  const [rows, setRows] = useState([]);

  const [imageKey, setImageKey] = useState(Date.now());

  useEffect(() => {
    fetchData();
  }, [imageKey]);

  async function fetchData() {
    try {
      const response = await axios.get(`${apiUrl}/api/PersonalInformation/Get-All`);
      const responseData = response.data;

      // Extract data from the response
      const personalInfo = responseData.personalInfo;
      const jobs = responseData.job;

      // Generate rows based on the fetched data
      const updatedRows = personalInfo.map((info, index) => {
        const jobData = jobs.find((job) => job.personalId === info.personalId);

        return {
          Employee_Photo: (
            <img
              key={`${info.personalId}_${imageKey}`}
              src={`${apiUrl}/${info.image}`}
              style={{ width: "80px", height: "80px", borderRadius: "50px" }}
              alt=""
            />
          ),
          Employee_No: (
            <SoftTypography variant="caption" color="text" fontWeight="medium">
              {info.employeeNumber}
            </SoftTypography>
          ),
          Name: (
            <SoftTypography variant="caption" color="text" fontWeight="medium">
              <Link to={`/employeeDetail/${info.personalId}`}>
                {`${info.firstName} ${info.lastName}`}
              </Link>
            </SoftTypography>
          ),
          Job_Title: (
            <SoftTypography variant="caption" color="text" fontWeight="medium">
              {jobs[index] ? jobs[index].team : "-"}
            </SoftTypography>
          ),
          Location: (
            <SoftTypography variant="caption" color="text" fontWeight="medium">
              {info.city}, {info.state}
            </SoftTypography>
          ),
          Hire_Date: (
            <SoftTypography variant="caption" color="text" fontWeight="medium">
              {jobData ? jobData.probationStartDate : "-"}
            </SoftTypography>
          ),
          Terminate: (
            <Link to={`/terminate/${info.personalId}`}>
              <Button type="primary">
                Terminate
              </Button>
            </Link>
          ),
        };
      });

      // Update rows state
      setRows(updatedRows);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  return { columns, rows };
}
