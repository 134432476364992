import React, { useState, useEffect } from "react";
import "./Onboarding.scss";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Table } from "antd";
import axios from "axios";
import Footer from "layouts/authentication/components/Footer";

const Onboarding = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/EmployeeJobApplication/GetEmployee-Onboarding-Detail`
        );
        setData(response.data.getemployee);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const statusMapping = {
    4: "Pending",
    5: "In Process",
    7: "In Process",
    8: "Done",
  };

  const columns = [
    { title: "Employee ID", dataIndex: "employeeId", key: "employeeId" },
    {
      title: "Name",
      key: "name",
      render: (text, record) => `${record.firstName} ${record.lastName}`,
    },
    { title: "Gender", dataIndex: "gender", key: "gender" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "For Job Apply", dataIndex: "forJobApply", key: "forJobApply" },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => statusMapping[status] || status,
    },
  ];

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <div className="card">
            <div className="card-header" style={{ position: "relative" }}>
              <b>Onboarding Checklist</b>
            </div>
            <div className="card-body">
              <div className="row">
                <Table columns={columns} dataSource={data} loading={loading} />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Onboarding;
