import { Table } from "antd";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import Footer from "layouts/authentication/components/Footer";

function ChildManager() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "childManagerId",
      key: "childManagerId",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => moment(createdAt).format("MMMM D, YYYY h:mm A"),
    },
    {
      title: "Assign_Role",
      dataIndex: "Assign_Role",
      key: "Assign_Role",
      render: (_, record) => {
        return (
          <span style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Link to={`/assignRole/${record.childManagerId}`}>
              <EyeOutlined className="eyeOutlined" style={{ marginLeft: "10px" }} />
            </Link>
          </span>
        );
      },
    },
  ];

  const [dataSource, setDataSource] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/ChildManager/GetAllChildManagers`);
      setDataSource(response.data);
    } catch (error) {
      console.error("Error fetching child managers:", error);
    }
  };

  const manager = () => {
    navigate("/childManager");
  };

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <div className="card">
            <div className="card-header" style={{ position: "relative" }}>
              <h5>Associate Managers</h5>
            </div>
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-md-12 mb-3">
                  <SoftButton
                    variant="outlined"
                    style={{ float: "inline-end" }}
                    color="info"
                    size="small"
                    onClick={manager}
                  >
                    <i className="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;Add New Manager
                  </SoftButton>
                </div>
                <Table
                  className="table-responsive"
                  bordered
                  dataSource={dataSource}
                  columns={columns}
                  pagination={{ pageSize: 10 }}
                />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default ChildManager;
