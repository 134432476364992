import React, { useState, useEffect, useRef } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Bar } from "react-chartjs-2";
import SoftButton from "components/SoftButton";
import { Table } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WorkingHoursService from "layouts/APIServices/AttendanceReport/WorkingHoursService";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Footer from "layouts/authentication/components/Footer";

function ReportsAttendance() {
  const [checkInData, setCheckInData] = useState([]);
  const [aggregatedData, setAggregatedData] = useState([]);
  const [currentMonth, setCurrentMonth] = useState("January");
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const years = Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [showFields, setShowFields] = useState(true);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("All Departments");

  const {
    formData,
    formDataError,
    handleChange,
    isLoading,
    handleAddWorkingHours,
    showdefinePolicy,
    handleDefinePolicy,
  } = WorkingHoursService();

  const handleViewMonthlyReport = () => {
    setShowFields(false);
  };

  useEffect(() => {
    fetchCheckInData();
  }, []);

  useEffect(() => {
    if (checkInData.length > 0) {
      aggregateCheckInData();
    }
  }, [checkInData, currentMonth, currentYear, selectedDepartment]);

  const fetchCheckInData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/AttendanceReport/getAllCheckIn`); // Update the URL as per your backend endpoint
      setCheckInData(response.data);
    } catch (error) {
      toast.error("Failed to fetch check-in data");
    }
  };

  const aggregateCheckInData = () => {
    const employeeMap = {};
    const monthIndex = new Date(`${currentMonth} 1, ${currentYear}`).getMonth();

    checkInData.forEach((checkIn) => {
      const { personalId, firstName, lastName, department, checkInDateTime, status } = checkIn;
      const checkInDate = new Date(checkInDateTime);

      if (
        checkInDate.getFullYear() === parseInt(currentYear, 10) &&
        checkInDate.getMonth() === monthIndex &&
        (selectedDepartment === "All Departments" || department === selectedDepartment)
      ) {
        if (!employeeMap[personalId]) {
          employeeMap[personalId] = {
            key: personalId,
            personalId,
            firstName,
            lastName,
            department,
            checkInDateTime,
            Present: 0,
            LateArrival: 0,
            Absent: 0,
          };
        }

        if (status === "Present") {
          employeeMap[personalId].Present += 1;
        } else if (status === "Late Arrival") {
          employeeMap[personalId].LateArrival += 1;
        } else if (status === "Absent") {
          employeeMap[personalId].Absent += 1;
        }
      }
    });

    setAggregatedData(Object.values(employeeMap));
  };

  const columns = [
    {
      title: "Employee Id",
      dataIndex: "personalId",
      key: "personalId",
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
    },
    {
      title: "Present",
      dataIndex: "Present",
      key: "Present",
    },
    {
      title: "Late Arrival",
      dataIndex: "LateArrival",
      key: "LateArrival",
    },
    {
      title: "Absent",
      dataIndex: "Absent",
      key: "Absent",
    },
  ];

  const aggregateAttendanceByDay = () => {
    const attendanceByDay = {};

    const getDayName = (dateTimeString) => {
      const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const date = new Date(dateTimeString);
      const dayIndex = date.getDay();
      return days[dayIndex];
    };

    checkInData.forEach((checkIn) => {
      if (selectedDepartment === "All Departments" || checkIn.department === selectedDepartment) {
        const dayName = getDayName(checkIn.checkInDateTime);

        if (!attendanceByDay[dayName]) {
          attendanceByDay[dayName] = {
            Present: 0,
            LateArrival: 0,
            Absent: 0,
          };
        }

        if (checkIn.status === "Present") {
          attendanceByDay[dayName].Present++;
        } else if (checkIn.status === "Late Arrival") {
          attendanceByDay[dayName].LateArrival++;
        } else if (checkIn.status === "Absent") {
          attendanceByDay[dayName].Absent++;
        }
      }
    });

    return attendanceByDay;
  };

  const attendanceByDay = aggregateAttendanceByDay();

  const barChartData = {
    labels: Object.keys(attendanceByDay),
    datasets: [
      {
        label: "Present",
        backgroundColor: "rgba(84, 132, 13, 1)",
        borderColor: "rgba(84, 132, 13, 1)",
        borderWidth: 1,
        barThickness: 20, // Adjust the thickness here (in pixels)
        data: Object.values(attendanceByDay).map((dayData) => dayData.Present),
      },
      {
        label: "Late Arrival",
        backgroundColor: "rgba(254, 164, 59, 1)",
        borderColor: "rgba(254, 164, 59, 1)",
        borderWidth: 1,
        barThickness: 20, // Adjust the thickness here (in pixels)
        data: Object.values(attendanceByDay).map((dayData) => dayData.LateArrival),
      },
      {
        label: "Absent",
        backgroundColor: "rgba(220, 21, 21, 1)",
        borderColor: "rgba(220, 21, 21, 1)",
        borderWidth: 1,
        barThickness: 20, // Adjust the thickness here (in pixels)
        data: Object.values(attendanceByDay).map((dayData) => dayData.Absent),
      },
    ],
  };

  const maxAttendanceCount = Math.max(
    ...Object.values(attendanceByDay).map((dayData) =>
      Math.max(dayData.Present, dayData.LateArrival, dayData.Absent)
    )
  );

  const options = {
    title: {
      display: true,
      fontSize: 15,
      text: "Attendance by Day",
    },
    legend: {
      display: true,
      position: "top",
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            suggestedMax: maxAttendanceCount,
          },
        },
      ],
    },
  };

  const handleDownloadReport = () => {
    const monthIndex = new Date(`${currentMonth} 1, ${currentYear}`).getMonth();
    const filteredData = checkInData.filter((checkIn) => {
      const checkInDate = new Date(checkIn.checkInDateTime);
      return (
        checkInDate.getFullYear() === parseInt(currentYear, 10) &&
        checkInDate.getMonth() === monthIndex &&
        (selectedDepartment === "All Departments" || checkIn.department === selectedDepartment)
      );
    });

    const doc = new jsPDF();

    doc.setFontSize(18);
    doc.text("End of Months Report", 14, 22);

    const lineY = 28; // Adjust the Y-coordinate as needed
    const lineWidth = 0.5;
    const lineColor = [192, 192, 192]; // Gray color for the line
    doc.setLineWidth(lineWidth);
    doc.setDrawColor(...lineColor); // Set line color
    doc.line(14, lineY, 200, lineY); // Adjust the X-coordinate and length as needed

    const marginTop = 3;
    const marginTopEOM = 2;
    doc.setFontSize(12);
    doc.text("Employee: All - All employees selected", 14, 32 + marginTop); // Adjust the Y-coordinate as needed

    // Get the current date
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString(); // Formats the date as MM/DD/YYYY

    doc.text(`End of Months: ${formattedDate}`, 14, 42 + marginTopEOM);
    doc.text("Exceptions: No Deletions", 14, 52);
    doc.text(`Department: ${selectedDepartment}`, 14, 59);

    const headers = [
      ["Employee Id", "First Name", "Last Name", "department", "Check-in DateTime", "Status"],
    ];
    const data = filteredData.map((item) => [
      item.personalId,
      item.firstName,
      item.lastName,
      item.department,
      item.checkInDateTime,
      item.status,
    ]);

    doc.autoTable({
      startY: 62,
      head: headers,
      body: data,
      headerStyles: {
        fillColor: [192, 192, 192], // Gray color for header cells
        fontSize: 8, // Reduce font size for header cells
      },
      styles: {
        fontSize: 8, // Reduce font size for table content
      },
    });

    doc.save(`attendance_report_${currentMonth}_${currentYear}.pdf`);
  };

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/AttendanceReport/Get-All-Departments`);
        const departmentNames = response.data.map((dept) => dept.department);
        setDepartments(departmentNames);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    fetchDepartments();
  }, [apiUrl]);

  const handleDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);
  };

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <ToastContainer />
        <div className="body flex-grow-1 px-3">
          <div className="card" style={{ height: "100%", width: "100%" }}>
            <div className="card-header" style={{ position: "relative" }}>
              <h5>Attendance</h5>
            </div>
            <div className="card-body">
              {showFields && showdefinePolicy && (
                <div className="row">
                  <div className="col-md-12">
                    <SoftButton
                      variant="outlined"
                      style={{ float: "inline-end" }}
                      color="info"
                      size="small"
                      onClick={handleViewMonthlyReport}
                    >
                      View Monthly Report
                    </SoftButton>
                    <SoftButton
                      variant="outlined"
                      style={{ float: "inline-end", marginRight: "10px" }}
                      color="info"
                      size="small"
                      onClick={handleDefinePolicy}
                    >
                      Define Policy
                    </SoftButton>
                  </div>
                  <Bar type="bar" data={barChartData} options={options} />
                </div>
              )}
              {!showFields && (
                <div className="row mb-3">
                  <label className="mb-3" style={{ textAlign: "left" }}>
                    <i className="fa fa-calendar" aria-hidden="true"></i>&nbsp;Monthly Attendance
                    Reports
                  </label>
                  <div className="row">
                    <div className="col-md-2 mb-3">
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        value={currentMonth}
                        onChange={(e) => setCurrentMonth(e.target.value)}
                      >
                        <option disabled>Months</option>
                        <option value="January">January</option>
                        <option value="February">February</option>
                        <option value="March">March</option>
                        <option value="April">April</option>
                        <option value="May">May</option>
                        <option value="June">June</option>
                        <option value="July">July</option>
                        <option value="August">August</option>
                        <option value="September">September</option>
                        <option value="October">October</option>
                        <option value="November">November</option>
                        <option value="December">December</option>
                      </select>
                    </div>
                    <div className="col-md-2 mb-3">
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        value={currentYear}
                        onChange={(e) => setCurrentYear(e.target.value)}
                      >
                        <option disabled>Years</option>
                        {years.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-3 mb-3">
                      <select
                        className="form-select form-select-sm"
                        value={selectedDepartment}
                        onChange={handleDepartmentChange}
                      >
                        <option value="All Departments">All Departments</option>
                        {departments.map((department) => (
                          <option key={department} value={department}>
                            {department}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-5 mb-3">
                      <button
                        className="btn btn-outline-info btn-sm"
                        style={{ float: "inline-end", marginTop: "-5px" }}
                        onClick={handleDownloadReport}
                      >
                        Download Reports
                      </button>
                    </div>
                  </div>
                  <Table
                    className="table-responsive"
                    bordered
                    dataSource={aggregatedData}
                    columns={columns}
                    pagination={{ pageSize: 10 }}
                  />
                </div>
              )}
              {!showdefinePolicy && (
                <>
                  <div className="row">
                    <div className="col-md-5">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInput"
                          className="form-label"
                          style={{ fontSize: "medium" }}
                        >
                          Working Hours From*
                        </label>
                        <input
                          type="time"
                          name="workingHoursFrom"
                          value={formData.workingHoursFrom}
                          onChange={handleChange}
                          className={`form-control ${
                            formDataError.workingHoursFromError ? "is-invalid" : ""
                          }`}
                        />
                        {formDataError.workingHoursFromError && (
                          <div className="invalid-feedback invalid-message">
                            {formDataError.workingHoursFromError}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInput"
                          className="form-label"
                          style={{ fontSize: "medium" }}
                        >
                          Working Hours To*
                        </label>
                        <input
                          type="time"
                          name="workingHoursTo"
                          value={formData.workingHoursTo}
                          onChange={handleChange}
                          className={`form-control ${
                            formDataError.workingHoursToError ? "is-invalid" : ""
                          }`}
                        />
                        {formDataError.workingHoursToError && (
                          <div className="invalid-feedback invalid-message">
                            {formDataError.workingHoursToError}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInput"
                          className="form-label"
                          style={{ fontSize: "medium" }}
                        >
                          Present *
                        </label>
                        <input
                          type="time"
                          name="presentTime"
                          value={formData.presentTime}
                          onChange={handleChange}
                          className={`form-control ${
                            formDataError.presentTimeError ? "is-invalid" : ""
                          }`}
                        />
                        {formDataError.presentTimeError && (
                          <div className="invalid-feedback invalid-message">
                            {formDataError.presentTimeError}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleInput"
                          className="form-label"
                          style={{ fontSize: "medium" }}
                        >
                          Late Arrival *
                        </label>
                        <input
                          type="time"
                          name="lateArrivalTime"
                          value={formData.lateArrivalTime}
                          onChange={handleChange}
                          className={`form-control ${
                            formDataError.lateArrivalTimeError ? "is-invalid" : ""
                          }`}
                        />
                        {formDataError.lateArrivalTimeError && (
                          <div className="invalid-feedback invalid-message">
                            {formDataError.lateArrivalTimeError}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <SoftButton
                      variant="outlined"
                      style={{ float: "inline-end" }}
                      color="info"
                      size="small"
                      onClick={handleAddWorkingHours}
                    >
                      {isLoading ? "Submitting..." : "Submit"}
                      {isLoading && (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                    </SoftButton>
                  </div>
                </>
              )}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default ReportsAttendance;
