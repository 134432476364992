import { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import ivancik from "assets/images/ivancik.jpg";
import axios from "axios";

export default function data() {
  const [trainingData, setTrainingData] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    async function fetchTrainingData() {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.get(`${apiUrl}/api/TrainingDevelopement/Get-Training-Detail`);
        setTrainingData(response.data.trainingDetail);
      } catch (error) {
        console.error("Error fetching training data:", error);
      }
    }

    fetchTrainingData();
  }, []);

  const columns = [
    { title: "Name", name: "Name", align: "left" },
    { title: "Provider", name: "Provider", align: "left" },
    { title: "Mentor", name: "Mentor", align: "center" },
    { title: "Allocated Budget", name: "Allocated_Budget", align: "center" },
    { title: "Start Date", name: "Start_Date", align: "center" },
    { title: "End Date", name: "End_Date", align: "center" },
  ];

  const rows = trainingData.map((training) => ({
    Name: (
      <SoftTypography variant="caption" color="text" fontWeight="medium">
        {training.trainingName}
      </SoftTypography>
    ),
    Provider: (
      <SoftTypography variant="caption" color="text" fontWeight="medium">
        {training.provider}
      </SoftTypography>
    ),
    Mentor: (
      <SoftTypography variant="caption" color="text" fontWeight="medium">
        {training.mentor}
      </SoftTypography>
    ),
    Allocated_Budget: (
      <SoftTypography variant="caption" color="text" fontWeight="medium">
        {training.allocated_budget}
      </SoftTypography>
    ),
    Start_Date: (
      <SoftTypography variant="caption" color="text" fontWeight="medium">
        {training.startDate}
      </SoftTypography>
    ),
    End_Date: (
      <SoftTypography variant="caption" color="text" fontWeight="medium">
        {training.endDate}
      </SoftTypography>
    ),
  }));

  return {
    columns,
    rows,
  };
}
